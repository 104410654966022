// USED IN NODES HPC USAGE / PPI 
import { EmailAlertNodeType } from '../containers/views/CompRep/NodesHPCUsage/types';
import { formatEntries, formatNumber, groupByKey } from '../utils/methods';
import { GeneralDataNodeType, ManageAllocationNodeType, ManageOverallocationNodeType, NodeUsageType, TimePeriodUsageResponseType, TimePeriodUsageType, UsageType } from './types';


export type ReducedDataType = {
  [key: string]: string;
  time_unit: string;
  type: 'node' | 'account';
}

type GroupedByTimeType = {
  [key : string] : ReducedDataType
};

type UsageDataType = {
  [key:string]: string;
}

type TimeUnitType = {name: string; id: string}


export const extractGraphFormatData = (
  nodesWithUsageData: TimePeriodUsageResponseType[],
  withTrimmedData?: UsageType[] | any
): ReducedDataType[] => {
  const extractedData: UsageType[] = withTrimmedData?.length ? withTrimmedData : 
    nodesWithUsageData.map(node => node.data.data[0]);

  const reducedIndividualData: ReducedDataType[] | any = extractedData.map(
    (nodeUsage: UsageType) => {
      return Object.keys(nodeUsage).map((key: string) => { 
        if (key === 'name') return undefined;
        if (key === 'type') return undefined;
        if (key === 'threshold') return undefined;

        return {
          [nodeUsage['id']]: nodeUsage[key],
          time_unit: key,
          type: nodeUsage['type'],
          ['threshold_' + nodeUsage['id']]: nodeUsage['threshold']
        };
      });
    }).flat().filter(e => e !== undefined && e.time_unit !== 'id');

  const groupedByTimeUnitData: GroupedByTimeType = groupByKey(reducedIndividualData, 'time_unit');

  const graphFormattedData: ReducedDataType[] = Object.values(groupedByTimeUnitData).map(
    (group) => Object.assign({}, ...group as any) as ReducedDataType);

  return graphFormattedData;
};

// USED IN MANAGE OVERALLOCATIONS
export const extractManageOverallocationData = (
  generalDataNodes: GeneralDataNodeType[]
): ManageOverallocationNodeType[] => {

  const arrayOfExtractedData: ManageOverallocationNodeType[] = generalDataNodes.map(
    (node: GeneralDataNodeType) => {
      return {
        name: node.name,
        description: node.description,
        category: node.type,
        org_id: node.id,
        allocation: formatNumber(parseInt(node.sbu_allocation)),
        sbu_usage: formatNumber(parseInt(node.sbu_usage)),
        children: node.children,
        sbu_overallocation: formatNumber(parseInt(node.sbu_overallocation)),
        sbu_overallocation_percentage: node.sbu_overallocation_percentage
      };
    });

  return arrayOfExtractedData;
};

export const extractManageEmailAlertData = (
  generalDataNodes: GeneralDataNodeType[]
): EmailAlertNodeType[] => {
  const arrayOfExtractedData: EmailAlertNodeType[] = generalDataNodes.map(node => {
    return {
      name: node.name,
      description: node.description,
      category: node.type,
      org_id: node.id,
      parent_id: node.parent?.id || null,
      allocation: node.sbu_allocation === '0.00' ? '-' : formatNumber(parseInt(node.sbu_allocation)),
      sbu_usage: formatNumber(parseInt(node.sbu_usage)),
      children: node.children,
      sbu_usage_percentage: node.sbu_allocation === '0.00' ? '-' : node.sbu_usage_percentage > 999 ? 999 : node.sbu_usage_percentage,
      email_alert_pct: node.email_alert_percentage
    };
  });

  return arrayOfExtractedData;
};

// USED IN MANAGE ALLOCATIONS
export const extractNameUsageAllocation = (
  generalDataNodes: GeneralDataNodeType[]
): ManageAllocationNodeType[] => {
  const arrayOfExtractedData: ManageAllocationNodeType[] = generalDataNodes.map(
    (node: GeneralDataNodeType) => {
      return {
        name: node.name,
        description: node.description,
        category: node.type,
        org_id: node.id,
        parent_id: node.parent ? node.parent.id : null,
        // allocation: node.sbu_allocation,
        allocation: formatNumber(parseInt(node.sbu_allocation)),
        sbu_usage: formatNumber(parseInt(node.sbu_usage)),
        undistributed: formatNumber(parseInt(node.sbu_undistributed)),
        distributed: node.sbu_distributed,
        children: node.children,
        sbu_available_for_transfer: node.sbu_available_for_transfer
      };
    });

  return arrayOfExtractedData;
};

// HEADER GENERATORS
// generates table headers for months / quarters / year data
const generateMonthsHeaders = (sbuUsageData: UsageDataType): string[] => {
  const monthsReference: TimeUnitType[] = [
    { name: 'Jan', id: '01' },
    { name: 'Feb', id: '02' },
    { name: 'Mar', id: '03' },
    { name: 'Apr', id: '04' },
    { name: 'May', id: '05' },
    { name: 'Jun', id: '06' },
    { name: 'Jul', id: '07' },
    { name: 'Aug', id: '08' },
    { name: 'Sep', id: '09' },
    { name: 'Oct', id: '10' },
    { name: 'Nov', id: '11' },
    { name: 'Dec', id: '12' }
  ];

  const monthHeaders: string[] = Object.keys(sbuUsageData).map((key:string) => {
    let yearOnly: string = key.substring(0, 4);
    let monthOnly: string = key.substr(-2);
    let monthTextObj: TimeUnitType | undefined = monthsReference.find(monthRef => monthRef.id === monthOnly);
    let monthText: string = monthTextObj?.name || 'Month';

    return `${monthText} ${yearOnly}`;
  });

  return monthHeaders;
};

const generateQuartersHeaders = (sbuUsageData: UsageDataType): string[] => {
  const quartersReference = [
    { name: 'Q1', id: '1' },
    { name: 'Q2', id: '2' },
    { name: 'Q3', id: '3' },
    { name: 'Q4', id: '4' }
  ];

  const quarterHeaders: string[] = Object.keys(sbuUsageData).map(key => {
    let yearOnly: string = key.substring(0, 4);
    let quarterOnly: string = key.substr(-1);
    let quarterTextObj: TimeUnitType | undefined = quartersReference.find(quarterRef => quarterRef.id === quarterOnly);
    let quarterText: string = quarterTextObj?.name || 'Quarter';

    return `${quarterText} ${yearOnly}`;
  });

  return quarterHeaders;
};

const generateYearsHeaders = (sbuUsageData: UsageDataType): string[] => {
  const yearHeaders = Object.keys(sbuUsageData).map((key: string) => key);
  return yearHeaders;
};


export const generateHeaders = (
  sbuUsageData: UsageDataType, 
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year'
): string[] => {
  if (timePeriod === 'usage-per-month') {
    return generateMonthsHeaders(sbuUsageData);
  } 

  if (timePeriod === 'usage-per-quarter') {
    return generateQuartersHeaders(sbuUsageData);
  }

  if (timePeriod === 'usage-per-year') {
    return generateYearsHeaders(sbuUsageData);
  }

  return [];
};

// MERGE 2 DATASETS + CRAFT DATA IDEAL FOR MONTHS / QUARTERS / YEARS TABLES
export const newMergeMonthsQuartersYearsData = (
  initialNodes: GeneralDataNodeType[], 
  nodesWithUsageData: TimePeriodUsageType[], 
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year' = 'usage-per-month'
): { mergedNodesData: NodeUsageType[]; timePeriodUsageHeaders: string[] } => {

  const mergedNodesData: NodeUsageType[] | any = initialNodes.map(node => {
    const usageDataForNode: TimePeriodUsageType | undefined = nodesWithUsageData.find(usageData => {
      return usageData.data[0].name === node.name;
    });

    // remove id and name from object
    if (usageDataForNode){
      const { id, name, type, ...rest } = { ...usageDataForNode.data[0] };
      // returned object for all months / quarters / years table of the app
      // some properties are only rendered in a certain tables and hidden in others
      return {
        name: node.name,
        description: node.description,
        org_id: node.id,
        // email_alert_pct: node.email_alert_percentage,
        allocation: formatNumber(parseInt(node.sbu_allocation)),
        children: node.children,
        category: node.type,
        sbus_usage: formatEntries({ ...rest })
      };
    } else return undefined;
  }).filter(n => n);

  // to generate consistent headers
  const nodeWithMostUsageData: NodeUsageType = mergedNodesData[0];
  const timePeriodUsageHeaders: string[] = generateHeaders(nodeWithMostUsageData.sbus_usage, timePeriod);

  return {
    mergedNodesData,
    timePeriodUsageHeaders
  };
}; 


export const craftMonthsQuartersYearsData = (
  nodesWithTableUsage: GeneralDataNodeType[], 
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year' = 'usage-per-month'
) => {

  const mergedNodesData = nodesWithTableUsage.map(node => {
 
    // remove id, type and name from object
    // @ts-ignore (temporary ignore, will fix asap)
    const { id, name, type, ...rest } = { ...node.tableUsage[timePeriod] };

    return {
      name: node.name,
      description: node.description,
      org_id: node.id,
      // email_alert_pct: node.email_alert_percentage,
      allocation: formatNumber(parseInt(node.sbu_allocation)),
      children: node.children,
      category: node.type,
      // @ts-ignore (temporary ignore, will fix asap)
      sbus_usage: formatEntries({ ...rest })
    };
  }).filter(n => n);
  
  const timePeriodUsageHeaders: string[] = generateHeaders(mergedNodesData[0].sbus_usage, timePeriod);

  return {
    mergedNodesData,
    timePeriodUsageHeaders
  };
}; 