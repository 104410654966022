import React, { useState } from 'react';
import Input from '../../Inputs/Input';
import Button from '../../Button';
import CloseIcon from '../../Icons/Close/CloseIcon';
import Typography from '../../Typography';
import * as S from '../styles';
import ModalOverlay from '../BaseModal';

interface IEditNodeDescription {
  refreshTable: Function,
  onRequestClose: Function, 
  oldDescription: string, 
  nodeId: string
}

const EditNodeDescription = ({ 
  refreshTable, 
  onRequestClose, 
  oldDescription, 
  nodeId 
}: IEditNodeDescription) => {
  const [newDescription, setNewDescription] = useState<string>('');
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);


  return (
    <S.ModalContainer variant="EditNodeName" data-testid="edit-node-name" style={{
      fontSize: 'small'
    }}>
      <S.ModalFlexSection>
        <Typography as="h2" margin="0">Edit node description</Typography>
        <CloseIcon onClick={() => onRequestClose()} />
      </S.ModalFlexSection>
      <hr/>
      <S.ModalSection>
        <S.NameChangeSection>
          <S.TableRowLabel>
            <Typography as="p" margin="0">Current node description:</Typography>
          </S.TableRowLabel>
          <S.TableRowValue type="grey" width="100%">
            {oldDescription}
          </S.TableRowValue>

        </S.NameChangeSection>
        <S.NameChangeSection>
          <S.TableRowLabel>
            <Typography as="p" margin="0">New node description:</Typography>
          </S.TableRowLabel>
          <Input 
            value={newDescription}
            onKeyDown={() => ''}
            onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => setNewDescription(e.target.value)} 
            placeholder="Enter a new description for this node"
            dataTestId="edit-node-name-input"
            style={{ width: 'auto' }}
          />
        </S.NameChangeSection>
      </S.ModalSection>
      <hr />
      <S.ButtonsContainer style={{ fontSize: 'small' }}>
        <Button content="Cancel" variant={'gray'} onClick={() => onRequestClose()}/> 
        <Button content="Proceed" onClick={() => toggleConfirmModal(!isConfirmModalOpen)} disabled={newDescription.length ? false : true}/> 
      </S.ButtonsContainer>

      {/* MODAL */}
      {isConfirmModalOpen && <ModalOverlay 
        modalName={'Confirm Description'}
        isOpen={isConfirmModalOpen}
        onRequestClose={() => toggleConfirmModal(!isConfirmModalOpen)}
        closeParentModal={() => onRequestClose()}
        newDescription={newDescription}
        oldDescription={oldDescription}
        nodeId={nodeId}
        refreshTable={() => refreshTable()}
      />}
    </S.ModalContainer>
  );
};

export default EditNodeDescription;