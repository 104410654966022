import styled from 'styled-components';

export const ModalContainer = styled.div<{
  variant?: 'EditNodeName' 
}>`
  padding: 1em 2em 1em 2em;
`;

export const ModalFlexSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0 0.5em 0;
`;

export const ModalSection = styled.div`
  margin: 0 0 2em 0;
`;

export const ModalSectionTitle = styled.div`
  margin: 0em 0 0.5em 0;
  font-weight: 600;
`;

export const ModalSectionTable = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 0em 0 0 0;
  width: 100%;
`;

export const ModalSectionTableRow = styled.div<{
  position?: 'last'
}>`


@media screen and (max-width: 1000px) {
  flex-direction: column;
  align-items: center;

  & label {
    margin: 0.5em 0 0.5em 0;
  }

  & span {
    // width: -webkit-fill-available;
    margin: 0.5em 0 1em 0;
  }
}

  padding: 0.5em 1em;
  border-bottom: ${({ position }) => position === 'last' ? 'none' : '1px solid lightgray'};
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

export const TableRowLabel = styled.label`
  margin: 0 0 0.5em 0;
  font-size: small;
`;

export const TableRowValue = styled.span<{
  type: 'info' | 'success' | 'danger' | 'grey' | 'warning',
  width?: string
}>`
@media screen and (max-width: 1000px) {
  margin: 0 !important;
}
  padding: 0.7em 0 0.7em 0;
  width: ${({ width }) => width ? width : '12em'}; 
  text-align: center; 
  background: ${({ type }) => {
    if (type === 'info') return 'aliceblue';
    if (type === 'success') return 'mintcream';
    if (type === 'grey') return 'whitesmoke';
    if (type === 'warning') return 'cornsilk';
    return 'pink';
  }};

  border: ${({ type }) => {
    if (type === 'info') return '1px solid dodgerblue';
    if (type === 'success') return '1px solid mediumspringgreen';
    if (type === 'grey') return '1px solid lightgray';
    if (type === 'warning') return '1px solid rgba(255,215,0, 1)';
    return '1px solid rgba(255,51,51, 1)';
  }};

  border-radius: 5px; 
  transition: 0.5s;
  cursor: not-allowed;
`;

export const ButtonsContainer = styled.div`

@media screen and (max-width: 1000px) {
  flex-direction: column;
  align-items: center;
  font-size: small;
  & button {
    width: 100% !important;
  }
  & button:first-of-type {
    margin: 0 0 1em 0 !important;
  }
}

  width: 100%;
  margin: 1.5em 0 1em 0;
  display: flex;
  justify-content: space-between;

  & button {
    width: 15.5em;
  }

  & button:first-of-type {
    margin: 0 1em 0 0;
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 1em 0;
`;

export const RecipientContainer = styled.div`
margin: 0 0 0 1.5em;
`;

export const NameChangeSection = styled.div`
@media screen and (max-width: 1170px) {
font-size: smaller;
}

@media screen and (max-width: 1000px) {
  flex-direction: column;
  align-items: center;

  & label {
    margin: 0.5em 0 0.5em 0;
  }

  & input {
    width: -webkit-fill-available;
    margin: 0.5em 0 0.5em 0;
  }
}

  display: flex;
  flex-direction: column;
  margin: 2em 0 0 0;
`;
