import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { connect } from 'react-redux';

import HPCUser from '../views/HPCUser';
import NodesHPCUsage from '../views/CompRep/NodesHPCUsage';
import ManageAllocations from '../views/CompRep/ManageAllocations';
import SearchUsers from '../views/CompRep/SearchUsers';
import SearchAccounts from '../views/Admin/SearchAccounts';
import ManageNodesAndAccess from '../views/Admin/ManageNodesAndAccess';
import ManageOverallocations from '../views/Admin/ManageOverallocations';
import ChangeLog from '../views/Admin/ChangeLog';
import FallbackPage from '../views/FallbackPage';

import Sidebar from '../../components/Sidebar';
import {
  addNodes,
  clearNodes,
  clearInitialNodes,
  clearUserAccounts,
  setInitialNodes,
  setTree,
  clearTree,
  setUserId,
  updatePolicies,
  setLoggedIn
} from '../../redux/actions';

import ScrollToTop from '../../utils/scrollToTop';
import { MANAGE_ALLOCATIONS, MANAGE_NODES_ACCESS, MANAGE_OVERALLOCATIONS, MY_USAGE, NODES_USAGE } from '../../utils/constants';
import { 
  requireAuth, 
  fetchAndReturnOrgTree, 
  // eslint-disable-next-line
  handleDevEnvironment, 
  handleLoggedInUserData, 
  handleRedirection, 
  handleInitialRedirect,
  checkLoggedIn
} from './utils';
import { UserRole, IRoutes } from './types';

import { CoreAppStyles } from './styles/styles';
import './styles/index.css';
import { fetchMainNodes } from '../../api/fetch-nodes';
import { GeneralDataNodeType, UserAccessibleAccountOrNode } from '../../api/types';
import { arrayToObject, refreshCaches } from '../../utils/methods';
import { TableSearchOption } from '../../components/Table/types';
import { GlobalStateType } from '../../redux/reducers/types';
import NodeDetails from '../views/Details/NodeDetails';
import AccountDetails from '../views/Details/AccountDetails';
import { AccountDetailsRoute, ChangeLogRoute, ManageAccessRoute, ManageAllocationsRoute, ManageOverallocationsRoute, MyAccountsRoute, NodesAndAccountsRoute, NodesDetailsRoute, SearchAccountsRoute, SearchUsersRoute } from '../../components/Sidebar/utils';

const BASENAME = '/sbu-accounting';

const Routes = (props: IRoutes) => {
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [recentlyUsedAccounts, setRecentlyUsedAccounts] = useState<UserAccessibleAccountOrNode[]>([]);
  const [routeLoading, setRouteLoading] = useState<boolean>(true);
  const [errorState, setError] = useState<boolean>(false);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(true);

  // LOGIN AND ROLES SETTING LOGIC
  useEffect(() => {
    // if (process.env['NODE_ENV'] === 'development') {
    //   return handleDevEnvironment(setRoles, setRouteLoading, props);
    // } else {
    fetchLoggedInUserData();
    // }
  }, []);

  // FETCH LOGGED-IN USER ACCOUNTS DATA + NODES DATA
  useEffect(() => {
    if (props.user && !props.initialNodes.length) {
      fetchAndFormatNodes();
    }
  }, [props.user, props.initialNodes]);
  
  // FETCH ORG TREE USEEFFECT
  useEffect(() => { 
    if (roles.length && !props.tree.length) {
      fetchOrgTree();
    }
  }, [roles, props.tree]);

  // refresh caches at UTC
  useEffect(() => {
    const every12H = 43200000; // todo: convert to 4am UTC 
    let timer = setInterval(() => {
      // runs every 12 hours
      props.clearInitialNodes([]);
      props.clearNodes({});
      props.clearTree([]);
      props.clearUserAccounts({});
      refreshCaches();
    }, every12H);
    return () => clearInterval(timer);
  }, []);
 
  // periodically check if logged in
  useEffect(() => {
    const timer = setInterval(async () => {
      const status = await checkLoggedIn();
      props.setLoggedIn(status);
    }, 30000);
    return () => clearInterval(timer);
  }, []);

  const fetchLoggedInUserData = async (): Promise<void> => {
    return await handleLoggedInUserData(setRoles, setRecentlyUsedAccounts, setError, setRouteLoading, props);
  };

  const fetchAndFormatNodes = async ():Promise<void> => {
    const mainNodes: GeneralDataNodeType[] | undefined = await fetchMainNodes();
    if (mainNodes?.length) {
      const appended = mainNodes?.map(node => ({
        ...node,
        tableUsage: {},
        graphsUsage: {
          sbuUsage: {},
          accumulationUsage: {}
        },
        adminAccessUsers: [],
        readAccessUsers: []
      }));
      // craft into cache-acceptable template
      const cacheFormattedNodes = appended.map(mainNode => { 
        return { 
          [mainNode.id]: {
            ...mainNode
          }
        };
      });

      const transformIntoObject = arrayToObject(cacheFormattedNodes);

      props.setInitialNodes(appended);
      props.addNodes(transformIntoObject);
    }
  };

  const fetchOrgTree = async (): Promise<void> => {
    const treeArray = await fetchAndReturnOrgTree();
    if (treeArray?.length) {
      const formattedSearchOptions: TableSearchOption[] = treeArray.map(
        ({ name, type, id }) => ({ value: name, label: name, type: type, id: id }));
      props.setTree(formattedSearchOptions);
    }
  };

  const renderSideBar = (): React.ReactElement | undefined => {
    if (routeLoading) return;
    if (roles.length && !routeLoading) return <Sidebar/>;
    return;
  };

  return (
    <BrowserRouter basename={BASENAME}>
      <CoreAppStyles>
        {props.isLoggedIn && renderSideBar()}
        <ScrollToTop />
        {roles.length && !detailsOpen ? handleRedirection(roles) : null}
        <CacheSwitch>
          <CacheRoute 
            exact 
            path="/" 
            className="wrap"
            render={() => 
              (props.isLoggedIn) ? 
                (roles && roles.length) ? 
                  handleInitialRedirect(roles) : <FallbackPage type="no-access" />
                :
                <FallbackPage type="login" />
            } 
          />
          <CacheRoute  
            exact 
            path={MyAccountsRoute.path}
            className="wrap"
            cacheKey={MY_USAGE}
            render={() => requireAuth(
              HPCUser,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              MyAccountsRoute.path,
              errorState,
              props.isLoggedIn
            )}
          />
          <CacheRoute  
            exact 
            path={NodesAndAccountsRoute.path} 
            className="wrap"
            cacheKey={NODES_USAGE}
            render={() => requireAuth(
              NodesHPCUsage,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              NodesAndAccountsRoute.path,
              errorState,
              props.isLoggedIn
            )} 
          />
          <CacheRoute  
            exact 
            path={ManageAllocationsRoute.path} 
            className="wrap"
            cacheKey={MANAGE_ALLOCATIONS}
            render={() => requireAuth(
              ManageAllocations,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              ManageAllocationsRoute.path,
              errorState,
              props.isLoggedIn
            )}
          />
          <CacheRoute  
            exact 
            path={ManageAccessRoute.path} 
            className="wrap"
            cacheKey={MANAGE_NODES_ACCESS}
            render={() => requireAuth(
              ManageNodesAndAccess,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              ManageAccessRoute.path,
              errorState,
              props.isLoggedIn
            )}
          />
          <CacheRoute 
            exact 
            path={ManageOverallocationsRoute.path}
            className="wrap"
            cacheKey={MANAGE_OVERALLOCATIONS}
            render={() => requireAuth(
              ManageOverallocations,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              ManageOverallocationsRoute.path,
              errorState,
              props.isLoggedIn
            )} 
          />
          <CacheRoute 
            exact 
            path={ChangeLogRoute.path}
            className="wrap"
            render={() => requireAuth(
              ChangeLog,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              ChangeLogRoute.path,
              errorState,
              props.isLoggedIn
            )} 
          />
          <CacheRoute 
            exact 
            path={SearchUsersRoute.path}
            className="wrap"
            render={() => requireAuth(
              SearchUsers,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              SearchUsersRoute.path,
              errorState,
              props.isLoggedIn
            )} 
          />
          <CacheRoute 
            exact 
            path={SearchAccountsRoute.path} 
            className="wrap"
            render={() => requireAuth(
              SearchAccounts,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              SearchAccountsRoute.path,
              errorState,
              props.isLoggedIn
            )} 
          />
          <CacheRoute
            path={NodesDetailsRoute.path + '/:id'}
            className='wrap'
            render={(params) => requireAuth(
              NodeDetails,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              NodesDetailsRoute.path,
              errorState,
              props.isLoggedIn,
              params,
              detailsOpen,
              setDetailsOpen
            )}
          />
          <CacheRoute
            path={AccountDetailsRoute.path + '/:id'}
            className='wrap'
            render={(params) => requireAuth(
              AccountDetails,
              routeLoading,
              roles,
              recentlyUsedAccounts,
              AccountDetailsRoute.path,
              errorState,
              props.isLoggedIn,
              params,
              detailsOpen,
              setDetailsOpen
            )}
          />
          <Route path="*" render={() => <FallbackPage type="not-found" />} />
        </CacheSwitch>
      </CoreAppStyles>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: GlobalStateType) => {
  return {
    user: state.user,
    initialNodes: state.initialNodes,
    tree: state.tree,
    isLoggedIn: state.isLoggedIn
  };
};

export default connect(mapStateToProps, { 
  setTree, 
  updatePolicies, 
  setUserId, 
  setInitialNodes, 
  addNodes,
  clearInitialNodes,
  clearNodes,
  clearTree,
  clearUserAccounts,
  setLoggedIn
})(Routes);
