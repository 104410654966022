import { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';

import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';

import DatePickerInput from '../../../../components/Inputs/DatePicker';
import Button from '../../../../components/Button';
import { fetchAllUsers } from '../../../../api/fetch-users';
import SearchDropdown from '../../../../components/Inputs/Search/SearchDropdown';

import CustomNotification from '../../../../components/Notification';
import SearchLoader from '../../../../components/Loaders/SearchLoader';
import { SearchUserType } from '../../../../api/types';
import { CraftedTableData, UserData, UserOption } from './types';
import { DEFAULT_USERDATA, renderResults, triggerSearch } from './utils';


const SearchUsers = () => {
  const [valueFrom, onChangeFrom] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
  const [valueTo, onChangeTo] = useState<Date>(new Date());
  const [selectedUser, setSelectedUser] = useState({ label: 'Type to search for a user...', value: 'search' });
  const [allUsers, setAllUsers] = useState<UserOption[]>([]);
  const [userData, setUserData] = useState<UserData>(DEFAULT_USERDATA);
  const [tableData, setTableData] = useState<CraftedTableData[]>([]);
  const [isResultLoading, setResultLoading] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // INITIAL FETCH USERS TO SEARCH THROUGH
  useEffect(() => {
    const fetchSearchableUsers = async (): Promise<void> => {
      const searchableUsers: SearchUserType[] | undefined = await fetchAllUsers();
      setIsLoading(false);
      if (searchableUsers && searchableUsers.length) {
        const craftedUserOptions: UserOption[] = searchableUsers.map((user: SearchUserType) => {
          return { value: user.name, label: user.name };
        });
        setAllUsers(craftedUserOptions);
      }
    };

    if (!allUsers.length){
      setIsLoading(true);
      fetchSearchableUsers();
    }
  }, [allUsers]);


  // DETECT CHANGE IN CALENDAR INPUTS VALUE THEN SEND REQUEST
  useEffect(() => {
    // ensure a value is selected first
    if (selectedUser.value !== 'search') {
      triggerSearch(
        isResultLoading, 
        setResultLoading, 
        selectedUser, 
        valueFrom, 
        valueTo, 
        setUserData,
        setTableData
      );
    }
  }, [valueFrom, valueTo, selectedUser]);

  // disable button useEffect
  useEffect(() => {
    if (selectedUser.value !== 'search') setButtonDisabled(false);
    if (isResultLoading) setButtonDisabled(true);
  }, [selectedUser, isResultLoading]);


  const renderDropdown = () => {
    if (isLoading || (isLoading && !allUsers.length)) {
      return <SearchLoader/>;
    }
    if (allUsers.length && !isLoading) {
      return (
        <SearchDropdown
          options={allUsers}
          toParent={(option:UserOption) => setSelectedUser(option)}
          currentOption={selectedUser}
        />
      );
    }
    if (!allUsers.length && !isLoading) {
      return (
        <SearchDropdown
          options={[]}
          toParent={() => ''}
          currentOption={{ value: '', label: 'No users available' }}
          isDisabled
        />
      );
    }
  };
 
  return (
    <Container margin='0'>
      {/* TOP SECTION */}
      <Section margin='0 0 1.5em 0' flex>
        <div>
          <Typography as="h1" margin="0.5em 0 0 0">Search users</Typography>
          <Typography as="h3" margin="0" fontSize={22}>User's usage per account for selected time period</Typography>
        </div>
      </Section>

      {/* search + calendar inputs */}
      <Section margin="0 0 3em 0" flex={true}>
        <div style={{ alignSelf: 'center', display: 'flex' }}>
          {renderDropdown()}

          <Button 
            content={'Search'}
            variant="change"
            isLoading={isResultLoading}
            disabled={isButtonDisabled}
            onClick={() => triggerSearch(
              isResultLoading, 
              setResultLoading, 
              selectedUser, 
              valueFrom, 
              valueTo, 
              setUserData,
              setTableData
            )}
            style={{ padding: '0.55em 0.5em 0.55em 0.5em', marginLeft: '0.5em' }}
          />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}> 
          <span style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0.5em'
          }}>
            <label style={{ alignSelf: 'center', margin: '0 1em 0 0' }}><small><b>From:</b></small></label>
            <DatePickerInput
              onChange={(e:Date) => onChangeFrom(e)}
              value={valueFrom}
              maxDate={valueTo}
            />
          </span>
          <span style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <label style={{ alignSelf: 'center', margin: '0 1em 0 0' }}>
              <small><b>To:</b></small>
            </label>
            <DatePickerInput
              onChange={(e:Date) => onChangeTo(e)}
              value={valueTo}
              minDate={valueFrom}
            />
          </span>
        </div>
      </Section>

      <hr/>

      {renderResults(isResultLoading, selectedUser, userData, tableData)}

      <ReactTooltip 
        id='user-search' 
        type='dark' 
        place="top" 
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>Search for a user (e.g: User 1)</span>
      </ReactTooltip>
      <CustomNotification/>
    </Container>
  );
};

export default SearchUsers;