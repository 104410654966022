import { client } from './client';
import { TreeNodesResponseType } from './types';

export const fetchTree = async (): Promise<TreeNodesResponseType | undefined> => {
  try {
    const tree: TreeNodesResponseType = await client.get('/trees/');

    return tree;
  } catch (error) {
    console.error('Error fetching trees: ', error);
  }

};