import { Redirect } from 'react-router-dom';
import FallbackPage from '../views/FallbackPage';
import { fetchConfig, fetchLoggedInUserRoles } from '../../api/fetch-auth';
import { fetchTree } from '../../api/fetch-tree';
import { FetchConfigResponse, LoggedInUserDataResponse, TreeNode, TreeNodesResponseType, UserAccessibleAccountOrNode } from '../../api/types';
import { flatten } from '../../utils/methods';
import { IRoutes, UserRole } from './types';
import { AccountDetailsRoute, GetAllowedRoutes, GetDefaultRouteForRole, MyAccountsRoute, NodesDetailsRoute } from '../../components/Sidebar/utils';
import { PREFIX } from '../../utils/constants';
import ModalOverlay from '../../components/Modals/BaseModal';

export const requireAuth = (
  Component: React.ComponentType | any,
  isLoading: boolean,
  userRoles: UserRole[],
  recentlyUsedAccounts: UserAccessibleAccountOrNode[],
  path: string,
  error: boolean,
  isLoggedIn: boolean,
  params?: any,
  detailsOpen?: boolean,
  setDetailsOpen?: Function
): React.ReactElement | undefined | null => {
  const hasAllowedRoutes = () => {
    let allowedRoutes = GetAllowedRoutes(userRoles);
    return allowedRoutes && allowedRoutes.length > 0;
  };

  const hasAccess = () => {
    let found = GetAllowedRoutes(userRoles).find(route => route.path === path) !== undefined;
    return found;
  };

  if (isLoading) {
    return <FallbackPage type="loading" />;
  }

  if (error) {
    return <FallbackPage type="error" />;
  }

  if (!isLoggedIn) {
    return <FallbackPage type='login' />;
  }

  if (hasAccess() && !isLoading && path === MyAccountsRoute.path 
      && !(recentlyUsedAccounts && recentlyUsedAccounts.length)) {
    return <FallbackPage type="no-recently-used-accounts" />;
  }

  if (hasAccess() && !isLoading) {
    if (path === NodesDetailsRoute.path && detailsOpen !== undefined && setDetailsOpen) {
      return renderNodeDetails(params.match.params.id, userRoles, detailsOpen, setDetailsOpen);
    }
    if (path === AccountDetailsRoute.path && detailsOpen !== undefined && setDetailsOpen) {
      return renderAccountDetails(params.match.params.id, userRoles, detailsOpen, setDetailsOpen);
    }

    sessionStorage.setItem(`${PREFIX}currentPath`, path);
    return <Component />;
  }

  if (!hasAccess() && !isLoading && !hasAllowedRoutes()) {
    return <FallbackPage type="no-access" />;
  }

  if (!hasAccess() && !isLoading && hasAllowedRoutes()) {
    return <FallbackPage type="custom" msg={'You don\'t have permission to access this page.'} returnToAccessible={true} />;
  }

  return;
};

export const fetchAndReturnOrgTree = async (): Promise<TreeNode[]> => {
  const treeRes: TreeNodesResponseType | undefined = await fetchTree();
  if (treeRes && treeRes.data && treeRes.data.length) {
    const flatList = flatten(treeRes.data);
    return flatList;
  } else {
    console.error('error fetching tree');
    return [];
  }
};

export const checkLoggedIn = async (): Promise<boolean> => {
  try {
    const res: FetchConfigResponse | undefined = await fetchConfig();
    if (res?.data.user !== null && res?.data.user.uid) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('could not fetch logged in user data');
    return false;
  }
};

export const handleLoggedInUserData = async (
  setRoles: Function,
  setRecentlyUsedAccounts: Function,
  setError: Function,
  setRouteLoading: Function,
  props: IRoutes
): Promise<void> => {
  // console.log('process.env:', process.env);
  try {
    // fetch frontend config user object first
    const res: FetchConfigResponse | undefined = await fetchConfig();
    if (res?.data.user !== null && res?.data.user.uid) {
      try {
        const userId: string = res.data.user.uid;
        // fetch logged in user's roles
        const userRolesRes: LoggedInUserDataResponse | undefined = await fetchLoggedInUserRoles(userId);

        if (userRolesRes) {
          const userRoles: UserRole[] = userRolesRes.data.policies[0].roles;

          if (userRoles === undefined) {
            setError(true);
            setRouteLoading(false);
          }

          if (!userRoles.length) {
            // console.log('User has no active user roles');
            setRouteLoading(false);
          }
          // local state set roles
          setRoles(userRoles);
          // local state set recently used accounts
          setRecentlyUsedAccounts(userRolesRes.data.accounts);

          // update redux policies
          props.updatePolicies(userRoles);
          // add uid to redux store 
          props.setUserId(userId);
          props.setLoggedIn(true);
          setRouteLoading(false);
        } else {
          setError(true);
          setRouteLoading(false);
        }

      } catch (error) {
        console.error('error fetching user roles');
        // error page
        setRouteLoading(false);
      }
    } else {
      // console.log('no data available for logged in user');
      props.setUserId(null);
      props.setLoggedIn(false);
      props.updatePolicies([]);
      // back to login page
      setRouteLoading(false);
    }
  } catch (error) {
    // error page
    console.error('could not fetch logged in user data: ', error);
    setRouteLoading(false);
  }
};

export const handleDevEnvironment = (
  setRoles: Function,
  setRouteLoading: Function,
  props: IRoutes
): void => {
  // set a default user for dev environment and default roles for that user
  const DEFAULT_UID = 'maer';
  const DEFAULT_ROLES: UserRole[] = ['user', 'computing-representative', 'admin', 'project-principal-investigator'];
  // local roles to display Sidebar and for routes to render respective views
  setRoles(DEFAULT_ROLES);
  // update redux policies to display role-based links in sidebar
  props.updatePolicies(DEFAULT_ROLES);
  // uid for My Usage, fetching uid's accounts
  props.setUserId(DEFAULT_UID);
  setTimeout(() => { setRouteLoading(false); }, 2000);
};

export const handleInitialRedirect = (roles: UserRole[]): React.ReactElement | null => {
  let firstRole = roles[0];
  let foundRoute = GetDefaultRouteForRole(firstRole);

  return foundRoute ? <Redirect to={foundRoute.path} /> : <Redirect to='/' />;
};

export const handleRedirection = (roles: UserRole[]): React.ReactElement | null => {
  const currentPath = sessionStorage.getItem(`${PREFIX}currentPath`);

  // for staying on the same page during a manual refresh
  if (currentPath && currentPath.length) {
    return <Redirect to={currentPath} />;
  }
  // for directing to the default view of the user's first role
  return handleInitialRedirect(roles);
};

const renderNodeDetails = (
  nodeId: string,
  roles: UserRole[],
  detailsOpen: boolean,
  setDetailsOpen: Function
): React.ReactElement | undefined | null => {

  if (detailsOpen) {
    return <ModalOverlay
      modalName={'Node Details'}
      isOpen={detailsOpen}
      onRequestClose={() => (setDetailsOpen(false))}
      memberName={''}
      nodeChildren={[]}
      nodeId={nodeId}
      directNav={true}
    />;
  }

  return handleRedirection(roles);
};

const renderAccountDetails = (
  accountId: string,
  roles: UserRole[],
  detailsOpen: boolean,
  setDetailsOpen: Function
): React.ReactElement | undefined | null => {

  if (detailsOpen) {
    return <ModalOverlay
      modalName={'Account Details'}
      isOpen={detailsOpen}
      onRequestClose={() => (setDetailsOpen(false))}
      memberName={accountId}
      directNav={true}
    />;
  }

  return handleRedirection(roles);
};