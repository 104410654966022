import { CachedNodeType } from './types';

export default (state: CachedNodeType = {}, { payload, type } : {payload: CachedNodeType, type: string}) => { // to type

  switch (type) {
    case 'ADD_NODES':
      return {
        ...state,
        ...payload
      };

    case 'UPDATE_NODE': 
      return {
        ...state,
        ...payload
      };

    case 'CLEAR_NODES':
      return {};

    default:
      return state;
  }
};


