import { SpinnerCircularFixed } from 'spinners-react';
import { StyledButton } from './styles';

interface ButtonProps {
  content: string | React.ReactNode;
  style?: Object;
  variant?: 'black' | 'gray' | 'blue' | 'transfer' | 'change' | 'csv';
  onClick: Function;
  dataTestId?: string;
  dataFor?: 'table-csv-download' | 'graph-csv-download' | 'toggle-graph-layout' | 'expand-table' | 'add-node' | 'graph-reset' | 'transfer-allocations' | string;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({ content, style, variant, onClick, dataTestId, dataFor, disabled, isLoading }: ButtonProps) => {
  return (
    <>
      <StyledButton 
        style={style} 
        variant={variant} 
        onClick={() => onClick()} 
        data-testid={dataTestId}
        data-tip 
        data-for={dataFor}
        disabled={disabled}
      >
        {isLoading ? (
          <SpinnerCircularFixed size={12} thickness={312} speed={178} color="rgba(255,255,255, 0.7)" secondaryColor="rgba(255, 255, 255, 0.17)" />
        ) : content}
      </StyledButton>
    </>
  );
};

export default Button;
