import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../Button';
import CloseIcon from '../../../Icons/Close/CloseIcon';
import Typography from '../../../Typography';
import * as S from '../../styles';
import toast from 'react-hot-toast';
import { confirmGrantReadAccess, confirmGrantAdminAccess } from '../../../../api/confirmations';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { updateNode } from '../../../../redux/actions';

interface ConfirmProps {
  onRequestClose: Function;
  closeParentModal: Function;
  accessType: string;
  userId: string;
  nodeName: string;
  nodeId: string;
  nodes: CachedNodeType;
  updateNode: Function;
}

const ConfirmGrantAccess = ({
  onRequestClose,
  closeParentModal,
  accessType,
  userId,
  nodeId,
  nodeName,
  nodes,
  updateNode
}: ConfirmProps) => {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleClick = async () => {
    setButtonLoading(!isButtonLoading);
    setButtonDisabled(true);

    try {
      // TODO READ ACCESS SHOULD BE FROM CONSTANT
      const updatedUsersWithAccess = accessType === 'READ ACCESS' ?
        await confirmGrantReadAccess(userId, nodeId) :
        await confirmGrantAdminAccess(userId, nodeId);

      if (updatedUsersWithAccess === undefined) {
        throw new Error();
      }

      let uidFormat = updatedUsersWithAccess?.length ? updatedUsersWithAccess.map(uid => ({ user_id: uid })) : [];

      toast.success(`Successfully granted ${userId} ${accessType} to ${nodeId}. Now redirecting you back.`);
      setTimeout(() => {
        // find nodeId in nodes, update that node
        if (accessType === 'READ ACCESS') {
          updateNode({
            [nodeId]: {
              ...nodes[nodeId],
              readAccessUsers: [...uidFormat]
            }
          });
        } else {
          updateNode({
            [nodeId]: {
              ...nodes[nodeId],
              adminAccessUsers: [...uidFormat]
            }
          });
        }
        closeParentModal();
      }, 3000);

      setButtonLoading(false);

    } catch (error) {
      setButtonLoading(false);
      setButtonDisabled(false);
      console.error('Failed to grant access to node: ', error);
      toast.error(`There was a problem granting user ${userId} ${accessType} to ${nodeId}. Please try again or contact your administrator.`);
    }
  };

  return (
    <>
      <S.ModalContainer data-testid="edit-node-name" style={{
        fontSize: 'small'
      }}>
        <S.ModalFlexSection>
          <Typography as="h2" margin="0">Grant Access Confirmation</Typography>
          <CloseIcon onClick={() => isButtonDisabled ? null : onRequestClose()} isDisabled={isButtonDisabled} />
        </S.ModalFlexSection>
        <hr />
        <S.ModalSection>

          <h2>Summary</h2>
          <p>This will grant user with user id <span style={{ textDecoration: 'underline' }}>{userId}</span> <b>{accessType}</b> to node <span style={{ textDecoration: 'underline' }}>{nodeName}</span>.</p>
          <p><b>Access Type:</b> {accessType}</p>
          <p><b>User Id:</b> {userId}</p>
          <p><b>Node Name:</b> {nodeName}</p>
          <p><b>Node Id:</b> {nodeId}</p>
          <p>Proceed ?</p>
        </S.ModalSection>
        <hr />
        <S.ButtonsContainer style={{ fontSize: 'small' }}>
          <Button content="Cancel" variant={'gray'} onClick={() => onRequestClose()} disabled={isButtonDisabled} />
          <Button
            content="Confirm Access"
            onClick={() => handleClick()}
            isLoading={isButtonLoading}
            disabled={isButtonDisabled}
          />
        </S.ButtonsContainer>
      </S.ModalContainer>
    </>
  );
};


const mapStateToProps = (state: GlobalStateType): {
  nodes: CachedNodeType;
} => {
  return {
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(ConfirmGrantAccess);