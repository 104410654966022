import styled from 'styled-components';
import { colors } from '../../../../../../globalStyles/theme';

export const AccessTableData = styled.td`
  padding: 0 !important;
`;

export const GrantAccessInput = styled.input`
  border: 1px solid ${colors.grey.main};
  padding: 0.625em;
  opacity: 0.6;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 1;
  }
`;

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0.625em;
  border-top: 1px solid ${colors.grey.secondary};
  display: flex;
  justify-content: space-between;
  &:first-of-type {
    border: none;
    padding: 0 0.625em 0 0px;
  }
`;

export const HideUsers = styled.div`
background: white;
border: 1px solid dodgerblue;
border-left: none;
border-right: none;
color: dodgerblue;
padding: 0.5em;
cursor: pointer;
&: hover {
  background: dodgerblue;
  color: white;
  transition: 0.2s;
}
`;
