import { checkForChildren } from '.';
import { ManageAllocationNodeType } from '../../../../../api/types';
import { extractManageEmailAlertData, extractNameUsageAllocation } from '../../../../../api/utils';
import { EmailAlertNodeType } from '../../../../../containers/views/CompRep/NodesHPCUsage/types';
import { CachedNodeType } from '../../../../../redux/reducers/types';
import { DataType } from '../types';

export const renderManageAllocationsData = (
  data: DataType,
  renderFirstColumnData: JSX.Element,
  renderManageAllocationsInput: Function,
  renderTransferAllocationsButton: Function
) => {
  return Object.keys(data).map((key: string, i: number) => {
    if (key === 'name') return renderFirstColumnData;
    if (key === 'description') return null;
    if (key === 'allocation') return renderManageAllocationsInput();
    if (key === 'category') return null;
    if (key === 'children') return null;
    if (key === 'org_id') return null;
    if (key === 'parent_id') return null;
    if (key === 'distributed') return null;
    if (key === 'manage_allocation_controls') return null;
    if (key === 'sbu_available_for_transfer') return renderTransferAllocationsButton();

    // React will throw a warning regarding keys here
    return (
      <>
        <td key={key} data-testid={key}>{data[key as keyof typeof data]}</td>
      </>
    );
  });
};

export const renderManageReadAccessData = (
  data: DataType,
  renderReadAccessData: Function,
  renderFirstColumnData: JSX.Element
) => {
  return Object.keys(data).map((key: string) => {
    if (key === 'name') return renderFirstColumnData;
    if (key === 'category') return null;
    if (key === 'read_access_users') return renderReadAccessData();
    if (key === 'comprep_access_users') return null;
    if (key === 'children') return null;
    if (key === 'org_id') return null;

    return <td key={key} data-testid={key}>{data[key as keyof typeof data]}</td>;
  });
};

export const renderManageEmailAlertsData = (
  data: DataType,
  renderFirstColumnData: JSX.Element,
  renderEmailAlertPctInput: Function
) => {
  return Object.keys(data).map((key: string) => {
    if (key === 'name') return renderFirstColumnData;
    if (key === 'description') return null;
    if (key === 'org_id') return null;
    if (key === 'parent_id') return null;
    if (key === 'category') return null;
    if (key === 'children') return null;
    if (key === 'email_alert_pct') return null; // renderEmailAlertPctInput(data[key], key);

    return <td key={key} data-testid={key}>{data[key as keyof typeof data]}</td>;
  });
};

export const extractTableAllocationData = (nodes: CachedNodeType, clickedNode: string, data: DataType) => {
  if (nodes && clickedNode.length) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) {
      const sanitisedData: ManageAllocationNodeType[] = extractNameUsageAllocation(siblingNodesWithSameParent);
      return sanitisedData;
    }
    return [];
  }
  return [];
};

export const extractTableEmailAlertData = (nodes: CachedNodeType, clickedNode: string, data: DataType) => {
  if (nodes && clickedNode.length) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) {
      const sanitisedData: EmailAlertNodeType[] = extractManageEmailAlertData(siblingNodesWithSameParent);
      return sanitisedData;
    }
    return [];
  }
  return [];
};