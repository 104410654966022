import styled from 'styled-components';
import { breakpoints } from '../../../globalStyles/theme';

export const StyledGraphInputGroup = styled.div`
@media screen and (max-width: ${breakpoints.custom.size4}){
  align-self: auto;
  margin: 0 0 1em 0;
}
  align-self: center;
`;

export const InputsContainer = styled.div`
@media screen and (max-width: ${breakpoints.custom.size4}){
  justify-content: space-between;
}
padding: 0;
display: flex;
font-size: small;
`;