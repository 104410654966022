import toast from 'react-hot-toast';
import { fetchUserAccountsData } from '../../../api/fetch-accounts';
import { fetchGraphDataFromStats, fetchGraphDataFromVariant } from '../../../api/fetch-graphs';
import { AccountDataType, AccountUsageType, GraphVariantObjType, VariantObjectType } from '../../../api/types';
import { formatNumber, getUniqueListBy } from '../../../utils/methods';
import { AccountOption, GraphOption, PieChartDataType, UserTableDataType } from './types';

export const fetchUserAccounts = async (
  defaultUserName: string,
  selectAccountOption: Function,
  setAccountOptions: Function,
  setAccountDataLoading: Function,
  setUsageGraphLoading: Function,
  setAccummulationGraphLoading: Function,
  setPieChartLoading: Function
): Promise<void> => {
  try {
    const accountsOfUser: AccountDataType[] | undefined = await fetchUserAccountsData(defaultUserName);

    if (accountsOfUser && accountsOfUser.length) {
      const craftedAccountOptions: AccountOption[] = accountsOfUser.map(acc => {
        return {
          value: acc.id,
          label: acc.name,
          email_alert_percentage: acc.email_alert_percentage,
          sbu_allocation: acc.sbu_allocation,
          sbu_usage: acc.sbu_usage,
          sbu_remaining: parseInt(acc.sbu_allocation) - parseInt(acc.sbu_usage),
          nb_users: acc.users.length,
          description: acc.description,
          currency_allocation: acc.currency_allocation,
          currency_remaining: parseInt(acc.currency_allocation) - parseInt(acc.currency_usage),
          currency_usage: acc.currency_usage,
          currency: acc.currency
        };
      });

      selectAccountOption({
        value: accountsOfUser[0].id,
        label: accountsOfUser[0].name,
        email_alert_percentage: accountsOfUser[0].email_alert_percentage,
        sbu_allocation: accountsOfUser[0].sbu_allocation,
        sbu_usage: accountsOfUser[0].sbu_usage,
        sbu_remaining: parseInt(accountsOfUser[0].sbu_allocation) - parseInt(accountsOfUser[0].sbu_usage),
        nb_users: accountsOfUser[0].users.length,
        description: accountsOfUser[0].description,
        currency_allocation: accountsOfUser[0].currency_allocation,
        currency_remaining: parseInt(accountsOfUser[0].currency_allocation) - parseInt(accountsOfUser[0].currency_usage),
        currency_usage: accountsOfUser[0].currency_usage,
        currency: accountsOfUser[0].currency
      } as AccountOption);
      setAccountOptions(craftedAccountOptions);
      setAccountDataLoading(false);
    } else {
      console.error('Error fetching accounts of user');
      setAccountDataLoading(false);
      setUsageGraphLoading(false);
      setAccummulationGraphLoading(false);
      setPieChartLoading(false);
      toast.error('No accounts available.');
    }
  } catch (error) {
    console.error('Error fetching accounts of user:', error);
    setAccountDataLoading(false);
    setUsageGraphLoading(false);
    setAccummulationGraphLoading(false);
    setPieChartLoading(false);
    toast.error('Error fetching your accounts.');
  }
};

// gets the default sbu usage graph data AND all usage graph options/variants
export const fetchUsageGraphData = async (
  isUsageGraphLoading: boolean,
  setUsageGraphLoading: Function,
  selectedAccountOption: AccountOption,
  setSBUUsages: Function,
  setUsageGraphVariants: Function,
  displayUnit: string,
): Promise<void> => {

  if (!isUsageGraphLoading) setUsageGraphLoading(true);

  const sbuGraphData: {
    graphDataSBU: AccountUsageType[];
    graphDataCurrency: AccountUsageType[];
    variants: VariantObjectType[];
  } | undefined = await fetchGraphDataFromStats(selectedAccountOption.value, 'account-usage-per-user');

  if (sbuGraphData) {
    let dataInUnit: AccountUsageType[] = [];
    if (displayUnit === 'SBU') {
      dataInUnit = sbuGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      dataInUnit = sbuGraphData.graphDataCurrency;
    }

    if (dataInUnit.length) {
      setSBUUsages({
        accountId: selectedAccountOption.value,
        variantId: sbuGraphData.variants[0].id, // first variant by default from backend
        type: displayUnit,
        data: dataInUnit
      });
      setUsageGraphLoading(false);
    }

    if (!dataInUnit || !dataInUnit.length) {
      setSBUUsages({
        accountId: selectedAccountOption.value,
        variantId: sbuGraphData.variants[0].id,
        type: displayUnit,
        data: []
      });
      setUsageGraphLoading(false);
    }

    if (sbuGraphData?.variants) {
      setUsageGraphVariants(sbuGraphData.variants);
    }

    if (!sbuGraphData.variants || !sbuGraphData.variants.length) {
      setUsageGraphVariants([]);
    }
  } else {
    toast.error('Could not fetch data for SBU Usage graph');
    setUsageGraphLoading(false);
  }
};

export const fetchAccumulationGraphData = async (
  isAccumulationGraphLoading: boolean,
  setAccummulationGraphLoading: Function,
  selectedAccountOption: AccountOption,
  setAccumUsages: Function,
  setAccumulationGraphVariants: Function,
  displayUnit: string
): Promise<void> => {

  if (!isAccumulationGraphLoading) {
    setAccummulationGraphLoading(true);
  }

  const accGraphData: {
    graphDataSBU: AccountUsageType[];
    graphDataCurrency: AccountUsageType[];
    variants: VariantObjectType[];
  } | undefined = await fetchGraphDataFromStats(selectedAccountOption.value, 'total-account-usage');

  if (accGraphData) {

    let dataInUnit: AccountUsageType[] = [];
    if (displayUnit === 'SBU') {
      dataInUnit = accGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      dataInUnit = accGraphData.graphDataCurrency;
    }

    if (dataInUnit.length) {

      const withoutThreshold = dataInUnit.map(obj => {
        let { threshold, ...without } = obj;
        return { ...without };
      });

      setAccumUsages({
        accountId: selectedAccountOption.value,
        variantId: accGraphData.variants[0].id,
        type: displayUnit,
        data: withoutThreshold
      });
      setAccummulationGraphLoading(false);
    }

    if (!dataInUnit || !dataInUnit.length) {
      setAccumUsages({
        accountId: selectedAccountOption.value,
        variantId: accGraphData.variants[0].id,
        type: displayUnit,
        data: []
      });
      setAccummulationGraphLoading(false);
    }

    if (accGraphData?.variants) {
      setAccumulationGraphVariants(accGraphData.variants);
    }

    if (!accGraphData.variants || !accGraphData.variants.length) {
      setAccumulationGraphVariants([]);
    }
  } else {
    toast.error('Could not fetch data for SBU Accumulation graph');
    setAccummulationGraphLoading(false);
  }
};

export const handleGraphOptions = (
  usageGraphVariants: VariantObjectType[],
  accumulationGraphVariants: VariantObjectType[],
  setGraphsOptions: Function,
  setGraphCurrentOption: Function,
  graphCurrentOption: GraphOption
): void => {
  const combined: VariantObjectType[] = [...usageGraphVariants, ...accumulationGraphVariants];
  const noDuplicates: GraphVariantObjType[] = getUniqueListBy(combined, 'id');

  const craftedGraphsOptions = noDuplicates.map(
    (variant: GraphVariantObjType) => ({ label: variant.name, value: variant.id }));

  setGraphsOptions(craftedGraphsOptions);
  if (graphCurrentOption.value) {
    setGraphCurrentOption(graphCurrentOption);
  } else {
    // sets BOTH GRAPHS DEFAULT OPTION to first item in array
    setGraphCurrentOption(craftedGraphsOptions[0]);
  }
};

export const fetchUserPieChartData = async (
  isPieChartLoading: boolean,
  setPieChartLoading: Function,
  selectedAccountOption: AccountOption,
  graphCurrentOption: GraphOption,
  setPieUsages: Function,
  setTableUsages: Function,
  displayUnit: string,
): Promise<void> => {
  if (!isPieChartLoading) {
    setPieChartLoading(true);
  }

  let account: string = selectedAccountOption.value;
  let pieChartStat = 'relative-account-usage-per-user-current-period';
  let variantId: string = graphCurrentOption?.value;

  const pieChartData: {
    graphDataSBU: AccountUsageType[];
    graphDataCurrency: AccountUsageType[];
  } | undefined =
    await fetchGraphDataFromVariant(account, pieChartStat, variantId);


  if (pieChartData) {
    let dataInUnit: AccountUsageType[] = [];
    if (displayUnit === 'SBU') {
      dataInUnit = pieChartData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      dataInUnit = pieChartData.graphDataCurrency;
    }

    const pieChartFormatted: PieChartDataType[] = dataInUnit.map((data: AccountUsageType) => {
      return {
        name: `${data.user_name}`,
        value: parseInt(data.usage),
        pct: data.usage_percentage
      };
    }).sort((a, b) => a.name.localeCompare(b.name));

    setPieUsages({
      accountId: account,
      variantId: variantId,
      type: displayUnit,
      data: pieChartFormatted
    });

    const tableFormatted: UserTableDataType[] = dataInUnit.map(data => {
      return {
        user_name: data.user_name,
        sbus_used: formatNumber(parseInt(data.usage)),
        usage_pct: data.usage_percentage,
      };
    }).sort((a, b) => a.user_name.localeCompare(b.user_name));
    setTableUsages({
      accountId: account,
      variantId: variantId,
      type: displayUnit,
      data: tableFormatted
    });
    setPieChartLoading(false);
  } else {

    let emptyData = {
      accountId: account,
      variantId: variantId,
      data: []
    };

    // no pie chart data available
    toast.error('No pie chart data available');
    setPieChartLoading(false);
    setPieUsages(emptyData);
    setTableUsages(emptyData);
  }
};

export const handleGraphCurrentOption = async (
  option: GraphOption,
  setGraphCurrentOption: Function,
  setUsageGraphLoading: Function,
  setAccummulationGraphLoading: Function,
  selectedAccountOption: AccountOption,
  setSBUUsages: Function,
  setAccumUsages: Function,
  displayUnit: string
): Promise<void> => {
  if (!option) return;

  setGraphCurrentOption(option);
  setUsageGraphLoading(true);
  setAccummulationGraphLoading(true);

  let account = selectedAccountOption.value;
  let sbuGraphStatId = 'account-usage-per-user';
  let accumGraphStatId = 'total-account-usage';
  let variantId = option.value;

  // fetch option's graphs data
  const sbuGraphData = await fetchGraphDataFromVariant(account, sbuGraphStatId, variantId);
  const accGraphData = await fetchGraphDataFromVariant(account, accumGraphStatId, variantId);
  let sbuDataInUnit: AccountUsageType[] = [];
  let accDataInUnit: AccountUsageType[] = [];

  if (sbuGraphData) {
    if (displayUnit === 'SBU') {
      sbuDataInUnit = sbuGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      sbuDataInUnit = sbuGraphData.graphDataCurrency;
    }
    if (sbuDataInUnit.length) {
      setSBUUsages({
        accountId: account,
        variantId: variantId, // 7-days, 30, 180 days, Current Year etc
        type: displayUnit,
        data: sbuDataInUnit
      });
      setUsageGraphLoading(false);
    }
  }

  if (accGraphData) {

    if (displayUnit === 'SBU') {
      accDataInUnit = accGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      accDataInUnit = accGraphData.graphDataCurrency;
    }
    if (accDataInUnit.length) {
      const withoutThreshold = accDataInUnit.map(obj => {
        let { threshold, ...without } = obj;
        return { ...without };
      });
      setAccumUsages({
        accountId: account,
        variantId: variantId,
        type: displayUnit,
        data: withoutThreshold
      });
      setAccummulationGraphLoading(false);
    }
  }

  // if no graphs do not show graph (usage or acc) + notify
  if (!sbuGraphData || !sbuDataInUnit.length) {
    setSBUUsages({
      accountId: account,
      variantId: variantId,
      type: displayUnit,
      data: []
    });
    setUsageGraphLoading(false);
    toast.error(`No SBU Usage graph data for ${option.label}.`);
  }

  if (!accGraphData || !sbuDataInUnit.length) {
    setAccumUsages({
      accountId: account,
      variantId: variantId,
      type: displayUnit,
      data: []
    });
    setAccummulationGraphLoading(false);
    toast.error(`No SBU Accumulation graph data for ${option.label}.`);
  }

  return;

};