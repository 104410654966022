import styled from 'styled-components';
import { breakpoints, colors } from '../../../globalStyles/theme';

export const StyledInputGroup = styled.div`
  @media screen and (max-width: ${breakpoints.custom.size4}) {
    margin-top: 1em;
  }
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  font-size: small;
  margin-top: 3em;
  color: rgba(0,0,0,0.8);
`;

export const StyledInput = styled.input`
margin-right: 1em;
text-align: center;
  padding: 0.2em 1em 0.2em 1em;
  width: 4em;
  height: 2.5em;
  border-radius: 0.25em;
  font-size: 1em;
  border: 1px solid ${colors.grey.quintary};
`;