import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledAnimations = styled.div`
  animation: ${fadeIn} 0.25s linear;
  transition: visibility 0.25s linear;
`;


interface AnimateProps {
  children: React.ReactNode
}

const Animate = ({ children }: AnimateProps) => {
  return (
    <StyledAnimations>
      {children}
    </StyledAnimations>
  );
};

export default Animate;