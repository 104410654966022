// COLORS
export const colors = {
  background: {
    default: '#F1F1F1'
  },
  primary: {
    main: '#000000'
  },
  secondary: {
    main: '#E08D23'
  },
  link: {
    main: '#008099'
  },
  success: {
    main: '#388E3C',
    secondary: '#00DD00'
  },
  warning: {
    main: '#FFC107'
  },
  danger: {
    main: '#F44336',
    secondary: '#FF0000'
  },
  white: {
    main: '#FFFFFF'
  },
  grey: {
    main: '#F5F5F5',
    secondary: '#AAAAAA',
    tertiary: '#D3D3D3',
    quartenary: '#D5D5D5',
    quintary: '#808080'
  },
  blue: {
    main: '#1E90FF'
  },
  green: {
    main: '#008000'
  },
  black: {
    main: '#000000',
    secondary: '#363636'
  }
};

export const breakpoints = {
  mobiles: {
    xsmall: '200px',
    small: '320px',
    normal: '375px',
    large: '425px'
  },
  tablets: {
    normal: '768px'
  },
  laptops: {
    normal: '1024px',
    large: '1440px'
  },
  monitors: {
    normal: '1600px',
    large: '1920px',
    xlarge: '2560px',
    xxlarge: '3840px' 
  },
  custom: {
    size1: '989px',
    size2: '702px',
    size3: '946px',
    size4: '999px',
    size5: '1000px',
    size6: '1001px',
    size7: '1240px',
    size8: '1120px',
    size9: '990px',
    size10: '989px',
    size11: '817px',
    size12: '816px',
    size13: '723px',
    size14: '722px',
    size15: '624px',
    size16: '623px',
    size17: '576px',
    size18: '575px',
    size19: '276px',
    size20: '650px'

  }
};

// TYPOGRAPHY
export const typography = {
  fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: 14,
  h1: {
    fontSize: '3rem',
    fontWeight: 300
  },
  h2: {
    fontSize: '2.4rem',
    fontWeight: 300
  },
  h3: {
    fontSize: '1.825rem',
    fontWeight: 300
  },
  h4: {
    fontSize: '1.3rem',
    fontWeight: 300
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 300
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 300,
    textTransform: 'uppercase'
  }
};



