import { stringify } from 'querystring';
import { AccessUsers } from '../../api/types';
import { 
  ACCOUNT_DETAIL_VIEW_MONTHS, 
  ACCOUNT_DETAIL_VIEW_QUARTERS, 
  ACCOUNT_DETAIL_VIEW_YEARS, 
  COMPREP_EMAIL_ALERT_TABLE, 
  COMPREP_MANAGE_ALLOCATIONS, 
  COMPREP_SEARCH_USERS, 
  ECMWF_ADMIN_SEARCH_ACCOUNTS, 
  ECMWF_ADMIN_CHANGE_LOG, 
  ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS, 
  ECMWF_ADMIN_MANAGE_OVERALLOCATIONS, 
  HPC_USER_SBUS_USAGE
} from '../../utils/constants';
import { useEffect } from 'react';

type CSVSort = {user_name?: string; org_id?:string, name?: string}

export const craftCSVData = (rowsData: any[], tableVariant: string) => {
  if (tableVariant === ECMWF_ADMIN_CHANGE_LOG) {
    return rowsData;
  }
  const is_account_table_variant = (
    tableVariant === ACCOUNT_DETAIL_VIEW_MONTHS || 
    tableVariant === ACCOUNT_DETAIL_VIEW_QUARTERS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_YEARS
  );
  const removedDuplicates = rowsData.reduce((unique: any, o) => {
    if (!unique.some((obj: any) => {
      return tableVariant === HPC_USER_SBUS_USAGE ?
        obj.user_name === o.user_name : 
        obj.name === o.name;
    })) {
      unique.push(o);
    }
    return unique;
  }, []);

  const sorted = removedDuplicates.sort((a: CSVSort, b: CSVSort) => {
    if (tableVariant === HPC_USER_SBUS_USAGE && a.user_name && b.user_name) {
      return a.user_name.localeCompare(b.user_name);
    }

    if (is_account_table_variant && a.name && b.name) {
      return a.name.localeCompare(b.name);
    }


    if ((is_account_table_variant || 
          tableVariant === COMPREP_SEARCH_USERS || 
          tableVariant === ECMWF_ADMIN_SEARCH_ACCOUNTS) && a.name && b.name) {
      return a.name.localeCompare(b.name);
    }

    if (a.org_id && b.org_id){
      return a.org_id.localeCompare(b.org_id);
    }

    return stringify(a).localeCompare(stringify(b)); // fallback

  });

  const csvFormat = sorted.map((data: any) => {
    if (tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS) {
      const { children, comprep_access_users, read_access_users, ...rest } = data;
      return {
        ...rest,
        comprep_access_users: data.comprep_access_users.map((u: AccessUsers) => u.user_id),
        read_access_users: data.read_access_users.map((u: AccessUsers) => u.user_id)
      };

    }

    if (tableVariant === COMPREP_MANAGE_ALLOCATIONS) {
      const { children, manage_allocation_controls, ...rest } = data;
      return {
        ...rest
      };
    }

    // m / q /y for COMPREP_SBUS_USAGE_MONTHS / q / y , PPI_SBUS_USAGE_QUARTERS / m / y
    const { children, sbus_usage, ...rest } = data;
    return {
      ...rest,
      ...data.sbus_usage
    };

  });

  return csvFormat;
};


export const handleHeaderTextAlign = (header: string) => {
  if (header === 'Email alert %' || header === 'Manage allocations' || header === 'Overallocation (%)') {
    return 'center';
  }

  if (['Account name', 'User', 'Description', 'Date & time', 'User ID', 'Logs'].includes(header)) {
    return 'left';
  }

  if (header === 'Total SBU usage for selected time period') {
    return 'right';
  }

  return; 

};

export const handleRowTextAlign = (tableVariant: string, timePeriod: string) => {
  if (timePeriod?.length || 
    tableVariant === COMPREP_MANAGE_ALLOCATIONS ||
    tableVariant === ECMWF_ADMIN_MANAGE_OVERALLOCATIONS ||
    tableVariant === HPC_USER_SBUS_USAGE ||
    tableVariant === COMPREP_SEARCH_USERS ||
    tableVariant === ECMWF_ADMIN_SEARCH_ACCOUNTS ||
    tableVariant === COMPREP_EMAIL_ALERT_TABLE
  ) return 'right';

  return 'center';
};

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      handler(event); // Call the handler only if the click is outside of the element passed.
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]); // Reload only if ref or handler changes
};
