
import { checkForChildren } from '.';
import { craftMonthsQuartersYearsData } from '../../../../../api/utils';
import { CachedNodeType } from '../../../../../redux/reducers/types';
import { 
  ACCOUNT_DETAIL_VIEW_MONTHS, 
  ACCOUNT_DETAIL_VIEW_QUARTERS, 
  ACCOUNT_DETAIL_VIEW_YEARS, 
  COMPREP_SBUS_USAGE_YEARS, 
  ECMWF_FINANCE_SBUS_USAGE_MONTHS, 
  ECMWF_FINANCE_SBUS_USAGE_QUARTERS, 
  ECMWF_FINANCE_SBUS_USAGE_YEARS, 
  NODE_DETAIL_VIEW_YEARS, 
  PPI_SBUS_USAGE_YEARS
} from '../../../../../utils/constants';
import { DataType } from '../types';

// Renders usage data for months, quarters, years and is reusable across many user types.
export const renderSBUsUsageData = (
  data: DataType,
  renderFirstColumnData: JSX.Element,
  renderEmailAlertPctInput: Function,
  tableVariant: string
) => {
  return Object.keys(data).map((key: string) => {
    if (key === 'name') {
      return renderFirstColumnData;
    }

    if (key === 'description') {
      return null;
    }

    if (key === 'org_id') {
      return null;
    }

    if (key === 'category') {
      return null;
    }

    if (key === 'allocation' && (tableVariant === ECMWF_FINANCE_SBUS_USAGE_MONTHS || 
      tableVariant === ECMWF_FINANCE_SBUS_USAGE_QUARTERS || 
      tableVariant === ECMWF_FINANCE_SBUS_USAGE_YEARS || 
      // tableVariant === PPI_SBUS_USAGE_MONTHS ||
      // tableVariant === PPI_SBUS_USAGE_QUARTERS ||
      tableVariant === PPI_SBUS_USAGE_YEARS ||
      // tableVariant === NODE_DETAIL_VIEW_MONTHS || 
      // tableVariant === NODE_DETAIL_VIEW_QUARTERS ||
      tableVariant === NODE_DETAIL_VIEW_YEARS ||  
      tableVariant === ACCOUNT_DETAIL_VIEW_MONTHS || 
      tableVariant === ACCOUNT_DETAIL_VIEW_QUARTERS ||
      tableVariant === ACCOUNT_DETAIL_VIEW_YEARS ||  
      tableVariant === COMPREP_SBUS_USAGE_YEARS)) {
      return null;
    }

    // to clean up
    if (key === 'sbus_usage') {
      return Object.keys(data[key]).length ? (
        Object.keys(data[key]).map((time: string, index: number) => {
          return <td key={index} data-testid="sbus-usage">{data.sbus_usage[time]}</td>;
        })
      ) : (
        <td data-testid={key}>{data[key]}</td>
      );
    }

    if (key === 'type') {
      return null;
    }

    if (key === 'children') {
      return null;
    }

    if (key === 'email_alert_pct') {
      return null;
    }

    if (key === 'email_alert_pct') {
      return renderEmailAlertPctInput(data[key]);
    }

    return <td key={key} data-testid={key}>{data[key as keyof typeof data]}</td>;
  });
};


export const extractTableUsageData = (
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year',
  nodes: CachedNodeType,
  clickedNode: string,
  data: DataType
) => {
  if (nodes && clickedNode.length) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) {
      // if nodes exist AND they do not have the selected table timePeriod
      const existingNodes = siblingNodesWithSameParent?.map(s => {
        let tableUsage = s?.tableUsage;
        let hasOptionValue = Object.prototype.hasOwnProperty.call(tableUsage, timePeriod);
        return hasOptionValue;
      });
      
      const nodesHaveDataForOption = existingNodes.every(Boolean);

      // if they exist and HAVE data for timePeriod
      if (nodesHaveDataForOption) {
        const { mergedNodesData } = craftMonthsQuartersYearsData(siblingNodesWithSameParent, timePeriod);
        return mergedNodesData;
      }
    }
    return [];
  }
  return [];
};

// checks if clicked node's children exist in store and also their table m/q/y usage
export const checkForTimePeriodExistence = (
  nodes: CachedNodeType, 
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year',
  data: DataType
) => {
  if (nodes && timePeriod) {
    // check if clickedNode has children with general data in store
    const siblingNodesWithSameParent = checkForChildren(nodes, data);

    if (siblingNodesWithSameParent.length) {
    // if nodes exist AND they do not have the selected table timePeriod in their tableUsage..
      const existingNodes = siblingNodesWithSameParent?.map(s => {
        let tableUsage = s?.tableUsage;
        let hasOptionValue = Object.prototype.hasOwnProperty.call(tableUsage, timePeriod);
        return hasOptionValue;
      });
    
      const nodesHaveDataForOption = existingNodes.every(Boolean);

      return {
        nodesHaveDataForOption,
        siblingNodesWithSameParent
      };
    }

    return {};
  }
  return {};
};