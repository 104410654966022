import styled from 'styled-components';
import { colors } from '../../../globalStyles/theme';

export const CoreAppStyles = styled.div`
  display: flex;
  min-height: calc(100vh - 48px - 50px);
  background: ${colors.background.default}; 
  hr {
    border: none;
    height: 1px;
    color: #333; 
    background-color: ${colors.grey.tertiary}; 
  }
`;