import styled from 'styled-components';
import { breakpoints, colors } from '../../../globalStyles/theme';

export const StyledContainer = styled.div<{
  margin?: string;
  width?: string;
}>`
  // margin: ${(props) => props.margin || '2em'};
  // width: ${(props) => props.width || '100%'};
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-right: 55px;
  padding-left: 55px;
  margin-right: auto;
  margin-left: auto;
  background: ${colors.background.default};

  @media screen and (min-width: ${breakpoints.custom
      .size19}) and (max-width: ${breakpoints.custom.size18}) {
    max-width: 130px;
    overflow-x: scroll;
  }

  @media screen and (min-width: ${breakpoints.custom
      .size17}) and (max-width: ${breakpoints.custom.size16}) {
    max-width: 200px;
    overflow-x: scroll;
  }

  @media screen and (min-width: ${breakpoints.custom
      .size15}) and (max-width: ${breakpoints.custom.size14}) {
    max-width: 250px;
    overflow-x: scroll;
  }

  @media screen and (min-width: ${breakpoints.custom
      .size13}) and (max-width: ${breakpoints.custom.size12}) {
    max-width: 400px;
    overflow-x: scroll;
  }

  @media screen and (min-width: ${breakpoints.custom
      .size11}) and (max-width: ${breakpoints.custom.size10}) {
    max-width: 450px;
    overflow-x: scroll;
  }

  @media screen and (min-width: ${breakpoints.custom.size9}) {
    max-width: 600px;
    overflow-x: hidden;
  }

  @media screen and (min-width: ${breakpoints.custom.size5}) {
    max-width: 640px;
    overflow-x: hidden;
  }

  @media screen and (min-width: ${breakpoints.custom.size8}) {
    max-width: 770px;
  }

  @media screen and (min-width: ${breakpoints.custom.size7}) {
    max-width: 880px;
  }

  @media screen and (min-width: ${breakpoints.laptops.large}) {
    max-width: 1040px;
  }

  @media screen and (min-width: ${breakpoints.monitors.large}) {
    max-width: 1380px;
  }

  @media screen and (min-width: ${breakpoints.monitors.xlarge}) {
    max-width: 1840px;
  }

  @media screen and (min-width: ${breakpoints.monitors.xxlarge}) {
    max-width: 2780px;
  }
`;
