import { TUserAccsPayload } from './types';

const DEFAULT_ACCOUNT_OPTION = {
  value: '',
  label: '',
  email_alert_percentage: 0,
  sbu_allocation: '',
  sbu_usage: '',
  sbu_remaining: 0,
  nb_users: 0,
};

const DEFAULT_STATE = {
  accountsOptions: [],
  selectedAccountOption: DEFAULT_ACCOUNT_OPTION,
  sbuUsages: [],
  accumUsages: [],
  pieUsages: [],
  tableUsages: [],
};

// const sbuUsages = {
//   'account1': {
//     'current-year': [],
//     '7-days': [],
//     '30-days': []
//   },
//   'account2': {
//     'current-year': [],
//     '7-days': [],
//     '30-days': []
//   }
// };

// const userAccounts = {
//   sbuUsages: {},
//   accumUsages: {},
//   pie: {},
//   table: {},
//   selectedAcc: {},
//   accountOptins: []
// };

export default (
  state = DEFAULT_STATE,
  { payload, type }: { payload: TUserAccsPayload; type: string }
) => {
  let sbuGraphUsageExists;
  let accumGraphUsageExists;
  let pieUsageExists;
  let tableUsageExists;

  switch (type) {
    case 'SET_ACCOUNTS_OPTIONS':
      return { ...state, accountsOptions: [...payload] };

    case 'SET_SELECTED_ACCOUNT_OPTION':
      return { ...state, selectedAccountOption: { ...payload } };

    case 'SET_SBU_USAGES':
      sbuGraphUsageExists = state.sbuUsages.find((x) => {
        return (
          x['accountId'] === payload.accountId &&
          x['variantId'] === payload.variantId &&
          x['type'] === payload.type
        );
      });

      if (sbuGraphUsageExists) {
        // return existing state, do not add new payload
        return { ...state, sbuUsages: [...state.sbuUsages] };
      }

      return { ...state, sbuUsages: [...state.sbuUsages, payload] };

    case 'SET_ACCUM_USAGES':
      accumGraphUsageExists = state.accumUsages.find((x) => {
        return (
          x['accountId'] === payload.accountId &&
          x['variantId'] === payload.variantId &&
          x['type'] === payload.type
        );
      });

      if (accumGraphUsageExists) {
        // return existing state, do not add new payload
        return { ...state, accumUsages: [...state.accumUsages] };
      }

      return { ...state, accumUsages: [...state.accumUsages, payload] };

    case 'SET_PIE_USAGES':
      pieUsageExists = state.pieUsages.find((x) => {
        return (
          x['accountId'] === payload.accountId &&
          x['variantId'] === payload.variantId &&
          x['type'] === payload.type
        );
      });

      if (pieUsageExists) return { ...state, pieUsages: [...state.pieUsages] };

      return { ...state, pieUsages: [...state.pieUsages, payload] };

    case 'SET_TABLE_USAGES':
      tableUsageExists = state.tableUsages.find((x) => {
        return (
          x['accountId'] === payload.accountId &&
          x['variantId'] === payload.variantId &&
          x['type'] === payload.type
        );
      });

      if (tableUsageExists)
        return { ...state, tableUsages: [...state.tableUsages] };

      return { ...state, tableUsages: [...state.tableUsages, payload] };

    case 'CLEAR_USER_ACCOUNTS':
      return DEFAULT_STATE;

    default:
      return state;
  }
};
