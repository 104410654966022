import toast from 'react-hot-toast';

import { fetchGraphStatsForTimePeriod, fetchNodeChildrenDataFromId, fetchNodeDataFromId, fetchTableChildrenGeneralNodes, fetchTableChildrenStatsForTimePeriod, searchTableUsageFromId } from '../../../../api/fetch-nodes';
import { BatchTimePeriodUsageResponse, GeneralDataNodeType, MainNodesType, NodeUsageType } from '../../../../api/types';
import { CachedNodeType } from '../../../../redux/reducers/types';
import { arrayToObject } from '../../../../utils/methods';
import { SearchOption } from '../../CompRep/NodesHPCUsage/types';
import { GraphOption } from './types';

export const DEFAULT_CARDSDATA = {
  sbus_allocation: '',
  sbus_usage: '',
  sbus_remaining: '',
  sbus_allocated: '',
  nb_members: 0,
  sbus_allocated_percentage: 0,
  sbus_remaining_percentage: 0
};

export const fetchNodeData = async (
  nodeId: string,
  setCardsLoading: Function,
  updateNode: Function
): Promise<void> => {
  try {
    const nodeData: GeneralDataNodeType | undefined = await fetchNodeDataFromId(nodeId);
    if (nodeData){
      let format = {
        [nodeData.id]: {
          ...nodeData,
          tableUsage: {},
          graphsUsage: {
            sbuUsage: {},
            accumulationUsage: {}
          }
        }
      };
      updateNode(format);
      setCardsLoading(false);
    } else {
      setCardsLoading(false);
      toast.error('Failed to fetch node data');
    }
  } catch (error) {
    console.error('failed to fetch node data');
    setCardsLoading(false);
  }
};

export const fetchGraphsData = async (
  setUsageGraphLoading:Function,
  setAccumulationGraphLoading: Function,
  graphCurrentOption: GraphOption,
  setHasFetchedGraphs: Function,
  nodes: CachedNodeType,
  nodeId: string,
  updateNode: Function,
  setGraphsOptions: Function
): Promise<void> => {
  let siblingNodesWithSameParent: GeneralDataNodeType[] = Object.values(nodes).filter(node => {
  // finds all nodes whose parent is nodeId
    return node.parent?.id === nodeId;
  }).filter(n => n);

  // fetch new nodes if they do not exist
  if (!siblingNodesWithSameParent.length) {
    setUsageGraphLoading(true);
    setAccumulationGraphLoading(true);

    try {
      // fetches clicked node's children with their general data (to craft new node below) 
      const childrenGeneralData = await fetchNodeChildrenDataFromId(nodeId);
      if (childrenGeneralData?.length) {
        const sbuGraphStats = await fetchGraphStatsForTimePeriod('usage-per-day', childrenGeneralData, graphCurrentOption?.value);
        const accumGraphStats = await fetchGraphStatsForTimePeriod('total-node-usage', childrenGeneralData, graphCurrentOption?.value);
        
        if (sbuGraphStats?.data.length && childrenGeneralData?.length && accumGraphStats?.data.length){
          let extracted = childrenGeneralData.map(nodeObj => {
            return { 
              [nodeObj.id]: {
                ...nodeObj,
                tableUsage: {},
                graphsUsage: {
                  sbuUsage: {},
                  accumulationUsage: {}
                }
              }
            };
          });
          const tempObjFormat: CachedNodeType = arrayToObject(extracted);
          const withStats = sbuGraphStats.data.map(statsData => {
            return {
              [statsData.data[0].id]: {
                ...tempObjFormat[statsData.data[0].id],
                graphsUsage: {
                  accumulationUsage: {
                    [statsData.variant.id]: 
                      accumGraphStats.data.find(sd => sd.data[0].id === statsData.data[0].id)?.data[0]
                  },
                  sbuUsage: {
                    [statsData.variant.id]: statsData.data[0]
                  }
                }
              } 
            };
          });
          const transformIntoObject: CachedNodeType = arrayToObject(withStats);
          updateNode(transformIntoObject);
          setGraphsOptions(sbuGraphStats.data[0].variants.map(v => ({ label: v.name, value: v.id })));
          setHasFetchedGraphs(true);
          setUsageGraphLoading(false);
          setAccumulationGraphLoading(false);
        }
      } else {
        console.error('no graph data result for clicked node');
        toast.error('no graph data result for clicked node');
        setUsageGraphLoading(false);
        setHasFetchedGraphs(true);
        setAccumulationGraphLoading(false);
      }
    } catch (error) {
      console.error('failed to fetch node graph data: ', error);
      setHasFetchedGraphs(true);
      toast.error('failed to fetch node graph data');
    }
  }

  // if nodes exist AND they do not have the selected graphoption.value in sbuUsage + acumUsage..
  const existingNodes = siblingNodesWithSameParent?.map(s => {
    let sbuUsage = s?.graphsUsage.sbuUsage;
    let accumUsage = s?.graphsUsage.accumulationUsage;
    let hasOptionValueForSBU = Object.prototype.hasOwnProperty.call(sbuUsage, graphCurrentOption.value);
    let hasOptionValueForAccum = Object.prototype.hasOwnProperty.call(accumUsage, graphCurrentOption.value);
    return hasOptionValueForSBU && hasOptionValueForAccum;
  });
  
  const nodesHaveDataForOption = existingNodes.every(Boolean);
  
  // if nodes with general data exist but have no usage for selected option, fetch stats only
  if (siblingNodesWithSameParent.length && !nodesHaveDataForOption) {
    setUsageGraphLoading(true);
    setAccumulationGraphLoading(true);
    try {
      const sbuGraphStats = await fetchGraphStatsForTimePeriod('usage-per-day', siblingNodesWithSameParent, graphCurrentOption.value);
      const accumGraphStats = await fetchGraphStatsForTimePeriod('total-node-usage', siblingNodesWithSameParent, graphCurrentOption.value);
    

      if (sbuGraphStats?.data.length && accumGraphStats?.data.length) {
        const withStats = sbuGraphStats.data.map(statsData => {
          return {
            [statsData.data[0].id]: {
              ...nodes[statsData.data[0].id],
              graphsUsage: {
                accumulationUsage: {
                  ...nodes[statsData.data[0].id].graphsUsage.accumulationUsage,
                  [statsData.variant.id]: 
                  accumGraphStats.data.find(sd => sd.data[0].id === statsData.data[0].id)?.data[0]
                },
                sbuUsage: {
                  ...nodes[statsData.data[0].id].graphsUsage.sbuUsage,
                  [statsData.variant.id]: statsData.data[0]
                }
              }
            } 
          };
        });

        const transformIntoObject: CachedNodeType = arrayToObject(withStats);
        updateNode(transformIntoObject);
        setGraphsOptions(sbuGraphStats.data[0].variants.map(v => ({ label: v.name, value: v.id })));
        setUsageGraphLoading(false);
        setHasFetchedGraphs(true);
        setAccumulationGraphLoading(false);
      } else {
        console.error('no graph data result for clicked node');
        toast.error('no graph data result for clicked node');
        setUsageGraphLoading(false);
        setHasFetchedGraphs(true);
        setAccumulationGraphLoading(false);
      }
      
    } catch (error) {
      console.error('failed to fetch clicked node graph data: ', error);
      toast.error('failed to fetch clicked node graph data');
      setUsageGraphLoading(false);
      setHasFetchedGraphs(true);
      setAccumulationGraphLoading(false);
    }
  }
  // nodes present AND they have data for selected option, do not fetch
  if (siblingNodesWithSameParent.length && nodesHaveDataForOption) {
    return;
  }
  return;
};


export const checkForChildren = (nodes: CachedNodeType, nodeId: string) => {
  const siblingNodesWithSameParent = Object.values(nodes).filter(node => {
    return node.parent?.id === nodeId;
  }).filter(n => n);

  return siblingNodesWithSameParent;
};

// checks if clicked node's children exist in store and also their table m/q/y usage
export const checkForTimePeriodExistence = (
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year',
  nodes: CachedNodeType,
  nodeId: string
) => {
  if (nodes && timePeriod) {
    // check if clickedNode has children with general data in store
    const siblingNodesWithSameParent = checkForChildren(nodes, nodeId);

    if (siblingNodesWithSameParent.length) {
      // if nodes exist AND they do not have the selected table timePeriod in their tableUsage..
      const existingNodes = siblingNodesWithSameParent?.map(s => {
        let tableUsage = s?.tableUsage;
        let hasOptionValue = Object.prototype.hasOwnProperty.call(tableUsage, timePeriod);
        return hasOptionValue;
      });
      
      const nodesHaveDataForOption = existingNodes.every(Boolean);

      return {
        nodesHaveDataForOption,
        siblingNodesWithSameParent
      };
    }

    return {};
  }
  return {};
};

const fetchChildren = async (nodeId: string): Promise<MainNodesType[]> => {
  const children: GeneralDataNodeType[] | undefined = await fetchNodeChildrenDataFromId(nodeId);
  if (children?.length) {
    const newChildren:MainNodesType[] = children.map(nodeObj => {
      return {
        href: nodeObj.href,
        id: nodeObj.id,
        name: nodeObj.name,
        type: nodeObj.type
      };
    });
    return newChildren;
  }
  return [];
};


export const fetchMonthTableChildren = async (
  nodeChildren: MainNodesType[],
  setMonthsTableLoading: Function,
  nodes: CachedNodeType,
  nodeId: string,
  updateNode: Function
): Promise<void> => {

  const timePeriod = 'usage-per-month';

  const { 
    siblingNodesWithSameParent, 
    nodesHaveDataForOption
  } = checkForTimePeriodExistence(timePeriod, nodes, nodeId);

  // if open directly nodeChildren prop is empty -> fetch children
  let children = [];
  if (!nodeChildren.length) {
    children = await fetchChildren(nodeId);
  } else {
    children = nodeChildren;
  }

  // if node's children exist but have no tableUsage data for requried timePeriod, fetch time period data
  if (siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
    const tableUsageStats: BatchTimePeriodUsageResponse | undefined = 
      await fetchTableChildrenStatsForTimePeriod(timePeriod, children);
  
    if (tableUsageStats?.data.length && nodes && updateNode) {
      const newNodesWithStats = tableUsageStats?.data.map(statsData => {
        return {
          [statsData.data[0].id]: {
            ...nodes[statsData.data[0].id],
            tableUsage: {
              ...nodes[statsData.data[0].id].tableUsage,
              [statsData.id]: statsData.data[0]
            }
          } 
        };
      });
      const cacheFormattedNodes = arrayToObject(newNodesWithStats);
      updateNode(cacheFormattedNodes);
      setMonthsTableLoading(false);

    } else {
      toast.error('Failed to fetch members for this node. Please try again.');
      setMonthsTableLoading(false);
    }
  }

  // if node's children exist and they have required timeperiod, return
  if (siblingNodesWithSameParent?.length && nodesHaveDataForOption) { 
    setMonthsTableLoading(false);
    return;
  }

  // if no children for node, fetch both general data about each child + their usage
  if (!siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
    const childrenWithGeneralData: MainNodesType[] | undefined = 
            await fetchTableChildrenGeneralNodes(children);
    const tableUsageStats: BatchTimePeriodUsageResponse | undefined = 
            await fetchTableChildrenStatsForTimePeriod(timePeriod, children);
    
    if (childrenWithGeneralData?.length && tableUsageStats?.data.length && updateNode) {
      const newNodes = childrenWithGeneralData.map(nodeObj => {
        return { 
          [nodeObj.id]: {
            ...nodeObj,
            tableUsage: {},
            graphsUsage: {
              sbuUsage: {},
              accumulationUsage: {}
            }
          }
        };
      });
      const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
      // assign each new node their usage-per-x stat data for tableUsage
      const newNodesWithStats = tableUsageStats?.data.map(statsData => {
        return {
          [statsData.data[0].id]: {
            ...newNodesObjFormat[statsData.data[0].id],
            tableUsage: {
              [statsData.id]: statsData.data[0]
            }
          } 
        };
      });
      const cacheFormattedNodes = arrayToObject(newNodesWithStats);
      updateNode(cacheFormattedNodes);
      setMonthsTableLoading(false);
    } else {
      // setNodeChildren([]);
      toast.error('Failed to fetch members for this node. Please try again.');
      setMonthsTableLoading(false);
    }
  }
};



export const fetchQuartersTableChildren = async (
  nodeChildren: MainNodesType[],
  setQuartersTableLoading: Function,
  nodes: CachedNodeType,
  nodeId: string,
  updateNode: Function
): Promise<void> => {

  const timePeriod = 'usage-per-quarter';

  const { 
    siblingNodesWithSameParent, 
    nodesHaveDataForOption
  } = checkForTimePeriodExistence(timePeriod, nodes, nodeId);

  // if open directly nodeChildren prop is empty -> fetch children
  let children = [];
  if (!nodeChildren.length) {
    children = await fetchChildren(nodeId);
  } else {
    children = nodeChildren;
  }

  // if node's children exist but have no tableUsage data for requried timePeriod, fetch time period data
  if (siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
    const tableUsageStats: BatchTimePeriodUsageResponse | undefined = 
      await fetchTableChildrenStatsForTimePeriod(timePeriod, children);
  
    if (tableUsageStats?.data.length && nodes && updateNode) {
      const newNodesWithStats = tableUsageStats?.data.map(statsData => {
        return {
          [statsData.data[0].id]: {
            ...nodes[statsData.data[0].id],
            tableUsage: {
              ...nodes[statsData.data[0].id].tableUsage,
              [statsData.id]: statsData.data[0]
            }
          } 
        };
      });
      const cacheFormattedNodes = arrayToObject(newNodesWithStats);
      updateNode(cacheFormattedNodes);
      setQuartersTableLoading(false);

    } else {
      toast.error('Failed to fetch members for this node. Please try again.');
      setQuartersTableLoading(false);
    }
  }

  // if node's children exist and they have required timeperiod, return
  if (siblingNodesWithSameParent?.length && nodesHaveDataForOption) { 
    setQuartersTableLoading(false);
    return;
  }

  // if no children for node, fetch both general data about each child + their usage
  if (!siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
    const childrenWithGeneralData: MainNodesType[] | undefined = 
            await fetchTableChildrenGeneralNodes(children);
    const tableUsageStats: BatchTimePeriodUsageResponse | undefined = 
            await fetchTableChildrenStatsForTimePeriod(timePeriod, children);
    
    if (childrenWithGeneralData?.length && tableUsageStats?.data.length && updateNode) {
      const newNodes = childrenWithGeneralData.map(nodeObj => {
        return { 
          [nodeObj.id]: {
            ...nodeObj,
            tableUsage: {},
            graphsUsage: {
              sbuUsage: {},
              accumulationUsage: {}
            }
          }
        };
      });
      const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
      // assign each new node their usage-per-x stat data for tableUsage
      const newNodesWithStats = tableUsageStats?.data.map(statsData => {
        return {
          [statsData.data[0].id]: {
            ...newNodesObjFormat[statsData.data[0].id],
            tableUsage: {
              [statsData.id]: statsData.data[0]
            }
          } 
        };
      });
      const cacheFormattedNodes = arrayToObject(newNodesWithStats);
      updateNode(cacheFormattedNodes);
      setQuartersTableLoading(false);
    } else {
      toast.error('Failed to fetch members for this node. Please try again.');
      setQuartersTableLoading(false);
    }
  }
};


export const fetchYearsTableChildren = async (
  nodeChildren: MainNodesType[],
  setYearsTableLoading: Function,
  nodes: CachedNodeType,
  nodeId: string,
  updateNode: Function
): Promise<void> => {

  const timePeriod = 'usage-per-year';

  const { 
    siblingNodesWithSameParent, 
    nodesHaveDataForOption
  } = checkForTimePeriodExistence(timePeriod, nodes, nodeId);

  // if open directly nodeChildren prop is empty -> fetch children
  let children = [];
  if (!nodeChildren.length) {
    children = await fetchChildren(nodeId);
  } else {
    children = nodeChildren;
  }

  // if node's children exist but have no tableUsage data for requried timePeriod, fetch time period data
  if (siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
    const tableUsageStats: BatchTimePeriodUsageResponse | undefined = 
      await fetchTableChildrenStatsForTimePeriod(timePeriod, children);
  
    if (tableUsageStats?.data.length && nodes && updateNode) {
      const newNodesWithStats = tableUsageStats?.data.map(statsData => {
        return {
          [statsData.data[0].id]: {
            ...nodes[statsData.data[0].id],
            tableUsage: {
              ...nodes[statsData.data[0].id].tableUsage,
              [statsData.id]: statsData.data[0]
            }
          } 
        };
      });
      const cacheFormattedNodes = arrayToObject(newNodesWithStats);
      updateNode(cacheFormattedNodes);
      setYearsTableLoading(false);

    } else {
      toast.error('Failed to fetch members for this node. Please try again.');
      setYearsTableLoading(false);
    }
  }

  // if node's children exist and they have required timeperiod, return
  if (siblingNodesWithSameParent?.length && nodesHaveDataForOption) { 
    setYearsTableLoading(false);
    return;
  }

  // if no children for node, fetch both general data about each child + their usage
  if (!siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
    const childrenWithGeneralData: MainNodesType[] | undefined = 
            await fetchTableChildrenGeneralNodes(children);
    const tableUsageStats: BatchTimePeriodUsageResponse | undefined = 
            await fetchTableChildrenStatsForTimePeriod(timePeriod, children);
    
    if (childrenWithGeneralData?.length && tableUsageStats?.data.length && updateNode) {
      const newNodes = childrenWithGeneralData.map(nodeObj => {
        return { 
          [nodeObj.id]: {
            ...nodeObj,
            tableUsage: {},
            graphsUsage: {
              sbuUsage: {},
              accumulationUsage: {}
            }
          }
        };
      });
      const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
      // assign each new node their usage-per-x stat data for tableUsage
      const newNodesWithStats = tableUsageStats?.data.map(statsData => {
        return {
          [statsData.data[0].id]: {
            ...newNodesObjFormat[statsData.data[0].id],
            tableUsage: {
              [statsData.id]: statsData.data[0]
            }
          } 
        };
      });
      const cacheFormattedNodes = arrayToObject(newNodesWithStats);
      updateNode(cacheFormattedNodes);
      setYearsTableLoading(false);
    } else {
      toast.error('Failed to fetch members for this node. Please try again.');
      setYearsTableLoading(false);
    }
  }
};

export const handleMonthsTableSearch = async (
  option: SearchOption,
  setMonthsTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;
    
  setMonthsTableSearchResult([]);
    
  try {
    const searchResult: NodeUsageType | undefined = await searchTableUsageFromId(option.id, 'usage-per-month');
    if (searchResult) {
      setMonthsTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching months table result. Please try again.');
    console.error('error at node view months table search: ', error);
  }
};

export const handleQuartersTableSearch = async (
  option: SearchOption,
  setQuartersTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;
      
  setQuartersTableSearchResult([]);
      
  try {
    const searchResult: NodeUsageType | undefined = await searchTableUsageFromId(option.id, 'usage-per-quarter');
    if (searchResult) {
      setQuartersTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching quarters table result. Please try again.');
    console.error('error at node view quarters table search: ', error);
  }
};

export const handleYearsTableSearch = async (
  option: SearchOption,
  setYearsTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;
        
  setYearsTableSearchResult([]);
        
  try {
    const searchResult: NodeUsageType | undefined = await searchTableUsageFromId(option.id, 'usage-per-year');
    if (searchResult) {
      setYearsTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching years table result. Please try again.');
    console.error('error at node view years table search: ', error);
  }
};
