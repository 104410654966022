import React from 'react';
import { StyledCard, StyledCards } from './styles';

interface CardsProps {
  allocationText: string;
  usageText: string;
  remainingText: string;
  accountData: {
    sbus_allocation: string;
    sbus_usage: string;
    sbus_remaining: string;
    nb_members?: number
  }
  variant?: 'Nodes' | 'Users' | 'Accounts' | 'Members';
  hideLastCard?: boolean;
}

const Cards = ({ 
  allocationText, 
  usageText, 
  remainingText, 
  accountData, 
  variant = 'Users',
  hideLastCard = false
}: CardsProps) => {


  const handleNumberDisplay = (num: string) => {
    if (!num || num === 'NaN') return '-';
    return num;
  };

  return (
    <StyledCards data-testid='cards-display'>
      <StyledCard>
        <label
        >{allocationText}</label>
        <h2 style={{
          fontWeight: 900,
          margin: 0
        }}>{accountData.sbus_allocation ? handleNumberDisplay(accountData.sbus_allocation) : '-'}</h2>
      </StyledCard>
      <StyledCard>
        <label
        >{remainingText}</label>
        <h2 style={{
          fontWeight: 900,
          margin: 0
        }}>{accountData.sbus_remaining ? handleNumberDisplay(accountData.sbus_remaining) : '-'}</h2>
      </StyledCard>
      <StyledCard>
        <label
        >{usageText}</label>
        <h2 style={{
          fontWeight: 900,
          margin: 0
        }}>{accountData.sbus_usage ? handleNumberDisplay(accountData.sbus_usage) : '-'}</h2>
      </StyledCard>
      {!hideLastCard && (
        <StyledCard>
          <label
          >{variant === 'Members' ? 'Child nodes' : variant}</label>
          <h2 style={{
            fontWeight: 900,
            margin: 0
          }}>{accountData.nb_members ? accountData.nb_members : '-'}</h2>
        </StyledCard>
      )}
    </StyledCards>
  );
};

export default Cards;
