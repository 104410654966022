import toast from 'react-hot-toast';

import { fetchAccountFromId, fetchAccountStatData } from '../../../../api/fetch-accounts';
import { fetchGraphDataFromStats, fetchGraphDataFromVariant } from '../../../../api/fetch-graphs';
import { AccountUsageType, GraphVariantObjType, VariantObjectType } from '../../../../api/types';
import { generateHeaders } from '../../../../api/utils';
import { formatEntries, formatNumber, getUniqueListBy } from '../../../../utils/methods';
import { AccountDetailCardsData, GraphOption, PieChartDataType, UserTableDataType } from './types';

export const DEFAULT_CARDDATA = {
  sbus_allocation: '-',
  sbus_usage: '-',
  sbus_usage_percentage: 0,
  sbus_remaining: 0,
  sbus_remaining_percentage: 0,
  nb_members: 0,
  currency_allocation: '-',
  currency_usage: '-',
  currency_usage_percentage: 0,
  currency_remaining: 0,
  currency: 'SBU',
  currency_remaining_percentage: 0
};

export const fetchAccountData = async (
  memberName: string,
  setCardsData: Function,
  setCardsLoading: Function,
  setAccountDescription: Function
): Promise<void> => {
  try {
    const accountData = await fetchAccountFromId(memberName);
    if (accountData) {
      const data: AccountDetailCardsData = {
        sbus_allocation: accountData.sbu_allocation,
        sbus_usage: accountData.sbu_usage,
        sbus_usage_percentage: accountData.sbu_usage_percentage,
        sbus_remaining: accountData.sbu_unused,
        sbus_remaining_percentage: accountData.sbu_unused_percentage,
        nb_members: accountData.users.length,
        currency_allocation: accountData.currency_allocation,
        currency_usage: accountData.currency_usage,
        currency_usage_percentage: accountData.currency_usage_percentage,
        currency_remaining: parseInt(accountData.currency_allocation) - parseInt(accountData.currency_usage),
        currency_remaining_percentage: accountData.currency_unused_percentage,
        currency: accountData.currency
      };
      setAccountDescription(accountData.description);
      setCardsData(data);
      setCardsLoading(false);
    } else {
      toast.error('Error fetching account data, please try again.');
      setCardsLoading(false);
    }

  } catch (error) {
    console.error('failed to fetch account data: ', error);
    setCardsLoading(false);
  }
};

export const fetchUsageGraphData = async (
  memberName: string,
  isUsageGraphLoading: boolean,
  setUsageGraphLoading: Function,
  setUsageGraphData: Function,
  setUsageGraphVariants: Function,
  displayUnit: string
): Promise<void> => {
  if (!isUsageGraphLoading) {
    setUsageGraphLoading(true);
  }
  const sbuGraphData: {
    graphDataSBU: AccountUsageType[];
    graphDataCurrency: AccountUsageType[];
    variants: VariantObjectType[];
  } | undefined = await fetchGraphDataFromStats(memberName, 'account-usage-per-user');

  if (sbuGraphData) {
    let dataInUnit: AccountUsageType[] = [];
    if (displayUnit === 'SBU') {
      dataInUnit = sbuGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      dataInUnit = sbuGraphData.graphDataCurrency;
    }

    if (dataInUnit.length) {
      setUsageGraphData(dataInUnit);
      setUsageGraphLoading(false);
    }

    if (!dataInUnit || !dataInUnit.length) {
      setUsageGraphData([]);
      setUsageGraphLoading(false);
    }

    if (sbuGraphData?.variants) {
      setUsageGraphVariants(sbuGraphData.variants);
    }

    if (!sbuGraphData.variants || !sbuGraphData.variants.length) {
      setUsageGraphVariants([]);
    }
  } else {
    toast.error('Could not fetch data for SBU Usage graph');
    setUsageGraphLoading(false);
  }
};


export const fetchAccumulationGraphData = async (
  memberName: string,
  isAccumulationGraphLoading: boolean,
  setAccummulationGraphLoading: Function,
  setAccumulationGraphData: Function,
  setAccumulationGraphVariants: Function,
  displayUnit: string
): Promise<void> => {
  if (!isAccumulationGraphLoading) {
    setAccummulationGraphLoading(true);
  }

  const accGraphData: {
    graphDataSBU: AccountUsageType[];
    graphDataCurrency: AccountUsageType[];
    variants: VariantObjectType[];
  } | undefined = await fetchGraphDataFromStats(memberName, 'total-account-usage');

  if (accGraphData) {

    let dataInUnit: AccountUsageType[] = [];
    if (displayUnit === 'SBU') {
      dataInUnit = accGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      dataInUnit = accGraphData.graphDataCurrency;
    }

    if (dataInUnit.length) {
      const withoutThreshold = dataInUnit.map(obj => {
        let { threshold, ...without } = obj;
        return {
          ...without
        };
      });

      setAccumulationGraphData(withoutThreshold);
      setAccummulationGraphLoading(false);
    }

    if (!dataInUnit || !dataInUnit.length) {
      setAccumulationGraphData([]);
      setAccummulationGraphLoading(false);
    }

    if (accGraphData?.variants) {
      setAccumulationGraphVariants(accGraphData.variants);
    }

    if (!accGraphData.variants || !accGraphData.variants.length) {
      setAccumulationGraphVariants([]);
    }
  } else {
    toast.error('Could not fetch data for SBU Usage graph');
    setAccummulationGraphLoading(false);
  }
};

export const handleGraphOptions = (
  usageGraphVariants: VariantObjectType[],
  accumulationGraphVariants: VariantObjectType[],
  setGraphsOptions: Function,
  setGraphCurrentOption: Function,
  graphCurrentOption: GraphOption
): void => {
  const combined: VariantObjectType[] = [...usageGraphVariants, ...accumulationGraphVariants];
  const noDuplicates: GraphVariantObjType[] = getUniqueListBy(combined, 'id');

  const craftedGraphsOptions = noDuplicates.map(
    (variant: GraphVariantObjType) => ({ label: variant.name, value: variant.id }));

  setGraphsOptions(craftedGraphsOptions);
  if (graphCurrentOption && graphCurrentOption.value) {
    setGraphCurrentOption(graphCurrentOption);
  } else {
    // sets BOTH GRAPHS DEFAULT OPTION to first item in array
    setGraphCurrentOption(craftedGraphsOptions[0]);
  }
};

export const fetchUserPieChartData = async (
  isPieChartLoading: boolean,
  setPieChartLoading: Function,
  memberName: string,
  graphCurrentOption: GraphOption,
  setPieChartData: Function,
  setUserTableData: Function,
  displayUnit: string
): Promise<void> => {
  if (!isPieChartLoading) {
    setPieChartLoading(true);
  }

  let account: string = memberName;
  let pieChartStat = 'relative-account-usage-per-user-current-period';
  let variantId: string = graphCurrentOption?.value;

  const pieChartData: {
    graphDataSBU: AccountUsageType[];
    graphDataCurrency: AccountUsageType[];
  } | undefined =
    await fetchGraphDataFromVariant(account, pieChartStat, variantId);

  if (pieChartData) {
    let dataInUnit: AccountUsageType[] = [];
    if (displayUnit === 'SBU') {
      dataInUnit = pieChartData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      dataInUnit = pieChartData.graphDataCurrency;
    }


    const pieChartFormatted: PieChartDataType[] = dataInUnit.map((data: AccountUsageType) => {
      return {
        name: `${data.user_name}`,
        value: parseInt(data.usage),
        pct: data.usage_percentage
      };
    }).sort((a, b) => a.name.localeCompare(b.name));

    setPieChartData(pieChartFormatted);

    const tableFormatted: UserTableDataType[] = dataInUnit.map(data => {
      return {
        user_name: data.user_name,
        sbus_used: formatNumber(parseInt(data.usage)),
        usage_pct: data.usage_percentage
      };
    }).sort((a, b) => a.user_name.localeCompare(b.user_name));

    setUserTableData(tableFormatted);
    setPieChartLoading(false);
  } else {
    // no pie chart data available
    toast.error('No pie chart data available');
    setPieChartLoading(false);
    setPieChartData([]);
    setUserTableData([]);
  }
};

export const fetchMonthsTableData = async (
  memberName: string,
  setMonthsTableData: Function,
  setMonthsTableHeaders: Function,
  setMonthsTableLoading: Function,
  displayUnit: string
): Promise<void> => {
  try {
    const monthsTableData: {
      graphDataSBU: AccountUsageType[];
      graphDataCurrency: AccountUsageType[];
    } | undefined = await fetchAccountStatData(memberName, 'usage-per-month');
    if (monthsTableData
      && monthsTableData.graphDataSBU.length > 0
      && monthsTableData.graphDataCurrency.length > 0) {
      let dataInUnit: AccountUsageType[] = [];
      if (displayUnit === 'SBU') {
        dataInUnit = monthsTableData.graphDataSBU;
      } else if (displayUnit === 'Currency') {
        dataInUnit = monthsTableData.graphDataCurrency;
      }

      const formattedTableData: AccountUsageType[] = dataInUnit.map((data: AccountUsageType) => {
        const { id, name, ...rest } = data;
        const formattedUsageOnly = formatEntries({ ...rest });
        return {
          name: name,
          ...formattedUsageOnly
        };
      });
      const headerFormat: AccountUsageType = formattedTableData[0];
      const monthsHeaders: string[] = generateHeaders(headerFormat, 'usage-per-month');
      const headers: string[] = ['User', ...monthsHeaders.slice(1)];
      setMonthsTableData(formattedTableData);
      setMonthsTableHeaders(headers);
      setMonthsTableLoading(false);
    } else {
      // toast.error('No months table data to display');
      setMonthsTableLoading(false);
      return;
    }

  } catch (error) {
    setMonthsTableLoading(false);
    toast.error('Error fetching months table data.');
    console.error('error fetching months table data: ', error);
  }
};

export const fetchQuartersTableData = async (
  memberName: string,
  setQuartersTableData: Function,
  setQuartersTableHeaders: Function,
  setQuartersTableLoading: Function,
  displayUnit: string
): Promise<void> => {
  try {
    const quartersTableData: {
      graphDataSBU: AccountUsageType[];
      graphDataCurrency: AccountUsageType[];
    } | undefined = await fetchAccountStatData(memberName, 'usage-per-quarter');
    if (quartersTableData
      && quartersTableData.graphDataSBU.length > 0
      && quartersTableData.graphDataCurrency.length > 0) {
      let dataInUnit: AccountUsageType[] = [];
      if (displayUnit === 'SBU') {
        dataInUnit = quartersTableData.graphDataSBU;
      } else if (displayUnit === 'Currency') {
        dataInUnit = quartersTableData.graphDataCurrency;
      }

      const formattedTableData: AccountUsageType[] = dataInUnit.map((data: AccountUsageType) => {
        const { id, name, ...rest } = data;
        const formattedUsageOnly = formatEntries({ ...rest });
        return {
          name: name,
          ...formattedUsageOnly
        };
      });
      const headerFormat: AccountUsageType = formattedTableData[0];
      const quartersHeaders: string[] = generateHeaders(headerFormat, 'usage-per-quarter');
      const headers: string[] = ['User', ...quartersHeaders.slice(1)];

      setQuartersTableData(formattedTableData);
      setQuartersTableHeaders(headers);
      setQuartersTableLoading(false);
    } else {
      setQuartersTableLoading(false);
      // toast.error('No quarters table data to display');
      return;
    }

  } catch (error) {
    setQuartersTableLoading(false);
    toast.error('Error fetching quarters table data.');
    console.error('error fetching quarters table data: ', error);
  }
};

export const fetchYearsTableData = async (
  memberName: string,
  setYearsTableData: Function,
  setYearsTableHeaders: Function,
  setYearsTableLoading: Function,
  displayUnit: string
): Promise<void> => {
  try {
    const yearsTableData: {
      graphDataSBU: AccountUsageType[];
      graphDataCurrency: AccountUsageType[];
    } | undefined = await fetchAccountStatData(memberName, 'usage-per-year');

    if (yearsTableData
      && yearsTableData.graphDataSBU.length > 0
      && yearsTableData.graphDataCurrency.length > 0) {
      let dataInUnit: AccountUsageType[] = [];
      if (displayUnit === 'SBU') {
        dataInUnit = yearsTableData.graphDataSBU;
      } else if (displayUnit === 'Currency') {
        dataInUnit = yearsTableData.graphDataCurrency;
      }

      const formattedTableData: AccountUsageType[] = dataInUnit.map((data: AccountUsageType) => {
        const { id, name, ...rest } = data;
        const formattedUsageOnly = formatEntries({ ...rest });
        return {
          name: name,
          ...formattedUsageOnly
        };
      });
      const headerFormat: AccountUsageType = formattedTableData[0];
      const yearsHeaders: string[] = generateHeaders(headerFormat, 'usage-per-year');
      const headers: string[] = ['User', ...yearsHeaders.slice(1)];

      setYearsTableData(formattedTableData);
      setYearsTableHeaders(headers);
      setYearsTableLoading(false);

    } else {
      setYearsTableLoading(false);
      // toast.error('No years table data to display');
      return;
    }

  } catch (error) {
    setYearsTableLoading(false);
    console.error('error fetching years table data: ', error);
    toast.error('Error fetching years table data.');
  }
};

export const handleGraphCurrentOption = async (
  memberName: string,
  option: GraphOption,
  setGraphCurrentOption: Function,
  setUsageGraphLoading: Function,
  setAccummulationGraphLoading: Function,
  setUsageGraphData: Function,
  setAccumulationGraphData: Function,
  displayUnit: string
): Promise<void> => {

  setGraphCurrentOption(option);
  setUsageGraphLoading(true);
  setAccummulationGraphLoading(true);

  let account = memberName;
  let sbuVariant = 'account-usage-per-user';
  let accumulationVariant = 'total-account-usage';

  // fetch option's graphs data
  const sbuGraphData = await fetchGraphDataFromVariant(account, sbuVariant, option.value);
  const accGraphData = await fetchGraphDataFromVariant(account, accumulationVariant, option.value);
  let sbuDataInUnit: AccountUsageType[] = [];
  let accDataInUnit: AccountUsageType[] = [];

  if (sbuGraphData) {
    if (displayUnit === 'SBU') {
      sbuDataInUnit = sbuGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      sbuDataInUnit = sbuGraphData.graphDataCurrency;
    }
    setUsageGraphData(sbuDataInUnit);
    setUsageGraphLoading(false);
  }

  if (accGraphData) {
    if (displayUnit === 'SBU') {
      accDataInUnit = accGraphData.graphDataSBU;
    } else if (displayUnit === 'Currency') {
      accDataInUnit = accGraphData.graphDataCurrency;
    }
    const withoutThreshold = accDataInUnit.map(obj => {
      let { threshold, ...without } = obj;
      return {
        ...without
      };
    });

    setAccumulationGraphData(withoutThreshold);
    setAccummulationGraphLoading(false);
  }

  // if no graphs do not show graph (usage or acc) + notify
  if (!sbuGraphData || !sbuDataInUnit.length) {
    setUsageGraphData([]);
    setUsageGraphLoading(false);
    toast.error(`No SBU Usage graph data for ${option.label}.`);
  }

  if (!accGraphData || !accDataInUnit.length) {
    setAccumulationGraphData([]);
    setAccummulationGraphLoading(false);
    toast.error(`No SBU Accumulation graph data for ${option.label}.`);
  }

  return;

};