import ReactTooltip from 'react-tooltip';
import toast from 'react-hot-toast';

// CONSTANTS
import {
  ECMWF_ADMIN_MANAGE_OVERALLOCATIONS,
  ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS,
  ECMWF_ADMIN_CHANGE_LOG,
  COMPREP_MANAGE_ALLOCATIONS,
  COMPREP_MANAGE_READ_ACCESS,
  COMPREP_SBUS_USAGE_MONTHS,
  COMPREP_SBUS_USAGE_QUARTERS,
  COMPREP_SBUS_USAGE_YEARS,
  COMPREP_SEARCH_USERS,
  ECMWF_ADMIN_SEARCH_ACCOUNTS,
  ECMWF_FINANCE_SBUS_USAGE_MONTHS,
  ECMWF_FINANCE_SBUS_USAGE_QUARTERS,
  ECMWF_FINANCE_SBUS_USAGE_YEARS,
  PPI_SBUS_USAGE_MONTHS,
  PPI_SBUS_USAGE_QUARTERS,
  PPI_SBUS_USAGE_YEARS,
  HPC_USER_SBUS_USAGE,
  NODE_DETAIL_VIEW_MONTHS,
  NODE_DETAIL_VIEW_QUARTERS,
  NODE_DETAIL_VIEW_YEARS,
  ACCOUNT_DETAIL_VIEW_MONTHS,
  ACCOUNT_DETAIL_VIEW_QUARTERS,
  ACCOUNT_DETAIL_VIEW_YEARS,
  COMPREP_EMAIL_ALERT_TABLE
} from '../../../../../utils/constants';

// ECMWF ADMIN TABLE DATA RENDERING
import {
  renderManageNodesAndAccessData,
  renderManageOverallocationsData,
  renderChangeLogData,
  checkForReadOrAdminAccess
} from './ecmwf-admin-tables';

// COMPREP TABLE DATA RENDERING
import {
  renderManageAllocationsData,
  renderManageEmailAlertsData,
  renderManageReadAccessData
} from './comprep-tables';

// SBUS USAGE TABLE DATA
import { checkForTimePeriodExistence, renderSBUsUsageData } from './sbus-usage-tables';

// TYPES
import { DataType } from '../types';
import { CachedNodeType } from '../../../../../redux/reducers/types';
import { BatchTimePeriodUsageResponse, GeneralDataNodeType, MainNodesType, WithAccessUsersType } from '../../../../../api/types';
import { arrayToObject } from '../../../../../utils/methods';
import { fetchChildrenWithAccess, fetchMainNodesChildren, fetchReadAndAdminAccesUsers, fetchTableChildrenGeneralNodes, fetchTableChildrenStatsForTimePeriod } from '../../../../../api/fetch-nodes';

// TABLE RENDER UTILS
export const renderDynamicTableData = (
  tableVariant: string,
  data: DataType,
  renderReadAccessData: Function,
  renderAdminAccessData: Function,
  renderFirstColumnData: JSX.Element,
  renderManageAllocationsInput: Function,
  renderEmailAlertPctInput: Function,
  renderOverallocationPct: Function,
  renderTransferAllocationsButton: Function,
  renderDescription: Function

) => {
  // ECMWF ADMIN RENDERING
  if (tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS) {
    return renderManageNodesAndAccessData(
      data,
      renderReadAccessData,
      renderAdminAccessData,
      renderFirstColumnData,
      renderDescription
    );
  }

  if (tableVariant === ECMWF_ADMIN_MANAGE_OVERALLOCATIONS) {
    return renderManageOverallocationsData(data, renderFirstColumnData, renderOverallocationPct);
  }

  if (tableVariant === ECMWF_ADMIN_CHANGE_LOG) {
    return renderChangeLogData(data);
  }

  // COMPUTER REPRESENTATIVE RENDERING
  if (tableVariant === COMPREP_MANAGE_ALLOCATIONS) {
    return renderManageAllocationsData(
      data,
      renderFirstColumnData,
      renderManageAllocationsInput,
      renderTransferAllocationsButton
    );
  }

  if (tableVariant === COMPREP_EMAIL_ALERT_TABLE) {
    return renderManageEmailAlertsData(data, renderFirstColumnData, renderEmailAlertPctInput);
  }

  if (tableVariant === COMPREP_MANAGE_READ_ACCESS) {
    return renderManageReadAccessData(
      data,
      renderReadAccessData,
      renderFirstColumnData
    );
  }


  // RENDERING FOR OTHER USER TYPES
  if (
    tableVariant === COMPREP_SBUS_USAGE_MONTHS ||
    tableVariant === COMPREP_SBUS_USAGE_QUARTERS ||
    tableVariant === COMPREP_SBUS_USAGE_YEARS ||
    tableVariant === COMPREP_SEARCH_USERS ||
    tableVariant === ECMWF_ADMIN_SEARCH_ACCOUNTS ||
    tableVariant === ECMWF_FINANCE_SBUS_USAGE_MONTHS ||
    tableVariant === ECMWF_FINANCE_SBUS_USAGE_QUARTERS ||
    tableVariant === ECMWF_FINANCE_SBUS_USAGE_YEARS ||
    tableVariant === PPI_SBUS_USAGE_MONTHS ||
    tableVariant === PPI_SBUS_USAGE_QUARTERS ||
    tableVariant === PPI_SBUS_USAGE_YEARS ||
    tableVariant === NODE_DETAIL_VIEW_MONTHS ||
    tableVariant === NODE_DETAIL_VIEW_QUARTERS ||
    tableVariant === NODE_DETAIL_VIEW_YEARS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_MONTHS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_QUARTERS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_YEARS ||
    tableVariant === HPC_USER_SBUS_USAGE
  ) {
    return renderSBUsUsageData(data, renderFirstColumnData, renderEmailAlertPctInput, tableVariant);
  }
};

// USER INTERFACE UTILS
export const indentChildren = (rowId: string): number => {
  // takes the FE generated row id, based on the number of occuring dots in the id -
  // gives an appropriate number for paddingLeft depending on hierachy
  // needs to be refactored

  const dots = rowId.match(/\./g);

  if (!dots) return 15;
  if (dots.length === 1) return 25;
  if (dots.length === 2) return 35;
  if (dots.length === 3) return 45;
  if (dots.length === 4) return 55;
  if (dots.length === 5) return 65;
  if (dots.length === 6) return 75;
  if (dots.length === 7) return 85;
  if (dots.length === 8) return 95;
  if (dots.length === 9) return 105;
  return 0;
};

export const checkForChildren = (nodes: CachedNodeType, data: DataType) => {
  const siblingNodesWithSameParent = Object.values(nodes).filter(node => {
    return node.parent?.id === data.org_id;
  }).filter(n => n);
  return siblingNodesWithSameParent;
};

export const APIfetchChildren = async (
  setClickedNode: Function,
  toggleExpansion: Function,
  setLoading: Function,
  isExpanded: boolean,
  data: DataType,
  tableVariant: string,
  nodes: CachedNodeType,
  updateNode: Function,
  setNodeChildren: Function,
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year' | undefined
): Promise<void> => {
  setClickedNode(data.org_id);
  toggleExpansion(!isExpanded);
  setLoading(true);
  ReactTooltip.hide();

  if (tableVariant === COMPREP_MANAGE_ALLOCATIONS && nodes && updateNode) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) { setLoading(false); return; }

    if (!siblingNodesWithSameParent.length) {
      const childrenWithGeneralData: GeneralDataNodeType[] | undefined = await fetchMainNodesChildren(data.children);
      if (childrenWithGeneralData?.length) {
        const newNodes = childrenWithGeneralData.filter(n => n).map(nodeObj => {
          return {
            [nodeObj.id]: {
              ...nodeObj,
              tableUsage: {},
              graphsUsage: {
                sbuUsage: {},
                accumulationUsage: {}
              },
              adminAccessUsers: [],
              readAccessUsers: []
            }
          };
        });
        const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
        updateNode(newNodesObjFormat);
        setLoading(false);
      } else {
        // setNodeChildren([]);
        setLoading(false);
        toast.error('Could not fetch members for this node');
      }
    }
  } else if (tableVariant === ECMWF_ADMIN_MANAGE_OVERALLOCATIONS && nodes && updateNode) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) { setLoading(false); return; }

    if (!siblingNodesWithSameParent.length) {
      const childrenWithGeneralData: GeneralDataNodeType[] | undefined = await fetchMainNodesChildren(data.children);
      if (childrenWithGeneralData?.length) {
        const newNodes = childrenWithGeneralData.filter(n => n).map(nodeObj => {
          return {
            [nodeObj.id]: {
              ...nodeObj,
              tableUsage: {},
              graphsUsage: {
                sbuUsage: {},
                accumulationUsage: {}
              },
              adminAccessUsers: [],
              readAccessUsers: []
            }
          };
        });

        const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
        updateNode(newNodesObjFormat);
        setLoading(false);
      } else {
        setNodeChildren([]);
        setLoading(false);
        toast.error('Could not fetch members for this node');
      }
    }
  } else if (tableVariant === COMPREP_EMAIL_ALERT_TABLE && nodes && updateNode) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) { setLoading(false); return; }

    if (!siblingNodesWithSameParent.length) {
      const childrenWithGeneralData: GeneralDataNodeType[] | undefined = await fetchMainNodesChildren(data.children);
      if (childrenWithGeneralData?.length) {
        const newNodes = childrenWithGeneralData.filter(n => n).map(nodeObj => {
          return {
            [nodeObj.id]: {
              ...nodeObj,
              tableUsage: {},
              graphsUsage: {
                sbuUsage: {},
                accumulationUsage: {}
              },
              adminAccessUsers: [],
              readAccessUsers: []
            }
          };
        });

        const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
        updateNode(newNodesObjFormat);
        setLoading(false);
      } else {
        setNodeChildren([]);
        setLoading(false);
        toast.error('Could not fetch members for this node');
      }
    }
  } else if (tableVariant === COMPREP_MANAGE_READ_ACCESS && nodes && updateNode) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) {
      const nodesHaveReadAccess = checkForReadOrAdminAccess('readAccessUsers', siblingNodesWithSameParent);

      if (nodesHaveReadAccess) { setLoading(false); return; }
      if (!nodesHaveReadAccess) {
        // fetch read access with the above nodes, do not fetch general data nodes
        const nodesWithUserAccess: WithAccessUsersType[] | undefined =
          await fetchReadAndAdminAccesUsers(siblingNodesWithSameParent);

        if (nodesWithUserAccess?.length) {
          const formatted = nodesWithUserAccess.filter(n => n).map(node => {
            return {
              [node.id]: {
                ...node,
                tableUsage: {},
                graphsUsage: {
                  sbuUsage: {},
                  accumulationUsage: {}
                }
              }
            };
          });
          const cacheFormattedNodes = arrayToObject(formatted);
          updateNode(cacheFormattedNodes);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error('Could not fetch members for this node');
        }
      }
    }
    if (!siblingNodesWithSameParent.length) {
      // fetch general data nodes + their read / admin accesses
      const nodesWithUserAccess: WithAccessUsersType[] | undefined = await fetchChildrenWithAccess(data.children);
      if (nodesWithUserAccess && nodesWithUserAccess.length) {
        const appended = nodesWithUserAccess.filter(n => n).map(node => {
          return {
            [node.id]: {
              ...node,
              tableUsage: {},
              graphsUsage: {
                sbuUsage: {},
                accumulationUsage: {}
              }
            }
          };
        });
        const cacheFormattedNodes = arrayToObject(appended);
        updateNode(cacheFormattedNodes);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Could not fetch members for this node');
      }
    }
  } else if (tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS && nodes && updateNode) {

    const siblingNodesWithSameParent = checkForChildren(nodes, data);

    if (siblingNodesWithSameParent.length) {
      const nodesHaveReadAccess = checkForReadOrAdminAccess('readAccessUsers', siblingNodesWithSameParent);
      const nodesHaveAdminAccess = checkForReadOrAdminAccess('adminAccessUsers', siblingNodesWithSameParent);

      // if they have 'readAccessUsers' and 'adminAccessUsers' properties..
      if (nodesHaveReadAccess && nodesHaveAdminAccess) {
        setLoading(false); return;
      }

      if (!nodesHaveReadAccess || !nodesHaveAdminAccess) {
        // fetch read and admin access with the above nodes, do not fetch general data nodes
        const nodesWithUserAccess: WithAccessUsersType[] | undefined =
          await fetchReadAndAdminAccesUsers(siblingNodesWithSameParent);

        if (nodesWithUserAccess?.length) {
          const formatted = nodesWithUserAccess.filter(n => n).map(node => {
            return {
              [node.id]: {
                ...node,
                tableUsage: {},
                graphsUsage: {
                  sbuUsage: {},
                  accumulationUsage: {}
                }
              }
            };
          });
          const cacheFormattedNodes = arrayToObject(formatted);
          updateNode(cacheFormattedNodes);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error('Could not fetch members for this node');
        }
      }
    }
    if (!siblingNodesWithSameParent.length) {
      // fetch general data nodes + their read / admin accesses
      const nodesWithUserAccess: WithAccessUsersType[] | undefined = await fetchChildrenWithAccess(data.children);
      if (nodesWithUserAccess && nodesWithUserAccess.length) {
        const appended = nodesWithUserAccess.filter(n => n).map(node => {
          return {
            [node.id]: {
              ...node,
              tableUsage: {},
              graphsUsage: {
                sbuUsage: {},
                accumulationUsage: {}
              }
            }
          };
        });
        const cacheFormattedNodes = arrayToObject(appended);
        updateNode(cacheFormattedNodes);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Could not fetch members for this node');
      }
    }
  } else {
    // FOR ANY OTHER EXPANDABLE TABLE TYPES M/Q/Y TABLES ETC

    if (nodes && timePeriod) {
      const {
        siblingNodesWithSameParent,
        nodesHaveDataForOption
      } = checkForTimePeriodExistence(nodes, timePeriod, data);
      // if clicked node's children exist but have no tableUsage data for requried timePeriod, fetch time period data
      if (siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
        const tableUsageStats: BatchTimePeriodUsageResponse | undefined =
          await fetchTableChildrenStatsForTimePeriod(timePeriod, data.children);

        if (tableUsageStats?.data.length && nodes && updateNode) {
          const newNodesWithStats = tableUsageStats?.data.filter(n => n).map(statsData => {
            return {
              [statsData.data[0].id]: {
                ...nodes[statsData.data[0].id],
                tableUsage: {
                  ...nodes[statsData.data[0].id].tableUsage,
                  [statsData.id]: statsData.data[0]
                }
              }
            };
          });
          const cacheFormattedNodes = arrayToObject(newNodesWithStats);
          updateNode(cacheFormattedNodes);
          setLoading(false);
        } else {
          toggleExpansion(isExpanded);
          setLoading(false);
          // setNodeChildren([]);
          toast.error('Failed to fetch members for this node. Please try again.');
        }
      }

      // if clicked node's children exist and they have required timeperiod, return
      if (siblingNodesWithSameParent?.length && nodesHaveDataForOption) {
        setLoading(false); return;
      }

      // if no children for clicked node, fetch both general data about each child + their usage
      if (!siblingNodesWithSameParent?.length && !nodesHaveDataForOption && timePeriod) {
        const childrenWithGeneralData: MainNodesType[] | undefined =
          await fetchTableChildrenGeneralNodes(data.children);
        const tableUsageStats: BatchTimePeriodUsageResponse | undefined =
          await fetchTableChildrenStatsForTimePeriod(timePeriod, data.children);

        if (childrenWithGeneralData?.length && tableUsageStats?.data.length && updateNode) {
          const newNodes = childrenWithGeneralData.filter(n => n).map(nodeObj => {
            return {
              [nodeObj.id]: {
                ...nodeObj,
                tableUsage: {},
                graphsUsage: {
                  sbuUsage: {},
                  accumulationUsage: {}
                },
                adminAccessUsers: [],
                readAccessUsers: []
              }
            };
          });
          const newNodesObjFormat: CachedNodeType = arrayToObject(newNodes);
          // assign each new node their usage-per-x stat data for tableUsage
          const newNodesWithStats = tableUsageStats?.data.filter(n => n).map(statsData => {
            return {
              [statsData.data[0].id]: {
                ...newNodesObjFormat[statsData.data[0].id],
                tableUsage: {
                  [statsData.id]: statsData.data[0]
                }
              }
            };
          });
          const cacheFormattedNodes = arrayToObject(newNodesWithStats);

          updateNode(cacheFormattedNodes);
          setLoading(false);
        } else {
          toggleExpansion(isExpanded);
          setLoading(false);
          // setNodeChildren([]);
          toast.error('Failed to fetch members for this node. Please try again.');
        }
      }
    }
  }
};