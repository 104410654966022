import React, { useState, useEffect } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import ReactTooltip from 'react-tooltip';
import toast from 'react-hot-toast';

import SelectInput from '../Select';
import { StyledInputGroup, StyledInput } from './styles';
import { setAccountEmailAlertPercentage } from '../../../api/confirmations';
import { DropdownOption } from '../Select/types';
import { setDisplayUnit } from '../../../redux/actions';
import { connect } from 'react-redux';
import { GlobalStateType } from '../../../redux/reducers/types';
import config from '../../../config';

type Option = {
  value: string;
  label: string;
  email_alert_percentage: number;
  sbu_allocation: string;
  sbu_usage: string;
};

interface InputGroupProps {
  options: Option[];
  selectedAccount: Function;
  currentOption: Option;
  emailInputValue: string;
  displayUnit: string;
  setDisplayUnit: Function;
}

const InputGroup = (
  { options, selectedAccount, currentOption, emailInputValue, displayUnit, setDisplayUnit }: InputGroupProps) => {
  const [textInputValue, setTextInputValue] = useState('');
  const [emailAlertPctLoading, setEAPCTLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);

  useEffect(() => {

    setDropdownOptions(options);
    setTextInputValue(emailInputValue);
  }, []);

  useEffect(() => {
    setTextInputValue(emailInputValue);
  }, [selectedAccount]);

  const handleEmailAlertPctChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbersInString = event.target.value.replace(/\D/, '');
    let onlyNumbersFromZeroToHundred = Math.min(100, Math.max(0, parseInt(onlyNumbersInString)));

    setTextInputValue(onlyNumbersFromZeroToHundred.toString());
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {

      setEAPCTLoading(true);

      try {
        const res = await setAccountEmailAlertPercentage(currentOption.value, parseInt(textInputValue));

        if (res) {
          // updates email alert % amongst options  
          const updatedOptions: Option[] = dropdownOptions.map(x => {
            return x.label === currentOption.label ?
              { ...x, email_alert_percentage: parseInt(textInputValue) } :
              x;
          });

          setDropdownOptions(updatedOptions);
          setEAPCTLoading(false);
          toast.success(`Email Alert Percentage set to ${textInputValue}%`);
        } else {
          setEAPCTLoading(false);
          toast.error('Error setting email alert percentage, please try again.');
          setTextInputValue(currentOption.email_alert_percentage.toString());
        }
      } catch (error) {
        console.error('Error setting email alert pct for account.', error);
        toast.error('Error setting email alert percentage, please try again.');
        setTextInputValue(currentOption.email_alert_percentage.toString());
        setEAPCTLoading(false);
      }
    }
    return;
  };

  const handleSelectedOption = (option: Option) => {
    selectedAccount(option);
  };


  const unitOptions = [{ label: 'SBU', value: 'SBU' }, { label: 'Currency', value: 'Currency' }];

  const handleSelectedUnit = (option: DropdownOption) => {
    setDisplayUnit(option.value);
  };

  return (
    <>
      {emailAlertPctLoading ? (
        <div style={{ position: 'relative' }}>
          <span style={{ position: 'absolute', top: 67, left: -45 }}>
            <SpinnerCircularFixed size={30} thickness={152} speed={178} color="rgba(30,144,255, 0.5)" secondaryColor="rgba(0, 0, 0, 0.17)" />
          </span>
        </div>
      ) : null}
      <StyledInputGroup data-testid="top-input-group">
        {config.currencyDisplayEnabled ?
          <form style={{ marginRight: '1em' }}>
            <label style={{ display: 'block', marginBottom: '0.5em' }}>
              <b style={{ fontWeight: 600 }}>Select data unit</b>
            </label>
            <SelectInput
              options={unitOptions}
              toParent={handleSelectedUnit}
              currentOption={{ label: displayUnit, value: displayUnit }}
            />
          </form>
          : null}
        {/* <form data-tip data-for="email-alert-percentage" onSubmit={e => e.preventDefault()}>
          <label style={{ display: 'block', marginBottom: '0.5em' }}>
            <b style={{ fontWeight: 600 }}>Email alert %</b>
          </label>
          <StyledInput
            type="number"
            placeholder="Enter %"
            value={textInputValue}
            onBlur={
              (e: React.ChangeEvent<HTMLInputElement>) => e.target.value === '' && setTextInputValue(emailInputValue)
            }
            onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
            onChange={handleEmailAlertPctChange}
            onKeyDown={(e) => handleKeyDown(e)}
            min={0}
            max={100}
            disabled={emailAlertPctLoading}
          />
        </form> */}
        <form>
          <label style={{ display: 'block', marginBottom: '0.5em' }}>
            <b style={{ fontWeight: 600 }}>Select an account</b>
          </label>
          <SelectInput
            options={dropdownOptions}
            toParent={handleSelectedOption}
            currentOption={currentOption}
          />
        </form>
      </StyledInputGroup>
      {/* <ReactTooltip id='email-alert-percentage' type='dark'>
        <span>Type and press Enter to set usage % to receive email alerts if exceeded</span>
      </ReactTooltip> */}
    </>
  );
};

const mapStateToProps = (state: GlobalStateType) => {
  return {
    displayUnit: state.displayUnit
  };
};

export default connect(
  mapStateToProps, { setDisplayUnit }
)(InputGroup);
