import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import Table from '../../../../components/Table';
import Cards from '../../../../components/Containers/Cards';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';
import TableLoader from '../../../../components/Loaders/TableLoader';
import CustomNotification from '../../../../components/Notification';
import CardsLoader from '../../../../components/Loaders/CardsLoader';
import DualGraphs from '../../../../components/Charts/DualGraphs';

import {
  COMPREP_EMAIL_ALERT_TABLE,
  COMPREP_SBUS_USAGE_MONTHS,
  COMPREP_SBUS_USAGE_QUARTERS,
  COMPREP_SBUS_USAGE_YEARS
} from '../../../../utils/constants';

import { extractGraphFormatData } from '../../../../api/utils';
import {
  DEFAULT_NODESHPC_DATA,
  fetchAccumulationGraphData,
  fetchGeneralNodesData,
  fetchMonthTableNodes,
  fetchQuarterTableNodes,
  fetchUsageGraphData,
  fetchYearTableNodes,
  handleEmailAlertTableSearch,
  handleGraphOptions,
  handleMonthsTableSearch,
  handleQuartersTableSearch,
  handleYearsTableSearch
} from './utils';

import { GeneralDataNodeType, NodeUsageType, UsageType, VariantObjectType } from '../../../../api/types';
import { NodesHPCUsageOverviewType, GraphOption, SearchOption, NodesHPCCardsDataType, EmailAlertNodeType } from './types';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { updateNode } from '../../../../redux/actions';
import { formatNumber, isEmpty } from '../../../../utils/methods';

interface INodesHPCUsage {
  initialNodes: GeneralDataNodeType[];
  updateNode: Function;
  nodes: CachedNodeType;
}

const NodesHPCUsage = (props: INodesHPCUsage) => {
  // COMPONENT STATE
  const [twoColumnGraphs, setTwoColumnGraphs] = useState<boolean>(true);
  const [graphCurrentOption, setGraphCurrentOption] = useState<GraphOption>({ value: '', label: '' });

  const [graphsOptions, setGraphsOptions] = useState<GraphOption[]>([]);
  const [usageGraphVariants, setUsageGraphVariants] = useState<VariantObjectType[]>([]);
  const [accumulationGraphVariants, setAccumulationGraphVariants] = useState<VariantObjectType[]>([]);


  const [isUsageGraphLoading, setUsageGraphLoading] = useState<boolean>(true);
  const [isAccumulationGraphLoading, setAccumulationGraphLoading] = useState<boolean>(true);
  const [hasFetchedSBUGraph, setHasFetchedSBUGraph] = useState<boolean>(false);
  const [hasFetchedAccumGraph, setHasFetchedAccumGraph] = useState<boolean>(false);


  // Table State

  const [emailAlertTableIsLoading, setEmailAlertTableLoading] = useState<boolean>(true);
  const [emailAlertTableData, setEmailAlertTableData] = useState<EmailAlertNodeType[]>([]);
  const [emailAlertTableSearchResult, setEmailAlertTableSearchResult] = useState<EmailAlertNodeType[]>();

  const [monthsTableHeaders, setMonthsTableHeaders] = useState<string[]>([]);
  const [monthsTableSearchResult, setMonthsTableSearchResult] = useState<NodeUsageType[]>([]);
  const [monthsTableIsLoading, setMonthsTableLoading] = useState<boolean>(true);
  const [hasFetchedMonths, setHasFetchedMonths] = useState<boolean>(false);

  const [quartersTableHeaders, setQuartersTableHeaders] = useState<string[]>([]);
  const [quartersTableSearchResult, setQuartersTableSearchResult] = useState<NodeUsageType[]>([]);
  const [quartersTableIsLoading, setQuartersTableLoading] = useState<boolean>(true);
  const [hasFetchedQuarters, setHasFetchedQuarters] = useState<boolean>(false);

  const [yearsTableHeaders, setYearsTableHeaders] = useState<string[]>([]);
  const [yearsTableSearchResult, setYearsTableSearchResult] = useState<NodeUsageType[]>([]);
  const [yearsTableIsLoading, setYearsTableLoading] = useState<boolean>(true);
  const [hasFetchedYears, setHasFetchedYears] = useState<boolean>(false);

  const [cardsLoading, setCardsLoading] = useState(true);
  const [generalNodesData, setGeneralNodesData] = useState<NodesHPCUsageOverviewType>(DEFAULT_NODESHPC_DATA);

  // FETCHES CARD DATA
  useEffect(() => {
    if (props.initialNodes) fetchGeneralNodesData(setGeneralNodesData, setCardsLoading, props.initialNodes);
  }, [props.initialNodes]);


  // FETCHES INITIAL SBU USAGE GRAPH DATA
  useEffect(() => {
    if (props.initialNodes && !isEmpty(props.nodes) && !hasFetchedSBUGraph) {
      fetchUsageGraphData(
        isUsageGraphLoading,
        setUsageGraphLoading,
        graphCurrentOption,
        props.updateNode,
        setUsageGraphVariants,
        props.initialNodes,
        props.nodes,
        setHasFetchedSBUGraph
      );
    }
  }, [props.initialNodes, props.nodes]);

  useEffect(() => {
    // craft and feed initialnodes to table via setTableData
    if (props.initialNodes.length && !isEmpty(props.nodes)) {
      const tableFormatted = props.initialNodes.map(initNode => {
        return {
          name: props.nodes[initNode.id].name,
          description: props.nodes[initNode.id].description,
          category: props.nodes[initNode.id].type,
          org_id: props.nodes[initNode.id].id,
          parent_id: props.nodes[initNode.id].parent?.id || null,
          allocation: props.nodes[initNode.id].sbu_allocation === '0.00' ? '-' : formatNumber(parseInt(props.nodes[initNode.id].sbu_allocation)),
          sbu_usage: formatNumber(parseInt(props.nodes[initNode.id].sbu_usage)),
          children: props.nodes[initNode.id].children,
          // sbu_usage_percentage: props.nodes[initNode.id].sbu_usage_percentage,
          sbu_usage_percentage: props.nodes[initNode.id].sbu_allocation === '0.00' ? '-' : props.nodes[initNode.id].sbu_usage_percentage > 999 ? 999 : props.nodes[initNode.id].sbu_usage_percentage,
          email_alert_pct: props.nodes[initNode.id].email_alert_percentage
        };
      });

      setEmailAlertTableLoading(false);
      setEmailAlertTableData(tableFormatted);
    }
  }, [props.nodes]);

  // FETCHES INITIAL SBU ACCUMULATION GRAPH DATA
  useEffect(() => {
    if (props.initialNodes && !isEmpty(props.nodes) && !hasFetchedAccumGraph && hasFetchedSBUGraph) {
      fetchAccumulationGraphData(
        isAccumulationGraphLoading,
        setAccumulationGraphLoading,
        props.updateNode,
        setAccumulationGraphVariants,
        graphCurrentOption,
        props.initialNodes,
        props.nodes,
        setHasFetchedAccumGraph
      );
    }
  }, [props.initialNodes, props.nodes]);

  // CRAFT GRAPH OPTIONS USEFFECT
  useEffect(() => {
    if (!graphsOptions.length && usageGraphVariants.length && accumulationGraphVariants.length) {
      handleGraphOptions(
        usageGraphVariants,
        accumulationGraphVariants,
        setGraphsOptions,
        setGraphCurrentOption
      );
    }
  }, [usageGraphVariants, accumulationGraphVariants]);


  // FETCHES MONTH TABLE DATA
  useEffect(() => {
    if (props.initialNodes.length &&
      !isEmpty(props.nodes) &&
      !monthsTableHeaders.length &&
      hasFetchedSBUGraph && hasFetchedAccumGraph &&
      !hasFetchedMonths
    ) {
      fetchMonthTableNodes(
        props.updateNode,
        setMonthsTableHeaders,
        setMonthsTableLoading,
        props.initialNodes,
        props.nodes,
        setHasFetchedMonths
      );
    }
  }, [props.initialNodes, props.nodes]);

  // FETCHES QUARTERLY TABLE DATA
  useEffect(() => {
    if (props.initialNodes.length &&
      !isEmpty(props.nodes) &&
      !quartersTableHeaders.length &&
      !hasFetchedQuarters &&
      hasFetchedSBUGraph && hasFetchedAccumGraph &&
      hasFetchedMonths
    ) {
      fetchQuarterTableNodes(
        props.updateNode,
        setQuartersTableHeaders,
        setQuartersTableLoading,
        props.initialNodes,
        props.nodes,
        setHasFetchedQuarters
      );
    }

  }, [props.initialNodes, props.nodes]);

  // FETCHES YEARS TABLE DATA
  useEffect(() => {
    if (props.initialNodes.length &&
      !isEmpty(props.nodes) &&
      !yearsTableHeaders.length &&
      hasFetchedSBUGraph && hasFetchedAccumGraph &&
      !hasFetchedYears &&
      hasFetchedMonths &&
      hasFetchedQuarters
    ) {
      fetchYearTableNodes(
        props.updateNode,
        setYearsTableHeaders,
        setYearsTableLoading,
        props.initialNodes,
        props.nodes,
        setHasFetchedYears
      );
    }

  }, [props.initialNodes, props.nodes]);


  // FETCHES BOTH GRAPH DATA FOR SELECTED TIME OPTION (i.e, 7,30,180 days, Current Year etc)
  const handleGraphOptionChange = (option: GraphOption) => {
    setGraphCurrentOption(option);
    setHasFetchedAccumGraph(false);
    setHasFetchedSBUGraph(false);

    let sbuUsageObj = props.nodes[props.initialNodes[0].id].graphsUsage.sbuUsage;
    let accumUsageObj = props.nodes[props.initialNodes[0].id].graphsUsage.accumulationUsage;
    let hasSBUUsage = Object.prototype.hasOwnProperty.call(sbuUsageObj, option.value);
    let hasAccumUsage = Object.prototype.hasOwnProperty.call(accumUsageObj, option.value);


    if (!hasSBUUsage && !hasAccumUsage) {
      if (!hasFetchedSBUGraph) {
        fetchUsageGraphData(
          isUsageGraphLoading,
          setUsageGraphLoading,
          option,
          props.updateNode,
          () => '',
          props.initialNodes,
          props.nodes,
          setHasFetchedSBUGraph
        );
      }

      if (hasFetchedSBUGraph) {
        fetchAccumulationGraphData(
          isAccumulationGraphLoading,
          setAccumulationGraphLoading,
          props.updateNode,
          () => '',
          option,
          props.initialNodes,
          props.nodes,
          setHasFetchedAccumGraph
        );
      }
    } else {
      setHasFetchedAccumGraph(true);
      setHasFetchedSBUGraph(true);
    }
  };


  const extractTableData = (statId: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year') => {
    if (props.initialNodes.length && !isEmpty(props.nodes)) {
      return props.initialNodes.map(initNode => {
        let statTableUsage = props.nodes[initNode.id].tableUsage[statId];
        return statTableUsage;
      }).filter(n => n);
    }

    return [];
  };

  const extractGraphData = (graph: 'sbuUsage' | 'accumulationUsage', selectedOption: GraphOption) => {
    if (props.initialNodes?.length) {
      let sbuUsageObj = props.nodes[props.initialNodes[0].id].graphsUsage.sbuUsage;
      let accumUsageObj = props.nodes[props.initialNodes[0].id].graphsUsage.accumulationUsage;
      let hasSBUUsage = Object.prototype.hasOwnProperty.call(sbuUsageObj, selectedOption.value);
      let hasAccumUsage = Object.prototype.hasOwnProperty.call(accumUsageObj, selectedOption.value);

      if (props.initialNodes.length &&
        !isEmpty(props.nodes) &&
        (hasFetchedSBUGraph || hasFetchedAccumGraph) &&
        (hasAccumUsage && hasSBUUsage)
      ) {
        const graphUsageByNode: UsageType[] = props.initialNodes.map(initNode => {
          return props.nodes[initNode.id].graphsUsage[graph][selectedOption.value];
        });

        const graphFormat = graphUsageByNode.length && graphUsageByNode.some(i => i !== undefined) ?
          extractGraphFormatData([], graphUsageByNode) : [];

        return graphFormat;
      }
    }

    return [];
  };

  // COMPONENT METHODS

  const renderEmailAlertTable = (): React.ReactElement | undefined => {
    if (emailAlertTableIsLoading) return <TableLoader />;
    return (
      <>
        <Typography as="p" margin="0 0 1em 0" fontSize={22}>Manage alerts</Typography>
        <Table
          showExpandButton
          headers={[
            'Node',
            'Allocation',
            'Usage',
            'Usage %'
            // 'Email alert %'
          ]}
          rowsData={emailAlertTableData}
          tableVariant={COMPREP_EMAIL_ALERT_TABLE}
          transferSearchTerm={
            (option: SearchOption) => handleEmailAlertTableSearch(option, setEmailAlertTableSearchResult)
          }
          searchTermResult={emailAlertTableSearchResult}
        />
      </>
    );
  };

  const renderMonthsTable = (): React.ReactElement | undefined => {
    const STAT_ID = 'usage-per-month';
    if (monthsTableIsLoading) return <TableLoader />;
    if (!monthsTableIsLoading && !monthsTableHeaders) return;

    return (
      <>
        <Typography as="p" margin="0 0 1em 0" fontSize={22}>SBU usage per month</Typography>
        <Table
          showExpandButton
          timePeriod={STAT_ID}
          headers={monthsTableHeaders}
          rowsData={hasFetchedMonths ? extractTableData(STAT_ID) : []}
          tableVariant={COMPREP_SBUS_USAGE_MONTHS}
          transferSearchTerm={(option: SearchOption) => handleMonthsTableSearch(option, setMonthsTableSearchResult)}
          searchTermResult={monthsTableSearchResult}
        />
      </>
    );
  };

  const renderQuartersTable = (): React.ReactElement | undefined => {
    const STAT_ID = 'usage-per-quarter';
    if (quartersTableIsLoading) return <TableLoader />;
    if (!quartersTableIsLoading && !quartersTableHeaders) return;

    return (
      <>
        <Typography as="p" margin="1em 0 1em 0" fontSize={22}>SBU usage per quarter</Typography>
        <Table
          showExpandButton
          timePeriod={STAT_ID}
          headers={quartersTableHeaders}
          rowsData={hasFetchedQuarters ? extractTableData(STAT_ID) : []}
          tableVariant={COMPREP_SBUS_USAGE_QUARTERS}
          transferSearchTerm={(option: SearchOption) => handleQuartersTableSearch(option, setQuartersTableSearchResult)}
          searchTermResult={quartersTableSearchResult}
        />
      </>
    );
  };

  const renderYearsTable = (): React.ReactElement | undefined => {
    const STAT_ID = 'usage-per-year';
    if (yearsTableIsLoading) return <TableLoader />;
    if (!yearsTableIsLoading && !yearsTableHeaders) return;

    return (
      <>
        <Typography as="p" margin="1em 0 1em 0" fontSize={22}>SBU usage per year</Typography>
        <Table
          showExpandButton
          timePeriod={STAT_ID}
          headers={yearsTableHeaders}
          rowsData={hasFetchedYears ? extractTableData(STAT_ID) : []}
          tableVariant={COMPREP_SBUS_USAGE_YEARS}
          transferSearchTerm={(option: SearchOption) => handleYearsTableSearch(option, setYearsTableSearchResult)}
          searchTermResult={yearsTableSearchResult}
        />
      </>
    );
  };

  const cardsData: NodesHPCCardsDataType = {
    sbus_allocation: generalNodesData.totalAllocation,
    sbus_usage: generalNodesData.totalUsage,
    sbus_remaining: generalNodesData.totalUndistributed,
    nb_members: generalNodesData.nbNodes
  };

  return (
    <>
      <Container margin='0'>
        {/* TOP SECTION */}
        <Section margin='0 0 1.5em 0' flex>
          <div>
            <Typography as="h1" margin="0.5em 0 0 0">Nodes & accounts</Typography>
            <Typography as="h3" margin="0" fontSize={22}>Nodes & accounts you have read access to</Typography>
          </div>
        </Section>
        {cardsLoading ? <CardsLoader /> : (
          <Cards
            allocationText="Total allocation"
            usageText="Total usage"
            remainingText="Total unallocated"
            accountData={cardsData}
            variant="Nodes"
          />
        )}

        <hr />

        {/* USAGE GRAPHS */}
        <Section margin="0 0 2em 0">
          <DualGraphs
            options={graphsOptions}
            handleGraphCurrentOption={(option: GraphOption) => handleGraphOptionChange(option)}
            graphCurrentOption={graphCurrentOption}
            setTwoColumnGraphs={() => setTwoColumnGraphs(!twoColumnGraphs)}
            twoColumnGraphs={twoColumnGraphs}
            isUsageGraphLoading={isUsageGraphLoading}
            usageGraphData={hasFetchedSBUGraph ? extractGraphData('sbuUsage', graphCurrentOption) : []}
            isAccumulationGraphLoading={isAccumulationGraphLoading}
            accumulationGraphData={hasFetchedAccumGraph ? extractGraphData('accumulationUsage', graphCurrentOption) : []}
            tiltYAxis
            showInputGroupLoader={hasFetchedYears ? false : true}
            nodes={props.nodes}
            updateNode={props.updateNode}
            showParent
          />
        </Section>

        <hr />

        <Section margin="1.5em 0 1.5em 0">
          {renderEmailAlertTable()}
        </Section>

        <hr />

        {/* SBU USAGE PER MONTH */}
        <Section margin="1.5em 0 1.5em 0">
          {renderMonthsTable()}
        </Section>
        <hr />

        {/* SBU USAGE PER QUARTER */}
        <Section margin="0 0 1.5em 0">
          {renderQuartersTable()}
        </Section>
        <hr />

        {/* SBU USAGE PER PAST YEARS */}
        <Section margin="0 0 2.5em 0">
          {renderYearsTable()}
        </Section>
      </Container>
      {/* TOOLTIP AND TOASTER NOFICATIONS */}
      <CustomNotification />
      <ReactTooltip id='graph-csv-download' type='dark'>
        <span>Download graph CSV</span>
      </ReactTooltip>
    </>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  initialNodes: GeneralDataNodeType[];
  nodes: CachedNodeType
} => {
  return {
    initialNodes: state.initialNodes,
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(NodesHPCUsage);
