import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import Table from '../../../../components/Table';
import Cards from '../../../../components/Containers/Cards';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';
import UsagePieChart from '../../../../components/Charts/UsagePieChart';
import PieChartContainer from '../../../../components/Charts/UsagePieChart/PieChartContainer';
import AllocationsBarChart from '../../../../components/Charts/BarChart';

import {
  HPC_USER_SBUS_USAGE,
  ACCOUNT_DETAIL_VIEW_MONTHS,
  ACCOUNT_DETAIL_VIEW_QUARTERS,
  ACCOUNT_DETAIL_VIEW_YEARS
} from '../../../../utils/constants';

import { AccountUsageType, VariantObjectType } from '../../../../api/types';

import { pieTableHeaders } from '../../../../utils/tableHeaders';

import CloseIcon from '../../../../components/Icons/Close/CloseIcon';
import CardsLoader from '../../../../components/Loaders/CardsLoader';
import { formatNumber } from '../../../../utils/methods';
import TableLoader from '../../../../components/Loaders/TableLoader';
import PieChartLoader from '../../../../components/Loaders/PieChartLoader';

import DualGraphs from '../../../../components/Charts/DualGraphs';
import CustomNotification from '../../../../components/Notification';

import { GraphOption, IAccountDetails, UserTableDataType, PieChartDataType, AccountDetailCardsData } from './types';
import {
  fetchAccountData,
  fetchUsageGraphData,
  fetchAccumulationGraphData,
  handleGraphOptions,
  fetchUserPieChartData,
  fetchMonthsTableData,
  fetchQuartersTableData,
  fetchYearsTableData,
  handleGraphCurrentOption,
  DEFAULT_CARDDATA
} from './utils';
import { useLocation } from 'react-router-dom';
import config from '../../../../config';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import { GlobalStateType } from '../../../../redux/reducers/types';
import { connect } from 'react-redux';
import { setDisplayUnit } from '../../../../redux/actions';
import SelectInput from '../../../../components/Inputs/Select';
import { DropdownOption } from '../../../../components/Inputs/Select/types';

const AccountDetails = ({ onRequestClose, memberName = 'Account', accountId = '', displayUnit, setDisplayUnit }: IAccountDetails) => {
  // COMPONENT STATE
  const [twoColumnGraphs, setTwoColumnGraphs] = useState<boolean>(true);
  const [graphCurrentOption, setGraphCurrentOption] = useState<GraphOption>({ value: '', label: '' });
  const [graphsOptions, setGraphsOptions] = useState<GraphOption[]>([]);

  // Graph State
  const [usageGraphVariants, setUsageGraphVariants] = useState<VariantObjectType[]>([]);
  const [accumulationGraphVariants, setAccumulationGraphVariants] = useState<VariantObjectType[]>([]);
  // const [pieChartVariants, setPieChartVariants] = useState([]);

  const [usageGraphData, setUsageGraphData] = useState<AccountUsageType[]>([]);
  const [accumulationGraphData, setAccumulationGraphData] = useState<AccountUsageType[]>([]);

  // Table State
  const [userTableData, setUserTableData] = useState<UserTableDataType[]>([]);

  const [monthsTableData, setMonthsTableData] = useState<AccountUsageType[]>([]);
  const [monthsTableHeaders, setMonthsTableHeaders] = useState<string[]>([]);
  const [monthsTableIsLoading, setMonthsTableLoading] = useState<boolean>(true);

  const [quartersTableData, setQuartersTableData] = useState<AccountUsageType[]>([]);
  const [quartersTableHeaders, setQuartersTableHeaders] = useState<string[]>([]);
  const [quartersTableIsLoading, setQuartersTableLoading] = useState<boolean>(true);

  const [yearsTableData, setYearsTableData] = useState<AccountUsageType[]>([]);
  const [yearsTableHeaders, setYearsTableHeaders] = useState<string[]>([]);
  const [yearsTableIsLoading, setYearsTableLoading] = useState<boolean>(true);

  const [isUsageGraphLoading, setUsageGraphLoading] = useState<boolean>(true);
  const [isAccumulationGraphLoading, setAccummulationGraphLoading] = useState<boolean>(true);

  const [pieChartData, setPieChartData] = useState<PieChartDataType[]>([]);
  const [isPieChartLoading, setPieChartLoading] = useState<boolean>(true);

  const [cardsLoading, setCardsLoading] = useState<boolean>(true);
  const [cardsData, setCardsData] = useState<AccountDetailCardsData>(DEFAULT_CARDDATA);

  const [accountDescription, setAccountDescription] = useState<string>('');

  const location = useLocation();
  const [isDirect, setIsDirect] = useState<boolean>(false);
  const directURL = `${window.location.origin}${config.mountPath}account-details/${memberName}`;
  // check if loaded directly
  useEffect(() => {
    if (location.pathname.includes('account-details')) {
      setIsDirect(true);
    }
  }, []);

  // FETCH ACCOUNT DATA
  useEffect(() => {
    // check if accounts exist in new redux store state: accounts:{}, similar to nodes:{}
    // if !exist, fetch, same pattern as in Node Detail
    fetchAccountData(memberName, setCardsData, setCardsLoading, setAccountDescription);
  }, []);


  // USAGE GRAPH USEEFFECT
  useEffect(() => {
    // if (selectedAccountOption.value.length){
    fetchUsageGraphData(
      memberName,
      isUsageGraphLoading,
      setUsageGraphLoading,
      setUsageGraphData,
      setUsageGraphVariants,
      displayUnit
    );
    // }
  }, [displayUnit]);


  // ACCUMULATION GRAPH USEEFFECT
  useEffect(() => {
    // if (selectedAccountOption.value.length){
    fetchAccumulationGraphData(
      memberName,
      isAccumulationGraphLoading,
      setAccummulationGraphLoading,
      setAccumulationGraphData,
      setAccumulationGraphVariants,
      displayUnit
    );
    // }
  }, [displayUnit]);

  // CRAFT GRAPH OPTIONS USEFFECT
  useEffect(() => {
    // if (usageGraphVariants.length && accumulationGraphVariants.length) {
    handleGraphOptions(
      usageGraphVariants,
      accumulationGraphVariants,
      setGraphsOptions,
      setGraphCurrentOption,
      graphCurrentOption
    );
    // }
  }, [usageGraphVariants, accumulationGraphVariants]);

  useEffect(() => {
    if (graphCurrentOption.value) {
      handleGraphCurrentOption(
        memberName,
        graphCurrentOption,
        setGraphCurrentOption,
        setUsageGraphLoading,
        setAccummulationGraphLoading,
        setUsageGraphData,
        setAccumulationGraphData,
        displayUnit
      );
    }
  }, [displayUnit]);

  // PIE CHART USEEFFFECT
  useEffect(() => {
    if (graphCurrentOption && graphCurrentOption.value.length) {
      fetchUserPieChartData(
        isPieChartLoading,
        setPieChartLoading,
        memberName,
        graphCurrentOption,
        setPieChartData,
        setUserTableData,
        displayUnit
      );
    }

  }, [graphCurrentOption, displayUnit]);

  // MONTHS TABLE DATA
  useEffect(() => {
    fetchMonthsTableData(
      memberName,
      setMonthsTableData,
      setMonthsTableHeaders,
      setMonthsTableLoading,
      displayUnit
    );
  }, [displayUnit]);

  // QUARTER TABLE DATA
  useEffect(() => {
    fetchQuartersTableData(
      memberName,
      setQuartersTableData,
      setQuartersTableHeaders,
      setQuartersTableLoading,
      displayUnit
    );
  }, [displayUnit]);

  // YEARS TABLE DATA
  useEffect(() => {
    fetchYearsTableData(
      memberName,
      setYearsTableData,
      setYearsTableHeaders,
      setYearsTableLoading,
      displayUnit
    );
  }, [displayUnit]);


  const renderMonthsTable = (): React.ReactElement => {
    if (monthsTableIsLoading) {
      return <TableLoader />;
    }

    if (!monthsTableIsLoading && !monthsTableHeaders && !monthsTableData) {
      return <div>No months usage data to display</div>;
    }

    if (!monthsTableIsLoading && !monthsTableData.length) {
      return <div>No months usage data to display</div>;
    }

    return (
      <>
        <Typography as="p" margin="0 0 1em 0" fontSize={22}>SBU usage per month</Typography>
        <Table
          showExpandButton
          timePeriod={'usage-per-month'}
          headers={monthsTableHeaders}
          rowsData={monthsTableData}
          tableVariant={ACCOUNT_DETAIL_VIEW_MONTHS}
          transferSearchTerm={() => null}
          alignCentreName
          hideSearch
        />
      </>
    );
  };

  const renderQuartersTable = (): React.ReactElement | undefined => {
    if (quartersTableIsLoading) {
      return <TableLoader />;
    }

    if (!quartersTableIsLoading && !quartersTableHeaders && !quartersTableData) {
      return;
    }

    if (!quartersTableIsLoading && !quartersTableData.length) {
      return <div>No quarters usage data to display</div>;
    }

    return (
      <>
        <Typography as="p" margin="1em 0 1em 0" fontSize={22}>SBU usage per quarter</Typography>
        <Table
          showExpandButton
          timePeriod={'usage-per-quarter'}
          headers={quartersTableHeaders}
          rowsData={quartersTableData}
          tableVariant={ACCOUNT_DETAIL_VIEW_QUARTERS}
          transferSearchTerm={() => null}
          alignCentreName
          hideSearch
        />
      </>
    );
  };

  const renderYearsTable = (): React.ReactElement | undefined => {
    if (yearsTableIsLoading) {
      return <TableLoader />;
    }

    if (!yearsTableIsLoading && !yearsTableHeaders && !yearsTableData) {
      return;
    }

    if (!yearsTableIsLoading && !yearsTableData.length) {
      return <div>No years usage data to display</div>;
    }

    return (
      <>
        <Typography as="p" margin="1em 0 1em 0" fontSize={22}>SBU usage per year</Typography>
        <Table
          showExpandButton
          timePeriod={'usage-per-year'}
          headers={yearsTableHeaders}
          rowsData={yearsTableData}
          tableVariant={ACCOUNT_DETAIL_VIEW_YEARS}
          transferSearchTerm={() => null}
          alignCentreName
          hideSearch
        />
      </>
    );
  };

  const accountDetailsCardsData = {
    sbus_allocation: formatNumber(parseInt(displayUnit === 'SBU' ? cardsData.sbus_allocation : cardsData.currency_allocation)),
    sbus_usage: formatNumber(parseInt(displayUnit === 'SBU' ? cardsData.sbus_usage : cardsData.currency_usage)),
    sbus_usage_percentage: formatNumber(displayUnit === 'SBU' ? cardsData.sbus_usage_percentage : cardsData.currency_usage_percentage),
    sbus_remaining: formatNumber(displayUnit === 'SBU' ? cardsData.sbus_remaining : cardsData.currency_remaining),
    nb_members: cardsData.nb_members
  };


  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(directURL);
    toast.success('Direct URL coppied to clipboard.', { id: directURL });
  };

  const unitOptions = [{ label: 'SBU', value: 'SBU' }, { label: 'Currency', value: 'Currency' }];

  const handleSelectedUnit = (option: DropdownOption) => {
    setDisplayUnit(option.value);
  };
  const currencySymbol = displayUnit === 'Currency' ? cardsData.currency : 'SBU';

  const allocationsBarChartData = [{
    name: 'Total',
    used: displayUnit === 'SBU' ? parseInt(cardsData.sbus_usage) : parseInt(cardsData.currency_usage),
    usedPct: displayUnit === 'SBU' ? cardsData.sbus_usage_percentage : cardsData.currency_usage_percentage,
    max: displayUnit === 'SBU' ? parseInt(cardsData.sbus_allocation) : parseInt(cardsData.currency_allocation)
  }];

  return (
    <>
      <Container margin='0' style={{ height: '95vh', overflowY: 'scroll' }}>
        {/* TOP SECTION */}
        <Section margin='0 0 1.85em 0' flex>
          <div>
            <Typography as="h1" margin="1em 0 0 0">{memberName}</Typography>
            <Typography as="h3" margin="0" fontSize={22}>Details for account</Typography>
            <Typography as='p' margin='0'>{accountId || memberName}{accountDescription ? ` - ${accountDescription}` : null}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <CloseIcon onClick={() => onRequestClose()} width="50px" height="50px" />
            <div style={{ display: 'flex', gap: '3em', alignItems: 'end' }}>
              {config.currencyDisplayEnabled ? 
                <form style={{ fontSize: 'small', marginRight: '3em' }}>
                  <label style={{ display: 'block', marginBottom: '0.5em' }}>
                    <b style={{ fontWeight: 600 }}>Select data unit</b>
                  </label>
                  <div style={{ width: '8em', display: 'flex' }}>
                    <SelectInput
                      options={unitOptions}
                      toParent={handleSelectedUnit}
                      currentOption={{ label: displayUnit, value: displayUnit }}
                    />
                  </div>
                </form> 
                : 
                null
              }
              <Button
                content='Copy direct URL'
                variant='gray'
                onClick={() => handleCopyToClipboard()}
                style={{
                  width: '8em',
                  height: '2em',
                  padding: '0',
                  marginBottom: '0.3em'
                }}
              />
            </div>
          </div>
        </Section>
        {cardsLoading ? <CardsLoader /> : (
          <Cards
            allocationText={currencySymbol + ' allocation'}
            usageText={currencySymbol + ' usage'}
            remainingText={currencySymbol + ' remaining'}
            accountData={accountDetailsCardsData}
            variant="Users"
          />
        )}
        <hr />
        {cardsLoading ? <CardsLoader /> : (
          <div style={{
            height: 100,
            margin: '2em 0em 2em 0em',
            background: '#fff',
            borderRadius: '0.25em',
            padding: '1em 0 2em 0'
          }}>
            <Typography as="p" margin="0" textAlign="center">{currencySymbol} usage</Typography>

            <AllocationsBarChart
              data={allocationsBarChartData}
              maxAllocation={displayUnit === 'SBU' ? parseInt(cardsData.sbus_allocation) : parseInt(cardsData.currency_allocation)}
              displayUnit={currencySymbol}
            />
          </div>
        )}
        <hr />
        {/* USAGE GRAPHS */}
        <Section margin="0 0 2em 0">
          <DualGraphs
            options={graphsOptions}
            handleGraphCurrentOption={(option: GraphOption) => {
              return handleGraphCurrentOption(
                memberName,
                option,
                setGraphCurrentOption,
                setUsageGraphLoading,
                setAccummulationGraphLoading,
                setUsageGraphData,
                setAccumulationGraphData,
                displayUnit
              );
            }}
            graphCurrentOption={graphCurrentOption}
            setTwoColumnGraphs={() => setTwoColumnGraphs(!twoColumnGraphs)}
            twoColumnGraphs={twoColumnGraphs}
            isUsageGraphLoading={isUsageGraphLoading}
            usageGraphData={usageGraphData}
            isAccumulationGraphLoading={isAccumulationGraphLoading}
            accumulationGraphData={accumulationGraphData}
            sbuThreshold={parseInt(cardsData.sbus_allocation)}
            inAccountView
            displayUnit={currencySymbol}
          />
        </Section>
        <hr />

        {/* PIE CHART */}
        <Typography as="h2" margin="2em 0 2em 0">
          Usage per user for {graphCurrentOption && graphCurrentOption.label ? graphCurrentOption.label : ''}
        </Typography>
        <Section flex pieChart>
          <div style={{ flex: 1 }}>
            {isPieChartLoading ? <TableLoader /> : !userTableData.length ? (
              <div style={{ textAlign: 'center', padding: '10em 0 10em 0' }}>
                No table data to display
              </div>
            ) : (
              <Table
                headers={pieTableHeaders(currencySymbol)}
                rowsData={userTableData}
                tableVariant={HPC_USER_SBUS_USAGE}
                transferSearchTerm={() => null}
                alignCentreName
                hideSearch
              />
            )}
          </div>

          <PieChartContainer>
            {isPieChartLoading ? <PieChartLoader /> : !userTableData.length ? (
              <div style={{ textAlign: 'center', padding: '10em 0 10em 0' }}>
                No pie chart data to display
              </div>
            ) : (
              <UsagePieChart data={pieChartData} displayUnit={currencySymbol} />
            )}
          </PieChartContainer>

        </Section>

        <hr />

        {/* SBU USAGE PER MONTH */}
        <Section margin="2em 0 1.5em 0">
          {renderMonthsTable()}
        </Section>
        <hr />

        {/* SBU USAGE PER QUARTER */}
        <Section margin="0 0 1.5em 0">
          {renderQuartersTable()}
        </Section>
        <hr />

        {/* SBU USAGE PER PAST YEARS */}
        <Section margin="0 0 2.5em 0">
          {renderYearsTable()}
        </Section>
        {/* TOOLTIP AND TOASTER NOFICATIONS */}
        <ReactTooltip id='graph-csv-download' type='dark'>
          <span>Download graph CSV</span>
        </ReactTooltip>
        {isDirect && <CustomNotification />}
      </Container>
    </>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  displayUnit: string;
} => {
  return { displayUnit: state.displayUnit };
};

export default connect(
  mapStateToProps, { setDisplayUnit }
)(AccountDetails);
