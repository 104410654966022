import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  LabelList
} from 'recharts';
import { BarChartProps } from '../types';


const renderCustomizedLabel = (props: any): React.ReactElement => {
  const { content, ...rest } = props;
  return <Label {...rest} fontSize="14" fill="#FFFFFF" fontWeight="Bold" formatter={(tick: number) => {
    return `${tick.toLocaleString()}%`;
  }} />;
};

const AllocationsBarChart = ({ data, maxAllocation, displayUnit = 'SBUs' }: BarChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        data={data}
        margin={{ left: 0, right: 50 }}
      >
        <XAxis type="number" domain={['auto', 100]} tickFormatter={tick => {
          return `${tick.toLocaleString()}%`;
        }} />
        <YAxis type="category" dataKey="name" stroke="#000" fontSize="12" />
        <Tooltip
          labelStyle={{ fontWeight: 'bold' }}
          formatter={(value: number, name: string, props: any) => {
            const valueNames = {
              'unallocatedPct': 'unallocated',
              'allocatedPct': 'allocated',
              'usedPct': 'used'
            };
            const valueName = valueNames[name as keyof object] || name;
            return [`${props.payload[valueName].toLocaleString()} ${displayUnit} (${value}%)`, valueName];
          }}
        />
        <Bar dataKey={data?.length && data[0].used ? 'usedPct' : 'allocatedPct'} fill={data?.length && data[0].used && data[0].used < 0 ? 'rgba(255,0,0,0.8)' : 'rgba(30,140,255,0.8)'} stackId="a">
          <LabelList
            dataKey={data?.length && data[0].used ? 'usedPct' : 'allocatedPct'}
            position="center"
            content={renderCustomizedLabel}
          />
        </Bar>
        <Bar dataKey={'unallocatedPct'} fill="rgba(0,0,0,0.8)" stackId="a">
          <LabelList
            dataKey={'unallocatedPct'}
            position="center"
            content={renderCustomizedLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AllocationsBarChart;
