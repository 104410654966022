import ContentLoader from 'react-content-loader';

const TableLoader = () => (
  <ContentLoader 
    speed={1}
    width={'100%'}
    height={350}
    viewBox="0 0 100% 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="287" y="97" rx="0" ry="0" width="92" height="0" /> 
    <rect x="0" y="57" rx="0" ry="0" width="1140" height="200" /> 
    <rect x="0" y="0" rx="0" ry="0" width="354" height="38" /> 
    <rect x="0" y="278" rx="0" ry="0" width="38" height="38" /> 
    <rect x="1064" y="0" rx="0" ry="0" width="76" height="38" /> 
    <rect x="963" y="280" rx="0" ry="0" width="176" height="48" />
  </ContentLoader>
);

export default TableLoader;