import ContentLoader from 'react-content-loader';

const InputGroupLoader = () => (
  <ContentLoader 
    speed={1}
    width={416}
    height={101}
    viewBox="0 0 416 101"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* <rect x="50" y="37" rx="5" ry="5" width="97" height="61" />  */}
    <rect x="171" y="36" rx="5" ry="5" width="248" height="61" />
  </ContentLoader>
);

export default InputGroupLoader;