const fetchEnvSetting = (
  setting: string,
  required: boolean = false
): string | undefined => {
  const value = process.env[setting];

  if (required && !value) {
    throw new Error(`Missing config variable: ${setting}`);
  }

  return value;
};

const apiUrl = fetchEnvSetting('REACT_APP_API_BASE_URL', true);
const mountPath = fetchEnvSetting('REACT_APP_MOUNT_PATH', true);
const buildEnv = fetchEnvSetting('REACT_APP_BUILD_ENV', true);
const nodeEnv = fetchEnvSetting('NODE_ENV', true);
const currencyDisplayEnabled =
  fetchEnvSetting('REACT_APP_ENABLE_CURRENCY_DISPLAY', false) === 'true';

export default Object.freeze({
  buildEnv,
  nodeEnv,
  apiUrl,
  mountPath,
  currencyDisplayEnabled,
});
