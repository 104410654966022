import styled from 'styled-components';
import { breakpoints } from '../../../globalStyles/theme';

export const StyledSection = styled.section<{
 margin?: string;
 width?: string;
 flex?: boolean;
 pieChart?: boolean;
}>`

${({ flex, pieChart }) => {
    if (flex) {
      return `
    @media screen and (max-width: ${breakpoints.custom.size4}) {
      flex-direction: ${pieChart ? 'column-reverse' : 'column'};
    }
  
    display: flex;
    justify-content: space-between;
    `;
    }
  }}

  margin: ${({ margin }) => margin};

  h1 {
    font-size: 2.5em;
  }
`;