import React, { KeyboardEvent, useState, useEffect } from 'react';

import { SpinnerCircularFixed } from 'spinners-react';
import ReactTooltip from 'react-tooltip';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';

import ModalOverlay from '../../../Modals/BaseModal';
import AccessTableData from './parts/AccessTableData';
import ExpandUsersText from './parts/ExpandUsersText';

import Edit from '../../../Icons/Edit';

import {
  READ_ACCESS,
  ADMIN_ACCESS,
  ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS,
  ECMWF_ADMIN_MANAGE_OVERALLOCATIONS,
  COMPREP_MANAGE_READ_ACCESS,
  COMPREP_MANAGE_ALLOCATIONS,
  COMPREP_SEARCH_USERS,
  ECMWF_ADMIN_SEARCH_ACCOUNTS,
  COMPREP_SBUS_USAGE_MONTHS,
  COMPREP_SBUS_USAGE_QUARTERS,
  COMPREP_SBUS_USAGE_YEARS,
  NODE_DETAIL_VIEW_MONTHS,
  NODE_DETAIL_VIEW_QUARTERS,
  NODE_DETAIL_VIEW_YEARS,
  COMPREP_EMAIL_ALERT_TABLE
} from '../../../../utils/constants';
import { APIfetchChildren, renderDynamicTableData } from './utils';
import * as S from './styles';
import Button from '../../../Button';
import Input from '../../../Inputs/Input';

import { setNodeAllocation, setNodeEmailAlertPercentage, setNodeOverallocationPercentage } from '../../../../api/confirmations';
import { arrayToObject, removeSeparator } from '../../../../utils/methods';
import { GeneralDataNodesResponseType } from '../../../../api/types';
import { IRowProps, DataType } from './types';

import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { updateNode } from '../../../../redux/actions';

import { extractTableUsageData } from './utils/sbus-usage-tables';
import { extractTableAllocationData, extractTableEmailAlertData } from './utils/comprep-tables';
import { extractTableAccessData, extractTableOverAllocationData } from './utils/ecmwf-admin-tables';

const Row = ({
  data,
  rowId,
  isChild,
  tableVariant,
  timePeriod,
  alignCentreName,
  refreshTable = () => '',
  passFetchedRowChildrenToParent,
  nodes,
  updateNode,
  autocompleteData = {}
}: IRowProps) => {
  // STATE
  const [isNodeDetailsModalOpen, toggleNodeDetailsModal] = useState<boolean>(false);
  const [isAccountDetailsModalOpen, toggleAccountDetailsModal] = useState<boolean>(false);
  const [isEditNodeDescriptionModalOpen, toggleEditNodeDescriptionModal] = useState<boolean>(false);
  const [isAddNodeModalOpen, toggleAddNodeModal] = useState<boolean>(false);
  const [isTransferModalOpen, toggleTransferModal] = useState<boolean>(false);
  const [isExpanded, toggleExpansion] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userAccessExpanded, expandUserAccess] = useState<boolean>(false);
  const [emailAlertPct, setEmailAlertPct] = useState<string>('0');
  const [emailAlertPctRequesting, setEmailAlertPctRequesting] = useState<boolean>(false);
  const [overAllocationPct, setOverAllocationPct] = useState<string>('0');
  const [overAllocationPctRequesting, setOverallocationPctRequesting] = useState<boolean>(false);

  const [allocationValue, setAllocationValue] = useState<string>('');
  const [allocationRequesting, setAllocationRequesting] = useState<boolean>(false);

  const [nodeChildren, setNodeChildren] = useState<any[]>([]);
  const [csvDataForParent, setCSVDataForParent] = useState<any[]>([]);
  const [clickedNode, setClickedNode] = useState('');

  // USEEFFECT
  useEffect(() => {
    if (tableVariant === ECMWF_ADMIN_MANAGE_OVERALLOCATIONS) {
      // Initialises overallocation %
      setOverAllocationPct(data['sbu_overallocation_percentage']); // for overallocations table
    }
    if (tableVariant === COMPREP_MANAGE_ALLOCATIONS) {
      setAllocationValue(data['allocation']);
    }
    if (tableVariant === COMPREP_EMAIL_ALERT_TABLE) {
      // Initialises email alert % 
      setEmailAlertPct(data['email_alert_pct']);
    }
  }, [data]);

  // include fetched nodeChildren in csvDataForParent 
  useEffect(() => {
    if (nodeChildren.length) setCSVDataForParent([...nodeChildren, ...csvDataForParent]);
  }, [nodeChildren]);
  // pass to parent table, for CSV crafting
  useEffect(() => {
    if (csvDataForParent.length) passFetchedRowChildrenToParent(csvDataForParent);
  }, [csvDataForParent]);
  // craft redux store data and set
  useEffect(() => {
    if (nodes && clickedNode.length) {
      // For months/quarters/years tables in "Nodes and Accounts" and "Node Detail" views only
      if (timePeriod) {
        if (tableVariant === COMPREP_SBUS_USAGE_MONTHS ||
          tableVariant === COMPREP_SBUS_USAGE_QUARTERS || // need to change this
          tableVariant === COMPREP_SBUS_USAGE_YEARS ||
          tableVariant === NODE_DETAIL_VIEW_MONTHS ||
          tableVariant === NODE_DETAIL_VIEW_QUARTERS ||
          tableVariant === NODE_DETAIL_VIEW_YEARS
        ) {
          const existingChildrenWithData = extractTableUsageData(timePeriod, nodes, clickedNode, data);
          if (existingChildrenWithData?.length) setNodeChildren(existingChildrenWithData);
        }
      }
      if (tableVariant === COMPREP_MANAGE_ALLOCATIONS) {
        const existingChildrenWithData = extractTableAllocationData(nodes, clickedNode, data);
        if (existingChildrenWithData?.length) setNodeChildren(existingChildrenWithData);
      }
      if (tableVariant === COMPREP_EMAIL_ALERT_TABLE) {
        const existingChildrenWithData = extractTableEmailAlertData(nodes, clickedNode, data);
        if (existingChildrenWithData?.length) setNodeChildren(existingChildrenWithData);
      }
      if (tableVariant === ECMWF_ADMIN_MANAGE_OVERALLOCATIONS) {
        const existingChildrenWithData = extractTableOverAllocationData(nodes, clickedNode, data);
        if (existingChildrenWithData?.length) setNodeChildren(existingChildrenWithData);
      }
      if (tableVariant === COMPREP_MANAGE_READ_ACCESS) {
        const existingChildrenWithAccess = extractTableAccessData(false, nodes, clickedNode, data);
        if (existingChildrenWithAccess?.length) setNodeChildren(existingChildrenWithAccess);
      }
      if (tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS) {
        const existingChildrenWithAccess = extractTableAccessData(true, nodes, clickedNode, data);
        if (existingChildrenWithAccess?.length) setNodeChildren(existingChildrenWithAccess);
      }
    }
  }, [nodes, clickedNode]);

  // MANAGE NODES AND ACCESS
  const renderAdminAccessData = (): React.ReactElement | void => {
    // show expanded mode by default
    if (tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS) {
      return !userAccessExpanded ? (
        <AccessTableData
          accessUsers={data.comprep_access_users}
          accessType={ADMIN_ACCESS}
          nodeName={data.name}
          nodeId={data.org_id}
          key="admin-access"
          onHideUsersClick={() => expandUserAccess(true)}
          refreshTable={() => refreshTable()}
          autocompleteData={autocompleteData}
        />
      ) : (
        <ExpandUsersText
          key="expand-admin-access-text"
          dataTestId="expand-admin-access"
          onClick={() => expandUserAccess(false)} />
      );
    }

    return;
  };
  const renderReadAccessData = (): React.ReactElement => {
    // show expanded mode by default
    return !userAccessExpanded &&
      (tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS ||
        tableVariant === COMPREP_MANAGE_READ_ACCESS) ? (
      <AccessTableData
        accessUsers={data.read_access_users}
        accessType={READ_ACCESS}
        nodeName={data.name}
        nodeId={data.org_id}
        key="read-access"
        onHideUsersClick={() => expandUserAccess(true)}
        refreshTable={() => refreshTable()}
        autocompleteData={autocompleteData}
      />
    ) : (
      <ExpandUsersText
        key="expand-read-access-text"
        dataTestId="expand-read-access"
        onClick={() => expandUserAccess(false)}
      />
    );
  };
  const renderEditNodeDescriptionButton = (): React.ReactElement | null => {
    if ((tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS ||
      tableVariant === COMPREP_MANAGE_READ_ACCESS)) {
      return <Edit onClick={() => toggleEditNodeDescriptionModal(!isEditNodeDescriptionModalOpen)} />;
    }

    return null;
  };

  const renderDescription = (description: string, key: string) => {
    return (<td key={description} data-testid={key} style={{ position: 'relative', whiteSpace: 'normal', paddingRight: '2em' }}>
      {description}
      <div style={{ position: 'absolute', top: 0, right: 0 }}>{renderEditNodeDescriptionButton()}</div>
    </td>);
  };

  // EMAIL ALERT TABLE IN NODES & ACCOUNTS 
  const handleEmailAlertPctInputKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>
  ): Promise<void> => {
    if (event.key === 'Enter') {
      setEmailAlertPctRequesting(true);

      try {
        const res: GeneralDataNodesResponseType | undefined =
          await setNodeEmailAlertPercentage(data.org_id, parseInt(emailAlertPct));

        if (res && nodes && updateNode) {
          toast.success(`Email Alert Percentage set to ${emailAlertPct}%`);

          const updatedNodes = res.data.map(returnedNode => {
            if (nodes[returnedNode.id]) {
              return {
                [returnedNode.id]: {
                  ...nodes[returnedNode.id], // maintain existing node's other properties
                  email_alert_percentage: returnedNode.email_alert_percentage // new value
                }
              };
            } else {
              return {
                [returnedNode.id]: {
                  ...returnedNode,
                  tableUsage: {},
                  graphsUsage: { sbuUsage: {}, accumulationUsage: {} },
                  adminAccessUsers: [],
                  readAccessUsers: [],
                  email_alert_percentage: returnedNode.email_alert_percentage // new value
                }
              };
            }
          });
          const cacheFormattedNodes = arrayToObject(updatedNodes);
          updateNode(cacheFormattedNodes);
          setEmailAlertPctRequesting(false);

        } else {
          setEmailAlertPctRequesting(false);
          toast.error('There was an error setting email alert percentage, please try again or contact your administrator.');
          setEmailAlertPct(data.email_alert_pct);
        }
      } catch (error) {
        console.error('Error setting email alert pct for account.', error);
        toast.error('Error setting email alert percentage, please try again or contact your administrator.');
        setEmailAlertPct(data.email_alert_pct);
        setEmailAlertPctRequesting(false);
      }
    }
    return;
  };
  const handleEmailAlertPctChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // email alert % validation  
    const onlyNumbersInString: string = event.target.value.replace(/\D/, '');
    let onlyNumbersFromZeroToHundred: number = Math.min(100, Math.max(0, parseInt(onlyNumbersInString)));

    if (isNaN(onlyNumbersFromZeroToHundred)) {
      toast.error('Please enter a valid percentage.');
      return;
    }
    setEmailAlertPct(onlyNumbersFromZeroToHundred.toString());
  };
  const renderEmailAlertPctInput = (pct: string): React.ReactElement => {
    return (
      <td key={pct} style={{ textAlign: 'center' }}>
        {emailAlertPctRequesting ? (
          <div style={{ position: 'relative' }}>
            <span style={{ position: 'absolute', top: 5, left: -2 }}>
              <SpinnerCircularFixed size={20} thickness={152} speed={178} color="rgba(30,144,255, 0.5)" secondaryColor="rgba(0, 0, 0, 0.17)" />
            </span>
          </div>
        ) : null}
        <Input
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
          value={emailAlertPct}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAlertPctChange(e)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleEmailAlertPctInputKeyDown(e)}
          dataTestId="email-alert-pct-input"
          dataFor="email-alert-pct-input"
          disabled={emailAlertPctRequesting}
          style={{
            padding: '0.5em 1em',
            width: '2em',
            height: '1em',
            textAlign: 'center',
            borderRadius: 5,
            fontSize: 'small',
            border: '1px solid lightgray'
          }}
        />
        <ReactTooltip id='email-alert-pct-input' type='dark' effect="solid">
          <span>Type in a value and press Enter to update email alert % for this node.</span>
        </ReactTooltip>
      </td>
    );
  };

  // MANAGE OVERALLOCATIONS
  const handleOverallocationPctInputKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>
  ): Promise<void> => {
    if (event.key === 'Enter') {
      setOverallocationPctRequesting(true);
      ReactTooltip.hide();

      try {
        const res: GeneralDataNodesResponseType | undefined =
          await setNodeOverallocationPercentage(data.org_id, parseInt(overAllocationPct));

        if (res && nodes && updateNode) {
          toast.success(`Overallocation Percentage set to ${overAllocationPct}%`);
          const updatedNodes = res.data.map(returnedNode => {
            if (nodes[returnedNode.id]) {
              return {
                [returnedNode.id]: {
                  ...nodes[returnedNode.id], // maintain existing node's other properties
                  sbu_overallocation_percentage: returnedNode.sbu_overallocation_percentage, // new value
                  sbu_overallocation: returnedNode.sbu_overallocation // new value
                }
              };
            } else {
              return {
                [returnedNode.id]: {
                  ...returnedNode,
                  tableUsage: {},
                  graphsUsage: { sbuUsage: {}, accumulationUsage: {} },
                  adminAccessUsers: [],
                  readAccessUsers: [],
                  sbu_overallocation_percentage: returnedNode.sbu_overallocation_percentage, // new value
                  sbu_overallocation: returnedNode.sbu_overallocation // new value
                }
              };
            }
          });
          const cacheFormattedNodes = arrayToObject(updatedNodes);
          updateNode(cacheFormattedNodes);
          setOverallocationPctRequesting(false);
        } else {
          setOverallocationPctRequesting(false);
          toast.error('There was an error setting overallocation percentage, please try again or contact your administrator.');
          setOverAllocationPct(data.sbu_overallocation_percentage);
        }
      } catch (error) {
        console.error('Error setting overallocation pct for account.', error);
        toast.error('Error setting overallocation percentage, please try again or contact your administrator.');
        setOverAllocationPct(data.sbu_overallocation_percentage);
        setOverallocationPctRequesting(false);
      }
    }
    return;
  };
  const handleOverallocationPctChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbersInString: string = event.target.value.replace(/\D/, '');
    let onlyNumbersFromZeroToHundred: number = Math.min(100, Math.max(0, parseInt(onlyNumbersInString)));

    if (isNaN(onlyNumbersFromZeroToHundred)) {
      toast.error('Please enter a valid percentage.');
      return;
    }
    setOverAllocationPct(onlyNumbersFromZeroToHundred.toString());
    // toast.success('Email alert percentage updated.');
  };
  const renderOverallocationPct = (pct: string, dataTestId: string): React.ReactElement => {
    return (
      <td key={pct} data-testid={dataTestId} style={{ position: 'relative', textAlign: 'center' }}>
        {overAllocationPctRequesting ? (
          <div style={{ position: 'relative' }}>
            <span style={{ position: 'absolute', top: 5, left: 35 }}>
              <SpinnerCircularFixed size={20} thickness={152} speed={178} color="rgba(30,144,255, 0.5)" secondaryColor="rgba(0, 0, 0, 0.17)" />
            </span>
          </div>
        ) : null}
        <Input
          value={overAllocationPct}
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOverallocationPctChange(e)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleOverallocationPctInputKeyDown(e)}
          dataTestId="overallocation-pct-input"
          dataFor="overallocation-input"
          disabled={overAllocationPctRequesting}
          style={{
            padding: '0.5em 1em',
            width: '2em',
            height: '1em',
            textAlign: 'center',
            borderRadius: 5,
            fontSize: 'small',
            border: '1px solid lightgray'
          }}
        />
        <ReactTooltip id='overallocation-input' type='dark' effect="solid">
          <span>Type in a value and press Enter to update overallocation % for this node.</span>
        </ReactTooltip>
      </td>
    );
  };

  // MANAGE ALLOCATIONS
  const handleAllocationInputKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>
  ): Promise<void> => {
    if (event.key === 'Enter') {
      setAllocationRequesting(true);
      ReactTooltip.hide();

      try {
        const res: GeneralDataNodesResponseType | undefined =
          await setNodeAllocation(data.org_id, parseInt(allocationValue));

        if (res && nodes && updateNode) {
          toast.success(`Allocation set to ${allocationValue}`);

          const updatedNodes = res.data.map(returnedNode => {
            if (nodes[returnedNode.id]) {
              return {
                [returnedNode.id]: {
                  ...nodes[returnedNode.id], // maintain existing node's other properties
                  sbu_allocation: returnedNode.sbu_allocation, // new value
                  sbu_distributed: returnedNode.sbu_distributed,
                  sbu_undistributed: returnedNode.sbu_undistributed
                }
              };
            } else {
              return {
                [returnedNode.id]: {
                  ...returnedNode,
                  tableUsage: {},
                  graphsUsage: { sbuUsage: {}, accumulationUsage: {} },
                  adminAccessUsers: [],
                  readAccessUsers: []
                  // sbu_allocation: returnedNode.sbu_allocation // new value
                }
              };
            }
          });
          const cacheFormattedNodes = arrayToObject(updatedNodes);
          updateNode(cacheFormattedNodes);
          setAllocationRequesting(false);
        } else {
          setAllocationRequesting(false);
          toast.error('There was an error setting the allocation, please try again or contact your administrator.');
          setAllocationValue(data.allocation);
        }
      } catch (error) {
        console.error('Error setting allocation for account.', error);
        toast.error('Error setting allocation for this node, please try again or contact your administrator.');
        setAllocationValue(data.allocation);
        setAllocationRequesting(false);
      }
    }
    return;
  };
  const handleAllocationValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbersInString: string = event.target.value.replace(/\D/, '');
    setAllocationValue(onlyNumbersInString);
  };
  const renderManageAllocationsInput = (pct: string, dataTestId: string): React.ReactElement => {
    return (
      <td key={pct} data-testid={dataTestId} >
        {allocationRequesting ? (
          <div style={{ position: 'relative' }}>
            <span style={{ position: 'absolute', top: 7, left: 75 }}>
              <SpinnerCircularFixed size={20} thickness={152} speed={178} color="rgba(30,144,255, 0.5)" secondaryColor="rgba(0, 0, 0, 0.17)" />
            </span>
          </div>
        ) : null}
        <Input
          value={allocationValue}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => setAllocationValue(data['allocation'])}
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => setAllocationValue(removeSeparator(allocationValue))}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAllocationValueChange(e)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleAllocationInputKeyDown(e)}
          dataTestId="allocation-pct-input"
          dataFor="allocation-input"
          disabled={allocationRequesting || !data.parent_id}
          style={{
            padding: '0.5em 1em',
            width: '8em',
            height: '1em',
            textAlign: 'right',
            borderRadius: 5,
            fontSize: 'medium',
            border: '1px solid lightgray',
            cursor: (allocationRequesting || !data.parent_id) ? 'not-allowed' : 'auto'
          }}
        />
        <ReactTooltip id='allocation-input' type='dark' effect="solid">
          <span>Type in a value and press Enter to update allocation.</span>
        </ReactTooltip>
      </td>
    );
  };

  const renderTransferAllocationsButton = () => {
    const isDisabled = !data.parent_id ||
      !data.sbu_available_for_transfer ||
      parseInt(data.sbu_available_for_transfer) <= 0;

    return (
      <td>
        <span>
          <Button
            variant="transfer"
            content="Transfer"
            dataFor={`transfer-allocations-${data.org_id}`}
            dataTestId="transfer-allocations-modal-button"
            onClick={() => toggleTransferModal(!isTransferModalOpen)}
            style={{ width: '6em', padding: '0.3em 0.5em 0.3em 0.5em', textAlign: 'center', fontSize: '0.8em' }}
            disabled={isDisabled}
          />
        </span>
        <ReactTooltip id={`transfer-allocations-${data.org_id}`} place='top' effect='float'>
          {isDisabled ?
            <span>No SBUs available for transfer from this {data.category}</span> :
            <span>Transfer allocations from this {data.category}</span>
          }</ReactTooltip>
      </td>

    );
  };

  const renderFirstColumnData = (hasChildren: boolean): React.ReactElement => {
    return (
      <S.TableData key={rowId} data-testid="name" alignCentreName={alignCentreName} tableVariant={tableVariant}>
        <S.TableDataName isChild={isChild} rowId={rowId} noChildren={!hasChildren} tableVariant={tableVariant}>
          {hasChildren && (isLoading ? (
            <span style={{
              padding: '0.1em 0.7em',
              borderRadius: '3px',
              position: 'relative',
              marginRight: '0.5em',
              marginLeft: 0
            }}>
              <span style={{ position: 'absolute', right: '0px', top: '4px' }}>
                <SpinnerCircularFixed size={20} thickness={152} speed={178} color="rgba(30,144,255, 0.5)" secondaryColor="rgba(0, 0, 0, 0.17)" />
              </span>
            </span>
          ) : (
            <S.PlusMinus
              isExpanded={isExpanded}
              onClick={() => (nodeChildren.length === 0 && nodes && updateNode) ?
                APIfetchChildren(
                  setClickedNode,
                  toggleExpansion,
                  setLoading,
                  isExpanded,
                  data,
                  tableVariant,
                  nodes,
                  updateNode,
                  setNodeChildren,
                  timePeriod
                ) :
                toggleExpansion(!isExpanded)
              }
              data-testid="plus-minus"
            >
              {isExpanded ? <S.MinusIcon>-</S.MinusIcon> : <S.PlusIcon data-tip
                data-for="load-members">+</S.PlusIcon>}{' '}
            </S.PlusMinus>
          ))}

          {tableVariant === ECMWF_ADMIN_SEARCH_ACCOUNTS ?
            <S.TableDataName
              isChild={isChild}
              rowId={rowId}
              tableVariant={tableVariant}
              noChildren={!hasChildren}
            >
              {data.name}
            </S.TableDataName>
            :
            <S.TableDataNameLink
              onClick={data.category === 'node' ? () => toggleNodeDetailsModal(!isNodeDetailsModalOpen) : () => toggleAccountDetailsModal(!isAccountDetailsModalOpen)}
              tableVariant={tableVariant}
              data-tip={data.description || 'Click for more details'}
              data-for={data.category?.length || tableVariant === COMPREP_SEARCH_USERS ? 'view-more-details' : ''}
            >
              {data.name}
            </S.TableDataNameLink>
          }

          {tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS && (
            <span style={{
              position: 'absolute',
              right: '40px',
              top: '12px',
              fontSize: 'smaller'
            }}>
              {(data.category === 'node') && (
                <Button
                  variant="change"
                  content="Add"
                  dataFor="add-node"
                  dataTestId="add-node-modal-button"
                  onClick={() => toggleAddNodeModal(!isAddNodeModalOpen)}
                  style={{ width: '6em', padding: '0.3em 0.5em 0.3em 0.5em', textAlign: 'center', fontSize: '0.8em' }}
                />
              )}
            </span>
          )}
        </S.TableDataName>
        <ReactTooltip id='view-more-details' place="left" effect="solid" />
        <ReactTooltip id='load-members' place="left" effect="float">
          <span>Click to view child nodes</span>
        </ReactTooltip>
        <ReactTooltip id='add-node' place="top" effect="float">
          <span>Add a node/account within this node</span>
        </ReactTooltip>
      </S.TableData>
    );
  };

  const renderRow = () => {
    if (isExpanded && nodeChildren.length) {

      return nodeChildren.map((child: DataType, i) => (
        <Row
          key={`${rowId}.${i}`}
          data={child}
          rowId={`${rowId}.${i}`}
          isChild
          tableVariant={tableVariant}
          timePeriod={timePeriod}
          alignCentreName={alignCentreName}
          refreshTable={refreshTable}
          passFetchedRowChildrenToParent={(subRowChildren: DataType[]) => {
            let combined = [...subRowChildren];
            setCSVDataForParent([...csvDataForParent, ...combined]);
          }}
          nodes={nodes}
          updateNode={updateNode}
          autocompleteData={autocompleteData}
        />));
    }
  };

  const hasChildren = data?.children?.length ? true : false;

  return (
    <>
      <S.ChildRow
        key={rowId}
        data-testid="child-row"
        tableVariant={tableVariant}
        timePeriod={timePeriod || ''}
      >
        {/* DYNAMICALLY RENDERS ALL TABLE DATA */}
        {renderDynamicTableData(
          tableVariant,
          data,
          renderReadAccessData,
          renderAdminAccessData,
          renderFirstColumnData(hasChildren),
          renderManageAllocationsInput,
          renderEmailAlertPctInput,
          renderOverallocationPct,
          renderTransferAllocationsButton,
          renderDescription
        )}
        {/* RENDERS APPROPRIATE MODALS */}
        {isEditNodeDescriptionModalOpen && <ModalOverlay
          modalName={'Edit Node Description'}
          isOpen={isEditNodeDescriptionModalOpen}
          oldDescription={data.description}
          nodeId={data.org_id}
          onRequestClose={() => toggleEditNodeDescriptionModal(!isEditNodeDescriptionModalOpen)}
          refreshTable={() => refreshTable()} // to remove
        />}
        {isNodeDetailsModalOpen && <ModalOverlay
          modalName={'Node Details'}
          isOpen={isNodeDetailsModalOpen}
          onRequestClose={() => toggleNodeDetailsModal(!isNodeDetailsModalOpen)}
          memberName={data.name}
          nodeChildren={data.children}
          nodeId={data.org_id}
        />}
        {isAccountDetailsModalOpen && <ModalOverlay
          modalName={'Account Details'}
          isOpen={isAccountDetailsModalOpen}
          onRequestClose={() => toggleAccountDetailsModal(!isAccountDetailsModalOpen)}
          memberName={data.name}
          accountId={data.org_id}
        />}
        {isAddNodeModalOpen && <ModalOverlay
          modalName={'Add Node'}
          isOpen={isAddNodeModalOpen}
          memberName={data.name}
          ownerNodeId={data.org_id}
          onRequestClose={() => toggleAddNodeModal(!isAddNodeModalOpen)}
          refreshTable={() => refreshTable()} // to remove
        />}
        {isTransferModalOpen && <ModalOverlay
          modalName={'Transfer'}
          isOpen={isTransferModalOpen}
          nodeId={data.org_id}
          memberName={data.name}
          onRequestClose={() => toggleTransferModal(!isTransferModalOpen)}
          refreshTable={() => refreshTable()}
          availableForTransfer={data.sbu_available_for_transfer}
        />}
      </S.ChildRow>
      {/* RECURSIVELY RENDERS DIRECT CHILDREN OF PARENTS */}
      {renderRow()}
    </>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  nodes: CachedNodeType;
} => {
  return {
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(Row);

