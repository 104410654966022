import toast from 'react-hot-toast';

import { extractGraphFormatData, ReducedDataType } from '../../../api/utils';
import { GeneralDataNodeType } from '../../../api/types';

import { CachedNodeType } from '../../../redux/reducers/types';
import { GraphOption } from '../../../containers/views/HPCUser/types';
import { arrayToObject } from '../../../utils/methods';
import { fetchGraphStatsForTimePeriod, fetchNodeChildrenDataFromId } from '../../../api/fetch-nodes';
import { GraphCurrentOption } from '../types';

export const extractGraphData = (
  graph: 'sbuUsage' | 'accumulationUsage',
  nodes: CachedNodeType | undefined,
  nodeIdClicked: string,
  localSelectedOption: GraphOption
) => {
  if (nodes) {
    let siblingNodesWithSameParent = Object.values(nodes).filter(node => {
      return node.parent?.id === nodeIdClicked;
    }).filter(n => n);

    if (siblingNodesWithSameParent.length) {
      // if nodes exist AND they do not have the selected graphoption.value in sbuUsage + acumUsage..
      const existingNodes = siblingNodesWithSameParent?.map(s => {
        let graphUsage = s?.graphsUsage[graph];
        let hasOptionValue = Object.prototype.hasOwnProperty.call(graphUsage, localSelectedOption.value);
        return hasOptionValue;
      });
      const nodesHaveDataForOption = existingNodes.every(Boolean);

      if (nodesHaveDataForOption) {
        let graphData = siblingNodesWithSameParent.map(node => {
          return node?.graphsUsage[graph][localSelectedOption.value];
        });
        const extractedFormat: ReducedDataType[] = extractGraphFormatData([], graphData);
        return extractedFormat;
      }
    }
    return [];
  }
  return [];
};

export const handleGraphNavigtion = async (
  nodeId: string,
  nodes: CachedNodeType | undefined,
  updateNode: Function | undefined,
  hasNavigated: boolean,
  localSelectedOption: GraphOption,
  graphCurrentOption: GraphCurrentOption,
  setSbuGraphNodeBeingFetched: Function,
  setAccumulationGraphNodeBeingFetched: Function
) => {
  // check if node id has children, if no fetch them + their data
  // if yes, check if each have option.value in sbuUsage, if no then fetch their sbu data
  if (nodes && Object.values(nodes).length && updateNode) {
    let siblingNodesWithSameParent: GeneralDataNodeType[] = Object.values(nodes).filter(node => {
      // finds all nodes whose parent is nodeId
      return node.parent?.id === nodeId;
    }).filter(n => n);

    
    const selection = hasNavigated ? localSelectedOption.value : graphCurrentOption.value;

    // fetch new nodes if they do not exist
    if (!siblingNodesWithSameParent.length) {
      setSbuGraphNodeBeingFetched(true);
      setAccumulationGraphNodeBeingFetched(true);
      try {
        // fetches clicked node's children with their general data (to craft new node below) 
        const childrenGeneralData = await fetchNodeChildrenDataFromId(nodeId);


        if (childrenGeneralData?.length) {
          const sbuGraphStats = await fetchGraphStatsForTimePeriod('usage-per-day', childrenGeneralData, selection);
          const accumGraphStats = await fetchGraphStatsForTimePeriod('total-node-usage', childrenGeneralData, selection);
        
          if (sbuGraphStats?.data.length && childrenGeneralData?.length && accumGraphStats?.data.length){

            let extracted = childrenGeneralData.map(nodeObj => {
              return { 
                [nodeObj.id]: {
                  ...nodeObj,
                  tableUsage: {},
                  graphsUsage: {
                    sbuUsage: {},
                    accumulationUsage: {}
                  },
                  adminAccessUsers: [],
                  readAccessUsers: []
                }
              };
            });
            const tempObjFormat: CachedNodeType = arrayToObject(extracted);

            const withStats = sbuGraphStats.data.map(statsData => {
              return {
                [statsData.data[0].id]: {
                  ...tempObjFormat[statsData.data[0].id],
                  graphsUsage: {
                    accumulationUsage: {
                      [statsData.variant.id]: 
                        accumGraphStats.data.find(sd => sd.data[0].id === statsData.data[0].id)?.data[0]
                    },
                    sbuUsage: {
                      [statsData.variant.id]: statsData.data[0]
                    }
                  }
                } 
              };
            });
            const transformIntoObject: CachedNodeType = arrayToObject(withStats);
            updateNode(transformIntoObject);
            setSbuGraphNodeBeingFetched(false);
            setAccumulationGraphNodeBeingFetched(false);
          }
        } else {
          console.error('no graph data result for clicked node');
          toast.error('no graph data result for clicked node');
          setSbuGraphNodeBeingFetched(false);
          setAccumulationGraphNodeBeingFetched(false);
        }
        
      } catch (error) {
        console.error('failed to fetch clicked node graph data: ', error);
        toast.error('failed to fetch clicked node graph data');
        setSbuGraphNodeBeingFetched(false);
        setAccumulationGraphNodeBeingFetched(false);
      }
    } 

    // if nodes exist AND they do not have the selected graphoption.value in sbuUsage + acumUsage..
    const existingNodes = siblingNodesWithSameParent?.map(s => {
      let sbuUsage = s?.graphsUsage.sbuUsage;
      let accumUsage = s?.graphsUsage.accumulationUsage;
      let hasOptionValueForSBU = Object.prototype.hasOwnProperty.call(sbuUsage, localSelectedOption.value);
      let hasOptionValueForAccum = Object.prototype.hasOwnProperty.call(accumUsage, localSelectedOption.value);
      return hasOptionValueForSBU && hasOptionValueForAccum;
    });

    const nodesHaveDataForOption = existingNodes.every(Boolean);

    // if nodes with general data exist but have no usage for selected option, fetch stats only
    if (siblingNodesWithSameParent.length && !nodesHaveDataForOption) {
      setSbuGraphNodeBeingFetched(true);
      setAccumulationGraphNodeBeingFetched(true);
      try {
        const sbuGraphStats = await fetchGraphStatsForTimePeriod('usage-per-day', siblingNodesWithSameParent, selection);
        const accumGraphStats = await fetchGraphStatsForTimePeriod('total-node-usage', siblingNodesWithSameParent, selection);
      
  
        if (sbuGraphStats?.data.length && accumGraphStats?.data.length) {
          const withStats = sbuGraphStats.data.map(statsData => {
            return {
              [statsData.data[0].id]: {
                ...nodes[statsData.data[0].id],
                graphsUsage: {
                  accumulationUsage: {
                    ...nodes[statsData.data[0].id].graphsUsage.accumulationUsage,
                    [statsData.variant.id]: 
                    accumGraphStats.data.find(sd => sd.data[0].id === statsData.data[0].id)?.data[0]
                  },
                  sbuUsage: {
                    ...nodes[statsData.data[0].id].graphsUsage.sbuUsage,
                    [statsData.variant.id]: statsData.data[0]
                  }
                }
              } 
            };
          });

          const transformIntoObject: CachedNodeType = arrayToObject(withStats);
          updateNode(transformIntoObject);
          setSbuGraphNodeBeingFetched(false);
          setAccumulationGraphNodeBeingFetched(false);
        } else {
          console.error('no graph data result for clicked node');
          toast.error('no graph data result for clicked node');
          setSbuGraphNodeBeingFetched(false);
          setAccumulationGraphNodeBeingFetched(false);
        }
        
      } catch (error) {
        console.error('failed to fetch clicked node graph data: ', error);
        toast.error('failed to fetch clicked node graph data');
        setSbuGraphNodeBeingFetched(false);
        setAccumulationGraphNodeBeingFetched(false);
      }
    }
      
    // nodes present AND they have data for selected option, do not fetch
    if (siblingNodesWithSameParent.length && nodesHaveDataForOption) {
      return;
    }
    return;
  }
}; 
