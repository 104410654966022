import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import smoothscroll from 'smoothscroll-polyfill';

const ScrollToTop = ({ history }: {history: History}) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      smoothscroll.polyfill();
    });
    return () => {
      unlisten();
    };
  });

  return (null);
};

export default withRouter(ScrollToTop);