import styled from 'styled-components';
import { colors } from '../../../../globalStyles/theme';
import {
  ACCOUNT_DETAIL_VIEW_MONTHS,
  ACCOUNT_DETAIL_VIEW_QUARTERS,
  ACCOUNT_DETAIL_VIEW_YEARS,
  COMPREP_EMAIL_ALERT_TABLE,
  COMPREP_MANAGE_ALLOCATIONS,
  COMPREP_SEARCH_USERS,
  ECMWF_ADMIN_SEARCH_ACCOUNTS,
  ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS,
  ECMWF_ADMIN_MANAGE_OVERALLOCATIONS,
  HPC_USER_SBUS_USAGE
} from '../../../../utils/constants';
import { indentChildren } from './utils';

export const TableData = styled.td<{
  alignCentreName: boolean;
  tableVariant: string;
}>`
  min-width: ${({ tableVariant }) =>
    tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS ? '15em' : '8em'};
  padding-left: ${({ alignCentreName }) =>
    alignCentreName ? '0' : '1.25em'} !important;
  position: relative;

  text-align: ${({ alignCentreName }) =>
    alignCentreName ? 'center' : 'left'} !important;
  width: 1%;
  white-space: nowrap;

  ${({ alignCentreName }) =>
    alignCentreName &&
    `
   span {
     margin-left: 0;
   }
  `};
`;

export const PlusMinus = styled.span<{
  isExpanded: boolean;
}>`
  cursor: pointer;
  ${({ isExpanded }) =>
    isExpanded
      ? `border: 1px solid ${colors.grey.quartenary}`
      : `background: ${colors.grey.quartenary}`};
  padding: 0em 0.7em;
  border-radius: 0.25em;
  position: relative;
  margin-right: 0.5em;
  margin-left: 0;

  &:hover {
    opacity: 0.4;
  }
`;

export const PlusIcon = styled.span`
  position: absolute;
  left: 7px;
  top: 0px;
`;

export const MinusIcon = styled.span`
  position: absolute;
  left: 9px;
  top: -1px;
`;

export const TableDataName = styled.span<{
  isChild: boolean | undefined;
  rowId: string;
  noChildren: boolean;
  tableVariant: string;
}>`
  padding-left: ${(props) =>
    props.isChild ? indentChildren(props.rowId) : 0}px;
  margin-left: ${(props) => (props.noChildren ? 30 : 0)}px;
`;

export const TableDataNameLink = styled.span<{
  tableVariant?: string;
}>`
  ${(props) =>
    props.tableVariant === HPC_USER_SBUS_USAGE ||
      props.tableVariant === ECMWF_ADMIN_SEARCH_ACCOUNTS ||
      props.tableVariant === ACCOUNT_DETAIL_VIEW_MONTHS ||
      props.tableVariant === ACCOUNT_DETAIL_VIEW_QUARTERS ||
      props.tableVariant === ACCOUNT_DETAIL_VIEW_YEARS
      ? `color: ${colors.primary.main};`
      : `
  color: ${colors.blue.main};
  cursor: pointer; 
  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`}
`;
export const ChildRow = styled.tr<{
  tableVariant: string;
  timePeriod: string;
}>`
  vertical-align: baseline;
  text-align: ${({ timePeriod, tableVariant }) =>
    timePeriod.length ||
      tableVariant === COMPREP_MANAGE_ALLOCATIONS ||
      tableVariant === ECMWF_ADMIN_MANAGE_OVERALLOCATIONS ||
      tableVariant === COMPREP_SEARCH_USERS ||
      tableVariant === ECMWF_ADMIN_SEARCH_ACCOUNTS ||
      tableVariant === COMPREP_EMAIL_ALERT_TABLE ||
      tableVariant === HPC_USER_SBUS_USAGE
      ? 'right'
      : 'left'} !important;

  ${({ tableVariant }) =>
    tableVariant === HPC_USER_SBUS_USAGE
      ? `td:first-of-type {
        text-align: ${tableVariant === HPC_USER_SBUS_USAGE ? 'left' : 'right'} !important;
      }`
      : ''}

  ${({ tableVariant }) =>
    [
      ACCOUNT_DETAIL_VIEW_MONTHS,
      ACCOUNT_DETAIL_VIEW_QUARTERS,
      ACCOUNT_DETAIL_VIEW_YEARS
    ].includes(tableVariant)
      ? `td:first-of-type {
      text-align: left !important;
      padding-left: 0.625em !important;
    }`
      : ''}
`;
