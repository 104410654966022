import toast from 'react-hot-toast';
import { searchOverallocationFromId } from '../../../../api/fetch-nodes';
import { GeneralDataNodeType, ManageOverallocationNodeType } from '../../../../api/types';
import { extractManageOverallocationData } from '../../../../api/utils';
import { TableOption } from '../ManageNodesAndAccess/types';

export const fetchOverallocationNodes = async (
  setTableData: Function,
  setTableLoading: Function,
  initialNodes: GeneralDataNodeType[]
): Promise<void> => {
  try {
    if (initialNodes?.length) {
      const sanitisedData: ManageOverallocationNodeType[] = extractManageOverallocationData(initialNodes);
      setTableData(sanitisedData);
      setTableLoading(false);
    } else {
      setTableLoading(false);
      setTableData([]);
      console.error('No generalDataNodes');
    }

  } catch (error) {
    console.error('Error fetching manage allocations table nodes: ', error);
    setTableLoading(false);
    setTableData([]);
  }
};

export const handleTableSearch = async (
  option: TableOption,
  setTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;

  setTableSearchResult([]);

  try {
    const searchResult: ManageOverallocationNodeType | undefined = await searchOverallocationFromId(option.id);
    if (searchResult) {
      setTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching result. Please try again.');
    console.error('error at manage nodes and access search: ', error);
  }
};