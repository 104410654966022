import Select, { createFilter } from 'react-select';
import { tableStyles, modalStyles, AddNodeModalStyles, customStyles } from './styles';
import { DropdownOption } from './types';


interface SelectInputProps {
  options: DropdownOption[];
  toParent: Function;
  currentOption: DropdownOption;
  type?: 'table' | 'modal' | 'add node modal' | 'graphs';
  isDisabled?: boolean;
}


const SelectInput = ({ options, type, toParent, currentOption, isDisabled }: SelectInputProps) => {
  const manageStyles = () => {
    if (type === 'table') return tableStyles;
    if (type === 'modal') return modalStyles;
    if (type === 'add node modal') return AddNodeModalStyles;

    return customStyles;
  };

  const handleOnChange = (option: DropdownOption | null): void => {
    toParent(option);
  };

  return (
    <Select 
      filterOption={createFilter({ ignoreAccents: false })} 
      options={options} 
      components={{ IndicatorSeparator: () => null }} 
      value={currentOption} 
      onChange={(option) => handleOnChange(option)}
      styles={manageStyles()}
      isDisabled={isDisabled}
    />
  );
};

export default SelectInput;