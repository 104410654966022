import { client, flexibleUrlClient } from './client';
import { formatEntries, formatNumber, isEmpty } from '../utils/methods';
import { 
  GeneralDataNodesResponseType, 
  MainNodesResponseType, 
  GeneralDataNodeType,
  GraphVariantType,
  TimePeriodType,
  MainNodesType,
  TimePeriodUsageResponseType,
  AccessResponse,
  WithAccessUsersType,
  ManageNodesAccessNodeType,
  ManageOverallocationNodeType,
  ManageAllocationNodeType,
  NodeUsageType,
  BatchTimePeriodUsageResponse,
  TransferNodeType,
  TransferNodesResponse
} from './types';
import { EmailAlertNodeType } from '../containers/views/CompRep/NodesHPCUsage/types';
import { CachedNodeType } from '../redux/reducers/types';

export const fetchMainNodes = async (): Promise<GeneralDataNodeType[] | undefined> => {
  try {
    const mainNodes: MainNodesResponseType = await client.get('/nodes/');

    if (mainNodes?.data) {
      const mainNodesPromises: Promise<GeneralDataNodesResponseType>[] = mainNodes.data.map(
        (node) => flexibleUrlClient.get(`${node.href}`));

      const generalDataNodesResponse: GeneralDataNodesResponseType[] = await Promise.all(mainNodesPromises);

      if (generalDataNodesResponse.length) {
        const generalDataNodes: GeneralDataNodeType[] = generalDataNodesResponse.map(
          (responseObj: GeneralDataNodesResponseType) => { return responseObj.data[0]; });

        return generalDataNodes;
      } else {
        return;
      }
    } else {
      return;
    }
  } catch (error) {
    console.error('Fetch Nodes error: ', error);
  }
};

export const fetchMainNodesChildren = async (
  parentNodeChildrenReference : MainNodesType[]
): Promise<GeneralDataNodeType[] | undefined> => {
  // STEP 1: fetch nodes with bare minimal 3 keys' data, "abbreviated" nodes
  if (parentNodeChildrenReference?.length) {
    // STEP 2: fetch general data about each of those abbreviated nodes
    const abbrNodesPromises : Promise<GeneralDataNodesResponseType>[] = parentNodeChildrenReference.map(
      (node:MainNodesType) => { return flexibleUrlClient.get(`${node.href}`); });

    try {
      const generalDataNodesResponse: GeneralDataNodesResponseType[] = await Promise.all(abbrNodesPromises);
      // if there are nodes with general data 
      if (generalDataNodesResponse.length) {
        // extract data array from each objects in generalDataNodesResponse array
        const extractedDataArray: GeneralDataNodeType[] = generalDataNodesResponse.map(
          (responseObj: GeneralDataNodesResponseType) => responseObj.data[0]);
        return extractedDataArray;
      } else {
        return;
      }
    } catch (error) {
      console.error('generalDataNodesResponse Error: ', error);
    }
  } else {
    console.error('No data in array');
    return [];
  }
};

// batch fetches m/q/y table stats, repeats below but will refactor to use only once asap
export const fetchNodesStatsForTimePeriod = async (
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year',
  initialNodes: GeneralDataNodeType[]
): Promise<BatchTimePeriodUsageResponse | undefined> => {
  let calculateVariantId = () => {
    if (timePeriod === 'usage-per-month') return '13-months';
    if (timePeriod === 'usage-per-quarter') return '5-quarters';
    if (timePeriod === 'usage-per-year') return '5-years';
  };
  let nodeIds = initialNodes.map(child => child.id).toString();
  let URL = `/stats/nodes/getStats?node_ids=${nodeIds}&stat_id=${timePeriod}&variant_id=${calculateVariantId()}`;
  try {
    const batchStats: BatchTimePeriodUsageResponse = await client.get(URL);
    return batchStats;
  } catch (error) {
    console.error('error fetching node stats for time period: ', error);
  }
};

// batch fetches graph stats
export const fetchGraphStatsForTimePeriod = async (
  graphStat: 'usage-per-day' | 'total-node-usage',
  initialNodes: MainNodesType[],
  variantId: GraphVariantType
): Promise<BatchTimePeriodUsageResponse | undefined> => {

  let nodeIds = initialNodes.map(node => node.id).toString();
  let URL = `/stats/nodes/getStats?node_ids=${nodeIds}&stat_id=${graphStat}&variant_id=${variantId}`;
  try {
    const batchStats: BatchTimePeriodUsageResponse = await client.get(URL);
    return batchStats;
  } catch (error) {
    console.error('error fetching graph stats for time period: ', error);
  }
};

// batch fetches m/q/y table stats 
export const fetchTableChildrenStatsForTimePeriod = async (
  timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year',
  parentNodeChildrenReference: MainNodesType[]
): Promise<BatchTimePeriodUsageResponse | undefined> => {
  let calculateVariantId = () => {
    if (timePeriod === 'usage-per-month') return '13-months';
    if (timePeriod === 'usage-per-quarter') return '5-quarters';
    if (timePeriod === 'usage-per-year') return '5-years';
  };
  let nodeIds = parentNodeChildrenReference.map(child => child.id).toString();
  let URL = `/stats/nodes/getStats?node_ids=${nodeIds}&stat_id=${timePeriod}&variant_id=${calculateVariantId()}`;
  try {
    const batchStats: BatchTimePeriodUsageResponse = await client.get(URL);
    return batchStats;
  } catch (error) {
    console.error('error fetching children stats for time period: ', error);
  }
};

export const fetchTableChildrenGeneralNodes = async (
  childrenReference: MainNodesType[]
): Promise<GeneralDataNodeType[] | undefined> => {
  try {
    const abbrNodesPromises: Promise<GeneralDataNodesResponseType>[] = childrenReference.map(
      (node:MainNodesType) => flexibleUrlClient.get(node.href));

    const generalDataNodesResponse: GeneralDataNodesResponseType[] = await Promise.all(abbrNodesPromises);
    const extractedDataArray: GeneralDataNodeType[] = generalDataNodesResponse.map(
      (responseObj:GeneralDataNodesResponseType) => responseObj.data[0]);      

    return extractedDataArray;

  } catch (error) {
    console.error('error fetching children with general data: ', error);
  }
};




export const fetchNodeDataFromId = async (
  nodeId: string
): Promise<GeneralDataNodeType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(`/nodes/${nodeId}`);
    return response.data[0];
  } catch (error) {
    console.error('Failed to fetch node data:', error);
    return;
  }
};

// fetches nodes with read and admin access
export const fetchNodesWithAccess = async (
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType
): Promise<WithAccessUsersType[] | undefined> => {
  try {
    if (initialNodes.length && !isEmpty(nodes)) {
      const withAccessPromise: Promise<WithAccessUsersType | any>[] = initialNodes.map(
        async (node: GeneralDataNodeType) => {
          try {
            const adminAccessResponse: AccessResponse = await flexibleUrlClient.get(node.access.admin.href);
            const readAccessResponse: AccessResponse = await flexibleUrlClient.get(node.access.read.href);

            return {
              ...nodes[node.id], // ...props.nodes[node.id] instead of empty initialnodes data
              adminAccessUsers: adminAccessResponse.data.map((id: string) => ({ user_id: id })),
              readAccessUsers: readAccessResponse.data.map((id:string) => ({ user_id: id }))
            };
          } catch (error) {
            console.error('failed to fetch read and admin access for node: ', error);
          }
        });
      const withAdminAndReadAccess: WithAccessUsersType[] = await Promise.all(withAccessPromise);

      return withAdminAndReadAccess;

    } else {
      console.error('No nodes present');
      return;
    }

  } catch (error){
    console.error('Error fetching general data nodes: ', error);
  }
};

export const fetchReadAndAdminAccesUsers = async (
  nodes: GeneralDataNodeType[]
): Promise<WithAccessUsersType[] | undefined> => {
  const withAccessPromise: Promise<WithAccessUsersType | any>[] = nodes.map(async (node:GeneralDataNodeType) => {
    try {
      const adminAccessResponse: AccessResponse = await flexibleUrlClient.get(node.access.admin.href);
      const readAccessResponse: AccessResponse = await flexibleUrlClient.get(node.access.read.href);

      return {
        ...node,
        adminAccessUsers: adminAccessResponse.data.map((id: string) => ({ user_id: id })),
        readAccessUsers: readAccessResponse.data.map((id: string) => ({ user_id: id }))
      };
    
    } catch (error) {
      console.error('failed to fetch read and admin access for node: ', error);
    }
  });

  const withAdminAndReadAccess: WithAccessUsersType[] = await Promise.all(withAccessPromise);
  return withAdminAndReadAccess;
};

// fetches nodes children with read and admin access
export const fetchChildrenWithAccess = async (
  children: MainNodesType[]
): Promise<WithAccessUsersType[] | undefined> => {
  try {
    if (children && children.length) {
      const abbrNodesPromises: Promise<GeneralDataNodesResponseType>[] = children.map(
        (node:MainNodesType) => flexibleUrlClient.get(node.href)); // can replace node.href with passed down nodes

      try {
        const generalDataNodesResponse: GeneralDataNodesResponseType[] = await Promise.all(abbrNodesPromises);

        if (generalDataNodesResponse && generalDataNodesResponse.length) {
          // extract data array from each objects in generalDataNodesResponse array
          const extractedDataArray: GeneralDataNodeType[] = generalDataNodesResponse.map(
            (responseObj: GeneralDataNodesResponseType) => responseObj.data[0]);

          const withAccessPromise: Promise<WithAccessUsersType | any>[] = extractedDataArray.map(
            async (node:GeneralDataNodeType) => {
              try {
                const adminAccessResponse: AccessResponse = await flexibleUrlClient.get(node.access.admin.href);
                const readAccessResponse: AccessResponse = await flexibleUrlClient.get(node.access.read.href);

                return {
                  ...node,
                  adminAccessUsers: adminAccessResponse.data.map((id: string) => ({ user_id: id })),
                  readAccessUsers: readAccessResponse.data.map((id: string) => ({ user_id: id }))
                };
            
              } catch (error) {
                console.error('failed to fetch read and admin access for node: ', error);
              }
            });

          const withAdminAndReadAccess: WithAccessUsersType[] = await Promise.all(withAccessPromise);
          return withAdminAndReadAccess;

        } else {
          console.error('Error fetching general data nodes');
          return;
        }
      } catch (error){
        console.error('Error fettching general data nodes: ', error);
      }
    } else {
      console.error('No data when fetching nodes with read and admin access');
      return;
    }
  } catch (error) {
    console.error('Failed to fetch nodes with read and admin access: ', error);
  }
};

// SEARCH
// fetches a single node or account with read and admin access users
export const searchForNodeOrAccountWithAccess = async (
  id:string
):Promise<ManageNodesAccessNodeType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(`/nodes/${id}`);

    if (response && response.data.length) {
      try {
        const adminAccessResponse: AccessResponse = await flexibleUrlClient.get(response.data[0].access.admin.href);
        const readAccessResponse: AccessResponse = await flexibleUrlClient.get(response.data[0].access.read.href);

        // crafted format for ManageNodes&Access table
        return {
          name: response.data[0].name,
          org_id: response.data[0].id,
          category: response.data[0].type,
          description: response.data[0].description,
          // policy: 'Policy 1',
          read_access_users: readAccessResponse.data.map((id:string) => ({ user_id: id })),
          comprep_access_users: adminAccessResponse.data.map((id: string) => ({ user_id: id })),
          children: response.data[0].children
        };
      } catch (error) {
        console.error('Error fetching admin and read access users: ', error);
      }

    } else {
      console.error('No data available');
      return;
    }

  } catch (error) {
    console.error('Failed to fetch node or account with read and admin access: ', error);
  }
};

// searches nodes and accounts in Manage Overallocations
export const searchOverallocationFromId = async (
  id:string
): Promise<ManageOverallocationNodeType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(`/nodes/${id}`);

    if (response && response.data && response.data.length) {
      return {
        name: response.data[0].name,
        description: response.data[0].description,
        category: response.data[0].type,
        org_id: response.data[0].id,
        allocation: formatNumber(parseInt(response.data[0].sbu_allocation)),
        sbu_usage: formatNumber(parseInt(response.data[0].sbu_usage)),
        children: response.data[0].children,
        sbu_overallocation: formatNumber(parseInt(response.data[0].sbu_overallocation)),
        sbu_overallocation_percentage: response.data[0].sbu_overallocation_percentage
      };
    } else {
      console.error('No data response while fetching member with overallocation from id');
      return;
    }

  } catch (error) {
    console.error('Error while fetching member with overallocation from id: ', error);
  }
};

// searches nodes and accounts in Manage Overallocations
export const searchEmailAlertFromId = async (
  id:string
): Promise<EmailAlertNodeType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(`/nodes/${id}`);

    if (response && response.data && response.data.length) {
      return {
        name: response.data[0].name,
        description: response.data[0].description,
        category: response.data[0].type,
        org_id: response.data[0].id,
        parent_id: response.data[0].parent?.id || null,
        allocation: response.data[0].sbu_allocation === '0.00' ? '-' : formatNumber(parseInt(response.data[0].sbu_allocation)),
        sbu_usage: formatNumber(parseInt(response.data[0].sbu_usage)),
        children: response.data[0].children,
        // sbu_usage_percentage: response.data[0].sbu_usage_percentage,
        sbu_usage_percentage: response.data[0].sbu_allocation === '0.00' ? '-' : response.data[0].sbu_usage_percentage > 999 ? 999 : response.data[0].sbu_usage_percentage,
        email_alert_pct: response.data[0].email_alert_percentage
      };

    } else {
      console.error('No data response while fetching member with overallocation from id');
      return;
    }

  } catch (error) {
    console.error('Error while fetching member with overallocation from id: ', error);
  }
};

// searches nodes and accounts in Manage Allocations
// used in table search only
export const searchAllocationFromId = async (
  id:string
): Promise<ManageAllocationNodeType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(`/nodes/${id}`);

    if (response && response.data && response.data.length) {
      return {
        name: response.data[0].name,
        description: response.data[0].description,
        category: response.data[0].type,
        org_id: response.data[0].id,
        parent_id: response.data[0].parent ? response.data[0].parent.id : null,
        allocation: formatNumber(parseInt(response.data[0].sbu_allocation)),
        // allocation: response.data[0].sbu_allocation,
        sbu_usage: formatNumber(parseInt(response.data[0].sbu_usage)),
        undistributed: formatNumber(parseInt(response.data[0].sbu_undistributed)),
        children: response.data[0].children,
        sbu_available_for_transfer: response.data[0].sbu_available_for_transfer
      };

    } else {
      console.error('No data response while fetching member with allocation from id');
      return;
    }

  } catch (error) {
    console.error('Error while fetching member with allocation from id: ', error);
  }
};

// search node or account's months / q / years table usage
export const searchTableUsageFromId = async (
  id: string,
  timePeriod: TimePeriodType
): Promise<NodeUsageType | undefined> => {
  try {
    const nodeResponse: GeneralDataNodesResponseType = await client.get(`/nodes/${id}`);
    const nodeStatResponse: TimePeriodUsageResponseType = await client.get(`/nodes/${id}/stats/${timePeriod}`);

    if (nodeResponse && nodeResponse.data.length && nodeStatResponse && nodeStatResponse.data.data) {
      // isolate ...rest and ...pureUsage only
      const { id, name, ...rest } = nodeStatResponse.data.data[0];
      const { type, ...pureUsage } = rest;

      return {
        name: nodeResponse.data[0].name,
        description: nodeResponse.data[0].description,
        org_id: nodeResponse.data[0].id,
        email_alert_pct: nodeResponse.data[0].email_alert_percentage,
        allocation: formatNumber(parseInt(nodeResponse.data[0].sbu_allocation)),
        children: nodeResponse.data[0].children,
        category: nodeResponse.data[0].type,
        sbus_usage: formatEntries({ ...pureUsage })
      };
    } else {
      console.error(`No usage data available for ${id}`);
      return;
    }

  } catch (error) {
    console.error(`Failed to fetch usage data from id for ${id}: `, error);
  }
};

// used in manage allocations to fetch recipient dropdown options
export const fetchNodeChildrenDataFromId = async (
  id: string
): Promise<GeneralDataNodeType[] | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(`/nodes/${id}`);

    if (response && response.data && response.data.length) {

      const childrenDataPromise: Promise<GeneralDataNodesResponseType>[] = 
       response.data[0].children.map(
         async (childObj: MainNodesType) => await flexibleUrlClient.get(childObj.href));

      const rawChildrenData: GeneralDataNodesResponseType[] = await Promise.all(childrenDataPromise);
      const extractedData: GeneralDataNodeType[] = rawChildrenData.map(obj => ({ ...obj.data[0] }));

      return extractedData;

    } else {
      console.error('No data available');
      return;
    }

  } catch (error) {
    console.error('Failed to fetch node children data from node id: ', error);
  }
};

// used in transfer allocations to fetch available recipient nodes
export const fetchTransferRecipientNodes = async (id: string): Promise<TransferNodeType[] | undefined> => {
  try {
    const response: TransferNodesResponse = await client.get(`/nodes/${id}/recipientNodes/`);

    if (response && response.data && response.data.length) {

      return response.data.map((node) => {
        return {
          id: node.id,
          name: node.name,
          sbu_allocation: node.sbu_allocation,
          sbu_undistributed: node.sbu_undistributed,
          sbu_distributed: node.sbu_distributed
        };
      });
    }

    console.error('No recipient nodes available');
    return;

  } catch (error) {
    console.error('Failed to fetch recipient nodes.', error);
  }
};