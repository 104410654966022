import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';

import Animate from '../../../../components/Animate';
import Table from '../../../../components/Table';
import Search from '../../../../components/Inputs/Search';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';

import { ECMWF_ADMIN_CHANGE_LOG } from '../../../../utils/constants';
import { CHANGE_LOG_HEADERS } from '../../../../utils/tableHeaders';
import DatePickerInput from '../../../../components/Inputs/DatePicker';
import Button from '../../../../components/Button';
import TableLoader from '../../../../components/Loaders/TableLoader';
import CustomNotification from '../../../../components/Notification';
import { fetchChangeLogs } from '../../../../api/fetch-logs';
import { LogType } from '../../../../api/types';


const ChangeLog = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [valueFrom, onChangeFrom] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
  const [valueTo, onChangeTo] = useState<Date>(new Date());
  const [fetchedLogs, setFetchedLogs] = useState<LogType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Triggers search by default and on date inputs change
  useEffect(() => {
    triggerSearch();
  }, [valueFrom, valueTo]);


  const triggerSearch = async (): Promise<void> => {
    setIsLoading(true);

    const logPayload = { 'from_date': valueFrom, 'search': searchValue, 'to_date': valueTo };

    try {
      const logs = await fetchChangeLogs(logPayload);

      if (logs && logs.length) {
        setFetchedLogs(logs);
        setIsLoading(false);
      } else {
        setFetchedLogs([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Logs not available: ', error);
      toast.error('Failed to fetch logs');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      triggerSearch();
    }
    return;
  };

  return (
    <Container margin='0'>
      {/* TOP SECTION */}
      <Section margin='0 0 1.5em 0' flex>
        <div>
          <Typography as="h1" margin="0.5em 0 0 0">Change log</Typography>
          <Typography as="h3" margin="0" fontSize={22}>Filter log entries by time period and search term</Typography>
        </div>
      </Section>
      {/* search + calendar inputs */}
      <Section margin="0 0 3em 0" flex={true}>
        <div style={{ alignSelf: 'center' }}>
          <Search
            isChangeLog
            value={searchValue}
            userType="filter log entries by search term"
            dataFor={'change-log-search'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
          />
          <Button
            disabled={isLoading ? true : false}
            isLoading={isLoading}
            content={'Filter'}
            variant="change"
            onClick={() => triggerSearch()}
            style={{ padding: '0.55em 0.5em 0.55em 0.5em', marginLeft: '0.5em' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0.5em'
          }}>
            <label style={{ alignSelf: 'center', margin: '0 1em 0 0' }}><small><b>From:</b></small></label>
            <DatePickerInput
              onChange={(e: Date) => onChangeFrom(e)}
              value={valueFrom}
              maxDate={valueTo}

            />
          </span>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label style={{ alignSelf: 'center', margin: '0 1em 0 0' }}>
              <small><b>To:</b></small>
            </label>
            <DatePickerInput
              onChange={(e: Date) => onChangeTo(e)}
              value={valueTo}
              minDate={valueFrom}
            />
          </span>
        </div>
      </Section>

      <hr />
      <Section margin='1em 0 15em 0'>
        {isLoading ? (<TableLoader />) : fetchedLogs.length && !isLoading ? (
          <Animate>
            <Typography as="h2" margin="1em 0 0 0">Results</Typography>
            <Table
              headers={CHANGE_LOG_HEADERS}
              rowsData={fetchedLogs}
              tableVariant={ECMWF_ADMIN_CHANGE_LOG}
              hideSearch
              transferSearchTerm={() => null}
              alignCentreName
            />
          </Animate>
        ) : <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography as='h4' margin='0'>No log entries found</Typography>
        </div>}

      </Section>
      <ReactTooltip
        id='change-log-search'
        type='dark'
        place="top"
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>enter a search term</span>
      </ReactTooltip>
      <CustomNotification />
    </Container>
  );
};

export default ChangeLog;