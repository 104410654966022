import styled from 'styled-components';
import { breakpoints, colors } from '../../../globalStyles/theme';

export const StyledCards = styled.div`
@media screen and (min-width: ${breakpoints.mobiles.xsmall}) and (max-width: ${breakpoints.custom.size5}) {
    flex-wrap: wrap;
}
  display: flex;
  justify-content: space-between;
  margin: 0em 0 2em 0;
`;

export const StyledCard = styled.div`
@media screen and (min-width: ${breakpoints.custom.size6}) and (max-width: ${breakpoints.laptops.large}) {
  &:nth-of-type(1) {
    margin: 0 0.5em 0 0;
  }
  &:nth-of-type(4) {
    margin: 0 0 0 0.5em;
  }
  margin: 0em 0.5em 0em 0.5em;
}

@media screen and (width: ${breakpoints.custom.size5}) {
  &:nth-of-type(4) {
    margin: 1em 0 0 0;
  }
}

@media screen and (min-width: ${breakpoints.custom.size3}) and (max-width: ${breakpoints.custom.size4}) {
   &:nth-of-type(4) {
    margin: 1em 0 0 0;
  }
}

@media screen and (min-width: ${breakpoints.custom.size2}) and (max-width: ${breakpoints.custom.size3}) {
  &:nth-of-type(3), &:nth-of-type(4) {
   margin: 1em 0 0 0;
 }
}

@media screen and (max-width: ${breakpoints.custom.size1}) {
  width: 100%;
  &:nth-of-type(2),&:nth-of-type(3), &:nth-of-type(4) {
    margin: 1em 0 0 0;
  }
}

  width: 12em;
  padding: 1.25em;
  text-align: center;
  background: ${colors.white.main};
  color: rgba(0,0,0,0.8);
  border-radius: 0.25em;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%)
`;
