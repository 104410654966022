import { PureComponent } from 'react';
import { formatNumber } from '../../utils/methods';

import { CustomizedAxisTickType, CustomTooltipType } from './types';

export const handleAlignment = (payloadValue: number) => {

  let nbDigits = ('' + payloadValue).length;
  // 1,000
  if (nbDigits <= 4) return 0;
  // 10,000
  if (nbDigits === 5) return 0;
  // 100,000
  if (nbDigits === 6) return 25;
  // 1,000,000
  if (nbDigits === 7) return 30;
  // 10,000,000
  if (nbDigits === 8) return 30;
  // 100,000,000
  if (nbDigits === 9) return 35;
  // 1,000,000,000 (1 billion)
  if (nbDigits === 10) return 45;
  // 10,000,000,000 (10 billion)
  if (nbDigits === 11) return 45;
  // 100,000,000,000 (100 billion)
  if (nbDigits === 12) return 50;
  // 1,000,000,000,000 (1 trillion)
  if (nbDigits === 13) return 55;
  // 10,000,000,000,000 (10 trillion)
  if (nbDigits === 14) return 60;
  // 100,000,000,000,000 (100 trillion)
  if (nbDigits === 15) return 65;

  return 0;
};


export const colorArray = [
  'dodgerblue', 
  'blue', 
  'steelblue', 
  'midnightblue', 
  '#1b1b1b',
  'deepskyblue', 
  'teal', 
  'lightseagreen', 
  'darkturquoise', 
  'mediumaquamarine', 
  'seagreen', 
  'mediumspringgreen', 
  '#ff4e4f', 
  '#faa43a', 
  '#1083ab', 
  'greenyellow', 
  'limegreen', 
  'lime', 
  'darkorange', 
  'orange', 
  'gold', 
  'tomato', 
  'coral', 
  'darkred', 
  'crimson', 
  'indianred', 
  'lightcoral', 
  'salmon', 
  'indigo', 
  'darkmagenta', 
  'darkorchid', 
  'mediumpurple', 
  'magenta', 
  'mediumvioletred', 
  'pink',
  'silver', 
  'darkgray', 
  'grey', 
  'dimgray', 
  'slategray', 
  'darkslategray' 
];


export const customTooltip = (
  e: CustomTooltipType,
  areaIndex: string
): React.ReactElement | '' => {
  if (e && e.payload && e.payload.length) {
    const foundPayload = e.payload.find(dataObj => areaIndex === dataObj.name);

    if (foundPayload) {
      return (
        <div style={{
          background: 'white',
          border: '1px solid lightgray',
          padding: '0.5em'
        }}>
          <p style={{ margin: '0em 0 0.4em 0', fontWeight: 'bold' }}>{foundPayload ? e.label : ''}</p>
          <p style={{
            color: foundPayload ? foundPayload.stroke : 'black',
            margin: '0.4em 0 0.4em 0'
          }}>{foundPayload ? foundPayload.name : ''}</p>
          <p style={{ margin: '0.4em 0 0.4em 0' }}>{foundPayload ? formatNumber(foundPayload.value) : ''}</p>
        </div>
      );
    }

    return '';
 
  }
  else {
    return '';
  }
};

export class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } : CustomizedAxisTickType = this.props;

    return payload.value === 0 ? (
      <g transform={`translate(${x},${y})`}>
        <text x={5} y={0} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 11 }}>
          {payload.value.toLocaleString()}
        </text>
      </g>
    ) : ( 
      <g transform={`translate(${x},${y})`}>
        <text x={handleAlignment(payload.value)} y={3.5} textAnchor="end" fill="#666" transform="rotate(0)" style={{ fontSize: 11 }}>
          {payload.value.toLocaleString()}
        </text>
      </g>
    );
  }
}