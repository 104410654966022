import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { GetAllowedRoutes } from './utils';
import { StyledMenu, StyledNav } from './styles';
import { GlobalStateType } from '../../redux/reducers/types';
import { UserRole } from '../../containers/Routes/types';

interface ISidebarProps {
  policies: string[];
}

const Sidebar = ({ policies }: ISidebarProps) => {

  const authorisedRoutes = GetAllowedRoutes(policies as UserRole[]).filter(
    route => route.showInSideNav?.valueOf() === true);

  const location = useLocation();
  const handleNavSelect = (route: string, path: string) => path === route ? true : false;

  const renderNavLinks = () => {
    return authorisedRoutes.map(navlink => {
      return (
        <Link to={navlink.path} key={navlink.path} data-testid="sidebar-nav-link">
          <StyledNav isSelected={handleNavSelect(navlink.path, location.pathname)}>
            {navlink.linkText}
          </StyledNav>
        </Link>
      );
    });
  };
  return (
    <StyledMenu>
      {renderNavLinks()}
    </StyledMenu>
  );
};

const mapStateToProps = (state: GlobalStateType) => {
  return {
    policies: state.policies
  };
};

export default connect(
  mapStateToProps
)(Sidebar);
