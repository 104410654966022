import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import './style.css';
import { SearchAccountsRoute } from '../../../components/Sidebar/utils';

type TFallbackPage = {
  type: 'loading' | 'no-access' | 'error' | 'login' | 'no-recently-used-accounts' | 'not-found';
} | {
  type: 'custom';
  msg: string;
  returnToAccessible: boolean;
};

const FallbackPage = (params: TFallbackPage) => {
  const history = useHistory();
  const renderText = () => {
    if (params.type === 'custom') {
      return params.msg;
    }

    if (params.type === 'no-access') {
      return <span>You don\'t have permission to access this page.</span>;
    }

    if (params.type === 'loading') {
      return 'Loading...';
    }

    if (params.type === 'error') {
      return 'An error occurred while logging in. Please sign out and try again or contact your administrator.';
    }

    if (params.type === 'login') {
      const link = '/sbu-accounting-api/sso/login/openid-connect/';
      return <span>Please <a href={link}>login</a> to be re-directed to your accessible pages.</span>;
    }

    if (params.type === 'no-recently-used-accounts') {
      return <span>No accounts with recent usage.</span>;
    }

    if (params.type === 'not-found') {
      return 'This page does not exist.';
    }

    return;
  };

  return (
    <div className="container">
      <h3 className="container-h3 ">
        {renderText()}
      </h3>
      {params.type === 'custom' && params.returnToAccessible ? <div style={{ display: 'flex', justifyContent: 'center' }}><Button
        content='Take me back'
        variant='transfer'
        onClick={() => { history.push('/'); }}
        style={{
          width: 'fit-content',
          innerHeight: 'fit-content',
          padding: '6px'
        }}
      /></div> : null}
      {params.type === 'no-recently-used-accounts' ? <div style={{ display: 'flex', justifyContent: 'center' }}><Button
        content='Search all my accounts'
        variant='transfer'
        onClick={() => { history.push(SearchAccountsRoute.path); }}
        style={{
          width: 'fit-content',
          innerHeight: 'fit-content',
          padding: '6px'
        }}
      /></div> : null}
    </div>
  );
};

export default FallbackPage;