import config from '../config';
import { client, flexibleUrlClient } from './client';
import { FetchConfigResponse, LoggedInUserDataResponse } from './types';

const CONFIG_URL = config.apiUrl + '/frontend/config';

export const fetchConfig = async (): Promise<FetchConfigResponse | undefined> => {
  try {
    const response: FetchConfigResponse = await flexibleUrlClient.get(CONFIG_URL);
    return response;
  } catch (error) {
    console.error('Error fetching frontend config ', error);
  }
};

export const fetchLoggedInUserRoles = async (
  uid:string
): Promise<LoggedInUserDataResponse | undefined> => {
  try {
    const response: LoggedInUserDataResponse = await client.get(`/users/${uid}`);
    return response;
  } catch (error) {
    console.error('Error fetching logged-in user roles: ', error);
  }
};