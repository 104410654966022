import axios from 'axios';
import config from '../config';
import { store } from '../index';

// const DEV_URL = 'https://apps-dev.ecmwf.int/sbu-accounting-api/v1';
const DEFAULT_TIMEOUT = 120 * 1000;

export const client = axios.create({
  // baseURL: config.apiUrl + '/v1' || DEV_URL,
  baseURL: config.apiUrl + '/v1',
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: config.nodeEnv !== 'production'
});


export const flexibleUrlClient = axios.create({
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: config.nodeEnv !== 'production'
});

// response error handler - check if logged in
const handleError = (error: any) => {
  axios.get(config.apiUrl + '/frontend/config', {
    timeout: DEFAULT_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }).then((response) => {
    const hasUser = response?.data.user !== null && response?.data.user.uid;
    store.dispatch({ type: 'SET_LOGGED_IN', payload: hasUser });
  }
  ).catch((error) => {
    console.error('error fetching config', error);
  });

  return Promise.reject(error);
};

client.interceptors.response.use(
  (response) => {
    return response;
  },
  handleError
);

flexibleUrlClient.interceptors.response.use(
  (response) => {
    return response;
  }, handleError
);