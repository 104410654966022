import { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';

import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';

import DatePickerInput from '../../../../components/Inputs/DatePicker';
import Button from '../../../../components/Button';
import { fetchAllAccounts } from '../../../../api/fetch-accounts';
import SearchDropdown from '../../../../components/Inputs/Search/SearchDropdown';

import CustomNotification from '../../../../components/Notification';
import SearchLoader from '../../../../components/Loaders/SearchLoader';
import { SearchAccountType } from '../../../../api/types';
import { CraftedTableData, AccountData, AccountOption } from './types';
import { DEFAULT_ACCOUNTDATA, renderResults, triggerSearch } from './utils';


const SearchAccounts = () => {
  const [valueFrom, onChangeFrom] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
  const [valueTo, onChangeTo] = useState<Date>(new Date());
  const [selectedAccount, setSelectedAccount] = useState({ label: 'Type to search for a account...', value: 'search' });
  const [allAccounts, setAllAccounts] = useState<AccountOption[]>([]);
  const [accountData, setAccountData] = useState<AccountData>(DEFAULT_ACCOUNTDATA);
  const [tableData, setTableData] = useState<CraftedTableData[]>([]);
  const [isResultLoading, setResultLoading] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // INITIAL FETCH USERS TO SEARCH THROUGH
  useEffect(() => {
    const fetchSearchableAccounts = async (): Promise<void> => {
      const searchableAccounts: SearchAccountType[] | undefined = await fetchAllAccounts();
      setIsLoading(false);
      if (searchableAccounts && searchableAccounts.length) {
        const craftedAccountOptions: AccountOption[] = searchableAccounts.map((account: SearchAccountType) => {
          return { value: account.name, label: account.name };
        });
        setAllAccounts(craftedAccountOptions);
      }
    };

    if (!allAccounts.length){
      setIsLoading(true);
      fetchSearchableAccounts();
    }
  }, [allAccounts]);


  // DETECT CHANGE IN CALENDAR INPUTS VALUE THEN SEND REQUEST
  useEffect(() => {
    // ensure a value is selected first
    if (selectedAccount.value !== 'search') {
      triggerSearch(
        isResultLoading, 
        setResultLoading, 
        selectedAccount, 
        valueFrom, 
        valueTo, 
        setAccountData,
        setTableData
      );
    }
  }, [valueFrom, valueTo, selectedAccount]);

  // disable button useEffect
  useEffect(() => {
    if (selectedAccount.value !== 'search') setButtonDisabled(false);
    if (isResultLoading) setButtonDisabled(true);
  }, [selectedAccount, isResultLoading]);


  const renderDropdown = () => {
    if (isLoading || (isLoading && !allAccounts.length)) {
      return <SearchLoader/>;
    }
    if (allAccounts.length && !isLoading) {
      return (
        <SearchDropdown
          options={allAccounts}
          toParent={(option:AccountOption) => setSelectedAccount(option)}
          currentOption={selectedAccount}
        />
      );
    }
    if (!allAccounts.length && !isLoading) {
      return (
        <SearchDropdown
          options={[]}
          toParent={() => ''}
          currentOption={{ value: '', label: 'No accounts available' }}
          isDisabled
        />
      );
    }
  };
 
  return (
    <Container margin='0'>
      {/* TOP SECTION */}
      <Section margin='0 0 1.5em 0' flex>
        <div>
          <Typography as="h1" margin="0.5em 0 0 0">Search accounts</Typography>
          <Typography as="h3" margin="0" fontSize={22}>Account usage per user for selected time period</Typography>
        </div>
      </Section>

      {/* search + calendar inputs */}
      <Section margin="0 0 3em 0" flex={true}>
        <div style={{ alignSelf: 'center', display: 'flex' }}>
          {renderDropdown()}

          <Button 
            content={'Search'}
            variant="change"
            isLoading={isResultLoading}
            disabled={isButtonDisabled}
            onClick={() => triggerSearch(
              isResultLoading, 
              setResultLoading, 
              selectedAccount, 
              valueFrom, 
              valueTo, 
              setAccountData,
              setTableData
            )}
            style={{ padding: '0.55em 0.5em 0.55em 0.5em', marginLeft: '0.5em' }}
          />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}> 
          <span style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0.5em'
          }}>
            <label style={{ alignSelf: 'center', margin: '0 1em 0 0' }}><small><b>From:</b></small></label>
            <DatePickerInput
              onChange={(e:Date) => onChangeFrom(e)}
              value={valueFrom}
              maxDate={valueTo}
            />
          </span>
          <span style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <label style={{ alignSelf: 'center', margin: '0 1em 0 0' }}>
              <small><b>To:</b></small>
            </label>
            <DatePickerInput
              onChange={(e:Date) => onChangeTo(e)}
              value={valueTo}
              minDate={valueFrom}
            />
          </span>
        </div>
      </Section>

      <hr/>

      {renderResults(isResultLoading, selectedAccount, accountData, tableData)}

      <ReactTooltip 
        id='account-search' 
        type='dark' 
        place="top" 
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>Search for a account (e.g: Account 1)</span>
      </ReactTooltip>
      <CustomNotification/>
    </Container>
  );
};

export default SearchAccounts;