import { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';

import Button from '../../Button';
import Section from '../../Containers/Section';
import Download from '../../Icons/Download';
import GraphInputGroup from '../../Inputs/GraphInputGroup';
import GraphLoader from '../../Loaders/GraphLoader';
import Typography from '../../Typography';
import AccumulationGraph from '../AccumulationGraph';
import UsageGraph from '../UsageGraph';
import InputGroupLoader from '../../Loaders/InputGroupLoader';
import { ReducedDataType } from '../../../api/utils';
import Reset from '../../Icons/Reset';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { GraphOption } from '../../../containers/views/HPCUser/types';
import { IDualGraphs } from '../types';
import { extractGraphData, handleGraphNavigtion } from './utils';

const DualGraphs = ({
  options,
  graphCurrentOption,
  handleGraphCurrentOption,
  setTwoColumnGraphs,
  twoColumnGraphs,
  isUsageGraphLoading,
  usageGraphData,
  isAccumulationGraphLoading,
  accumulationGraphData,
  tiltYAxis = false,
  sbuThreshold,
  inNodeView,
  inAccountView,
  showInputGroupLoader,
  nodes,
  updateNode,
  showParent,
  displayUnit = 'SBU'
}: IDualGraphs) => {

  const [sbuGraphResult, setSbuGraphResult] = useState<ReducedDataType[]>([]);
  const [sbuGraphNodeBeingFetched, setSbuGraphNodeBeingFetched] = useState<boolean>(false);
  const [accumulationGraphResult, setAccumulationGraphResult] = useState<ReducedDataType[]>([]);
  const [accumulationGraphBeingFetched, setAccumulationGraphNodeBeingFetched] = useState<boolean>(false);
  const [clickedNodeIdInSBUGraph, setClickedNodeInInSBUGraph] = useState<string>('');
  const [hasNavigated, setHasNavigated] = useState<boolean>(false);
  const [localSelectedOption, setLocalSelectedOption] = useState<GraphOption>({ value: '', label: '' });
  const [nodeIdClicked, setNodeIdClicked] = useState<string>('');


  // USEEFFECT
  // 
  useEffect(() => {
    if (!localSelectedOption?.value) { setLocalSelectedOption(graphCurrentOption); }
  }, [graphCurrentOption]);

  // for local dropdown change only
  useEffect(() => {
    if (nodeIdClicked?.length && localSelectedOption?.value.length &&
      !sbuGraphNodeBeingFetched && !accumulationGraphBeingFetched) {
      handleGraphNavigtion(
        nodeIdClicked,
        nodes,
        updateNode,
        hasNavigated,
        localSelectedOption,
        graphCurrentOption,
        setSbuGraphNodeBeingFetched,
        setAccumulationGraphNodeBeingFetched
      );
    }
  }, [localSelectedOption]);

  useEffect(() => {
    if (nodes && nodeIdClicked.length) {
      // sbu graph
      let sbu = extractGraphData('sbuUsage', nodes, nodeIdClicked, localSelectedOption);
      if (sbu?.length) setSbuGraphResult(sbu);

      // accum graph
      let accum = extractGraphData('accumulationUsage', nodes, nodeIdClicked, localSelectedOption);
      if (accum?.length) setAccumulationGraphResult(accum);
    }

  }, [localSelectedOption, nodeIdClicked, nodes, graphCurrentOption]);


  const { width } = useWindowDimensions();
  useEffect(() => { if (width <= 1000) setTwoColumnGraphs(false); }, [width]);

  const handleClickedNode = (nodeId: string) => {
    setHasNavigated(true);
    setNodeIdClicked(nodeId);
    setClickedNodeInInSBUGraph(nodeId);
    handleGraphNavigtion(
      nodeId,
      nodes,
      updateNode,
      hasNavigated,
      localSelectedOption,
      graphCurrentOption,
      setSbuGraphNodeBeingFetched,
      setAccumulationGraphNodeBeingFetched
    );
  };

  const handleResetGraphs = (): void => {
    setHasNavigated(false);
    setNodeIdClicked('');
    setClickedNodeInInSBUGraph('');
    setAccumulationGraphResult([]);
    setSbuGraphResult([]);
    // setLocalSelectedOption(graphCurrentOption);
    ReactTooltip.hide();
  };

  return (
    <>
      <Section flex>
        <div style={{ margin: '1em 0' }}>
          <Typography as="h2" margin="0">
            {hasNavigated ? `Total usage for ${localSelectedOption && localSelectedOption.label ? localSelectedOption.label : ''}` :
              `Total usage for ${graphCurrentOption && graphCurrentOption.label ? graphCurrentOption.label : ''}`
            }
          </Typography>
          {showParent && nodeIdClicked && (<Typography as='p' margin='0'>Parent node: {nodeIdClicked}</Typography>)}
        </div>
        {!options.length || showInputGroupLoader ? <InputGroupLoader /> : (
          <GraphInputGroup
            options={options}
            selectedOption={(option: GraphOption) => {
              // return hasNavigated ? setLocalSelectedOption(option) : handleGraphCurrentOption(option);
              if (hasNavigated) {
                setLocalSelectedOption(option);
              } else {
                setLocalSelectedOption(option);
                // this triggers parent view fetching
                handleGraphCurrentOption(option);
              }
              // handleResetGraphs();
            }}
            currentOption={hasNavigated ? localSelectedOption : graphCurrentOption}
            setGraphLayout={() => setTwoColumnGraphs(!twoColumnGraphs)}
          />
        )}
      </Section>
      <Section style={{ display: 'flex', flexDirection: twoColumnGraphs ? 'row' : 'column' }}>
        {isUsageGraphLoading ? <span style={{ marginRight: '2em', flex: 1 }}><GraphLoader width={530} /> </span> : (
          <div style={{
            width: twoColumnGraphs ? '47.9%' : '98.6%',
            background: 'white',
            padding: '1em 1em 0 0em',
            marginRight: '1em',
            borderRadius: '0.25em',
            boxShadow: '0 1px 3px rgb(0 0 0 / 12%)'
          }}
            data-testid="usage-graph"
          >
            <div style={{ display: 'flex', margin: '0 0 0 2em' }}>
              <div style={{ flex: 1, alignSelf: 'center' }}>
                <Typography as="p" margin="0 0 0 2.5em" textAlign="center">{displayUnit} usage</Typography>
              </div>
              <div>
                {!inAccountView && (
                  <Button
                    content={(<Reset />)}
                    onClick={() => handleResetGraphs()}
                    variant="csv"
                    disabled={hasNavigated ? false : true}
                    dataFor='graph-reset'
                    style={{ width: 'unset', padding: '0.4em 0.5em 0.1em 0.5em', marginRight: '0.2em' }}
                  />
                )}

                <Button
                  content={(
                    <CSVLink
                      data={(hasNavigated && !sbuGraphNodeBeingFetched) ?
                        extractGraphData('sbuUsage', nodes, nodeIdClicked, localSelectedOption) :
                        usageGraphData
                      }
                      filename={'sbu-usage-graph.csv'}>
                      <Download />
                    </CSVLink>
                  )}
                  onClick={() => ''}
                  variant="csv"
                  disabled={sbuGraphNodeBeingFetched ? true : false}
                  dataFor='graph-csv-download'
                  style={{ width: 'unset', padding: '0.4em 0.5em 0.1em 0.5em' }}
                />
              </div>
            </div>

            <ReactTooltip id='graph-csv-download' type='dark'>
              <span>Download graph CSV</span>
            </ReactTooltip>
            <ReactTooltip id='graph-reset' type='dark'>
              <span>Reset graphs</span>
            </ReactTooltip>
            {usageGraphData.length ? (
              <UsageGraph
                data={(hasNavigated && sbuGraphResult.length) ? sbuGraphResult : usageGraphData}
                fetchClickedNode={(nodeId: string) => handleClickedNode(nodeId)}
                nodeBeingFetched={sbuGraphNodeBeingFetched}
                tiltYAxis={tiltYAxis}
              />
            ) : (
              <div style={{ textAlign: 'center', padding: '10em 0 10em 0' }}>
                No data available for this graph
              </div>
            )}

          </div>
        )}
        {isAccumulationGraphLoading ? (
          <div>
            <span>
              <GraphLoader width={530} />
            </span>
          </div>) : (
          <div style={{
            width: twoColumnGraphs ? '47.9%' : '98.6%',
            margin: `${twoColumnGraphs ? '0em' : '1em'} 0 0 0`,
            padding: '1em 1em 0 0',
            background: 'white',
            borderRadius: '0.25em',
            boxShadow: '0 1px 3px rgb(0 0 0 / 12%)'
          }}
            data-testid="accumulation-graph"
          >
            <div style={{
              display: 'flex',
              margin: '0 0 0 2em'
            }}>
              <div style={{ flex: 1, alignSelf: 'center' }}>
                <Typography as="p" margin="0 0 0 2.5em" textAlign="center">{displayUnit} accumulation</Typography>
              </div>
              <div>
                {!inAccountView && (
                  <Button
                    content={(<Reset />)}
                    onClick={() => handleResetGraphs()}
                    variant="csv"
                    disabled={hasNavigated ? false : true}
                    dataFor='graph-reset'
                    style={{ width: 'unset', padding: '0.4em 0.5em 0.1em 0.5em', marginRight: '0.2em' }}
                  />
                )}
                <Button
                  content={(
                    <CSVLink
                      data={(hasNavigated && !accumulationGraphBeingFetched) ?
                        extractGraphData('accumulationUsage', nodes, nodeIdClicked, localSelectedOption) :
                        accumulationGraphData
                      }
                      filename={'sbu-accumulation-graph.csv'}>
                      <Download />
                    </CSVLink>
                  )}
                  disabled={accumulationGraphBeingFetched ? true : false}
                  onClick={() => ''}
                  variant="csv"
                  dataFor='graph-csv-download'
                  style={{ width: 'unset', padding: '0.4em 0.5em 0.1em 0.5em' }}
                />
              </div>
            </div>

            <ReactTooltip id='graph-csv-download' type='dark'>
              <span>Download graph CSV</span>
            </ReactTooltip>
            <ReactTooltip id='graph-reset' type='dark'>
              <span>Reset graphs</span>
            </ReactTooltip>
            {accumulationGraphData.length ? (
              <AccumulationGraph
                data={
                  (hasNavigated && accumulationGraphResult.length) ? accumulationGraphResult : accumulationGraphData
                }
                fetchClickedNode={(nodeId: string) => handleClickedNode(nodeId)}
                nodeBeingFetched={accumulationGraphBeingFetched}
                clearedResults={accumulationGraphResult.length ? false : true}
                clickedNodeIdInSBUGraph={clickedNodeIdInSBUGraph}
                sbuThreshold={sbuThreshold}
                inNodeView={inNodeView}
                inAccountView={inAccountView}
              />
            ) : (
              <div style={{ textAlign: 'center', padding: '10em 0 10em 0' }}>
                No data available for this graph
              </div>
            )}
          </div>
        )}
      </Section>
    </>
  );
};



export default DualGraphs;