import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from '../../../../components/Table';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';

import TableLoader from '../../../../components/Loaders/TableLoader';
import CustomNotification from '../../../../components/Notification';
import { isEmpty } from '../../../../utils/methods';
import { GeneralDataNodeType, ManageNodesAccessNodeType, SearchUserType } from '../../../../api/types';
import { fetchTableNodesWithAccess, handleTableSearch, returnHeaders, returnTableVariant } from './utils';
import { TableOption } from './types';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { UserRole } from '../../../Routes/types';
import { updateNode } from '../../../../redux/actions';
import { fetchAllUsers } from '../../../../api/fetch-users';
import { UserOption } from '../SearchAccounts/types';

// redux policies
const ManageNodesAndAccess = (
  { policies, initialNodes, updateNode, nodes }:
    { policies: UserRole[], initialNodes: GeneralDataNodeType[], updateNode: Function, nodes: CachedNodeType; }
) => {
  const [tableData, setTableData] = useState<ManageNodesAccessNodeType[]>([]);
  const [tableSearchResult, setTableSearchResult] = useState<ManageNodesAccessNodeType[]>([]);
  const [isTableLoading, setTableLoading] = useState<boolean>(true);
  const [allUsers, setAllUsers] = useState<UserOption[]>([]);

  const includesAdmin = policies.includes('admin');

  // initial fetching of admin / read access users
  useEffect(() => {
    if (initialNodes.length && !isEmpty(nodes) && !tableData.length) {
      fetchTableNodesWithAccess(isTableLoading, setTableLoading, initialNodes, updateNode, nodes);
    }
  }, [nodes]);


  // Fetch users for autocomlete
  useEffect(() => {
    const fetchSearchableUsers = async (): Promise<void> => {
      const searchableUsers: SearchUserType[] | undefined = await fetchAllUsers();
      if (searchableUsers && searchableUsers.length) {
        const craftedUserOptions: UserOption[] = searchableUsers.map((user: SearchUserType) => {
          return { value: user.name, label: user.name };
        });
        setAllUsers(craftedUserOptions);
      }
    };

    if (!allUsers.length) {
      fetchSearchableUsers();
    }
  }, [allUsers]);

  useEffect(() => {
    // craft and feed initialnodes to table via setTableData
    if (initialNodes.length && !isEmpty(nodes)) {
      const tableFormatted = initialNodes.map(initNode => {
        return {
          name: nodes[initNode.id].name,
          org_id: nodes[initNode.id].id,
          category: nodes[initNode.id].type,
          description: nodes[initNode.id].description,
          // policy: 'Policy 1',
          read_access_users: nodes[initNode.id].readAccessUsers,
          comprep_access_users: nodes[initNode.id].adminAccessUsers,
          children: nodes[initNode.id].children
        };
      });

      setTableData(tableFormatted);
    }


    if (!initialNodes.length && isEmpty(nodes)) {
      setTableLoading(true);
    }

  }, [nodes]);


  return (
    <>
      <Container margin='0'>
        {/* TOP SECTION */}
        <Section margin='0 0 1.5em 0' flex>
          <div>
            <Typography as="h1" margin="0.5em 0 0 0">Manage nodes & access</Typography>
            <Typography as="h3" margin="0" fontSize={22}>Create nodes and manage read or admin access for users</Typography>
          </div>
        </Section>

        <Section margin='1.85em 0 40em 0'>
          {isTableLoading ? <TableLoader /> : (
            <Table
              showExpandButton
              headers={returnHeaders(includesAdmin)}
              rowsData={tableData}
              tableVariant={returnTableVariant(includesAdmin)}
              transferSearchTerm={(option: TableOption) => handleTableSearch(option, setTableSearchResult)}
              searchTermResult={tableSearchResult}
              autocompleteData={{ 'users': allUsers }}
            />
          )}
        </Section>
        <CustomNotification />
      </Container>
    </>
  );
};


const mapStateToProps = (state: GlobalStateType): {
  policies: UserRole[],
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType;
} => {
  return {
    policies: state.policies,
    initialNodes: state.initialNodes,
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(ManageNodesAndAccess);
