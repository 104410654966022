import { refreshByCacheKey } from 'react-router-cache-route';
import { AccountUsageType, GeneralDataNodeType, GraphVariantObjType, TreeNode, VariantObjectType } from '../api/types';
import { ReducedDataType } from '../api/utils';
import { CachedNodeType } from '../redux/reducers/types';
import { MANAGE_ALLOCATIONS, MANAGE_NODES_ACCESS, MANAGE_OVERALLOCATIONS, MY_USAGE, NODES_USAGE, READ_ONLY_ACCESS } from './constants';

// ensures no duplicates in an array
export const getUniqueListBy = (
  arr: VariantObjectType[], 
  key: string = 'id'
): GraphVariantObjType[] => {
  return [...new Map(arr.map((item: any) => [item[key], item])).values()];
};

// group an array of objects by key
export const groupByKey = (list: ReducedDataType[], key: string = 'time_unit') => 
  list.reduce((hash: any, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

// calculates total value of each unknown property of an object from a list of objects
export const dynamicArraySum = (array: AccountUsageType[]): AccountUsageType => 
  array.reduce((acc: {[key:string] : any}, item) => {
    Object
      .keys(item)
      .forEach((key) => {
        acc[key] = (parseInt(acc[key]) || 0) + parseInt(item[key]);
      });
    delete acc.time_unit; 
    return acc;
  }, {});

// flattens an array with recursively nested objects in one flat hiearchy
export const flatten = (arr: TreeNode[]): TreeNode[] => {
  return arr.flatMap((el: TreeNode) => {
    if ((el?.children.length)) {
      // el version without children array
      let withoutChildren = { ...el, children: [] };
      let x = [withoutChildren, ...el.children];
      return flatten(x);
    } else {
      return el;
    }
  });
};

// round decimals + add separators
export const formatNumber = (number: number): string => Math.round(number).toLocaleString();

// remove comma separators from a string value
export const removeSeparator = (string: string): string => string.replace(/,(?=\d{3})/g, '');

// round decimals + add separators to multiple entries in an object
export const formatEntries = (usageObj: AccountUsageType) => {
  let obj = {};

  for (const [key, value] of Object.entries(usageObj)) {
    obj = {
      ...obj,
      [key]: formatNumber(parseInt(value))
    };
  }

  return obj;
};

export const refreshPage = (): void => window.location.reload(); 

export const convertDate = (date: Date):string => date.toString().split('T')[0];

export const isEmpty = (inputObject: GeneralDataNodeType | CachedNodeType): boolean => {
  return Object.keys(inputObject).length === 0;
};

// refreshes relevant view's caches (used after performing update actions)
export const refreshCaches = (): void => {
  let views = [
    MY_USAGE, 
    READ_ONLY_ACCESS, 
    NODES_USAGE,
    MANAGE_ALLOCATIONS, 
    MANAGE_NODES_ACCESS, 
    MANAGE_OVERALLOCATIONS
  ];

  return views.forEach(view => refreshByCacheKey(view));
};

// transforms an array of objects into one object
export const arrayToObject = (array: any[]): {} => {
  return array.reduce((accum, val) => {
    Object.assign(accum, val);
    return accum;
  }, {}); 
};