import { useState, useEffect, useRef, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { SpinnerCircularFixed } from 'spinners-react';
import ReactTooltip from 'react-tooltip';

import Row from './parts/Row';
import Button from '../../components/Button';
import Download from '../../components/Icons/Download';
import Typography from '../Typography';
import SearchLoader from '../Loaders/SearchLoader';
import SearchDropdown from '../Inputs/Search/SearchDropdown';

import { craftCSVData, handleHeaderTextAlign, handleRowTextAlign, useOnClickOutside } from './utils';

import { CachedNodeType, GlobalStateType } from '../../redux/reducers/types';
import { TableProps, TableSearchOption } from './types';
import { DataType } from './parts/Row/types';
import { TreeNode } from '../../api/types';

import * as S from './styles';
import CloseIcon from '../Icons/Close/CloseIcon';
import Section from '../Containers/Section';
import { SORTABLE_TABLE_VARIANTS } from '../../utils/constants';
import Sort from '../Icons/Sort';

const DEFAULT_SELECTED_OPTION = {
  label: 'Type to search table...',
  value: '',
  id: '',
  type: ''
};


const Table = ({
  timePeriod,
  headers,
  rowsData,
  tableVariant,
  hideSearch,
  hideCSVButton,
  transferSearchTerm,
  showExpandButton,
  alignCentreName = false,
  searchTermResult,
  // redux tree
  tree,
  refreshTable = () => '',
  autocompleteData = {}
}: TableProps) => {
  const [displaySearchResults, setDisplaySearchResults] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<TableSearchOption>(DEFAULT_SELECTED_OPTION);
  const [searchOptions, setSearchOptions] = useState<TableSearchOption[]>([]);
  const [searchResultLoading, setSearchResultLoading] = useState<boolean>(false);
  const [localSearchResult, setLocalSearchResult] = useState<any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const [dataForCSV, setDataForCSV] = useState<any[]>([]);

  const isSortable = SORTABLE_TABLE_VARIANTS.includes(tableVariant);
  const [sortColumn, setSortColumn] = useState('');
  enum SortOrder {
    Original = 0,
    Ascending = 1,
    Descending = -1
  }
  const [sortOrder, setSortOrder] = useState(SortOrder.Original);

  const rowsDataObjectKeys = isSortable && rowsData.length ? Object.keys(rowsData[0]) : [];

  const sortRowsData = (data: any[], column: string, order: SortOrder) => {
    if (!column) {
      return data;
    }

    if (order === SortOrder.Original) {
      return data;
    }

    const copy = [...data];
    if (order === SortOrder.Ascending) {
      const sorted = (copy as []).sort((a, b) => {
        const valueA = a[column] as string;
        const valueB = b[column] as string;
        if (isNaN(parseInt(valueA.replaceAll(',', ''))) || isNaN(parseInt(valueB.replaceAll(',', '')))) {
          return valueA.localeCompare(valueB);
        } else {
          return parseInt(valueA.replaceAll(',', '')) - parseInt(valueB.replaceAll(',', ''));
        }
      });
      return sorted;
    }

    // order is descending
    const sorted = (copy as []).sort((a, b) => {
      const valueA = a[column] as string;
      const valueB = b[column] as string;
      if (isNaN(parseInt(valueA.replaceAll(',', ''))) || isNaN(parseInt(valueB.replaceAll(',', '')))) {
        return valueB.localeCompare(valueA);
      }
      return parseInt(valueB.replaceAll(',', '')) - parseInt(valueA.replaceAll(',', ''));
    });
    return sorted;
  };

  const handleHeaderClick = (e: MouseEvent) => {
    if (!isSortable) {
      return;
    }

    const colHeader = (e.currentTarget as HTMLTableCellElement).getAttribute('data-objkey');
    if (!colHeader) {
      return;
    }

    if (colHeader !== sortColumn) {
      setSortColumn(colHeader);
      setSortOrder(SortOrder.Ascending);
      return;
    }

    if (colHeader === sortColumn) {
      if (sortOrder === SortOrder.Original) {
        setSortOrder(SortOrder.Ascending);
        return;
      }
      if (sortOrder === SortOrder.Ascending) {
        setSortOrder(SortOrder.Descending);
        return;
      }
      if (sortOrder === SortOrder.Descending) {
        setSortOrder(SortOrder.Original);
        return;
      }
    }
  };

  // SET SEARCH INPUT OPTIONS
  useEffect(() => {
    if (tree && tree.length && !hideSearch) {
      setSearchOptions(tree);
    }
  }, [tree]);

  // initial setting for rowsData only
  useEffect(() => {
    if (rowsData.length && !localSearchResult.length) {
      setDataForCSV(rowsData);
    }
  }, [rowsData, localSearchResult]);

  // initial setting for rowsData only
  useEffect(() => {
    if (localSearchResult.length) {
      setDataForCSV(localSearchResult);
    }
  }, [localSearchResult]);

  // search term local manipulation
  useEffect(() => {
    setDisplaySearchResults(false);
    if (searchTermResult && searchTermResult?.length) {
      setLocalSearchResult(searchTermResult);
      setDisplaySearchResults(true);
    }
  }, [searchTermResult]);

  // clear local search
  useEffect(() => {
    if (!selectedOption) {
      setLocalSearchResult([]);
    }
  }, [selectedOption]);

  // search term loading
  useEffect(() => {
    if (hideSearch) return;
    if (searchTermResult?.length) {
      setSearchResultLoading(false);
    }
  });


  const handleOptionToParent = (option: TableSearchOption) => {
    setSearchResultLoading(true);
    setSelectedOption(option);
    transferSearchTerm(option);
  };

  const renderExpandedModeTitle = () => {
    if (timePeriod?.length && isExpanded) {
      if (timePeriod === 'usage-per-month') {
        return <Typography as="p" margin="0" fontSize={22}>SBU Usage Per Month</Typography>;
      }
      if (timePeriod === 'usage-per-quarter') {
        return <Typography as="p" margin="0" fontSize={22}>SBU Usage Per Quarter</Typography>;
      }
      if (timePeriod === 'usage-per-year') {
        return <Typography as="p" margin="0" fontSize={22}>SBU Usage Per Year</Typography>;
      }
    }

    return '';
  };

  // Handle click outside of expanded table
  const wrapperRef = useRef<HTMLDivElement>(null);
  const clickOutsideHandler = () => {
    setIsExpanded(false);
  };
  useOnClickOutside(wrapperRef, clickOutsideHandler);

  const renderOrderIcon = (column: string) => {
    if (!column) {
      return null;
    }
    if (column !== sortColumn) {
      return <Sort order='original' />;
    }
    if (sortOrder === SortOrder.Ascending) {
      return <Sort order='ascending' />;
    }
    if (sortOrder === SortOrder.Descending) {
      return <Sort order='descending' />;
    }
    if (sortOrder === SortOrder.Original) {
      return <Sort order='original' />;
    }

  };

  return (
    <S.TableWrapper
      data-testid="table"
      searchResultLoading={searchResultLoading}
      isExpanded={isExpanded}
      tableVariant={tableVariant}
      ref={wrapperRef}
    >
      <Section margin="0 0 1em 0" flex>
        <div>
          {renderExpandedModeTitle()}
        </div>
        {showExpandButton && isExpanded && (
          <CloseIcon onClick={() => setIsExpanded(!isExpanded)} width="30px" height="30px" />
        )}
      </Section>
      <div style={{ margin: '0 0 1em 0' }}>
        <section style={{ display: 'flex', justifyContent: 'space-between' }}>
          {!hideSearch && (
            <form onSubmit={e => e.preventDefault()}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {searchOptions.length ? (
                  <SearchDropdown
                    options={searchOptions}
                    toParent={(option: TableSearchOption) => handleOptionToParent(option)}
                    currentOption={selectedOption}
                    isClearable={selectedOption && selectedOption.value ? true : false}
                  />
                ) : (
                  <SearchLoader />
                )}
                {searchResultLoading ? (
                  <div style={{ position: 'relative' }}>
                    <span style={{ position: 'absolute', top: 5, left: 15 }}>
                      <SpinnerCircularFixed size={30} thickness={152} speed={178} color="rgba(30,144,255, 0.5)" secondaryColor="rgba(0, 0, 0, 0.17)" />
                    </span>
                  </div>
                ) : null}
              </div>
            </form>
          )}
          {hideSearch && <div />}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {showExpandButton && !isExpanded && (
              <Button
                content={isExpanded ? 'Exit' : 'Expand'}
                variant="transfer"
                dataFor="expand-table"
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  fontSize: 'smaller',
                  background: isExpanded ? 'red' : 'dodgerblue',
                  borderColor: isExpanded ? 'red' : 'dodgerblue'
                }}
              />)}
            {!hideCSVButton ? (
              <div style={{ alignSelf: 'center' }}>
                <Button
                  content={
                    <CSVLink data={craftCSVData(dataForCSV, tableVariant)} filename={'table-data.csv'}>
                      <Download />
                    </CSVLink>
                  }
                  variant="csv"
                  dataFor="table-csv-download"
                  onClick={() => null}
                  style={{ width: 'unset', padding: '0.4em 0.5em 0.1em 0.5em' }}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        </section>
      </div>
      <S.ScrollableWrapper isExpanded={isExpanded}>
        <S.Table>
          <S.TableHead>
            <tr style={{
              textAlign: handleRowTextAlign(tableVariant, timePeriod?.length ? timePeriod : '')
            }}>
              {headers.map((header: string, index: number) => {
                return (
                  <th
                    key={header}
                    data-testid="table-header"
                    data-objkey={rowsDataObjectKeys[index]}
                    style={{ textAlign: handleHeaderTextAlign(header), cursor: isSortable ? 'pointer' : 'default' }}
                    onClick={(e) => handleHeaderClick(e)}
                  >
                    {handleHeaderTextAlign(header) === 'left' ? <>
                      <span style={{ marginRight: '1em' }}>{header}</span>
                      {renderOrderIcon(rowsDataObjectKeys[index])}
                    </>
                      : <>
                        {renderOrderIcon(rowsDataObjectKeys[index])}
                        <span style={{ marginLeft: '1em' }}>{header}</span>
                      </>}
                  </th>
                );
              })}
            </tr>
          </S.TableHead>
          <tbody>
            {!localSearchResult.length ?
              sortRowsData(rowsData, sortColumn, sortOrder).map((node: DataType, i: number) => (
                <Row
                  key={i}
                  data={node}
                  rowId={`${i}`}
                  tableVariant={tableVariant}
                  isChild={false}
                  timePeriod={timePeriod}
                  alignCentreName={alignCentreName}
                  refreshTable={() => refreshTable()}
                  passFetchedRowChildrenToParent={(fetchedRowChildren: DataType[]) => {
                    let csvCraftedArray = [...rowsData, ...fetchedRowChildren];
                    setDataForCSV([...csvCraftedArray, ...dataForCSV]);
                  }}
                  autocompleteData={autocompleteData}
                />
              )) : null}

            {localSearchResult.length && displaySearchResults ? localSearchResult.map((node: DataType, i: number) => (
              <Row
                key={i}
                data={node}
                rowId={`${i}`}
                tableVariant={tableVariant}
                isChild={false}
                timePeriod={timePeriod}
                alignCentreName={alignCentreName}
                refreshTable={() => refreshTable()}
                passFetchedRowChildrenToParent={(fetchedRowChildren: DataType[]) => {
                  let csvCraftedArray = [...localSearchResult, ...fetchedRowChildren];
                  setDataForCSV(csvCraftedArray);
                }}
                autocompleteData={autocompleteData}
              />
            )) : null}
          </tbody>
        </S.Table>
      </S.ScrollableWrapper>
      <ReactTooltip id='table-csv-download' type='dark'>
        <span>Download table CSV</span>
      </ReactTooltip>
      <ReactTooltip id='expand-table' type='dark'>
        <span>View table in compact or expanded formats</span>
      </ReactTooltip>
    </S.TableWrapper>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  tree: TreeNode[],
  nodes: CachedNodeType;
} => {
  return {
    tree: state.tree,
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, null)(Table);
