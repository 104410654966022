import toast from 'react-hot-toast';

import { searchAllocationFromId } from '../../../../api/fetch-nodes';
import { GeneralDataNodeType, ManageAllocationNodeType } from '../../../../api/types';
import { extractNameUsageAllocation } from '../../../../api/utils';
import { TableOption } from '../../Admin/ManageNodesAndAccess/types';
import { DataOverviewNodesType } from './types';

export const DEFAULT_OVERVIEW = {
  totalAllocation: 0,
  totalUsage: 0,
  totalUndistributed: 0,
  totalDistributed: 0,
  nbNodes: 0,
  totalDistributedPct: 0,
  totalUndistributedPct: 0
};

export const fetchManageAllocationsTableNodes = async (
  cardsLoading: boolean,
  tableIsLoading: boolean,
  setTableLoading: Function,
  setCardsLoading: Function,
  setGeneralNodesData: Function,
  setTableData: Function,
  initialNodes: GeneralDataNodeType[]
): Promise<void> => {
  try {
    if (!cardsLoading && !tableIsLoading) {
      setTableLoading(true);
      setCardsLoading(true);
    }

    if (initialNodes?.length) {
      // calculate total SBU allocation across those fetched nodes
      const totalAllocation: number = initialNodes.reduce((a, { sbu_allocation }) => {
        return a + parseInt(sbu_allocation);
      }, 0);
      // calculate total SBU usage across them
      const totalUsage: number = initialNodes.reduce(
        (a, { sbu_usage }) => a + parseInt(sbu_usage), 0);
      // calculate total undistributed
      const totalUndistributed: number = initialNodes.reduce(
        (a, { sbu_undistributed }) => a + parseInt(sbu_undistributed), 0);

      const totalDistributed: number = initialNodes.reduce(
        (a, { sbu_distributed }) => a + parseInt(sbu_distributed), 0);

      // Calculate the distributed / undistributed precentages
      const totalDistributedPct: number = 
        totalAllocation > 0 ? Math.min(Math.round(1000.0 * totalDistributed / totalAllocation) / 10.0, 100.0) : 100.0;

      const totalUndistributedPct: number = 100.0 - totalDistributedPct;

      // calculale number of nodes 
      const nbNodes: number = initialNodes.length;

      const generalDataFetchedNodes: DataOverviewNodesType = {
        totalAllocation,
        totalUsage,
        totalUndistributed,
        totalDistributed,
        nbNodes,
        totalDistributedPct,
        totalUndistributedPct
      };

      setGeneralNodesData(generalDataFetchedNodes);

      const sanitisedData: ManageAllocationNodeType[] = extractNameUsageAllocation(initialNodes);
      setTableData(sanitisedData);
      setTableLoading(false);
      setCardsLoading(false);
    } else {
      setTableLoading(false);
      setTableData([]);
      setCardsLoading(false);
      console.error('No generalDataNodes');
    }

  } catch (error) {
    console.error('Error fetching manage allocations table nodes: ', error);
    setTableLoading(false);
    setCardsLoading(false);
    setTableData([]);
  }
};

export const handleTableSearch = async (
  option: TableOption,
  setTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;

  setTableSearchResult([]);

  try {
    const searchResult: ManageAllocationNodeType | undefined = await searchAllocationFromId(option.id);
    if (searchResult) {
      setTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching result. Please try again.');
    console.error('error at manage nodes and access search: ', error);
  }
};