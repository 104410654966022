import styled from 'styled-components';
import { colors } from '../../globalStyles/theme';

export const StyledMenu = styled.menu`
  background: ${colors.white.main};
  height: auto;
  padding: 1.2em 0 0 0;
  margin: 0;
  border-right: 1px solid #e1e1e1;
  min-width: 16.4em;
  a {
    text-decoration: none;
    color: ${colors.grey.main};
  }
`;
export const StyledNav = styled.nav<{
  isSelected: boolean
}>`
  background: ${({ isSelected }) => isSelected ? `${colors.background.default}` : `${colors.white.main}`};
  color: rgba(0,0,0,0.87);
  padding: .77em 1em .77em 1em;
  font-size: 1.15em;
  font-weight: ${({ isSelected }) => isSelected ? '600' : '500'};
  &:hover {
    background: ${colors.background.default};
  }
`;