import React from 'react';
import { StyledInput } from './styles';

interface InputProps {
  onChange: Function;
  onKeyDown: Function;
  onFocus?: Function;
  onBlur?: Function;
  value?: string;
  placeholder?: string;
  style?: Object
  dataTestId?: string;
  dataFor?: string;
  type?: 'number' | 'text';
  min?: number;
  max?: number;
  disabled?: boolean;
}

// DUPLICATED IN INPUTGROUP
const Input = ({ 
  onChange, 
  onKeyDown, 
  onFocus = () => '', 
  onBlur = () => '',
  value, 
  placeholder, 
  style, 
  dataTestId, 
  dataFor, 
  type = 'text',
  min,
  max,
  disabled
}: InputProps) => {
  return (
    <StyledInput
      type={type}
      style={style}
      data-testid={dataTestId}
      placeholder={placeholder}
      data-tip
      data-for={dataFor}
      onChange={e => onChange(e)}
      onKeyDown={e => onKeyDown(e)}
      onFocus={(e) => onFocus(e)}
      onBlur={e => onBlur(e)}
      value={value}
      min={min}
      max={max}
      disabled={disabled}
    />
  );
};

export default Input;
