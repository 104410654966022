import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '../../../components/Table';
import Cards from '../../../components/Containers/Cards';
import Container from '../../../components/Containers/Container';
import Section from '../../../components/Containers/Section';
import InputGroup from '../../../components/Inputs/InputGroup';
import UsagePieChart from '../../../components/Charts/UsagePieChart';
import PieChartContainer from '../../../components/Charts/UsagePieChart/PieChartContainer';
import Typography from '../../../components/Typography';
import CustomNotification from '../../../components/Notification';
import InputGroupLoader from '../../../components/Loaders/InputGroupLoader';
import CardsLoader from '../../../components/Loaders/CardsLoader';
import TableLoader from '../../../components/Loaders/TableLoader';
import PieChartLoader from '../../../components/Loaders/PieChartLoader';
import DualGraphs from '../../../components/Charts/DualGraphs';

import { pieTableHeaders } from '../../../utils/tableHeaders';
import { HPC_USER_SBUS_USAGE } from '../../../utils/constants';
import { formatNumber } from '../../../utils/methods';
import { VariantObjectType } from '../../../api/types';
import { AccountOption, IHPCUser, GraphOption } from './types';
import { GlobalStateType, TGraphUsagePayload } from '../../../redux/reducers/types';
import {
  setAccountsOptions,
  selectAccountOption,
  setSBUUsages,
  setAccumUsages,
  setPieUsages,
  setTableUsages
} from '../../../redux/actions/index';

import {
  fetchAccumulationGraphData,
  fetchUsageGraphData,
  fetchUserAccounts,
  fetchUserPieChartData,
  handleGraphCurrentOption,
  handleGraphOptions
} from './utils';
import { ReducedDataType } from '../../../api/utils';

const HPCUser = (props: IHPCUser) => {
  // COMPONENT STATE
  // Accounts state
  const [isAccountDataLoading, setAccountDataLoading] = useState(true);

  // Graphs State
  const [twoColumnGraphs, setTwoColumnGraphs] = useState<boolean>(true);
  const [usageGraphVariants, setUsageGraphVariants] = useState<VariantObjectType[]>([]);
  const [accumulationGraphVariants, setAccumulationGraphVariants] = useState<VariantObjectType[]>([]);
  const [graphsOptions, setGraphsOptions] = useState<GraphOption[]>([]);
  const [graphCurrentOption, setGraphCurrentOption] = useState<GraphOption>({ value: '', label: '' });

  // Loading State
  const [isUsageGraphLoading, setUsageGraphLoading] = useState<boolean>(true);
  const [isAccumulationGraphLoading, setAccummulationGraphLoading] = useState<boolean>(true);
  const [isPieChartLoading, setPieChartLoading] = useState<boolean>(true);

  // SBU & Graph Conditions
  const accountSelected = props.selectedAccountOption.value.length;
  const sBUUsageExists = props.sbuUsages.find(u => u.accountId === props.selectedAccountOption.value);
  const accumUsageExists = props.accumUsages.find(u => u.accountId === props.selectedAccountOption.value);
  // Pie Chart + Table Conditions
  const hasGraphOptionValue = graphCurrentOption.value.length;
  const pieUsageExists = props.pieUsages.find(u => u.accountId === props.selectedAccountOption.value);
  const pieUsageExistsForGraphOptions = props.pieUsages.find(u => {
    return ((u.accountId === props.selectedAccountOption.value) && (u.variantId === graphCurrentOption.value) &&
      (u.type === props.displayUnit));
  });
  const sbuUsageExistsForOptions = (newOption: GraphOption) => props.sbuUsages.find(u => {
    return ((u.accountId === props.selectedAccountOption.value) &&
      (u.variantId === newOption.value) && (u.type === props.displayUnit));
  });

  const accumUsageExistsForOptions = (newOption: GraphOption) => props.accumUsages.find(u => {
    return ((u.accountId === props.selectedAccountOption.value) &&
      (u.variantId === newOption.value) && (u.type === props.displayUnit));
  });

  // ACCOUNTS USEEFFECT
  useEffect(() => {
    const USER_NAME: string = props.user;

    if (!props.accountsOptions.length) {
      fetchUserAccounts(
        USER_NAME,
        props.selectAccountOption,
        props.setAccountsOptions,
        setAccountDataLoading,
        setUsageGraphLoading,
        setAccummulationGraphLoading,
        setPieChartLoading
      );
    }
  }, []);

  useEffect(() => {
    if (graphCurrentOption.value 
        && !sbuUsageExistsForOptions(graphCurrentOption) 
        && !accumUsageExistsForOptions(graphCurrentOption)) {
      handleGraphCurrentOption(
        graphCurrentOption,
        setGraphCurrentOption,
        setUsageGraphLoading,
        setAccummulationGraphLoading,
        props.selectedAccountOption,
        props.setSBUUsages,
        props.setAccumUsages,
        props.displayUnit
      );
    }
  }, [props.displayUnit]);

  // USAGE GRAPH USEEFFECT (fetches sbu graph data on account dropdown change)
  useEffect(() => {
    if (accountSelected && !sBUUsageExists) {
      fetchUsageGraphData(
        isUsageGraphLoading,
        setUsageGraphLoading,
        props.selectedAccountOption,
        props.setSBUUsages,
        setUsageGraphVariants,
        props.displayUnit
      );
    }
  }, [props.selectedAccountOption]);

  // ACCUMULATION GRAPH USEEFFECT (fetches accum graph data on account dropdown change)
  useEffect(() => {

    if (accountSelected && !accumUsageExists) {

      fetchAccumulationGraphData(
        isAccumulationGraphLoading,
        setAccummulationGraphLoading,
        props.selectedAccountOption,
        props.setAccumUsages,
        setAccumulationGraphVariants,
        props.displayUnit
      );
    }
  }, [props.selectedAccountOption]);


  // CRAFT GRAPH OPTIONS USEFFECT
  useEffect(() => {
    if (usageGraphVariants.length && accumulationGraphVariants.length) {
      handleGraphOptions(
        usageGraphVariants,
        accumulationGraphVariants,
        setGraphsOptions,
        setGraphCurrentOption,
        graphCurrentOption
      );
    }

  }, [usageGraphVariants, accumulationGraphVariants]);


  // PIE CHART USEEFFFECT (fetches pie chart data on account dropdown AND graph option changes)
  useEffect(() => {
    if (
      hasGraphOptionValue
      && accountSelected
      && (!pieUsageExists || !pieUsageExistsForGraphOptions)) {
      fetchUserPieChartData(
        isPieChartLoading,
        setPieChartLoading,
        props.selectedAccountOption,
        graphCurrentOption,
        props.setPieUsages,
        props.setTableUsages,
        props.displayUnit
      );
    }
  }, [props.selectedAccountOption, props.displayUnit, graphCurrentOption]);


  // COMPONENT METHODS
  const handleAccountSelection = (option: AccountOption): void => props.selectAccountOption(option);

  const findUsageGraphData = (usages: TGraphUsagePayload[]): ReducedDataType[] | any | [] => {
    let found = usages.find(x => {
      return (
        x.variantId === graphCurrentOption.value)
        && (x.accountId === props.selectedAccountOption.value)
        && (x.type === props.displayUnit);
    });
    return found ? found.data : [];
  };

  const currencySymbol = props.displayUnit === 'Currency' ? props.selectedAccountOption.currency : 'SBU';
  const cardsData = {
    sbus_allocation: formatNumber(parseInt(props.displayUnit === 'SBU' ? props.selectedAccountOption.sbu_allocation : props.selectedAccountOption.currency_allocation)),
    sbus_usage: formatNumber(parseInt(props.displayUnit === 'SBU' ? props.selectedAccountOption.sbu_usage : props.selectedAccountOption.currency_usage)),
    sbus_remaining: formatNumber(props.displayUnit === 'SBU' ? props.selectedAccountOption.sbu_remaining : props.selectedAccountOption.currency_remaining),
    nb_members: props.selectedAccountOption.nb_users
  };

  return (
    <>
      <Container margin='0'>
        {/* TOP SECTION */}
        <Section margin='0 0 1.5em 0' flex>
          <div>
            <Typography as="h1" margin="0.5em 0 0 0">My accounts</Typography>
            <Typography as="h3" margin="0" fontSize={22}>Details for account</Typography>
            {
              props.selectedAccountOption.description && props.selectedAccountOption.description.length > 0 ? <Typography as='p' margin='0'>{props.selectedAccountOption.label} - {props.selectedAccountOption.description}</Typography> :
                <Typography as='p' margin='0'>{props.selectedAccountOption.label}</Typography>
            }
          </div>
          <div>
            {isAccountDataLoading ? <InputGroupLoader /> :
              <InputGroup
                options={props.accountsOptions}
                currentOption={props.selectedAccountOption}
                emailInputValue={props.selectedAccountOption.email_alert_percentage.toString()}
                selectedAccount={(option: AccountOption) => handleAccountSelection(option)}
              />}
          </div>
        </Section>
        {isAccountDataLoading ? <CardsLoader /> :
          <Cards
            allocationText={currencySymbol + ' allocation'}
            usageText={currencySymbol + ' usage'}
            remainingText={currencySymbol + ' remaining'}
            accountData={cardsData}
          />}
        <hr />

        {/* USAGE GRAPHS */}
        <Section margin="0 0 2em 0">
          <DualGraphs
            options={graphsOptions}
            handleGraphCurrentOption={(newOption: GraphOption) => {
              // fetches dual graphs data on graph option change 
              if (sbuUsageExistsForOptions(newOption) && accumUsageExistsForOptions(newOption)) {
                setGraphCurrentOption(newOption);
              }
              if (!sbuUsageExistsForOptions(newOption) && !accumUsageExistsForOptions(newOption)) {
                handleGraphCurrentOption(
                  newOption,
                  setGraphCurrentOption,
                  setUsageGraphLoading,
                  setAccummulationGraphLoading,
                  props.selectedAccountOption,
                  props.setSBUUsages,
                  props.setAccumUsages,
                  props.displayUnit
                );
              }
            }}
            graphCurrentOption={graphCurrentOption}
            setTwoColumnGraphs={() => setTwoColumnGraphs(!twoColumnGraphs)}
            twoColumnGraphs={twoColumnGraphs}
            isUsageGraphLoading={isUsageGraphLoading}
            usageGraphData={findUsageGraphData(props.sbuUsages)}
            isAccumulationGraphLoading={isAccumulationGraphLoading}
            accumulationGraphData={findUsageGraphData(props.accumUsages)}
            sbuThreshold={parseInt(props.selectedAccountOption.sbu_allocation)}
            inAccountView
            displayUnit={currencySymbol}
          />
        </Section>

        <hr />

        {/* PIE CHART */}
        {graphCurrentOption && graphCurrentOption.label ? (
          <Typography as="h2" margin="2em 0 2em 0">Usage per user for current year</Typography>
        ) : null}
        <Section flex pieChart margin="0 0 5em 0">
          <div style={{ flex: 1 }}>
            {isPieChartLoading ? <TableLoader /> : !findUsageGraphData(props.tableUsages).length ? (
              <div style={{ textAlign: 'center', padding: '10em 0 10em 0' }}>
                No table data to display
              </div>
            ) : (
              <Table
                headers={pieTableHeaders(currencySymbol)}
                rowsData={findUsageGraphData(props.tableUsages)}
                tableVariant={HPC_USER_SBUS_USAGE}
                transferSearchTerm={() => null}
                alignCentreName
                hideSearch
              />
            )}
          </div>

          <PieChartContainer>
            {isPieChartLoading ? <PieChartLoader /> : !findUsageGraphData(props.pieUsages).length ? (
              <div style={{ textAlign: 'center', padding: '10em 0 10em 0' }}>
                No pie chart data to display
              </div>
            ) : (
              <UsagePieChart data={findUsageGraphData(props.pieUsages)} displayUnit={currencySymbol} />
            )}
          </PieChartContainer>
        </Section>
        {/* TOOLTIP AND TOASTER NOFICATIONS */}
        <CustomNotification />
      </Container>
    </>
  );
};


const mapStateToProps = (state: GlobalStateType): {
  user: string;
  accountsOptions: AccountOption[];
  selectedAccountOption: AccountOption;
  sbuUsages: TGraphUsagePayload[];
  accumUsages: TGraphUsagePayload[];
  pieUsages: TGraphUsagePayload[];
  tableUsages: TGraphUsagePayload[];
  displayUnit: string;
} => {
  return {
    user: state.user,
    accountsOptions: state.userAccounts.accountsOptions,
    selectedAccountOption: state.userAccounts.selectedAccountOption,
    sbuUsages: state.userAccounts.sbuUsages,
    accumUsages: state.userAccounts.accumUsages,
    pieUsages: state.userAccounts.pieUsages,
    tableUsages: state.userAccounts.tableUsages,
    displayUnit: state.displayUnit
  };
};

export default connect(
  mapStateToProps, {
    setAccountsOptions,
    selectAccountOption,
    setSBUUsages,
    setAccumUsages,
    setPieUsages,
    setTableUsages
  }
)(HPCUser);
