import toast from 'react-hot-toast';

import { fetchGraphStatsForTimePeriod, fetchNodesStatsForTimePeriod, searchEmailAlertFromId, searchTableUsageFromId } from '../../../../api/fetch-nodes';
import { BatchTimePeriodUsageResponse, GeneralDataNodeType, GraphVariantObjType, NodeUsageType, TimePeriodUsageType, VariantObjectType } from '../../../../api/types';
import { newMergeMonthsQuartersYearsData } from '../../../../api/utils';
import { CachedNodeType } from '../../../../redux/reducers/types';
import { arrayToObject, formatNumber, getUniqueListBy } from '../../../../utils/methods';
import { EmailAlertNodeType, GraphOption, NodesHPCUsageOverviewType, SearchOption } from './types';

export const DEFAULT_NODESHPC_DATA = {
  totalAllocation: '-',
  totalUsage: '-',
  totalUndistributed: '-',
  nbNodes: 0
};

export const fetchGeneralNodesData = async (
  setGeneralNodesData: Function,
  setCardsLoading: Function,
  initialNodes: GeneralDataNodeType[]
): Promise<void> => {
  try {
 
    if (initialNodes?.length) {
      // calculate total SBU allocation across those fetched nodes
      const totalAllocation: number = initialNodes.reduce((a, { sbu_allocation }) => {
        return a + parseInt(sbu_allocation);
      }, 0);
      // calculate total SBU usage across them
      const totalUsage: number = initialNodes.reduce(
        (a, { sbu_usage }) => a + parseInt(sbu_usage), 0);
      // calculate total undistributed
      const totalUndistributed: number = initialNodes.reduce(
        (a, { sbu_undistributed }) => a + parseInt(sbu_undistributed), 0);
      // calculale number of nodes 
      const nbNodes: number = initialNodes.length;

      const generalDataFetchedNodes: NodesHPCUsageOverviewType = {
        totalAllocation: formatNumber(totalAllocation),
        totalUsage: formatNumber(totalUsage),
        totalUndistributed: formatNumber(totalUndistributed),
        nbNodes
      };

      setGeneralNodesData(generalDataFetchedNodes);
      setCardsLoading(false);
    } else {
      setCardsLoading(false);
      console.error('No generalDataNodes');
    }

  } catch (error) {
    console.error('error fetching gneral nodes data: ', error);
  }
};

export const fetchUsageGraphData = async (
  isUsageGraphLoading: boolean,
  setUsageGraphLoading: Function,
  graphCurrentOption: GraphOption,
  updateNode: Function,
  setUsageGraphVariants: Function,
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType,
  setHasFetchedSBUGraph: Function
): Promise<void> => {
  const TIME_PERIOD = 'usage-per-day';
  const DEFAULT_VARIANT = 'current-year';

  if (!isUsageGraphLoading) setUsageGraphLoading(true);

  let selectedOption = graphCurrentOption.value;

  const graphStatsForTimePeriod = 
    await fetchGraphStatsForTimePeriod(TIME_PERIOD, initialNodes, selectedOption ? selectedOption : DEFAULT_VARIANT);

  if (graphStatsForTimePeriod?.data.length) {
    const reformatted = graphStatsForTimePeriod.data.map(nwud => {
      return {
        [nwud.data[0].id]: {
          ...nodes[nwud.data[0].id],
          graphsUsage: {
            ...nodes[nwud.data[0].id].graphsUsage,
            sbuUsage: {
              ...nodes[nwud.data[0].id].graphsUsage.sbuUsage,
              [nwud.variant.id]: nwud.data[0]
            }
          }
        }
      };
    });

    const cacheFormattedNodes = arrayToObject(reformatted);

    setHasFetchedSBUGraph(true);
    updateNode(cacheFormattedNodes);
    setUsageGraphVariants(graphStatsForTimePeriod?.data[0].variants);
    setUsageGraphLoading(false);

  } else {
    // no fetchedNodes
    toast.error('No nodes found for SBU usage graph');
    setHasFetchedSBUGraph(true);
  }
};

export const fetchAccumulationGraphData = async (
  isAccumulationGraphLoading: boolean,
  setAccumulationGraphLoading: Function,
  updateNode: Function,
  setAccumulationGraphVariants: Function,
  graphCurrentOption: GraphOption,
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType,
  setHasFetchedAccumGraph: Function
): Promise<void> => {
  const TIME_PERIOD = 'total-node-usage';
  if (!isAccumulationGraphLoading) setAccumulationGraphLoading(true);

  const DEFAULT_VARIANT = 'current-year';
  let selectedOption = graphCurrentOption.value;


  const graphStatsForTimePeriod = 
  await fetchGraphStatsForTimePeriod(TIME_PERIOD, initialNodes, selectedOption ? selectedOption : DEFAULT_VARIANT);

  if (graphStatsForTimePeriod?.data.length) {

    const reformatted = graphStatsForTimePeriod.data.map(nwud => {
      return {
        [nwud.data[0].id]: {
          ...nodes[nwud.data[0].id],
          graphsUsage: {
            ...nodes[nwud.data[0].id].graphsUsage,
            accumulationUsage: {
              ...nodes[nwud.data[0].id].graphsUsage.accumulationUsage,
              [nwud.variant.id]: nwud.data[0]
            }
          }
        }
      };
    });

    const cacheFormattedNodes = arrayToObject(reformatted);

    setHasFetchedAccumGraph(true);
    updateNode(cacheFormattedNodes);
    setAccumulationGraphVariants(graphStatsForTimePeriod?.data[0].variants);
    setAccumulationGraphLoading(false);
  } else {
    // no fetchedNodes
    toast.error('No nodes found for SBU usage graph');
    setHasFetchedAccumGraph(true);
  }
};

export const handleGraphOptions = (
  usageGraphVariants: VariantObjectType[],
  accumulationGraphVariants: VariantObjectType[],
  setGraphsOptions: Function,
  setGraphCurrentOption: Function
): void => {
  const combined: VariantObjectType[] = [...usageGraphVariants, ...accumulationGraphVariants];
  const noDuplicates: GraphVariantObjType[] = getUniqueListBy(combined, 'id');

  const craftedGraphsOptions = noDuplicates.map(
    (variant: GraphVariantObjType) => ({ label: variant.name, value: variant.id }));

  setGraphsOptions(craftedGraphsOptions);
  // sets BOTH GRAPHS DEFAULT OPTION to first item in array
  setGraphCurrentOption(craftedGraphsOptions[0]);
};

export const transformMergedNodeArray = (
  mergedNodesData: NodeUsageType[], 
  nodes: CachedNodeType,
  nodesWithUsageData: TimePeriodUsageType[]
) => {
  return mergedNodesData.map((mergedNode, i) => {
    return {
      [mergedNode.org_id]: {
        ...nodes[mergedNode.org_id],
        tableUsage: {
          ...nodes[mergedNode.org_id].tableUsage,
          [nodesWithUsageData[0].id]: mergedNodesData[i]
        }
      }
    };
  });
};

export const fetchMonthTableNodes = async (
  updateNode: Function,
  setMonthsTableHeaders: Function,
  setMonthsTableLoading: Function,
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType,
  setHasFetchedMonths: Function
): Promise<void> => {
  const TIME_PERIOD = 'usage-per-month';

  let nodeStatsForTimePeriod: BatchTimePeriodUsageResponse | undefined = 
    await fetchNodesStatsForTimePeriod(TIME_PERIOD, initialNodes);
 
  if (initialNodes.length && nodeStatsForTimePeriod?.data.length) {
    const { mergedNodesData, timePeriodUsageHeaders } : 
    { mergedNodesData: NodeUsageType[]; timePeriodUsageHeaders: string[] } = 
     newMergeMonthsQuartersYearsData(initialNodes, nodeStatsForTimePeriod.data, TIME_PERIOD);

    const fullTableHeaders: string[] = ['Node', 'Allocation', ...timePeriodUsageHeaders];

    const transformed = transformMergedNodeArray(mergedNodesData, nodes, nodeStatsForTimePeriod.data);
    const cacheFormattedNodes = arrayToObject(transformed);

    setMonthsTableHeaders(fullTableHeaders);
    setHasFetchedMonths(true);
    updateNode(cacheFormattedNodes);
    setMonthsTableLoading(false);
  } else {
    // no fetchedNodes
    setMonthsTableLoading(false);
    setHasFetchedMonths(true);
    toast.error('No nodes found for usage per month table');
  }
};

export const fetchQuarterTableNodes = async (
  updateNode: Function,
  setQuartersTableHeaders: Function,
  setQuartersTableLoading: Function,
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType,
  setHasFetchedQuarters: Function) => {
  const TIME_PERIOD = 'usage-per-quarter';

  let nodeStatsForTimePeriod: BatchTimePeriodUsageResponse | undefined = 
  await fetchNodesStatsForTimePeriod(TIME_PERIOD, initialNodes);

  if (initialNodes.length && nodeStatsForTimePeriod?.data.length) {
    const { 
      mergedNodesData, timePeriodUsageHeaders
    } = newMergeMonthsQuartersYearsData(initialNodes, nodeStatsForTimePeriod.data, TIME_PERIOD);
    const fullTableHeaders = ['Node', 'Allocation', ...timePeriodUsageHeaders];

    const transformed = transformMergedNodeArray(mergedNodesData, nodes, nodeStatsForTimePeriod.data);
    const cacheFormattedNodes = arrayToObject(transformed);

    setQuartersTableHeaders(fullTableHeaders);
    setHasFetchedQuarters(true);
    updateNode(cacheFormattedNodes);
    setQuartersTableLoading(false);
  } else {
    // no fetchedNodes
    setQuartersTableLoading(false);
    setHasFetchedQuarters(true);
    toast.error('No nodes found for usage per quarter table');
  }
};

export const fetchYearTableNodes = async (
  updateNode: Function,
  setYearsTableHeaders: Function,
  setYearsTableLoading: Function,
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType,
  setHasFetchedYears: Function
):Promise<void> => {
  const TIME_PERIOD = 'usage-per-year';

  let nodeStatsForTimePeriod: BatchTimePeriodUsageResponse | undefined = 
  await fetchNodesStatsForTimePeriod(TIME_PERIOD, initialNodes);

  if (initialNodes.length && nodeStatsForTimePeriod?.data.length) {
    const { 
      mergedNodesData, timePeriodUsageHeaders
    } = newMergeMonthsQuartersYearsData(initialNodes, nodeStatsForTimePeriod.data, TIME_PERIOD);
    const fullTableHeaders = ['Node', ...timePeriodUsageHeaders];

    const transformed = transformMergedNodeArray(mergedNodesData, nodes, nodeStatsForTimePeriod.data);
    const cacheFormattedNodes = arrayToObject(transformed);

    setYearsTableHeaders(fullTableHeaders);
    setHasFetchedYears(true);
    updateNode(cacheFormattedNodes);
    setYearsTableLoading(false);
  } else {
    // no fetchedNodes
    setYearsTableLoading(false);
    setHasFetchedYears(true);
    toast.error('No nodes found for usage per year table');
  }
};

export const handleMonthsTableSearch = async (
  option: SearchOption,
  setMonthsTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;
  
  setMonthsTableSearchResult([]);
  
  try {
    const searchResult: NodeUsageType | undefined = await searchTableUsageFromId(option.id, 'usage-per-month');
    if (searchResult) {
      setMonthsTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching months table result. Please try again.');
    console.error('error at Nodes Usage months table search: ', error);
  }
};

export const handleQuartersTableSearch = async (
  option: SearchOption,
  setQuartersTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;
    
  setQuartersTableSearchResult([]);
    
  try {
    const searchResult : NodeUsageType | undefined = await searchTableUsageFromId(option.id, 'usage-per-quarter');
    if (searchResult) {
      setQuartersTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching quarters table result. Please try again.');
    console.error('error at Nodes Usage quarters table search: ', error);
  }
};

export const handleYearsTableSearch = async (
  option: SearchOption,
  setYearsTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;
      
  setYearsTableSearchResult([]);
      
  try {
    const searchResult: NodeUsageType | undefined = await searchTableUsageFromId(option.id, 'usage-per-year');
    if (searchResult) {
      setYearsTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching years table result. Please try again.');
    console.error('error at Nodes Usage years table search: ', error);
  }
};


export const handleEmailAlertTableSearch = async (
  option: SearchOption,
  setEmailAlertTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;

  setEmailAlertTableSearchResult([]);

  try {
    const searchResult: EmailAlertNodeType | undefined = await searchEmailAlertFromId(option.id);
    if (searchResult) {
      setEmailAlertTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching result. Please try again.');
    console.error('error at manage nodes and access search: ', error);
  }
};