import React from 'react';
import { StyledSection } from './styles';

const Section = ({
  children,
  margin,
  width,
  flex,
  style,
  pieChart
}: {
  children: React.ReactNode;
  margin?: string;
  width?: string;
  flex?: boolean;
  style?: Object;
  pieChart?: boolean;
}) => {
  return (
    <StyledSection
      id='section'
      margin={margin}
      style={style}
      width={width}
      flex={flex}
      pieChart={pieChart}
    >
      {children}
    </StyledSection>
  );
};

export default Section;
