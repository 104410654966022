import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../Button';
import CloseIcon from '../../../Icons/Close/CloseIcon';
import Typography from '../../../Typography';
import * as S from '../../styles';
import toast from 'react-hot-toast';
import { editNodeDescription } from '../../../../api/confirmations';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { updateNode } from '../../../../redux/actions';
import { arrayToObject } from '../../../../utils/methods';

interface ConfirmProps {
  onRequestClose: Function;
  newDescription: string;
  oldDescription: string;
  closeParentModal: Function;
  nodeId: string;
  nodes: CachedNodeType;
  updateNode: Function;
}

const ConfirmDescription = ({ 
  onRequestClose, 
  newDescription, 
  oldDescription, 
  nodeId,
  closeParentModal,
  nodes,
  updateNode
} : ConfirmProps) => {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleClick = async () => {
    setButtonLoading(!isButtonLoading);
    setButtonDisabled(true);

    try {
      const res = await editNodeDescription(nodeId, newDescription);
      if (res?.data) {
        setButtonLoading(false);
        toast.success(`Description of ${nodeId} successfully changed to ${newDescription}. Now redirecting you back.`); 
        setTimeout(() => {

          const withUpdatedDescription = res.data.map(updated => {
            if (nodes[updated.id]) {
              return {
                [updated.id]: {
                  ...nodes[updated.id],
                  description: updated.description
                }
              };
            } else {
              return {
                [updated.id]: {
                  ...updated,
                  tableUsage: {},
                  graphsUsage: { sbuUsage: {}, accumulationUsage: {} },
                  description: updated.description
                }
              };
            }
          });

          const cacheFormatted = arrayToObject(withUpdatedDescription);
          
          updateNode(cacheFormatted);
          closeParentModal();
        }, 4000);
      } else {
        setButtonLoading(false);
        setButtonDisabled(false);
        toast.error('Failed to edit node description. Please try again or contact your administrator.'); 
      }

    } catch (error) {
      console.error('Error while editing node description: ', error);
      toast.error('Failed to edit node description. Please try again or contact your administrator.'); 
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <S.ModalContainer data-testid="edit-node-name" style={{ fontSize: 'small' }}>
        <S.ModalFlexSection>
          <Typography as="h2" margin="0">New description confirmation</Typography>
          <CloseIcon onClick={() => isButtonDisabled ? null : onRequestClose()} isDisabled={isButtonDisabled}/>
        </S.ModalFlexSection>
        <hr/>
        <S.ModalSection>

          <h2>Summary</h2>
          <p><b>Node ID: </b>{nodeId}</p>
          <p><b>Current description: </b>{oldDescription}</p>
          <p><b>New description: </b>{newDescription}</p>
        </S.ModalSection>
        <hr />
        <S.ButtonsContainer style={{ fontSize: 'small' }}>
          <Button content="Cancel" variant={'gray'} onClick={() => onRequestClose()} disabled={isButtonDisabled}/> 
          <Button 
            content="Update description" 
            onClick={() => handleClick()} 
            isLoading={isButtonLoading}
            disabled={isButtonDisabled}
          /> 
        </S.ButtonsContainer>
      </S.ModalContainer>
    </>
  );
};



const mapStateToProps = (state: GlobalStateType): {
  nodes: CachedNodeType
} => {
  return {
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(ConfirmDescription);
