import Input from '../Input';
import SearchIcon from './SearchIcon';

interface SearchProps {
  userType: string;
  onChange: Function;
  onKeyDown: Function;
  value: string;
  dataFor?: string;
  isChangeLog?: boolean
  disabled?: boolean;
}

const Search = ({ userType, onChange, onKeyDown, value, dataFor, isChangeLog, disabled }: SearchProps) => {
  return (
    <span style={{
      position: 'relative'
    }}>
      <Input 
        disabled={disabled}
        placeholder={isChangeLog ? userType : `Search ${userType}`}
        value={value}
        dataFor={dataFor}
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => onKeyDown(e)}
        style={{
          height: '1.9em',
          // width: 'auto',
          paddingRight: '3em'
        }}
      />
      <span style={{
        position: 'absolute',
        right: '11px',
        bottom: '-8px'
      }}>
        <SearchIcon/>
      </span>
    </span>
  );
};

export default Search;