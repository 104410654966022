import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import SelectInput from '../Select';
import Button from '../../Button';
import { InputsContainer, StyledGraphInputGroup } from './styles';
import useWindowDimensions from '../../../utils/useWindowDimensions';

type Option = {
  value: string;
  label: string;
}

interface GraphInputGroupProps {
  options: Option[];
  selectedOption: Function;
  currentOption: Option;
  setGraphLayout?: Function;
  hideInput?: boolean;
}

const GraphInputGroup = ({ 
  options, 
  selectedOption, 
  currentOption, 
  setGraphLayout, 
  hideInput
}: GraphInputGroupProps) => {
  const [isDisabled, disableButton] = useState<boolean>(false);
  // USEEFFECT
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width <= 1000) {
      disableButton(true);
    } else {
      disableButton(false);
    }
  
  }, [width]);

  return (
    <>
      <StyledGraphInputGroup data-testid="graph-input-group">
        <InputsContainer> 
          {setGraphLayout && (
            <Button 
              content='Toggle view' 
              onClick={() => setGraphLayout()}
              variant="transfer"
              dataFor="toggle-graph-layout"
              style={{ 
                padding: '0 0.5em 0 0.5em', 
                width: '7.5em', 
                margin: '0 0.5em 0 0', 
                fontSize: 'small' }}
              disabled={isDisabled}
            />
          )}
          {!hideInput && (
            <SelectInput 
              options={options} 
              currentOption={currentOption}
              toParent={(option: Option) => selectedOption(option)}
            />
          )}
        </InputsContainer>
      </StyledGraphInputGroup>
      <ReactTooltip id='toggle-graph-layout' type='dark'>
        <span>Toggle compact or expanded graph layouts</span>
      </ReactTooltip>
    </>
  );
};

export default GraphInputGroup;