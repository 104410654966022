import styled from 'styled-components';
import { breakpoints, colors } from '../../../globalStyles/theme';

export const StyledInput = styled.input`
  padding: 0.2em 1em 0.2em 1em;
  width: 18em;
  height: 2.5em;

  border-radius: 0.25em;
  font-size: 1em;
  border: 1px solid ${colors.grey.quintary};

  @media screen and (min-width: ${breakpoints.mobiles.small}) and (max-width: ${breakpoints.custom.size20}) {
   font-size: small;
  }
`;