import styled from 'styled-components';
import { colors } from '../../globalStyles/theme';
import { ACCOUNT_DETAIL_VIEW_MONTHS, ACCOUNT_DETAIL_VIEW_QUARTERS, ACCOUNT_DETAIL_VIEW_YEARS, COMPREP_MANAGE_ALLOCATIONS, ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS, NODE_DETAIL_VIEW_MONTHS, NODE_DETAIL_VIEW_QUARTERS, NODE_DETAIL_VIEW_YEARS } from '../../utils/constants';

export const ScrollableWrapper = styled.div<{
  isExpanded: boolean
}>`
  overflow: auto;
  max-width: 100%;
  ${({ isExpanded }) => isExpanded ? `
  max-height: 30em;
  overflow-y: scroll;
  ` : ''};
  background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  border-radius: 0.25em;
  box-shadow: 0 1px 10px rgb(0 0 0 / 12%);
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: center;

  td,
  th {
    border-bottom: 1px solid ${colors.grey.tertiary};
  }

  th {
    padding: 0.75em  0.93em;
  }

  th:first-of-type {
    text-align: center;
  }

  td {
    padding: 0.75em  0.625em;
    width: 1%;
    white-space: nowrap;
    border-left: 1px solid ${colors.grey.tertiary};
  }
`;

export const TableHead = styled.thead`
  background: ${colors.primary.main};
  color: ${colors.white.main};

  th {
    min-width: 7em;
    font-weight: normal;
  }

  th:last-of-type {
    border-right: 1px solid ${colors.primary.main};
  }
`;

const inDetailView = (tableVariant: string) => {
  return (tableVariant === NODE_DETAIL_VIEW_MONTHS ||
    tableVariant === NODE_DETAIL_VIEW_QUARTERS ||
    tableVariant === NODE_DETAIL_VIEW_YEARS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_MONTHS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_QUARTERS ||
    tableVariant === ACCOUNT_DETAIL_VIEW_YEARS) ? true : false;
};

export const TableWrapper = styled.div<{
  searchResultLoading: boolean;
  isExpanded: boolean;
  tableVariant: string;
}>`
cursor: ${({ searchResultLoading }) => searchResultLoading ? 'wait' : 'auto'}

${({ isExpanded, tableVariant }) => isExpanded ? `
overflow-y: scroll;
background: ${colors.grey.main};
z-index: ${(tableVariant === COMPREP_MANAGE_ALLOCATIONS || 
  tableVariant === ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS) ? '0' : '9997'};
width: ${inDetailView(tableVariant) ? 'auto' : '95vw'};
position: fixed;
padding: 2.5em 2em 2.5em 2em;
box-shadow: 10px 10px 100px 110vh rgb(0 0 0 / 70%);
margin-left: auto;
margin-right: auto;
top: 50%;
margin-top: -18em;
left: 0;
right: 0;
text-align: center;
` : ''}
`;

