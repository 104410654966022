import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '../../../../components/Table';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';

import { ECMWF_ADMIN_MANAGE_OVERALLOCATIONS } from '../../../../utils/constants';
import { MANAGE_OVERALLOCATION_HEADERS } from '../../../../utils/tableHeaders';
import TableLoader from '../../../../components/Loaders/TableLoader';
import CustomNotification from '../../../../components/Notification';
import { GeneralDataNodeType, ManageOverallocationNodeType } from '../../../../api/types';
import { TableOption } from '../ManageNodesAndAccess/types';
import { fetchOverallocationNodes, handleTableSearch } from './utils';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { formatNumber, isEmpty } from '../../../../utils/methods';

interface IManageOverAllocations {
  initialNodes: GeneralDataNodeType[]
  nodes: CachedNodeType;
}

const ManageOverallocations = (props: IManageOverAllocations) => {
  const [tableData, setTableData] = useState<ManageOverallocationNodeType[]>([]);
  const [tableSearchResult, setTableSearchResult] = useState<ManageOverallocationNodeType[]>([]);
  const [tableIsLoading, setTableLoading] = useState<boolean>(true);
    
  // USEEFFECT
  useEffect(() => {
    if (props.initialNodes && !isEmpty(props.nodes)){
      fetchOverallocationNodes(
        setTableData, 
        setTableLoading, 
        props.initialNodes
      );
    }
  }, [props.initialNodes, props.nodes]);


  useEffect(() => {
    // craft and feed initialnodes to table via setTableData
    if (props.initialNodes.length && !isEmpty(props.nodes)) {
      const tableFormatted = props.initialNodes.map(initNode => {
        return {
          name: props.nodes[initNode.id].name,
          description: props.nodes[initNode.id].description,
          category: props.nodes[initNode.id].type,
          org_id: props.nodes[initNode.id].id,
          allocation: formatNumber(parseInt(props.nodes[initNode.id].sbu_allocation)),
          sbu_usage: formatNumber(parseInt(props.nodes[initNode.id].sbu_usage)),
          children: props.nodes[initNode.id].children,
          sbu_overallocation: formatNumber(parseInt(props.nodes[initNode.id].sbu_overallocation)),
          sbu_overallocation_percentage: props.nodes[initNode.id].sbu_overallocation_percentage
        };
      });
      setTableData(tableFormatted);
    }
  }, [props.nodes]);


  return (
    <>
      <Container margin='0'>
        {/* TOP SECTION */}
        <Section margin='0 0 1.5em 0' flex>
          <div>
            <Typography as="h1" margin="0.5em 0 0 0">Manage overallocations</Typography>
            <Typography as="h3" margin="0" fontSize={22}>Manage overallocations for nodes & accounts</Typography>
          </div>
        </Section>
        <hr />
        {/* OVERALLCOATION TABLE */}
        <Section margin="2em 0 25em 0">
          {tableIsLoading ? <TableLoader /> : (
            <Table
              showExpandButton
              headers={MANAGE_OVERALLOCATION_HEADERS}
              rowsData={tableData}
              tableVariant={ECMWF_ADMIN_MANAGE_OVERALLOCATIONS}
              transferSearchTerm={(option: TableOption) => handleTableSearch(option, setTableSearchResult)}
              searchTermResult={tableSearchResult}
            />
          )}
        </Section>
        <CustomNotification />
      </Container>
    </>
  );
};


const mapStateToProps = (state: GlobalStateType): {
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType
} => {
  return {
    initialNodes: state.initialNodes,
    nodes: state.nodes
  };
};
export default connect(mapStateToProps, null)(ManageOverallocations);



