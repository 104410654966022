import toast from 'react-hot-toast';
import { fetchNodesWithAccess, searchForNodeOrAccountWithAccess } from '../../../../api/fetch-nodes';
import { GeneralDataNodeType, ManageNodesAccessNodeType, WithAccessUsersType } from '../../../../api/types';
import { CachedNodeType } from '../../../../redux/reducers/types';
import { COMPREP_MANAGE_READ_ACCESS, ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS } from '../../../../utils/constants';
import { arrayToObject } from '../../../../utils/methods';
import { TableOption } from './types';


export const fetchTableNodesWithAccess = async (
  isTableLoading: boolean,
  setTableLoading: Function,
  initialNodes: GeneralDataNodeType[],
  updateNode: Function,
  nodes: CachedNodeType
): Promise<void> => {
  if (!isTableLoading) setTableLoading(true);

  try {
    const nodesWithUserAccess: WithAccessUsersType[] | undefined = await fetchNodesWithAccess(initialNodes, nodes);
    if (nodesWithUserAccess && nodesWithUserAccess.length) {
      const transformed = nodesWithUserAccess.map(node => {
        return {
          [node.id]: {
            ...node
          }
        };
      });
      const toObjetFormat = arrayToObject(transformed);
      updateNode(toObjetFormat);
      setTableLoading(false);
    } else {
      setTableLoading(false);
      toast.error('Error fetching table data. Please try again.');
    }

  } catch (error) {
    console.error('Error fetching nodes with read and admin acccess: ', error);
    // toast 
    toast.error('Error fetching table data. Please try again.');
    // set loading false
    setTableLoading(false);
    // no data to display
  }
};

export const handleTableSearch = async (
  option: TableOption,
  setTableSearchResult: Function
): Promise<void> => {
  // for cleared input, option will be null
  if (!option) return;

  setTableSearchResult([]);

  try {
    const searchResult : ManageNodesAccessNodeType | undefined = await searchForNodeOrAccountWithAccess(option.id);
    if (searchResult) {
      setTableSearchResult([searchResult]);
    } else {
      console.error('no search result');
      toast.error('No search result found');
    }
  } catch (error) {
    toast.error('Error while fetching result. Please try again.');
    console.error('error at manage nodes and access search: ', error);
  }
};

export const returnHeaders = (includesAdmin: boolean): string[] => {
  const comprepHeaders = [
    'Node',
    'Description',
    'Read-only access users'
  ];

  const adminHeaders = [
    'Node',
    'Description',
    'Read-only access users',
    'Admin access users'
  ];

  if (includesAdmin) {
    return adminHeaders;
  }

  return comprepHeaders;
};

export const returnTableVariant = (includesAdmin: boolean): string => {
  if (includesAdmin) {
    return ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS;
  }

  return COMPREP_MANAGE_READ_ACCESS;
};
