import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Table from '../../../../components/Table';
import Cards from '../../../../components/Containers/Cards';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import Typography from '../../../../components/Typography';
import AllocationsBarChart from '../../../../components/Charts/BarChart';
import CardsLoader from '../../../../components/Loaders/CardsLoader';
import DualGraphs from '../../../../components/Charts/DualGraphs';
import CustomNotification from '../../../../components/Notification';
import CloseIcon from '../../../../components/Icons/Close/CloseIcon';
import TableLoader from '../../../../components/Loaders/TableLoader';

import {
  NODE_DETAIL_VIEW_MONTHS,
  NODE_DETAIL_VIEW_QUARTERS,
  NODE_DETAIL_VIEW_YEARS
} from '../../../../utils/constants';

import { craftMonthsQuartersYearsData, extractGraphFormatData, ReducedDataType } from '../../../../api/utils';
import { formatNumber } from '../../../../utils/methods';

import { NodeUsageType } from '../../../../api/types';
import { GraphOption, INodeDetails, NodeDetailsCardsType } from './types';
import {
  checkForChildren,
  DEFAULT_CARDSDATA,
  fetchGraphsData,
  fetchMonthTableChildren,
  fetchNodeData,
  fetchQuartersTableChildren,
  fetchYearsTableChildren,
  handleMonthsTableSearch,
  handleQuartersTableSearch,
  handleYearsTableSearch
} from './utils';
import { SearchOption } from '../../CompRep/NodesHPCUsage/types';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { updateNode } from '../../../../redux/actions';
import Button from '../../../../components/Button';
import config from '../../../../config';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

const NodeDetails = ({ onRequestClose, memberName, nodeChildren, nodeId, nodes, updateNode }: INodeDetails) => {
  // COMPONENT STATE
  const [twoColumnGraphs, setTwoColumnGraphs] = useState<boolean>(true);
  const [graphCurrentOption, setGraphCurrentOption] = useState<GraphOption>({ value: 'current-year', label: 'Current Year' });
  const [graphsOptions, setGraphsOptions] = useState<GraphOption[]>([]);

  const [usageData, setUsageData] = useState<ReducedDataType[]>([]);
  const [accumulationData, setAccumulationData] = useState<ReducedDataType[]>([]);
  const [isUsageGraphLoading, setUsageGraphLoading] = useState<boolean>(true);
  const [isAccumulationGraphLoading, setAccumulationGraphLoading] = useState<boolean>(true);
  const [hasFetchedGraphs, setHasFetchGraphs] = useState(false);

  const [monthsTableData, setMonthsTableData] = useState<NodeUsageType[]>([]);
  const [monthsTableHeaders, setMonthsTableHeaders] = useState<string[]>([]);
  const [monthsTableSearchResult, setMonthsTableSearchResult] = useState<NodeUsageType[]>([]);
  const [monthsTableIsLoading, setMonthsTableLoading] = useState<boolean>(true);


  const [quartersTableData, setQuartersTableData] = useState<NodeUsageType[]>([]);
  const [quartersTableHeaders, setQuartersTableHeaders] = useState<string[]>([]);
  const [quartersTableSearchResult, setQuartersTableSearchResult] = useState<NodeUsageType[]>([]);
  const [quartersTableIsLoading, setQuartersTableLoading] = useState<boolean>(true);

  const [yearsTableData, setYearsTableData] = useState<NodeUsageType[]>([]);
  const [yearsTableHeaders, setYearsTableHeaders] = useState<string[]>([]);
  const [yearsTableSearchResult, setYearsTableSearchResult] = useState<NodeUsageType[]>([]);
  const [yearsTableIsLoading, setYearsTableLoading] = useState<boolean>(true);

  const [cardsLoading, setCardsLoading] = useState(true);
  const [cardsData, setCardsData] = useState<NodeDetailsCardsType>(DEFAULT_CARDSDATA);

  const [nodeDescription, setNodeDescription] = useState<string>('');

  const location = useLocation();
  const [isDirect, setIsDirect] = useState<boolean>(false);
  const directURL = `${window.location.origin}${config.mountPath}node-details/${nodeId}`;
  const [nodeName, setNodeName] = useState<string>('');
  // check if loaded directly
  useEffect(() => {
    if (location.pathname.includes('node-details')) {
      setIsDirect(true);
    }
  }, []);

  // FETCH NODE CARDS DATA
  useEffect(() => {
    if (cardsLoading) {
      if (nodes[nodeId]) {
        setCardsData({
          sbus_allocation: nodes[nodeId].sbu_allocation,
          sbus_usage: nodes[nodeId].sbu_usage,
          sbus_remaining: nodes[nodeId].sbu_undistributed,
          sbus_allocated: nodes[nodeId].sbu_distributed,
          nb_members: nodes[nodeId].children.length,
          sbus_allocated_percentage: nodes[nodeId].sbu_distributed_percentage,
          sbus_remaining_percentage: nodes[nodeId].sbu_undistributed_percentage
        });
        setCardsLoading(false);
        setNodeDescription(nodes[nodeId].description);
        setNodeName(nodes[nodeId].name);
      } else {
        fetchNodeData(nodeId, setCardsLoading, updateNode);
      }
    }

  }, [nodes]);

  useEffect(() => {
    if (nodes && nodeId.length) {
      // sbu graph
      let sbu = extractGraphData('sbuUsage');
      if (sbu?.length) {
        setUsageData(sbu);
        setUsageGraphLoading(false);
      }
      // accum graph
      let accum = extractGraphData('accumulationUsage');
      if (accum?.length) {
        setAccumulationData(accum);
        setAccumulationGraphLoading(false);
      }
    }

  }, [nodes, graphCurrentOption]);


  // FETCH GRAPHS DATA
  useEffect(() => {
    if (!cardsLoading) {
      // fetch usage here but not the generaldata of node[nodeid].children as it exists already
      // store in redux
      // craft and feed in above useEffect
      fetchGraphsData(
        setUsageGraphLoading,
        setAccumulationGraphLoading,
        graphCurrentOption,
        setHasFetchGraphs,
        nodes,
        nodeId,
        updateNode,
        setGraphsOptions
      );
    }

  }, [nodes, graphCurrentOption, cardsLoading]);


  useEffect(() => {
    const {
      mergedNodesData,
      timePeriodUsageHeaders
    } = extractTableUsageData('usage-per-month');

    if (mergedNodesData?.length) {
      setMonthsTableData(mergedNodesData);
      setMonthsTableLoading(false);
    }

    if (timePeriodUsageHeaders?.length) {
      let fullHeaders = ['Node', 'Allocation', ...timePeriodUsageHeaders];
      setMonthsTableHeaders(fullHeaders);
    }

  }, [nodes]);

  // FETCH MONTH TABLE DATA
  useEffect(() => {
    if (hasFetchedGraphs) {
      fetchMonthTableChildren(
        nodeChildren,
        setMonthsTableLoading,
        nodes,
        nodeId,
        updateNode
      );
    }
  }, [hasFetchedGraphs]);


  useEffect(() => {
    const {
      mergedNodesData,
      timePeriodUsageHeaders
    } = extractTableUsageData('usage-per-quarter');

    if (mergedNodesData?.length) {
      setQuartersTableData(mergedNodesData);
      setQuartersTableLoading(false);
    }
    if (timePeriodUsageHeaders?.length) {
      let fullHeaders = ['Node', 'Allocation', ...timePeriodUsageHeaders];
      setQuartersTableHeaders(fullHeaders);
    }

  }, [nodes]);

  // FETCH QUARTERS TABLE DATA
  useEffect(() => {
    if (!monthsTableIsLoading) {
      fetchQuartersTableChildren(
        nodeChildren,
        setQuartersTableLoading,
        nodes,
        nodeId,
        updateNode
      );
    }
  }, [monthsTableIsLoading]);


  useEffect(() => {
    const {
      mergedNodesData,
      timePeriodUsageHeaders
    } = extractTableUsageData('usage-per-year');

    if (mergedNodesData?.length) {
      setYearsTableData(mergedNodesData);
      setYearsTableLoading(false);
    }
    if (timePeriodUsageHeaders?.length) {
      let fullHeaders = ['Node', ...timePeriodUsageHeaders];
      setYearsTableHeaders(fullHeaders);
    }

  }, [nodes]);


  // FETCH YEARS TABLE DATA
  useEffect(() => {
    if (!quartersTableIsLoading) {
      fetchYearsTableChildren(
        nodeChildren,
        setYearsTableLoading,
        nodes,
        nodeId,
        updateNode
      );
    }
  }, [quartersTableIsLoading]);


  // this is all about consuming data
  const extractTableUsageData = (
    timePeriod: 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year'
  ): {
    mergedNodesData: NodeUsageType[],
    timePeriodUsageHeaders: string[];
  } => {
    if (nodes && nodeId.length) {
      const siblingNodesWithSameParent = checkForChildren(nodes, nodeId);
      if (siblingNodesWithSameParent.length) {
        // if nodes exist AND they do not have the selected table timePeriod
        const existingNodes = siblingNodesWithSameParent?.map(s => {
          let tableUsage = s?.tableUsage;
          let hasOptionValue = Object.prototype.hasOwnProperty.call(tableUsage, timePeriod);
          return hasOptionValue;
        });

        const nodesHaveDataForOption = existingNodes.every(Boolean);

        // if they exist and HAVE data for timePeriod
        if (nodesHaveDataForOption) {

          const {
            mergedNodesData,
            timePeriodUsageHeaders
          } = craftMonthsQuartersYearsData(siblingNodesWithSameParent, timePeriod);
          return {
            mergedNodesData,
            timePeriodUsageHeaders
          };
        }
      }
      return {
        mergedNodesData: [],
        timePeriodUsageHeaders: []
      };
    }
    return {
      mergedNodesData: [],
      timePeriodUsageHeaders: []
    };
  };


  const extractGraphData = (graph: 'sbuUsage' | 'accumulationUsage') => {
    if (nodes) {
      let siblingNodesWithSameParent = Object.values(nodes).filter(node => {
        return node.parent?.id === nodeId;
      }).filter(n => n);

      if (siblingNodesWithSameParent.length) {
        // if nodes exist AND they do not have the selected graphoption.value in sbuUsage + acumUsage..
        const existingNodes = siblingNodesWithSameParent?.map(s => {
          let graphUsage = s?.graphsUsage[graph];
          let hasOptionValue = Object.prototype.hasOwnProperty.call(graphUsage, graphCurrentOption.value);
          return hasOptionValue;
        });
        const nodesHaveDataForOption = existingNodes.every(Boolean);

        if (nodesHaveDataForOption) {
          let graphData = siblingNodesWithSameParent.map(node => {
            return node?.graphsUsage[graph][graphCurrentOption.value];
          });
          const extractedFormat: ReducedDataType[] = extractGraphFormatData([], graphData);
          return extractedFormat;
        }
      }
      return [];
    }
    return [];
  };


  const handleGraphCurrentOption = (option: GraphOption): void => {
    setGraphCurrentOption(option);
  };

  const renderMonthsTable = (): React.ReactElement | undefined => {
    if (monthsTableIsLoading) {
      return <TableLoader />;
    }

    if (!monthsTableIsLoading && !monthsTableHeaders && !monthsTableData) {
      return;
    }

    return (
      <>
        <Typography as="p" margin="0 0 1em 0" fontSize={22}>SBU usage per month</Typography>
        <Table
          showExpandButton
          timePeriod={'usage-per-month'}
          headers={monthsTableHeaders}
          rowsData={monthsTableData}
          tableVariant={NODE_DETAIL_VIEW_MONTHS}
          transferSearchTerm={(option: SearchOption) => handleMonthsTableSearch(option, setMonthsTableSearchResult)}
          searchTermResult={monthsTableSearchResult}

        />
      </>
    );
  };

  const renderQuartersTable = (): React.ReactElement | undefined => {
    if (quartersTableIsLoading) {
      return <TableLoader />;
    }

    if (!quartersTableIsLoading && !quartersTableHeaders && !quartersTableData) {
      return;
    }

    return (
      <>
        <Typography as="p" margin="1em 0 1em 0" fontSize={22}>SBU usage per quarter</Typography>
        <Table
          showExpandButton
          timePeriod={'usage-per-quarter'}
          headers={quartersTableHeaders}
          rowsData={quartersTableData}
          tableVariant={NODE_DETAIL_VIEW_QUARTERS}
          transferSearchTerm={(option: SearchOption) => handleQuartersTableSearch(option, setQuartersTableSearchResult)}
          searchTermResult={quartersTableSearchResult}

        />
      </>
    );
  };

  const renderYearsTable = (): React.ReactElement | undefined => {
    if (yearsTableIsLoading) {
      return <TableLoader />;
    }

    if (!yearsTableIsLoading && !yearsTableHeaders && !yearsTableData) {
      return;
    }

    return (
      <>
        <Typography as="p" margin="1em 0 1em 0" fontSize={22}>SBU usage per year</Typography>
        <Table
          showExpandButton
          timePeriod={'usage-per-year'}
          headers={yearsTableHeaders}
          rowsData={yearsTableData}
          tableVariant={NODE_DETAIL_VIEW_YEARS}
          transferSearchTerm={(option: SearchOption) => handleYearsTableSearch(option, setYearsTableSearchResult)}
          searchTermResult={yearsTableSearchResult}

        />
      </>
    );
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(directURL);
    toast.success('Direct URL coppied to clipboard.', { id: directURL });
  };

  return (
    <Container margin='0' style={{ height: '95vh', overflowY: 'scroll' }}>
      {/* TOP SECTION */}
      <Section margin='0 0 1.85em 0' flex>
        <div>
          <Typography as="h1" margin="1em 0 0 0">{memberName || nodeName}</Typography>
          <Typography as="h3" margin="0" fontSize={22}>Details for node</Typography>
          <Typography as='p' margin='0'>{nodeId}{nodeDescription ? ` - ${nodeDescription}` : null}</Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <CloseIcon onClick={() => onRequestClose()} width="50px" height="50px" />
          <Button
            content='Copy direct URL'
            variant='gray'
            onClick={() => handleCopyToClipboard()}
            style={{
              width: '8em',
              innerHeight: '2em',
              padding: '0',
              alignSelf: 'flex-start'
            }}
          />
        </div>
      </Section>
      {cardsLoading ? <CardsLoader /> : (
        <Cards
          allocationText="Allocation"
          usageText="SBU used"
          remainingText="SBU unallocated"
          accountData={{
            sbus_allocation: formatNumber(parseInt(cardsData.sbus_allocation)),
            sbus_usage: formatNumber(parseInt(cardsData.sbus_usage)),
            sbus_remaining: formatNumber(parseInt(cardsData.sbus_remaining)),
            nb_members: cardsData.nb_members
          }}
          variant="Members"
        />
      )}

      <hr />
      {cardsLoading ? <CardsLoader /> : (
        <div style={{
          height: 100,
          margin: '2em 0em 2em 0em',
          background: '#fff',
          borderRadius: '0.25em',
          padding: '1em 0 2em 0'
        }}>

          <Typography as="p" margin="0" textAlign="center">SBU allocation</Typography>
          <AllocationsBarChart
            data={[{
              name: 'Total',
              allocated: parseInt(cardsData.sbus_allocated),
              unallocated: parseInt(cardsData.sbus_remaining),
              max: parseInt(cardsData.sbus_allocation),
              allocatedPct: cardsData.sbus_allocated_percentage,
              unallocatedPct: cardsData.sbus_remaining_percentage
            }]}
            maxAllocation={parseInt(cardsData.sbus_allocated)}
          />
        </div>
      )}

      <hr />
      {/* USAGE GRAPHS */}
      <Section margin="0 0 2em 0">
        <DualGraphs
          options={graphsOptions}
          handleGraphCurrentOption={(option: GraphOption) => handleGraphCurrentOption(option)}
          graphCurrentOption={graphCurrentOption}
          setTwoColumnGraphs={() => setTwoColumnGraphs(!twoColumnGraphs)}
          twoColumnGraphs={twoColumnGraphs}
          isUsageGraphLoading={isUsageGraphLoading}
          usageGraphData={usageData}
          isAccumulationGraphLoading={isAccumulationGraphLoading}
          accumulationGraphData={accumulationData}
          tiltYAxis
          sbuThreshold={parseInt(cardsData.sbus_allocation)}
          inNodeView
        />
      </Section>
      <hr />


      {/* SBU USAGE PER MONTH */}
      <Section margin="1.5em 0 1.5em 0">
        {renderMonthsTable()}
      </Section>
      <hr />

      {/* SBU USAGE PER QUARTER */}
      <Section margin="0 0 1.5em 0">
        {renderQuartersTable()}
      </Section>
      <hr />

      {/* SBU USAGE PER PAST YEARS */}
      <Section margin="0 0 2.5em 0">
        {renderYearsTable()}
      </Section>

      {/* TOOLTIP AND TOASTER NOFICATIONS */}
      <ReactTooltip id='graph-csv-download' type='dark'>
        <span>Download graph CSV</span>
      </ReactTooltip>
      {isDirect && <CustomNotification />}
    </Container>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  nodes: CachedNodeType;
} => {
  return {
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(NodeDetails);


