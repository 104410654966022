import React from 'react';
import ReactTooltip from 'react-tooltip';
import { colors } from '../../../globalStyles/theme';
import { StyledRemove } from '../styles';

interface IRemove {
  onClick: Function;
  accessType: 'Admin Access' | 'Read Access'
}

const Remove = ({ onClick, accessType }: IRemove) => {
  return (
    <>
      <StyledRemove onClick={() => onClick()} data-testid="revoke-access" data-tip data-for="revoke-access" width="1.55em" height="1.55em" viewBox="-0.5 -0.5 14 14" style={{ backgroundColor: colors.white.main }}>
        <g>
          <ellipse cx="7" cy="7" rx="6" ry="6" fill={colors.danger.main} stroke="none" pointerEvents="all"/>
          <path d="M 3.4 7 L 10.6 7" fill="none" stroke={colors.white.main} strokeMiterlimit="10" pointerEvents="all"/>
        </g>
      </StyledRemove>
      <ReactTooltip 
        id='revoke-access' 
        type='dark' 
        place="top" 
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>Revoke {accessType === 'Admin Access' ? 'admin' : 'read-only'} access to this user</span>
      </ReactTooltip>
    </>
  );
};

export default Remove;