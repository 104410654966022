export default (state: string = '', { payload, type } : {payload: string, type: string}) => {
  switch (type) {
    case 'SET_UID':
      return payload;

    case 'CLEAR_UID':
      return '';

    default:
      return state;
  }
};