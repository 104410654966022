import { UserRole, SbuAppRoute } from '../../containers/Routes/types';

export const MyAccountsRoute = ({
  path: '/my-accounts',
  linkText: 'My accounts',
  showInSideNav: true,
  policies: ['user'],
  defaultRouteFor: ['user']
}) as SbuAppRoute;

export const NodesAndAccountsRoute = ({
  path: '/nodes-and-accounts',
  linkText: 'Nodes & accounts',
  showInSideNav: true,
  policies: ['computing-representative', 'project-principal-investigator', 'admin'],
  defaultRouteFor: ['computing-representative', 'project-principal-investigator', 'admin']
}) as SbuAppRoute;

export const NodesDetailsRoute = ({
  path: '/node-details',
  linkText: 'Node details',
  showInSideNav: false,
  policies: ['computing-representative', 'project-principal-investigator', 'admin']
}) as SbuAppRoute;

export const AccountDetailsRoute = ({
  path: '/account-details',
  linkText: 'Account details',
  showInSideNav: false,
  policies: ['computing-representative', 'project-principal-investigator', 'admin']
}) as SbuAppRoute;

export const ManageAllocationsRoute = ({
  path: '/manage-allocations',
  linkText: 'Manage allocations',
  showInSideNav: true,
  policies: ['computing-representative', 'admin']
}) as SbuAppRoute;

export const ManageOverallocationsRoute = ({
  path: '/manage-overallocations',
  linkText: 'Manage overallocations',
  showInSideNav: false,
  policies: ['admin']
}) as SbuAppRoute;

export const ManageAccessRoute = ({
  path: '/manage-nodes-access',
  linkText: 'Manage nodes & access',
  showInSideNav: true,
  policies: ['computing-representative', 'admin']
}) as SbuAppRoute;

export const SearchUsersRoute = ({
  path: '/search-users',
  linkText: 'Search users',
  showInSideNav: true,
  policies: ['computing-representative', 'admin']
}) as SbuAppRoute;

export const SearchAccountsRoute = ({
  path: '/search-accounts',
  linkText: 'Search accounts',
  showInSideNav: true,
  policies: ['user', 'project-principal-investigator', 'computing-representative', 'admin']
}) as SbuAppRoute;

export const ChangeLogRoute = ({
  path: '/change-log',
  linkText: 'Change log',
  showInSideNav: true,
  policies: ['computing-representative', 'admin']
}) as SbuAppRoute;

export const ALL_ROUTES = [
  MyAccountsRoute,
  NodesAndAccountsRoute,
  ManageAllocationsRoute,
  ManageAccessRoute,
  ChangeLogRoute,
  SearchUsersRoute,
  SearchAccountsRoute,
  NodesDetailsRoute,
  AccountDetailsRoute
];

export function GetAllowedRoutes(roles: UserRole[]) : SbuAppRoute[] {
  if (!roles || !roles.length) return [];

  return ALL_ROUTES.filter(route => route.policies.filter(pol => roles.includes(pol)).length > 0);
}

export function GetDefaultRouteForRole(role: UserRole): SbuAppRoute | undefined {
  let defaultRoute = ALL_ROUTES.find(routeObj => routeObj.defaultRouteFor?.includes(role));
  return defaultRoute;
}

export const ADMIN_ROUTES:SbuAppRoute[] = ALL_ROUTES.filter(route => route.policies.includes('admin'));
export const COMPREP_ROUTES:SbuAppRoute[] = ALL_ROUTES.filter(route => route.policies.includes('computing-representative'));
export const NON_ADMIN_ROUTES:SbuAppRoute[] = ALL_ROUTES.filter(route => route.policies.includes('user'));