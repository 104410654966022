import { GeneralDataNodeType } from '../../api/types';

export default (
  state: GeneralDataNodeType[] = [], 
  { payload, type } : {payload: GeneralDataNodeType[], type: string}
) => {
  switch (type) {
    case 'SET_INITIAL_NODES':
      return [...state, ...payload];

    case 'CLEAR_INITIAL_NODES':
      return [];

    default:
      return state;
  }
};
