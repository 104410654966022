import styled from 'styled-components';
import { colors } from '../../globalStyles/theme';

const handleVariant = (variant?: string, disabled?: boolean) => {
  if (disabled) {
    return `${colors.grey.tertiary}`;
  } else {
    if (variant === 'gray') return `${colors.grey.quintary}`;
    if (variant === 'blue') return `${colors.blue.main}`;
    if (variant === 'transfer') return `${colors.blue.main}`;
    if (variant === 'change') return `${colors.blue.main}`;
    return `${colors.black.main}`;
  }
};

export const StyledButton = styled.button<{
  disabled?: boolean;
  variant?: 'black' | 'gray' | 'blue' | 'transfer' | 'change' | 'csv';
}>`
  padding: ${({ variant }) => variant === 'transfer' || variant === 'change' || variant === 'csv' ? '0.2em 2em 0.2em 2em' : '1.25em 0em 1.25em 0em'};
  width:   ${({ variant }) => variant === 'transfer' || variant === 'change' || variant === 'csv' ? '8em' : '20em'};
  margin-right: ${({ variant }) => variant === 'transfer' ? '1em' : 0};
  background: ${({ variant, disabled }) => handleVariant(variant, disabled)};
  color: ${colors.white.main};
  border: 1px solid ${({ variant, disabled }) => handleVariant(variant, disabled)};
  border-radius: 0.25em;
  font-size: inherit;
  cursor: ${({ variant, disabled }) => disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    opacity: 0.85;
    cursor: ${({ variant, disabled }) => disabled ? 'not-allowed' : 'pointer'};
  }

  &:active {
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
  }
`;