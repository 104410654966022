
export const FULL_POLICIES = ['user', 'computing-representative', 'admin', 'project-principal-investigator'];
export const NON_ADMIN_POLICIES = ['user', 'project-principal-investigator'];
export const DEFAULT_POLICIES = [...FULL_POLICIES];

export default (state: string[] = [], { payload, type } : {payload: string[], type: string}) => {
  switch (type) {
    case 'UPDATE_POLICIES':
      return payload;

    default:
      return state;
  }
};
