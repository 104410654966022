import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const ExpandText = styled.span`
  cursor: pointer;
  color: dodgerblue;

  &: hover {
    opacity: 0.7;
  }
`;

const ExpandUsersText = ({
  onClick,
  dataTestId
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  dataTestId: 'expand-admin-access' | 'expand-read-access'
}) => {
  return (
    <td>
      <ExpandText
        data-tip
        data-for="expand-text"
        onClick={onClick}
        data-testid={dataTestId} 
      >
        <b style={{ fontWeight: 600 }}>+ Show users</b>
      </ExpandText>
      <ReactTooltip 
        id='expand-text' 
        type='dark' 
        place="top" 
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>
          Reveal all users with 
          {dataTestId === 'expand-read-access' ? ' read-only' : ' admin'} access to this node
        </span>
      </ReactTooltip>
    </td>
  );
};

export default ExpandUsersText;
