import { checkForChildren } from '.';
import { GeneralDataNodeType, ManageNodesAccessNodeType, ManageOverallocationNodeType } from '../../../../../api/types';
import { extractManageOverallocationData } from '../../../../../api/utils';
import { CachedNodeType } from '../../../../../redux/reducers/types';
import { DataType, WithReadAccessOnly } from '../types';

export const renderManageNodesAndAccessData = (
  data: DataType,
  renderReadAccessData: Function,
  renderAdminAccessData: Function,
  renderFirstColumnData: JSX.Element,
  renderDescription: Function
) => {
  return Object.keys(data).map((key: string) => {
    if (key === 'name') {
      return renderFirstColumnData;
    }

    if (key === 'category') {
      return null;
    }

    if (key === 'org_id') {
      return null;
    }

    if (key === 'read_access_users') {
      return renderReadAccessData();
    }

    if (key === 'comprep_access_users') {
      return renderAdminAccessData();
    }

    if (key === 'children') {
      return null;
    }

    if (key === 'description') {
      return renderDescription(data[key], key);
    }

    return <td key={key} data-testid={key}>{data[key as keyof typeof data]}</td>;
  });
};

export const renderManageOverallocationsData = (
  data: DataType,
  renderFirstColumnData: JSX.Element,
  renderOverallocationPct: Function
) => {
  return Object.keys(data).map((key: string) => {
    if (key === 'name') {
      return renderFirstColumnData;
    }

    if (key === 'description') {
      return null;
    }

    if (key === 'org_id') {
      return null;
    }

    if (key === 'category') {
      return null;
    }

    if (key === 'children') {
      return null;
    }

    if (key === 'sbu_overallocation_percentage') {
      return renderOverallocationPct(data[key], key);
    }

    return <td key={key} data-testid={key}>{data[key as keyof typeof data]}</td>;
  });
};


export const renderChangeLogData = (data: { datetime: string, user_id: string, text: string; }) => {
  return (
    <>
      <td key={data.datetime} data-testid="cl-date">{data.datetime}</td>
      <td key={data.user_id} data-testid="cl-uid">{data.user_id}</td>
      <td key={data.text} data-testid="cl-change">{data.text}</td>
    </>
  );

};

export const extractTableOverAllocationData = (
  nodes: CachedNodeType,
  clickedNode: string,
  data: DataType
) => {
  if (nodes && clickedNode.length) {
    const siblingNodesWithSameParent = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent.length) {
      const sanitisedData: ManageOverallocationNodeType[] = extractManageOverallocationData(siblingNodesWithSameParent);
      return sanitisedData;
    }
    return [];
  }
  return [];
};

export const checkForReadOrAdminAccess = (
  accessType: 'adminAccessUsers' | 'readAccessUsers',
  array: GeneralDataNodeType[]
) => {
  // check if siblingNodes have read/admin access
  const existingNodes = array?.map(s => {
    let hasAccessUsers = Object.prototype.hasOwnProperty.call(s, accessType);
    return hasAccessUsers;
  });

  const nodesHaveDataForOption = existingNodes.every(Boolean);
  return nodesHaveDataForOption;
};

export const extractTableAccessData = (
  isAdmin: boolean,
  nodes: CachedNodeType,
  clickedNode: string,
  data: DataType
) => {
  if (nodes && clickedNode.length) {
    const siblingNodesWithSameParent: GeneralDataNodeType[] = checkForChildren(nodes, data);
    if (siblingNodesWithSameParent?.length) {
      const nodesHaveReadAccess = checkForReadOrAdminAccess('readAccessUsers', siblingNodesWithSameParent);
      if (isAdmin) {
        const nodesHaveAdminAccess = checkForReadOrAdminAccess('adminAccessUsers', siblingNodesWithSameParent);
        if (nodesHaveReadAccess && nodesHaveAdminAccess) {
          const tableFormat: ManageNodesAccessNodeType[] = siblingNodesWithSameParent.map(node => {
            return {
              name: node.name,
              org_id: node.id,
              category: node.type,
              description: node.description,
              read_access_users: node.readAccessUsers,
              comprep_access_users: node.adminAccessUsers,
              children: node.children
            } as ManageNodesAccessNodeType;
          });
          return tableFormat;
        }
      }
      if (nodesHaveReadAccess) {
        const tableFormat: WithReadAccessOnly[] = siblingNodesWithSameParent.map(node => {
          return {
            name: node.name,
            org_id: node.id,
            category: node.type,
            description: node.description,
            read_access_users: node.readAccessUsers,
            children: node.children
          } as WithReadAccessOnly;
        });
        return tableFormat;
      }
    }
    return [];
  }
  return [];
};