import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import { colors } from '../../../globalStyles/theme';

const DatePickerWrapper = styled.div`
background: ${colors.white.main};
border-radius: 0.25em !important;
.react-date-picker__calendar--open {
  left: -195px !important;
}

.react-date-picker__wrapper {
  border-radius: 0.25em !important;
  border-color: ${colors.grey.quintary};
  padding: 0.25em;
}

.react-date-picker__button svg {
  stroke: ${colors.blue.main};
}
`;

interface DatePickerProps {
  onChange: Function;
  value: Date;
  maxDate?: Date;
  minDate?: Date;
}

const DatePickerInput = ({ onChange, value, maxDate, minDate }: DatePickerProps) => {
  return (
    <DatePickerWrapper>
      <DatePicker
        onChange={(e) => onChange(e)}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        clearIcon={null}
      />
    </DatePickerWrapper>
  );
};

export default DatePickerInput;