
import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DropdownOption, TProvidedSelect, TStateSelect } from '../Select/types';
import SearchIcon from './SearchIcon';
// import { StyledSelect } from './styles';


interface SelectInputProps {
  options: DropdownOption[];
  toParent: Function;
  currentOption?: DropdownOption;
  type?: 'table' | 'modal' | 'add node modal' | 'graphs';
  isDisabled?: boolean;
  isClearable?: boolean;
  creatable?: boolean;
  placeholder?: string;
}

const searchInputStyles = {
  option: (provided: TProvidedSelect, state: TStateSelect) => ({
    ...provided,
    borderBottom: '1px solid white',
    color: state.isSelected ? 'blue' : 'black',
    cursor: 'pointer',
    padding: 20,
    margin: 'auto'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid grey',
    borderRadius: '0.25em',
    padding: '0.2em 1em 0.2em 1em',
    width: '20em',
    height: '2em',
    background: 'white',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto'
  }),

  singleValue: (provided: TProvidedSelect, state: TStateSelect) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 100ms';
    return { ...provided, opacity, transition };
  },

  dropdownIndicator: (base: TProvidedSelect) => ({
    ...base,
    color: 'black' // Custom colour
  })

};

const creatableInputStyles = {
  option: (provided: TProvidedSelect, state: TStateSelect) => ({
    ...provided,
    borderBottom: '1px solid white',
    color: state.isSelected ? 'blue' : 'black',
    cursor: 'pointer',
    padding: 4,
    margin: 'auto'
  }),

  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid rgba(128,128,128, 0.5)',
    borderRadius: '0.25em',
    padding: '0',
    height: 'max-content',
    background: 'white',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto'
  }),

  singleValue: (provided: TProvidedSelect, state: TStateSelect) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 100ms';
    return { ...provided, opacity, transition };
  },

  dropdownIndicator: (base: TProvidedSelect) => ({
    ...base,
    color: 'black' // Custom colour
  }),

  container: (provided: TProvidedSelect) => ({
    ...provided,
    width: '100%'
  }),

  placeholder: (provided: TProvidedSelect) => ({
    ...provided,
    fontSize: '13px'

  })
};

const SearchDropdown = ({
  options,
  type,
  toParent,
  currentOption,
  isDisabled,
  isClearable = false,
  creatable = false,
  placeholder = 'Type to search table..',
}: SelectInputProps) => {
  const handleOnChange = (option: DropdownOption | null) => {
    toParent(option);
  };

  if (creatable) {
    return (
      <CreatableSelect
        filterOption={createFilter({ ignoreAccents: false })}
        options={options}
        components={{
          DropdownIndicator: () => (
            <div style={{ margin: '5px 0 0 2px' }}>
              <SearchIcon />
            </div>
          )
        }}
        value={currentOption}
        onChange={(option) => handleOnChange(option)}
        styles={creatableInputStyles}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={isClearable}
        menuPlacement='auto'
        formatCreateLabel={(value) => `freetext: ${value}`}
      />
    );
  }

  return (
    <Select
      filterOption={createFilter({ ignoreAccents: false })}
      options={options}
      components={{
        DropdownIndicator: () => (
          <div style={{ margin: '5px 0 0 10px' }}>
            <SearchIcon />
          </div>
        )
      }}
      value={currentOption}
      onChange={(option) => handleOnChange(option)}
      styles={searchInputStyles}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isClearable={isClearable}
      menuPlacement='auto'
    />
  );

};

export default SearchDropdown;