import { GeneralDataNodeType, TreeNode } from '../../api/types';
import {
  AccountOption,
  PieChartDataType,
  UserTableDataType
} from '../../containers/views/HPCUser/types';
import { CachedNodeType, TGraphUsagePayload } from '../reducers/types';

// USER ID
export const setUserId = (payload: string = '') => ({
  type: 'SET_UID',
  payload
});

// USER ACCOUNTS
export const setAccountsOptions = (payload: AccountOption[]) => ({
  type: 'SET_ACCOUNTS_OPTIONS',
  payload
});
export const selectAccountOption = (payload: AccountOption) => ({
  type: 'SET_SELECTED_ACCOUNT_OPTION',
  payload
});
export const setSBUUsages = (payload: TGraphUsagePayload) => ({
  type: 'SET_SBU_USAGES',
  payload
});
export const setAccumUsages = (payload: TGraphUsagePayload) => ({
  type: 'SET_ACCUM_USAGES',
  payload
});
export const setPieUsages = (payload: PieChartDataType) => ({
  type: 'SET_PIE_USAGES',
  payload
});
export const setTableUsages = (payload: UserTableDataType) => ({
  type: 'SET_TABLE_USAGES',
  payload
});
export const clearUserAccounts = (payload: {}) => ({
  type: 'CLEAR_USER_ACCOUNTS',
  payload
});

// INITIAL NODES
// to type
export const setInitialNodes = (payload: GeneralDataNodeType[]) => ({
  type: 'SET_INITIAL_NODES',
  payload
});
export const clearInitialNodes = (payload: []) => ({
  type: 'CLEAR_INITIAL_NODES',
  payload
});

// NODES
export const addNodes = (payload: CachedNodeType) => ({
  type: 'ADD_NODES',
  payload
});
export const clearNodes = (payload: {}) => ({ type: 'CLEAR_NODES', payload });
export const updateNode = (payload: CachedNodeType) => ({
  type: 'UPDATE_NODE',
  payload
}); // to type

// SERVICE
export const switchService = (payload: string = 'hpc') => ({
  type: 'SWITCH_SERVICE',
  payload
});
// POLICIES
export const updatePolicies = (payload: string[] = ['user']) => ({
  type: 'UPDATE_POLICIES',
  payload
});
// TREE
export const setTree = (payload: TreeNode[]) => ({ type: 'SET_TREE', payload });
export const clearTree = (payload: TreeNode[]) => ({
  type: 'CLEAR_TREE',
  payload
});
// CACHES
export const setRefreshCache = (payload: boolean) => ({
  type: 'REFRESH_CACHE',
  payload
});

export const setLoggedIn = (payload: boolean) => ({
  type: 'SET_LOGGED_IN',
  payload
});

export const setDisplayUnit = (payload: string) => ({
  type: 'SET_DISPLAY_UNIT',
  payload
});
