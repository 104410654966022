import toast from 'react-hot-toast';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';


import Animate from '../../../../components/Animate';
import Table from '../../../../components/Table';
import Cards from '../../../../components/Containers/Cards';
import Section from '../../../../components/Containers/Section';
import UsageGraph from '../../../../components/Charts/UsageGraph';
import Typography from '../../../../components/Typography';

import CardsLoader from '../../../../components/Loaders/CardsLoader';
import GraphLoader from '../../../../components/Loaders/GraphLoader';
import TableLoader from '../../../../components/Loaders/TableLoader';

import { ECMWF_ADMIN_SEARCH_ACCOUNTS } from '../../../../utils/constants';
import { CraftedTableData, AccountOption, AccountData } from './types';
import { convertDate, dynamicArraySum, formatNumber, removeSeparator } from '../../../../utils/methods';
import { AccountUsageType, SearchedAccountUsersUsageMetaType } from '../../../../api/types';
import { searchAccountUsersUsage } from '../../../../api/fetch-accounts';
import Button from '../../../../components/Button';
import Download from '../../../../components/Icons/Download';


export const DEFAULT_ACCOUNTDATA: AccountData = {
  usageData: null,
  title: '',
  from: new Date(),
  to: new Date(),
  totalUsage: '',
  nbAccounts: 0,
  avgUsagePerAccount: ''
};


export const renderResults = (
  isResultLoading: boolean, 
  selectedAccount: AccountOption, 
  accountData: AccountData,
  tableData: CraftedTableData[]
): React.ReactElement | string => {
  if (isResultLoading) {
    return (
      <>
        <Section margin='1em 0 1em 0'>
          <CardsLoader/>
        </Section>
        <hr/>
        <Section margin="2em 0 2em 0">
          <GraphLoader/>
        </Section>
        <hr/>
        <Section margin='2em 0 10em 0'>
          <TableLoader/>
        </Section>
      </>
    );
  }

  if (selectedAccount.value === 'search' || accountData?.usageData === null) {
    return '';
  }

  if (accountData && accountData.usageData && !accountData.usageData.length) {
    return <p>No recorded usage between {convertDate(accountData.from)} and {convertDate(accountData.to)}.</p>;
  }

  if (accountData && accountData.usageData && accountData.usageData.length) {
    return (
      <Animate>
        {/* TOP SECTION */}
        <Section margin='1em 0 1em 0'>
          <Typography as="h2" margin="1em 0 1em 0">
            {accountData.title} from {convertDate(accountData.from)} to {convertDate(accountData.to)}
          </Typography>
          <Cards 
            allocationText="Total SBU used"
            usageText="Account users"
            remainingText="Avg usage per user"
            accountData={{
              sbus_allocation: accountData.totalUsage,
              sbus_usage: accountData.nbAccounts.toString(),
              sbus_remaining: accountData.avgUsagePerAccount
            }}
            hideLastCard={true}
          />
        </Section>

        <hr/> 

        <Section margin="2em 0 2em 0">
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button 
              content={(
                <CSVLink 
                  data={accountData.usageData} 
                  filename={'account-usage-graph.csv'}>
                  <Download/>
                </CSVLink>
              )} 
              onClick={() => ''}
              variant="csv"
              disabled={false}
              dataFor='graph-csv-download'
              style={{ width: 'unset', padding: '0.4em 0.5em 0.1em 0.5em' }}
            />
          </div>
          <ReactTooltip id='graph-csv-download' type='dark'>
            <span>Download graph CSV</span>
          </ReactTooltip>
          
          <UsageGraph 
            data={accountData.usageData}
            fetchClickedNode={() => null}
            nodeBeingFetched={false}
          />
        </Section>
        <hr/>
        <Section margin='2em 0 10em 0'>
          <Table
            headers={[
              'User',
              'Total SBU usage for selected time period'
            ]}
            rowsData={tableData}
            tableVariant={ECMWF_ADMIN_SEARCH_ACCOUNTS}
            transferSearchTerm={() => null}
            hideSearch
          />
        </Section>
      </Animate>
    );
  }

  return '';

};

export const triggerSearch = async (
  isResultLoading: boolean,
  setResultLoading: Function,
  selectedAccount: AccountOption,
  valueFrom: Date,
  valueTo: Date,
  setAccountData: Function,
  setTableData: Function
): Promise<void> => {
  if (!isResultLoading) {
    setResultLoading(true);
  }

  const SEARCH_TERM = selectedAccount.value;

  try {
    const searchedAccountUsers: SearchedAccountUsersUsageMetaType | undefined = 
     await searchAccountUsersUsage(SEARCH_TERM, valueFrom, valueTo);

    if (searchedAccountUsers && searchedAccountUsers.sbu_data) {
      const craftedTableDataObject: AccountUsageType = dynamicArraySum(searchedAccountUsers.sbu_data);
      const craftedTableData: CraftedTableData[] = Object.keys(craftedTableDataObject).map((key:string) => {
        return {
          name: key,
          sbu_usage: formatNumber(parseInt(craftedTableDataObject[key]))
        };
      });

      // total used across all of account's accounts
      const totalUsage: number = craftedTableData.reduce((a, { sbu_usage }) => {
        return a + parseInt(removeSeparator(sbu_usage));
      }, 0);
      const nbAccounts: number = craftedTableData.length;
      const avgUsagePerAccount: number = totalUsage / nbAccounts;

      const dataToSet: AccountData = {
        usageData: searchedAccountUsers.sbu_data,
        title: searchedAccountUsers.name,
        from: searchedAccountUsers.variant.from_date,
        to: searchedAccountUsers.variant.to_date,
        totalUsage: formatNumber(totalUsage),
        nbAccounts,
        avgUsagePerAccount: formatNumber(avgUsagePerAccount)
      };

      setAccountData(dataToSet);
      setTableData(craftedTableData);
      setResultLoading(false);

    } else {
      toast.error('Unable to fetch account data');
      setResultLoading(false);
    } 
  } catch (error) {
    console.error('error');
    setResultLoading(false);
  }

};