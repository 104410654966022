export const CHANGE_LOG_HEADERS = ['Date & time', 'User ID', 'Logs'];

export const MANAGE_OVERALLOCATION_HEADERS = [
  'Node',
  'Allocation',
  'Usage',
  'Overallocation (SBU)',
  'Overallocation (%)'
];

export const MANAGE_ALLOCATION_HEADERS = [
  'Node',
  'Allocation',
  'Usage',
  'Unallocated',
  'Transfer'
];

export const pieTableHeaders = (currency: string) => {
  return ['User', `Usage [${currency}]`, 'Usage [%]'];
};
