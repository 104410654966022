import React from 'react';
import { StyledContainer } from './styles';

const Container = ({
  children,
  margin,
  width,
  style
}: {
  children: React.ReactNode;
  margin?: string;
  width?: string;
  style?: Object;
}) => {
  return (
    <StyledContainer
      id='container'
      margin={margin}
      width={width}
      style={style}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
