import { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import toast from 'react-hot-toast';
import Add from '../../../../../Icons/Add';
import Remove from '../../../../../Icons/Remove';
import { GRANT_ACCESS } from '../../../../../../utils/constants';
import { AccessUser, ATDProps } from './types';
import * as S from './styles';
import ModalOverlay from '../../../../../Modals/BaseModal';
import SearchDropdown from '../../../../../Inputs/Search/SearchDropdown';
import { UserOption } from '../../../../../../containers/views/CompRep/SearchUsers/types';

const AccessTableData = ({
  accessUsers,
  accessType,
  onHideUsersClick,
  nodeName,
  nodeId,
  refreshTable = () => '',
  autocompleteData
}: ATDProps) => {
  const [accessUserId, setAccessUser] = useState<string>('');
  const [usersWithAccess, setUsersWithAccess] = useState<AccessUser[]>([]);
  const [isGrantAccessModalOpen, toggleGrantAccessModal] = useState(false);
  const [isRevokeAccessModalOpen, toggleRevokeAccessModal] = useState(false);

  const [uidToRevoke, setUidToRevoke] = useState('');

  useEffect(() => {
    setUsersWithAccess(accessUsers);
  }, [accessUsers]);

  const handleInputChange = (option?: UserOption): void => {
    setAccessUser(option?.value || '');
  };

  const validateEntry = () => {
    return !accessUserId.length ? (
      toast.error('Please enter a user id.')
    ) : toggleGrantAccessModal(true);
  };


  const renderSearchInput = () => {
    return (
      <SearchDropdown
        options={autocompleteData?.users}
        toParent={handleInputChange}
        creatable={true}
        placeholder={GRANT_ACCESS}
        isClearable={true}
      />
    );
  };

  return (
    <S.AccessTableData data-testid="access-data" >
      <S.UserRow>
        {renderSearchInput()}
        <Add
          accessType={accessType}
          data-testid="grant-access"
          onClick={() => validateEntry()}
        />
      </S.UserRow>
      {usersWithAccess.map((u: AccessUser, i: number) => (
        <S.UserRow key={i}>
          <span>
            {u.user_id}
          </span>{' '}
          <Remove
            accessType={accessType}
            data-testid="revoke-access"
            onClick={() => {
              setUidToRevoke(u.user_id);
              toggleRevokeAccessModal(true);
            }}
          />
        </S.UserRow>
      ))}
      <S.HideUsers onClick={onHideUsersClick}>Hide users</S.HideUsers>
      {isGrantAccessModalOpen && <ModalOverlay
        modalName={'Confirm Grant Access'}
        isOpen={isGrantAccessModalOpen}
        accessType={accessType}
        userId={accessUserId}
        nodeName={nodeName}
        nodeId={nodeId}
        onRequestClose={() => toggleGrantAccessModal(!isGrantAccessModalOpen)}
        refreshTable={() => refreshTable()}
      />}
      {isRevokeAccessModalOpen && <ModalOverlay
        modalName={'Confirm Revoke Access'}
        isOpen={isRevokeAccessModalOpen}
        accessType={accessType}
        userId={uidToRevoke}
        nodeName={nodeName}
        nodeId={nodeId}
        onRequestClose={() => toggleRevokeAccessModal(!isRevokeAccessModalOpen)}
        refreshTable={() => refreshTable()}
      />}
    </S.AccessTableData>
  );
};

export default AccessTableData;
