import { Toaster } from 'react-hot-toast';
import { colors } from '../../globalStyles/theme';

const CustomNotification = () => {
  return (
    <Toaster 
      position="bottom-center"
      reverseOrder={false}
      toastOptions={{
        // Define default options
        className: '',
        style: {
          margin: '40px',
          background: `${colors.black.secondary}`,
          color: `${colors.white.main}`,
          zIndex: 9999
        },
        duration: 5000,
        // Default options for specific types
        success: {
          duration: 3000,
          theme: {
            primary: `${colors.green.main}`,
            secondary: `${colors.primary.main}`
          }
        },
        error: {
          duration: 7000
        }
      }}
    />
  );
};

export default CustomNotification;