import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Input from '../../Inputs/Input';
import Button from '../../Button';
import CloseIcon from '../../Icons/Close/CloseIcon';
import Typography from '../../Typography';
import * as S from '../styles';

import ModalOverlay from '../BaseModal';

const AddNode = ({ 
  onRequestClose, 
  memberName,
  ownerNodeId,
  refreshTable
}: {onRequestClose: Function, memberName: string, ownerNodeId: string, refreshTable: Function}) => {
  const [newNodeName, setNewNodeName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [startYear, setStartYear] = useState<string>('');
  const [endYear, setEndYear] = useState<string>('');
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const [nodeType, setNodeType] = useState<string>('node');


  const handleEndYear = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // email alert % validation  
    const onlyNumbersInString = event.target.value.replace(/\D/, '');
    let onlyNumbersFrom2000To9999 = Math.min(9999, Math.max(0, parseInt(onlyNumbersInString)));

    if (isNaN(onlyNumbersFrom2000To9999)) {
      setEndYear('0');
    }
    setEndYear(onlyNumbersFrom2000To9999.toString());
  };

  const handleStartYear = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // email alert % validation  
    const onlyNumbersInString = event.target.value.replace(/\D/, '');
    let onlyNumbersFrom2000To9999 = Math.min(9999, Math.max(0, parseInt(onlyNumbersInString)));

    if (isNaN(onlyNumbersFrom2000To9999)) {
      setStartYear('0');
    }
    setStartYear(onlyNumbersFrom2000To9999.toString());
  };


  const handleNodeNameValidation = () => {

    if (!newNodeName) return;
    
    // only allow alphanumeric characters
    if (!newNodeName.match(/^[0-9a-z]+$/)) {
      toast.error('New name must only consist of alpha numeric characters. Please enter a valid name.');
      setNewNodeName('');
    }
    return;
  };

  const handleDisabled = () => {
    if (!description || !newNodeName || !newNodeName.match(/^[0-9a-z]+$/)) {
      return true;
    } else {
      return false;
    }
  };


  return (
    <>
      <S.ModalContainer variant="EditNodeName" data-testid="add-node" style={{
        fontSize: 'small'
      }}>
        <S.ModalFlexSection>
          <Typography as="h2" margin="0">Create a node or an account</Typography>
          <CloseIcon onClick={() => onRequestClose()} />
        </S.ModalFlexSection>
        <hr/>
        <S.ModalSection>
          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as='p' margin='0'>Type:</Typography>
            </S.TableRowLabel>
            <S.ModalFlexSection style={{ justifyContent: 'center' }}>
              <Typography as='p' margin='0 1em'><label htmlFor='node-radio'>Node</label>
                <input
                  type='radio'
                  value='node'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNodeType(e.target.value)}
                  style={{
                  }}
                  id='node-radio'
                  checked={nodeType === 'node'}
                />
              </Typography>
              <Typography as='p' margin='0 1em'><label htmlFor='account-radio'>Account</label>
                <input
                  type='radio'
                  value='account'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNodeType(e.target.value)}
                  style={{
                  }}
                  id='account-radio'
                  checked={nodeType === 'account'}
                />
              </Typography>
            </S.ModalFlexSection>
          </S.NameChangeSection>
          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as="p" margin="0" textAlign="left">Owner node:</Typography>
            </S.TableRowLabel>
            <S.TableRowValue type="grey" width="100%">
              {memberName}
            </S.TableRowValue>

          </S.NameChangeSection>

          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as="p" margin="0">Name:</Typography>
            </S.TableRowLabel>
            <Input 
              value={newNodeName}
              onKeyDown={() => ''}
              onBlur={() => handleNodeNameValidation()}
              onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => setNewNodeName(e.target.value)} 
              placeholder="Enter a name"
              dataTestId="enter-node-name-input"
              style={{
                width: 'auto'
              }}
            />
          </S.NameChangeSection>

          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as="p" margin="0">Description:</Typography>
            </S.TableRowLabel>
            <Input 
              value={description}
              onKeyDown={() => ''}
              onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)} 
              placeholder="Add a description"
              dataTestId="enter-description-input"
              style={{
                width: 'auto'
              }}
            />
          </S.NameChangeSection>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <S.NameChangeSection>
              <S.TableRowLabel>
                <Typography as="p" margin="0">Start year:</Typography>
              </S.TableRowLabel>
              <Input 
                type="number"
                min={2000}
                max={9999}
                value={startYear}
                onKeyDown={() => ''}
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleStartYear(e)} 
                placeholder="YYYY"
                dataTestId="start-year-input"
                style={{
                  width: '10em'
                }}
              />
            </S.NameChangeSection>

            <S.NameChangeSection>
              <S.TableRowLabel>
                <Typography as="p" margin="0">End year:</Typography>
              </S.TableRowLabel>
              <Input 
                type="number"
                min={2000}
                max={9999}
                value={endYear}
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleEndYear(e)} 
                onKeyDown={() => ''}
                placeholder="YYYY"
                dataTestId="end-year-input"
                style={{ width: '10em' }}
              />
            </S.NameChangeSection>
          </div>
        </S.ModalSection>
        <hr />
        <S.ButtonsContainer style={{ fontSize: 'small' }}>
          <Button content="Cancel" variant={'gray'} onClick={() => onRequestClose()}/> 
          <Button 
            content="Proceed" 
            onClick={() => toggleConfirmModal(!isConfirmModalOpen)}
            disabled={handleDisabled()}/> 
        </S.ButtonsContainer>

        {/* MODAL */}
        {isConfirmModalOpen && <ModalOverlay 
          modalName={'Confirm New Node'}
          isOpen={isConfirmModalOpen}
          onRequestClose={() => toggleConfirmModal(!isConfirmModalOpen)}
          closeParentModal={() => onRequestClose()}
          startYear={startYear}
          endYear={endYear}
          ownerNode={memberName}
          ownerNodeId={ownerNodeId}
          newNodeName={newNodeName}
          description={description}
          refreshTable={() => refreshTable()}
          nodeType={nodeType}
        />}
      </S.ModalContainer>
    </>
  );
};

export default AddNode;