import * as S from './styles';

const Typography = ({
  as, children, margin, fontSize, textAlign
}: { 
  as: 'h1' | 'h2' |'h3' | 'h4' | 'p', 
  children: React.ReactNode, 
  margin: string, 
  fontSize?: number,
  textAlign?: 'left' | 'center' | 'right'
}) => {
  return (
    <>
      {as === 'h1' && (
        <S.H1
          margin={margin}
          fontSize={fontSize}
          textAlign={textAlign}
        >
          {children}
        </S.H1>
      )}
      {as === 'h2' && (
        <S.H2
          margin={margin}
          fontSize={fontSize}
          textAlign={textAlign}
        >
          {children}
        </S.H2>
      )}
      {as === 'h3' && (
        <S.H3
          margin={margin}
          fontSize={fontSize}
          textAlign={textAlign}
        >
          {children}
        </S.H3>
      )}
      {as === 'h4' && (
        <S.H4
          margin={margin}
          fontSize={fontSize}
          textAlign={textAlign}
        >
          {children}
        </S.H4>
      )}
      {as === 'p' && (
        <S.Paragraph
          margin={margin}
          fontSize={fontSize}
          textAlign={textAlign}
        >
          {children}
        </S.Paragraph>
      )}
    </>
  );
};  

export default Typography;