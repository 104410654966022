import { TreeNode } from '../../api/types';

export default (state: TreeNode[] = [], { payload, type } : {payload: [], type: string}) => {
  switch (type) {
    case 'SET_TREE':
      return payload;

    case 'CLEAR_TREE':
      return [];

    default:
      return state;
  }
};