import React, { useState } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';

import { CustomTooltipType } from '../types';
import { CustomizedAxisTick, customTooltip } from '../utils';

import { colorArray } from '../utils';

interface UsageProps {
  userType?: string;
  data?: any;
  graphWidth?: '50%' | '100%';
  fetchClickedNode: Function;
  nodeBeingFetched: boolean;
  tiltYAxis?: boolean;
}

const UsageGraph = ({ 
  tiltYAxis, 
  userType, 
  data, 
  graphWidth, 
  fetchClickedNode, 
  nodeBeingFetched
}: UsageProps) => {

  const [areaIndex, setAreaIndex] = useState('');

  return (
    <ResponsiveContainer width={graphWidth || '100%'} height={350}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: -15,
          bottom: 0
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='time_unit' tick={{ fontSize: 13 }}/>
        <YAxis tick={<CustomizedAxisTick />} />
        <Tooltip content={(e: CustomTooltipType | any) => customTooltip(e, areaIndex)} />
        {data.length ? Object.keys(data[0]).map((key, i) => {
          if (key === 'time_unit') return null;
          if (key === 'type') return null;

          return (
            <Area
              key={key}
              type="linear"
              dataKey={key}
              stackId='1'
              onMouseEnter={() => setAreaIndex(key)}
              onMouseLeave={() => setAreaIndex('')}
              stroke={colorArray[i - 1]}
              fill={colorArray[i - 1]}
              onClick={() => data[0]['type'] === 'node' ? fetchClickedNode(key) : null}
              style={{ 
                cursor: nodeBeingFetched ? 'wait' : data[0]['type'] === 'node' ? 'pointer' : 'not-allowed',
                opacity: nodeBeingFetched ? 0.5 : 1
              }}
              animationDuration={250}
            />
          );
        }) : null}
        {/* {sbuThreshold === 0 ? null : (
          <ReferenceLine 
            y={sbuThreshold} 
            stroke='red' 
            // ifOverflow="visible"
            // alwaysShow
          />
        )} */}

      </AreaChart>
    </ResponsiveContainer>

  );
};

export default UsageGraph;

