import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../Button';
import CloseIcon from '../../../Icons/Close/CloseIcon';
import Typography from '../../../Typography';
import * as S from '../../styles';
import toast from 'react-hot-toast';
import { createNode } from '../../../../api/confirmations';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';
import { updateNode } from '../../../../redux/actions';
import { arrayToObject } from '../../../../utils/methods';

interface ConfirmProps {
  onRequestClose: Function;
  startYear?:string;
  endYear?: string;
  ownerNode?: string;
  newNodeName?: string;
  description?: string;
  ownerNodeId?: string;
  closeParentModal: Function;
  nodes: CachedNodeType;
  updateNode: Function;
  type: string;
}

const ConfirmNewNode = ({ 
  onRequestClose, 
  startYear = new Date().getFullYear().toString(),
  endYear = '9999',
  ownerNode = '',
  newNodeName = '',
  description = '',
  ownerNodeId = '',
  closeParentModal,
  nodes,
  updateNode,
  type
} : ConfirmProps) => {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleClick = async () => {
    setButtonLoading(!isButtonLoading);
    setButtonDisabled(true);
    try {
      const res = await createNode(ownerNodeId, newNodeName, description, startYear, endYear, type);
      if (res) {
        setButtonLoading(false);
        toast.success(`Successfully created ${type} within ${ownerNodeId}. Now redirecting you back.`); 
        setTimeout(() => {
          const appendedNewObjArray = res.data.map(newNode => {
            return {
              [newNode.id]: {
                ...newNode,
                tableUsage: {},
                graphsUsage: { sbuUsage: {}, accumulationUsage: {} },
                adminAccessUsers: [],
                readAccessUsers: []
              },
              [ownerNodeId]: {
                ...nodes[ownerNodeId],
                children: [
                  ...nodes[ownerNodeId].children,
                  {
                    href: newNode.href,
                    id: newNode.id,
                    name: newNode.name,
                    type: newNode.type
                  }
                ]
              }
            };
          });
          const cacheFormatted = arrayToObject(appendedNewObjArray);
          updateNode(cacheFormatted);
          closeParentModal();
        }, 4000);
      } else {
        setButtonLoading(false);
        setButtonDisabled(false);
        toast.error(`Failed to create ${type}. Please try again or contact your administrator.`); 
      }
    } catch (error) {
      console.error(`Error while creating ${type}: `, error);
      toast.error(`Failed to create ${type}. Please try again or contact your administrator.`); 
      setButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <S.ModalContainer data-testid="edit-node-name" style={{ fontSize: 'small' }}>
        <S.ModalFlexSection>
          <Typography as="h2" margin="0">New {type} confirmation</Typography>
          <CloseIcon onClick={() => isButtonDisabled ? null : onRequestClose()} isDisabled={isButtonDisabled}/>
        </S.ModalFlexSection>
        <hr/>
        <S.ModalSection>
          <h2>Summary</h2>
          <p><b>New {type} name: </b>{newNodeName}</p>
          <p><b>Owner node: </b>{ownerNode}</p>
          <p><b>Description: </b>{description}</p>
          <p><b>Start year: </b>{startYear}</p>
          <p><b>End year: </b>{endYear}</p>
        </S.ModalSection>
        <hr />
        <S.ButtonsContainer style={{ fontSize: 'small' }}>
          <Button content="Cancel" variant={'gray'} onClick={() => onRequestClose()} disabled={isButtonDisabled}/> 
          <Button 
            content={`Create ${type}`} 
            onClick={() => handleClick()} 
            isLoading={isButtonLoading}
            disabled={isButtonDisabled}
          /> 
        </S.ButtonsContainer>
      </S.ModalContainer>
    </>
  );
};


const mapStateToProps = (state: GlobalStateType): {
  nodes: CachedNodeType
} => {
  return {
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(ConfirmNewNode);
