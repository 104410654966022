import { client } from './client';
import {  
  SearchedUserAccountsResponseType,
  SearchedUserAccountsUsageMetaType,
  SearchUsersResponseType, 
  SearchUserType
} from './types';

export const fetchAllUsers = async (): Promise<SearchUserType[] | undefined> => {
  try {
    const allUsersResponse: SearchUsersResponseType = await client.get('/users/');

    if (allUsersResponse?.data.length) {
      return allUsersResponse.data;

    } else {
      console.error('No users found.');
      return;
    }

  } catch (error) {
    console.error('Unable to fetch all users: ', error);
    return;
  }
};

export const searchUserAccountsUsage = 
 async (searchTerm: string, from: Date, to: Date): Promise<SearchedUserAccountsUsageMetaType | undefined> => {
   try {
     const response: SearchedUserAccountsResponseType = await client.get(`/users/${searchTerm}/stats/usage-per-day`, { params: {
       'variant_id': 'custom-timespan',
       'from_date': from,
       'to_date': to
     } });

     if (response?.data) {
       return response.data;
     } else {
       return;
     }

   } catch (error) {
     console.error('Error fetching user accounts usage: ', error);
     return;
   }
 };