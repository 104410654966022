import styled from 'styled-components';

const StyledCloseIcon = styled.svg<{
isDisabled: boolean
}>`
  margin: auto 0;
  cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};

  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  
  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.3;
  }
`;

const CloseIcon = ({ onClick, width = '28px', height = '28px', isDisabled = false }: {isDisabled?: boolean; onClick: Function; width?: string; height?: string;}) => {
  return (
    <StyledCloseIcon 
      viewBox="0 0 24 24" 
      fill="black" 
      isDisabled={isDisabled}
      width={width}
      height={height} 
      onClick={() => onClick()}
      data-testid="close-icon"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
    </StyledCloseIcon>
  );
};

export default CloseIcon;