import React from 'react';
import ReactTooltip from 'react-tooltip';
import { colors } from '../../../globalStyles/theme';
import { StyledEdit } from '../styles';

const Edit = ({ onClick }: {onClick: Function}) => {
  return (
    <>
      <StyledEdit 
        onClick={() => onClick()} data-testid="edit" data-tip data-for="edit" width="1.1em" height="1.1em" viewBox="-0.5 -0.5 32 32" 
        style={{ backgroundColor: colors.white.main, position: 'absolute', right: 15, top: 13.5 }}
      >
        <g>
          <rect x="1" y="3.5" width="26.9" height="26.9" fill={colors.white.main} stroke={colors.blue.main} strokeWidth="2" pointerEvents="all"/>
          <path d="M 28.49 1.41 C 28.91 1 29.57 1 29.99 1.41 C 30.4 1.83 30.4 2.49 29.99 2.91 L 11.46 21.43 C 11.05 21.85 10.38 21.85 9.97 21.43 C 9.55 21.02 9.55 20.35 9.97 19.94 Z" fill="#ffffff" stroke="#0080f0" strokeWidth="2" strokeMiterlimit="10" pointerEvents="all"/>
        </g>
      </StyledEdit>
      <ReactTooltip 
        id='edit' 
        type='dark' 
        place="top" 
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>Edit this node's description</span>
      </ReactTooltip>
    </>
  );
};

export default Edit;