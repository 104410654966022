import styled from 'styled-components';

const IconButton = styled.svg`
  cursor: pointer;
    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.4;
    }
`;

export const StyledAdd = styled(IconButton)`
  margin:  0.625em 0px  0.625em 0px;
`;

export const StyledRemove = styled(IconButton)``;

export const StyledEdit = styled(IconButton)``;