import ContentLoader from 'react-content-loader';

const CardsLoader = () => (
  <ContentLoader 
    speed={1}
    width={'100%'}
    height={154}
    viewBox="0 0 100% 154"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="11" rx="5" ry="5" width="470" height="117" /> 
    <rect x="238" y="12" rx="5" ry="5" width="470" height="117" /> 
    <rect x="429" y="12" rx="5" ry="5" width="470" height="117" /> 
    <rect x="698" y="12" rx="5" ry="5" width="470" height="117" />
  </ContentLoader>
);

export default CardsLoader;