import styled from 'styled-components';
import { TProvidedSelect, TStateSelect } from './types';

export const StyledSelect = styled.div`
width: 100%;
height: 100%;
`;

export const customStyles = {
  option: (provided: TProvidedSelect, state: TStateSelect) => ({
    ...provided,
    borderBottom: '1px solid white',
    color: state.isSelected ? 'blue' : 'black',
    cursor: 'pointer',
    padding: 20,
    margin: 'auto'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid grey',
    borderRadius: '0.25em',
    padding: '0.2em 1em 0.2em 1em',
    width: '10em',
    height: '2.5em',
    background: 'white',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto'
  }),
  singleValue: (provided: TProvidedSelect, state: TStateSelect) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 100ms';
    return { ...provided, opacity, transition };
  },
  dropdownIndicator: (base: TProvidedSelect) => ({
    ...base,
    color: 'black' // Custom colour
  })
};

export const modalStyles = {
  option: (provided: TProvidedSelect, state: TStateSelect) => ({
    ...provided,
    borderBottom: '1px solid white',
    color: state.isSelected ? 'blue' : 'black',
    cursor: 'pointer',
    padding: 20,
    margin: 'auto'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid grey',
    borderRadius: '0.25em',
    padding: '0.2em 1em 0.2em 1em',
    height: '2em',
    background: 'white',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto'
  }),
  singleValue: (provided: TProvidedSelect, state: TStateSelect) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 100ms';
    return { ...provided, opacity, transition };
  },
  dropdownIndicator: (base: TProvidedSelect, state: TStateSelect) => ({
    ...base,
    color: state.isDisabled ? 'lightgrey' : 'black' // Custom colour
  })
};

export const AddNodeModalStyles = {
  ...modalStyles,
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid grey',
    borderRadius: '0.25em',
    padding: '0.2em 1em 0.2em 1em',
    background: 'white',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto'
  }),
  dropdownIndicator: (base: TProvidedSelect) => ({
    ...base,
    color: 'black' // Custom colour
  })
};

export const tableStyles = {
  option: (provided: TProvidedSelect, state: TStateSelect) => ({
    ...provided,
    borderBottom: '1px solid white',
    color: state.isSelected ? 'blue' : 'black',
    cursor: 'pointer',
    padding: 10
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid gray',
    borderRadius: '0.25em',
    color: 'black',
    background: 'white',
    display: 'flex',
    cursor: 'pointer'
  }),

  dropdownIndicator: (base: TProvidedSelect) => ({
    ...base,
    color: 'black' // Custom colour
  }),

  singleValue: (provided: TProvidedSelect, state: TStateSelect) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 100ms';
    return { ...provided, opacity, transition };
  }
};