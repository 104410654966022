import { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  ReferenceLine,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';

import { formatNumber } from '../../../utils/methods';
import { colorArray } from '../utils';
import { AccumulationProps, CustomTooltipType } from '../types';
import { customTooltip, CustomizedAxisTick } from '../utils';


const AccumulationGraph = ({ 
  data, 
  graphWidth, 
  fetchClickedNode, 
  nodeBeingFetched, 
  clearedResults,
  clickedNodeIdInSBUGraph,
  sbuThreshold,
  inNodeView,
  inAccountView
}: AccumulationProps) => {

  const [parentThreshold, setParentThreshold] = useState<number>(0);
  const [areaIndex, setAreaIndex] = useState('');


  useEffect(() => {
    if (inNodeView && sbuThreshold && sbuThreshold !== null) {
      setParentThreshold(sbuThreshold);
    }
  }, [sbuThreshold]);

  useEffect(() => {
    // this ensures the right threshold is displayed in accumulation graph 
    // when Area is clicked in SBU Usage graph
    if (clickedNodeIdInSBUGraph && clickedNodeIdInSBUGraph.length && data?.length) {
      setParentThreshold(data[0]['threshold_' + clickedNodeIdInSBUGraph]);
    }

  }, [clickedNodeIdInSBUGraph]);

  const handleAreaClick = (key: string, thresholdForParent: number): void => {
    fetchClickedNode(key); 
    setParentThreshold(thresholdForParent);
  };

  const handleReferenceLine = () : number | undefined => {
    if (clearedResults && inNodeView && sbuThreshold) return sbuThreshold;
    if (clearedResults && !inNodeView) return;
    return parentThreshold;
  };

  const handleReferenceLineLabel = (): string | undefined => {
    if (clearedResults && inNodeView && sbuThreshold) return `Allocation: ${formatNumber(sbuThreshold)} SBU`;
    if (clearedResults && !inNodeView) return;
    return `Allocation: ${formatNumber(parentThreshold)} SBU`;
  };

  return (
    <ResponsiveContainer width={graphWidth || '100%'} height={350}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: -15,
          bottom: 0
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='time_unit' tick={{ fontSize: 13 }}/>
        <YAxis tick={<CustomizedAxisTick />} />
        <Tooltip content={(e: CustomTooltipType | any) => customTooltip(e, areaIndex)} />
        {data.length ? Object.keys(data[0]).map((key, i) => {

          if (key === 'time_unit') return null;
          if (key === 'type') return null;
          // for accounts
          if (key === 'threshold') return null;
          // for nodes
          if (key.startsWith('threshold')) return null;

          return (
            <Area
              key={key}
              type={'linear'}
              dataKey={key}
              stackId='1'
              onMouseEnter={() => setAreaIndex(key)}
              onMouseLeave={() => setAreaIndex('')}
              stroke={colorArray[i - 1]}
              fill={colorArray[i - 1]}
              onClick={() => data[0]['type'] === 'node' ? handleAreaClick(key, parseInt(data[0]['threshold_' + key])) : null}
              style={{ 
                cursor: nodeBeingFetched ? 'wait' : data[0]['type'] === 'node' ? 'pointer' : 'not-allowed',
                opacity: nodeBeingFetched ? 0.5 : 1
              }}
              animationDuration={250}
            />
          );
        }) : null}

        {inAccountView && sbuThreshold !== 0 ? (
          <ReferenceLine 
            y={sbuThreshold}
            stroke='red' 
            label={{ 
              position: 'bottom', 
              value: `Allocation: ${sbuThreshold?.toLocaleString()} SBU`, 
              fill: 'black', 
              fontSize: 12, 
              fontWeight: 'bold'
            }}
            ifOverflow="extendDomain"
          />
        ) : sbuThreshold === 0 || !sbuThreshold ? null : (
          <ReferenceLine 
            y={handleReferenceLine()}
            stroke='red' 
            label={{ 
              position: 'bottom', 
              value: handleReferenceLineLabel(), 
              fill: 'black', 
              fontSize: 12, 
              fontWeight: 'bold'
            }}
            ifOverflow="extendDomain"
          />
        )}

      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AccumulationGraph;
