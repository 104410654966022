import { client } from './client';
import { LogPayloadType, LogResponseType, LogType } from './types';

export const fetchChangeLogs = async (data: LogPayloadType): Promise<LogType[] | undefined> => {
  try {
    const response: LogResponseType = await client.post('/events/', data);
    return response.data;
  } catch (error) {
    console.error('Could not fetch change logs: ', error);
  }
};