import ContentLoader from 'react-content-loader';

const SearchLoader = () => (
  <ContentLoader 
    speed={1}
    width={354}
    height={40}
    viewBox="0 0 354 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="354" height="40" />
  </ContentLoader>
);

export default SearchLoader;