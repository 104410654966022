import React, { useEffect, useState } from 'react';
import CloseIcon from '../../Icons/Close/CloseIcon';
import Typography from '../../Typography';
import * as S from '../styles';
import Input from '../../Inputs/Input';
import Button from '../../Button';
import SearchLoader from '../../Loaders/SearchLoader';
import SearchDropdown from '../../Inputs/Search/SearchDropdown';
import { fetchTransferRecipientNodes } from '../../../api/fetch-nodes';
import { confirmTransfer, previewTransfer } from '../../../api/confirmations';
import { PreviewTransferType } from '../../../api/types';
import toast from 'react-hot-toast';
import { arrayToObject } from '../../../utils/methods';
import { CachedNodeType, GlobalStateType } from '../../../redux/reducers/types';
import { updateNode } from '../../../redux/actions';
import { connect } from 'react-redux';

type SearchOption = {
  value: string;
  label: string;
  id: string;
}

const TransferAllocations = ({
  nodeId,
  nodeName,
  onRequestClose,
  availableForTransfer,
  nodes,
  updateNode
}: {
  nodeId: string,
  nodeName: string,
  onRequestClose: Function,
  availableForTransfer: number,
  nodes: CachedNodeType,
  updateNode: Function
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nodeSearchOptions, setNodeSearchOptions] = useState<SearchOption[]>([]);
  const [targetNode, setTargetNode] = useState<SearchOption>({ label: '', value: '', id: '' });
  const [amount, setAmount] = useState<string>('');
  const [isAmountValid, setAmountValid] = useState<boolean>(true);
  const [previewLoaded, setPreviewLoaded] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<PreviewTransferType>();


  useEffect(() => {
    const fetchAvailableNodes = async (): Promise<void> => {
      const searchableNodes = await fetchTransferRecipientNodes(nodeId);
      setIsLoading(false);
      if (searchableNodes && searchableNodes.length) {
        const searchOptions: SearchOption[] = searchableNodes.map((node) => {
          return { value: node.name, label: node.name, id: node.id };
        });
        setNodeSearchOptions(searchOptions);
      }
    };

    if (!nodeSearchOptions.length) {
      setIsLoading(true);
      fetchAvailableNodes();
    }
  }, [nodeSearchOptions]);



  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value.replace(/\D/, '');
    setAmountValid(
      !isNaN(parseInt(inputValue)) &&
      parseInt(inputValue) <= availableForTransfer &&
      parseInt(inputValue) > 0
    );
    setAmount(inputValue);
  };

  const handleDisabled = () => {
    if (!targetNode.id || !amount || !isAmountValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleProceed = async () => {
    if (!previewLoaded) {
      const previewResponse = await previewTransfer(nodeId, targetNode.id, amount);
      if (previewResponse) {
        setPreviewData(previewResponse);
        setPreviewLoaded(true);
      }
      return;
    }

    const res = await confirmTransfer(nodeId, targetNode.id, amount);
    if (res) {
      toast.success(`Successfully transfered ${amount} from ${nodeName} to ${targetNode.label}.`);

      const updatedNodes = res.data.map(returnedNode => {
        if (nodes[returnedNode.id]) {
          return {
            [returnedNode.id]: {
              ...nodes[returnedNode.id], // maintain existing node's other properties
              sbu_allocation: returnedNode.sbu_allocation, // new value
              sbu_distributed: returnedNode.sbu_distributed,
              sbu_undistributed: returnedNode.sbu_undistributed
            }
          };
        } else {
          return {
            [returnedNode.id]: {
              ...returnedNode,
              tableUsage: {},
              graphsUsage: { sbuUsage: {}, accumulationUsage: {} },
              adminAccessUsers: [],
              readAccessUsers: []
            }
          };
        }
      });
      const cacheFormattedNodes = arrayToObject(updatedNodes);
      updateNode(cacheFormattedNodes);
      onRequestClose();
    }
  };

  const renderDropdown = () => {
    if (isLoading || (isLoading && !nodeSearchOptions.length)) {
      return <SearchLoader />;
    }
    if (nodeSearchOptions.length && !isLoading) {
      return (
        <SearchDropdown
          options={nodeSearchOptions}
          toParent={(option: SearchOption) => setTargetNode(option)}
          currentOption={targetNode}
        />
      );
    }
    if (!nodeSearchOptions.length && !isLoading) {
      return (
        <SearchDropdown
          options={[]}
          toParent={() => ''}
          currentOption={{ value: '', label: 'No nodes available' }}
          isDisabled
        />
      );
    }
  };

  return (
    <>
      <S.ModalContainer style={{ fontSize: 'small' }}>

        <S.ModalFlexSection>
          <Typography as='h2' margin='0'>Transfer allocations</Typography>
          <CloseIcon onClick={() => onRequestClose()} />
        </S.ModalFlexSection>

        <hr />

        <S.ModalSection>
          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as='p' margin='0' textAlign='left'>From node:</Typography>
            </S.TableRowLabel>
            <S.TableRowValue type='grey' width='100%'>{nodeName}</S.TableRowValue>
          </S.NameChangeSection>

          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as='p' margin='0'>To node:</Typography>
            </S.TableRowLabel>
            {renderDropdown()}
          </S.NameChangeSection>

          <S.NameChangeSection>
            <S.TableRowLabel>
              <Typography as='p' margin='0'>Amount (available: {availableForTransfer}):</Typography>
            </S.TableRowLabel>
            <Input
              type='number'
              min={0}
              max={availableForTransfer}
              value={amount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAmount(e)}
              onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
              onKeyDown={() => ''}
              style={{
                backgroundColor: isAmountValid ? 'white' : 'pink',
                width: 'auto'
              }}
            />
          </S.NameChangeSection>
        </S.ModalSection>

        {previewLoaded && (
          <S.ModalSection>
            <hr />
            <S.ModalSectionTitle>Preview:</S.ModalSectionTitle>
            <S.ModalFlexSection>
              <S.ModalSectionTable>
                <S.ModalSectionTableRow>
                  <Typography margin='0' as='p'>Source node: {previewData?.source.name}</Typography>
                  <Typography margin='0' as='p'>New allocation: {previewData?.source.sbu_allocation}</Typography>
                </S.ModalSectionTableRow>
              </S.ModalSectionTable>
              <S.ModalSectionTable>
                <S.ModalSectionTableRow>
                  <Typography margin='0' as='p'>Recipient node: {previewData?.target.name}</Typography>
                  <Typography margin='0' as='p'>New allocation: {previewData?.target.sbu_allocation}</Typography>
                </S.ModalSectionTableRow>
              </S.ModalSectionTable>
            </S.ModalFlexSection>
          </S.ModalSection>
        )}

        <S.ButtonsContainer style={{ fontSize: 'small' }}>
          <Button content="Cancel" variant={'gray'} onClick={() => onRequestClose()} />
          <Button
            content={previewLoaded ? 'Confirm' : 'Preview'}
            onClick={() => handleProceed()}
            disabled={handleDisabled()} />
        </S.ButtonsContainer>
      </S.ModalContainer>
    </>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  nodes: CachedNodeType
} => {
  return {
    nodes: state.nodes
  };
};

export default connect(mapStateToProps, { updateNode })(TransferAllocations);