import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import EditNodeDescription from '../EditNodeDescription';

import AddNode from '../AddNode';
import './styles.css';
import NodeDetails from '../../../containers/views/Details/NodeDetails';
import AccountDetails from '../../../containers/views/Details/AccountDetails';

import ConfirmNewNode from '../ConfirmModals/ConfirmNewNode';
import ConfirmGrantAccess from '../ConfirmModals/ConfirmGrantAcess';
import ConfirmRevokeAccess from '../ConfirmModals/ConfirmRevokeAccess';
import ConfirmDescription from '../ConfirmModals/ConfirmDescription';
import { MainNodesType } from '../../../api/types';
import TransferAllocations from '../TransferAllocations';
import { fetchAccountFromId } from '../../../api/fetch-accounts';
import { fetchNodeDataFromId } from '../../../api/fetch-nodes';
import FallbackPage from '../../../containers/views/FallbackPage';

type ModalName = 'Node Details' | 'Account Details' | 'Transfer' | 'Change' | 'Edit Node Description' | 'Add Node' | 'Edit Overallocation' | 'Expand Table' | 'Confirm Transfer' | 'Confirm Allocation' | 'Confirm Description' | 'Confirm New Node' | 'Confirm Grant Access' | 'Confirm Revoke Access';


interface ModalOverlayProps {
  isOpen: boolean;
  onRequestClose: Function;
  modalName: ModalName;
  monthsTable?: React.ReactNode;
  // Transfer Allocation Modal  
  memberName?: string;
  closeParentModal?: Function;
  // Update Allocation Modal
  nodeName?: string;
  // Confirm Name Modal
  newDescription?: string;
  oldDescription?: string;
  // Confirm Add Node Modal
  startYear?: string;
  endYear?: string;
  ownerNode?: string;
  newNodeName?: string;
  description?: string;
  ownerNodeId?: string;
  nodeChildren?: MainNodesType[];
  nodeId?: string;
  accountId?: string;
  // Manage Nodes & Access
  accessType?: 'Read Access' | 'Admin Access';
  userId?: string;
  refreshTable?: Function;
  availableForTransfer?: string;
  nodeType?: string;
  directNav?: boolean;
}


const ModalOverlay = ({
  isOpen,
  onRequestClose,
  modalName,
  memberName = '',
  monthsTable,
  closeParentModal = () => onRequestClose(),
  nodeName = '',
  newDescription = '',
  oldDescription = '',
  startYear = '2020',
  endYear = '9999',
  ownerNode = '',
  newNodeName = '',
  description = '',
  ownerNodeId = '',
  nodeChildren = [],
  nodeId = '',
  accountId = '',
  accessType,
  userId = '',
  refreshTable = () => '',
  availableForTransfer = '',
  nodeType = '',
  directNav = false
}: ModalOverlayProps
) => {
  const [showFallback, setShowFallback] = useState(false);
  useEffect(() => {
    if (directNav) {
      if (memberName) {
        fetchAccountFromId(memberName).then((res) => {
          if (!res) {
            setShowFallback(true);
            return;
          }
          setShowFallback(false);
        }).catch(() => {
          setShowFallback(true);
        });
        return;
      }

      if (nodeId) {
        fetchNodeDataFromId(nodeId).then((res) => {
          if (!res) {
            setShowFallback(true);
            return;
          }
          setShowFallback(false);
        }).catch(() => {
          setShowFallback(true);
        });
        return;
      }
    }
  }, []);

  const returnModals = (modalName: ModalName) => {
    if (modalName === 'Edit Node Description') {
      return <EditNodeDescription
        onRequestClose={onRequestClose}
        oldDescription={oldDescription || ''}
        nodeId={nodeId}
        refreshTable={() => refreshTable()}
      />;
    }

    if (modalName === 'Add Node') {
      return <AddNode
        onRequestClose={onRequestClose}
        memberName={memberName || 'Member'}
        ownerNodeId={ownerNodeId}
        refreshTable={() => refreshTable()}
      />;
    }

    if (modalName === 'Node Details') {
      return (
        <NodeDetails
          onRequestClose={onRequestClose}
          memberName={memberName}
          nodeChildren={nodeChildren}
          nodeId={nodeId}
        />
      );
    }

    if (modalName === 'Account Details') {
      return (
        <AccountDetails
          onRequestClose={onRequestClose}
          memberName={memberName}
          accountId={accountId}
        />
      );
    }

    if (modalName === 'Expand Table') {
      return monthsTable;
    }


    if (modalName === 'Confirm Description') {
      return (
        <ConfirmDescription
          onRequestClose={onRequestClose}
          newDescription={newDescription}
          oldDescription={oldDescription}
          nodeId={nodeId}
          closeParentModal={() => closeParentModal()}
        />
      );
    }


    if (modalName === 'Confirm New Node') {
      return (
        <ConfirmNewNode
          onRequestClose={onRequestClose}
          closeParentModal={() => closeParentModal()}
          startYear={startYear}
          endYear={endYear}
          ownerNode={ownerNode}
          newNodeName={newNodeName}
          description={description}
          ownerNodeId={ownerNodeId}
          type={nodeType}
        />
      );
    }

    if (modalName === 'Confirm Grant Access') {
      return (
        <ConfirmGrantAccess
          onRequestClose={onRequestClose}
          closeParentModal={() => closeParentModal()}
          nodeName={nodeName}
          nodeId={nodeId}
          userId={userId}
          accessType={accessType || ''}
        />
      );
    }

    if (modalName === 'Confirm Revoke Access') {
      return (
        <ConfirmRevokeAccess
          onRequestClose={onRequestClose}
          closeParentModal={() => closeParentModal()}
          nodeName={nodeName}
          nodeId={nodeId}
          userId={userId}
          accessType={accessType || ''}
        />
      );
    }

    if (modalName === 'Transfer') {
      return (
        <TransferAllocations
          nodeId={nodeId}
          nodeName={memberName}
          onRequestClose={onRequestClose}
          availableForTransfer={parseInt(availableForTransfer)}
        />
      );
    }

    return '';
  };

  if (showFallback) {
    return (<FallbackPage type='custom' msg={`You don't have permission to access ${memberName ? `account ${memberName}` : nodeId ? `node ${nodeId}` : ''}`} returnToAccessible={true} />);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      contentLabel="Modal"
      ariaHideApp={false}
      className={{
        base: 'modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close'
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close'
      }}
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={2000}
    >
      {returnModals(modalName)}
      {/* <button onClick={onRequestClose}>Close</button> */}
    </Modal>
  );
};
export default ModalOverlay;