import styled from 'styled-components';

const StyledPieChartContainer = styled.div`
  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  width: 47.5%;
`;

const PieChartContainer = ({ children }: {children: React.ReactNode;}) => (
  <StyledPieChartContainer>
    {children}
  </StyledPieChartContainer>
);

export default PieChartContainer;