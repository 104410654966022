import styled from 'styled-components';


export const H1 = styled.h1<{
  margin: string;
  fontSize?: number;
  textAlign?: 'left' | 'center' | 'right';
}>`
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  font-size: ${props => props.fontSize ? props.fontSize : '2.5'}em;
  color: rgba(0,0,0,0.8);
  font-weight: 600;
`;

export const H2 = styled.h2<{
  margin: string;
  fontSize?: number;
  textAlign?: 'left' | 'center' | 'right';
}>`
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  font-size: ${props => props.fontSize}px;
  color: rgba(0,0,0,0.8);
  font-weight: 600;
`;

export const H3 = styled.h3<{
  margin: string;
  fontSize?: number;
  textAlign?: 'left' | 'center' | 'right';
}>`
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  font-size: ${props => props.fontSize}px;
  font-weight: 300;
  color: rgba(0,0,0,0.8);
`;

export const H4 = styled.h4<{
  margin: string;
  fontSize?: number;
  textAlign?: 'left' | 'center' | 'right';
}>`
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  font-size: ${props => props.fontSize}px;
  color: rgba(0,0,0,0.8);
`;

export const Paragraph = styled.p<{
  margin: string;
  fontSize?: number;
  textAlign?: 'left' | 'center' | 'right';
}>`
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  font-size: ${props => props.fontSize}px;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`;