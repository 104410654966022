import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '../../../../components/Table';
import Cards from '../../../../components/Containers/Cards';
import Container from '../../../../components/Containers/Container';
import Section from '../../../../components/Containers/Section';
import AllocationsBarChart from '../../../../components/Charts/BarChart';
import Typography from '../../../../components/Typography';
import TableLoader from '../../../../components/Loaders/TableLoader';
import CardsLoader from '../../../../components/Loaders/CardsLoader';
import CustomNotification from '../../../../components/Notification';

import { MANAGE_ALLOCATION_HEADERS } from '../../../../utils/tableHeaders';

import { GeneralDataNodeType, ManageAllocationNodeType } from '../../../../api/types';
import { TableOption } from '../../Admin/ManageNodesAndAccess/types';
import { CardsDataType, DataOverviewNodesType } from './types';

import { formatNumber, isEmpty } from '../../../../utils/methods';
import { COMPREP_MANAGE_ALLOCATIONS } from '../../../../utils/constants';
import { DEFAULT_OVERVIEW, fetchManageAllocationsTableNodes, handleTableSearch } from './utils';
import { CachedNodeType, GlobalStateType } from '../../../../redux/reducers/types';

interface IManageAllocations {
  initialNodes: GeneralDataNodeType[];
  nodes: CachedNodeType;
}

const ManageAllocations = (props: IManageAllocations) => {
  const [tableData, setTableData] = useState<ManageAllocationNodeType[]>([]);
  const [tableSearchResult, setTableSearchResult] = useState<ManageAllocationNodeType[]>([]);
  const [tableIsLoading, setTableLoading] = useState<boolean>(true);
  const [cardsLoading, setCardsLoading] = useState<boolean>(true);
  const [generalNodesData, setGeneralNodesData] = useState<DataOverviewNodesType>(DEFAULT_OVERVIEW);
    
  // USEEFFECT
  useEffect(() => {
    if (props.initialNodes && !isEmpty(props.nodes)) {
      fetchManageAllocationsTableNodes(
        cardsLoading,
        tableIsLoading,
        setTableLoading,
        setCardsLoading,
        setGeneralNodesData,
        setTableData,
        props.initialNodes
      );
    }
  }, [props.initialNodes, props.nodes]);

  useEffect(() => {
    // craft and feed initialnodes to table via setTableData + set them again when they update
    if (props.initialNodes.length && !isEmpty(props.nodes)) {
      const tableFormatted = props.initialNodes.map(initNode => {
        return {
          name: props.nodes[initNode.id].name,
          description: props.nodes[initNode.id].description,
          category: props.nodes[initNode.id].type,
          org_id: props.nodes[initNode.id].id,
          parent_id: props.nodes[initNode.id].parent?.id || null,
          // allocation: formatNumber(parseInt(props.nodes[initNode.id].sbu_allocation)),
          allocation: formatNumber(parseInt(props.nodes[initNode.id].sbu_allocation)),
          sbu_usage: formatNumber(parseInt(props.nodes[initNode.id].sbu_usage)),
          undistributed: formatNumber(parseInt(props.nodes[initNode.id].sbu_undistributed)),
          distributed: formatNumber(parseInt(props.nodes[initNode.id].sbu_undistributed)),
          children: props.nodes[initNode.id].children,
          sbu_available_for_transfer: props.nodes[initNode.id].sbu_available_for_transfer
        };
      });
      setTableData(tableFormatted);
    }
  }, [props.nodes]);


  useEffect(() => {
    if (!props.initialNodes.length) {
      setCardsLoading(true);
      setTableLoading(true);
      setGeneralNodesData(DEFAULT_OVERVIEW);
    }

  }, [props.initialNodes]);


  const renderTable = () => {
    if (tableIsLoading) {
      return <TableLoader/>;
    }

    if (!tableIsLoading && !tableData) {
      return;
    }

    return (
      <>
        <Table
          showExpandButton
          headers={MANAGE_ALLOCATION_HEADERS}
          rowsData={tableData}
          tableVariant={COMPREP_MANAGE_ALLOCATIONS}
          transferSearchTerm={(option: TableOption) => handleTableSearch(option, setTableSearchResult)}
          searchTermResult={tableSearchResult}
        />
      </>
    );
  };

  const cardsData: CardsDataType = {
    sbus_allocation: formatNumber(generalNodesData.totalAllocation),
    sbus_usage: formatNumber(generalNodesData.totalUsage),
    sbus_remaining: formatNumber(generalNodesData.totalUndistributed),
    nb_members: generalNodesData.nbNodes
  };

  return (
    <Container margin='0'>
      {/* TOP SECTION */}
      <Section margin='0 0 1.5em 0' flex>
        <div>
          <Typography as="h1" margin="0.5em 0 0 0">Manage allocations</Typography>
          <Typography as="h3" margin="0" fontSize={22}>Manage & transfer allocations between nodes</Typography>
        </div>
      </Section>
      {cardsLoading ? <CardsLoader/> : (
        <Cards 
          allocationText="Total allocation"
          usageText="Total usage"
          remainingText="Total unallocated"
          accountData={cardsData}
          variant="Members"
        />
      )}

      <hr />

      <Section margin='2em 0 10em 0'>
        {cardsLoading ? <CardsLoader/> : (
          <div style={{ 
            height: 100, 
            margin: '0 0em 2em 0em',
            background: '#fff',
            borderRadius: '0.25em',
            padding: '1em 0 1.5em 0' }}>
            <Typography as="p" margin="0" textAlign="center">SBU allocation</Typography>
            <AllocationsBarChart 
              data={[{ 
                name: 'Total', 
                allocated: generalNodesData.totalDistributed, 
                unallocated: generalNodesData.totalUndistributed, 
                max: generalNodesData.totalAllocation,
                allocatedPct: generalNodesData.totalDistributedPct,
                unallocatedPct: generalNodesData.totalUndistributedPct
              }]} 
              maxAllocation={generalNodesData.totalAllocation}
            />
          </div>
        )}
        <div>
          {renderTable()}
        </div>
      </Section>
      <CustomNotification/>
    </Container>
  );
};

const mapStateToProps = (state: GlobalStateType): {
  initialNodes: GeneralDataNodeType[],
  nodes: CachedNodeType
} => {
  return {
    initialNodes: state.initialNodes,
    nodes: state.nodes
  };
};
export default connect(mapStateToProps, null)(ManageAllocations);

