import ContentLoader from 'react-content-loader';

const PieChartLoader = () => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={394}
    viewBox="0 0 100% 394"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="271" cy="143" r="147" />
  </ContentLoader>
);

export default PieChartLoader;