import ContentLoader from 'react-content-loader';

const GraphLoader = (props: {[key : string]: number}) => (
  <ContentLoader 
    speed={1}
    width={'100%'}
    height={396}
    viewBox="0 0 100% 396"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="-1" y="-21" rx="5" ry="5" width={'100%'} height="522" />
  </ContentLoader>
);

export default GraphLoader;