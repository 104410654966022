import { client } from './client';
import { AccessActionResponse, AccountWithGeneralDataResponse, GeneralDataNodesResponseType, PreviewTransferType, TransferNodesResponse } from './types';

export const confirmGrantReadAccess = async (
  userId: string, 
  nodeId: string
): Promise<string[] | undefined> => {
  try {
    const response: AccessActionResponse = await client.patch(`/nodes/${nodeId}/access/read/`, [{
      id: userId
    }]);

    return response.data;
  } catch (error) {
    console.error('Error while granting read access to node: ', error);
  }
};

export const confirmGrantAdminAccess = async (
  userId: string, 
  nodeId: string
): Promise<string[] | undefined> => {
  try {
    const response: AccessActionResponse = await client.patch(`/nodes/${nodeId}/access/admin/`, [{
      id: userId
    }]);

    return response.data;
  } catch (error) {
    console.error('Error while granting admin access to node: ', error);
  }
};

export const confirmRevokeReadAccess = async (
  userId:string, 
  nodeId:string
): Promise<string[] | undefined> => {
  try {
    const response: AccessActionResponse = await client.delete(`/nodes/${nodeId}/access/read/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error while revoking read access to node: ', error);
  }
};


export const confirmRevokeAdminAccess = async (
  userId: string, 
  nodeId: string
): Promise<string[] | undefined> => {
  try {
    const response = await client.delete(`/nodes/${nodeId}/access/admin/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error while revoking admin access to node: ', error);
  }
};

export const previewTransfer = async (
  nodeId: string,
  recipientNodeId: string,
  amount: string
): Promise<PreviewTransferType | undefined> => {
  try {
    const response: TransferNodesResponse = await client.post(`/nodes/${nodeId}/transferAllocations?recipient_node_id=${recipientNodeId}&amount_sbus=${amount}&preview_result=true`);

    if (response && response.data && response.data.length) {

      const sourceNodePreview = response.data.find(node => node.id === nodeId);
      const targetNodePreview = response.data.find(node => node.id === recipientNodeId);

      if (sourceNodePreview && targetNodePreview) {
        return {
          source: {
            id: sourceNodePreview.id,
            name: sourceNodePreview.name,
            sbu_allocation: sourceNodePreview.sbu_allocation,
            sbu_undistributed: sourceNodePreview.sbu_undistributed,
            sbu_distributed: sourceNodePreview.sbu_distributed
          },
          target: {
            id: targetNodePreview.id,
            name: targetNodePreview.name,
            sbu_allocation: targetNodePreview.sbu_allocation,
            sbu_undistributed: targetNodePreview.sbu_undistributed,
            sbu_distributed: targetNodePreview.sbu_distributed
          }
        };
      }
    }
  } catch (error) {
    console.error('Error while making transfer preview request: ', error);
  }
};

export const confirmTransfer = async (
  nodeId: string,
  recipientNodeId: string,
  amount: string
): Promise<GeneralDataNodesResponseType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.post(
      `/nodes/${nodeId}/transferAllocations?recipient_node_id=${recipientNodeId}&amount_sbus=${amount}`
    );
    return response;
  } catch (error) {
    console.error('Error while making transfer request: ', error);
  }
};

export const setNewAllocation = async (
  nodeId:string,
  nodeNewAllocation:number
): Promise<boolean | undefined> => {
  try {

    const nodeRes: GeneralDataNodesResponseType = await client.patch(`/nodes/${nodeId}`, {
      'sbu_allocation': nodeNewAllocation
    });

    if (nodeRes?.status === 200) {
      return true;
    } else {
      return false;
    }
  
  } catch (error) {
    console.error('Error while setting new allocation: ', error);
  }
};


export const setAccountEmailAlertPercentage = async (
  accountId: string, 
  pct: number
): Promise<AccountWithGeneralDataResponse | undefined> => {
  try {
    const response: AccountWithGeneralDataResponse = await client.patch(`/accounts/${accountId}`, {
      'email_alert_percentage': pct
    });

    return response;
  } catch (error) {
    console.error('Error while setting email alert pct: ', error);
  }
};

export const setNodeEmailAlertPercentage = async (
  nodeId: string, 
  pct: number
): Promise<GeneralDataNodesResponseType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.patch(`/nodes/${nodeId}`, {
      'email_alert_percentage': pct
    });

    return response;
  } catch (error) {
    console.error('Error while setting email alert pct: ', error);
  }
};


export const setNodeOverallocationPercentage = async (
  nodeId: string, 
  overallocationPct: number
): Promise<GeneralDataNodesResponseType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.patch(`/nodes/${nodeId}`, {
      'sbu_overallocation_percentage': overallocationPct
    });

    return response;
  } catch (error) {
    console.error('Error while setting overallocation percentage: ', error);
  }
};

export const setNodeAllocation = async (
  nodeId: string, 
  allocation: number
): Promise<GeneralDataNodesResponseType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.patch(`/nodes/${nodeId}`, {
      'sbu_allocation': allocation
    });
    return response;
  } catch (error) {
    console.error('Error while setting allocation: ', error);
  }
};


export const editNodeDescription = async (
  nodeId: string, 
  newDescription: string
): Promise<GeneralDataNodesResponseType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.patch(`/nodes/${nodeId}`, {
      'description': newDescription
    });

    return response;
  } catch (error) {
    console.error('Error while setting overallocation percentage: ', error);
  }
};


export const createNode = async (
  parentNodeId: string,
  newName: string, 
  nodeDescription: string,
  startYear: string,
  endYear: string,
  type: string
): Promise<GeneralDataNodesResponseType | undefined> => {
  try {
    const response: GeneralDataNodesResponseType = await client.post('/nodes/', {
      'name': newName,
      'type': type,
      'description': nodeDescription,
      'start_year': startYear,
      'end_year': endYear,
      'parent': { 'id': parentNodeId }
    });

    return response;
  } catch (error) {
    console.error('Error while setting overallocation percentage: ', error);
  }
};