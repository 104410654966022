export default (
  state: string = 'SBU',
  { payload, type }: { payload: string; type: string }
) => {
  switch (type) {
    case 'SET_DISPLAY_UNIT': {
      localStorage.setItem('DISPLAY_UNIT', payload);
      return payload;
    }

    default: {
      const fromStorage = localStorage.getItem('DISPLAY_UNIT');
      return fromStorage || state;
    }
  }
};
