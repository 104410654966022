import React from 'react';
import ReactTooltip from 'react-tooltip';
import { colors } from '../../../globalStyles/theme';
import { StyledAdd } from '../styles';

interface IAdd {
  onClick: Function;
  accessType: 'Admin Access' | 'Read Access'
}

const Add = ({ onClick, accessType }: IAdd) => {
  return (
    <>
      <StyledAdd onClick={() => onClick()} data-testid="grant-access" data-tip data-for="grant-access" width="1.7em" height="1.7em" viewBox="-0.5 -0.5 14 14" style={{
        backgroundColor: colors.white.main
      }}>
        <g>
          <ellipse cx="7" cy="7" rx="6" ry="6" fill={colors.success.secondary} stroke="none" pointerEvents="all"/>
          <path d="M 7 3.4 L 7 10.6 M 3.4 7 L 10.6 7" fill="none" stroke={colors.white.main} strokeMiterlimit="10" pointerEvents="all"/>
        </g>
      </StyledAdd>
      <ReactTooltip 
        id='grant-access' 
        type='dark' 
        place="top" 
        effect="solid"
        afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
      >
        <span>Grant {accessType === 'Admin Access' ? 'admin' : 'read-only'} access to a user</span>
      </ReactTooltip>
    </>
  );
};

export default Add;